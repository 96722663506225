export const freeFieldComponent = function (optionType, index) {
  let dom =
        `<div class="flex items-center my-[16px]" id="add_value_${optionType}_${index}" data-index="${index}">
        <div class="sk-form-field flex items-center w-full" data-cy="productName">
            <input type="text" placeholder=" " class="sk-form-field__input w-[99%] ov-input-fld" id="option_value_${optionType}_${index}"
            data-action="keydown->pro-options#punchOptionValue" data-option-type="${optionType}" data-option-value-index="${index}"
            data-delete-id="option_value_delete_${optionType}_${index}">
            <label for="option_value_${optionType}_${index}" class="sk-form-field__label">Add value</label>
            <p class="sk-error-message" id="option_value_${optionType}_${index}_error_message"></p>
        </div>
        <i class="material-icons-outlined cursor-pointer text-black/[0.60] hover:bg-blue/[0.08] hover:rounded-full p-[8px]"
            data-option-type="${optionType}"
            data-value=""
            id="option_value_delete_${optionType}_${index}"
            data-action="click->pro-options#removeOptionValue" data-option-value-dom-id="add_value_${optionType}_${index}">delete</i>
    </div>`;

  return dom;
};
