import {I18n} from '../translation';
import {Controller} from 'stimulus';
import {
  passwordConfirmationValidation,
  passwordValidation,
  passwordVisibility
} from '../services/authentications/password_service';
import {emailValidation} from '../services/authentications/email_service';
import {phoneValidation} from '../services/authentications/phone_service';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {memberIdValidation} from '../services/authentications/member_id_service';
import {validityCount} from '../services/authentications/validation-count_service';
import {AsYouType} from 'libphonenumber-js';
import {countryCode} from '../services/authentications/country-code_service';
import {zipcodeValidation} from '../services/authentications/zipcode_service';
import {streetNameValidation} from '../services/authentications/street-validation_service';
import {validateLength} from '../services/authentications/min-length_service';

export default class extends Controller {
  translate = I18n;
  requiredField = [
    'register_first_name',
    'register_last_name',
    'register_phone',
    'register_city',
    'register_zipcode',
    'register_street_name',
    'register_email',
    'register_password',
    'register_password_confirmation',
    'confirmation_check',
    'register_state'
  ];

  validCount = ['register_phone', 'register_state'];

  completeRegistration() {
    document.getElementById('register_submit_button').disabled =
        JSON.stringify(this.validCount.sort()) !== JSON.stringify(this.requiredField.sort());
  }

  connect() {
    let code = countryCode('Switzerland');
    let _this = this;
    if (JSON.parse(this.element.dataset.params).checked) {
      document.getElementById('member_check_box').click();
    }

    let functionMap = {
      'register_member_id': validateMemberId,
      'register_first_name': validateName,
      'register_last_name': validateName,
      'register_phone': validatePhone,
      'register_email': validateEmail,
      'register_password': validatePassword,
      'register_password_confirmation': validatePasswordConfirmation,
      'register_street_name': validateStreet,
      'register_zipcode': validateZipcode,
      'register_city': validateName
    };

    let inputs = document.querySelectorAll('.registration_field');

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].value.trim()) {
        functionMap[inputs[i].id](inputs[i]);
      }
    }

    let confirm = document.getElementById('confirmation_check');

    let passwords = document.querySelectorAll('.register_password_visibility');

    passwordVisibility(passwords);

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('input', function () {
        functionMap[this.id](this);
        _this.completeRegistration(_this.validCount);
      });
    }

    confirm.addEventListener('click', function () {
      validityCount(this.checked, this.id, _this.validCount);
      _this.completeRegistration(_this.validCount);
    });

    function validateMemberId(input) {
      let memberIdStatus = memberIdValidation(input, `${input.id}_error_message`);
      validityCount(memberIdStatus, input.id, _this.validCount);
    }

    function validateName(input) {
      let freeFieldStatus = freeFieldValidation(input, `${input.id}_error_message`);
      validityCount(freeFieldStatus, input.id, _this.validCount);
    }

    function validateStreet(input) {
      let streetFieldStatus = streetNameValidation(input, `${input.id}_error_message`);
      validityCount(streetFieldStatus, input.id, _this.validCount);
    }

    function validateZipcode(input) {
      let zipcodeStatus = zipcodeValidation(input, `${input.id}_error_message`);
      validityCount(zipcodeStatus, input.id, _this.validCount);
    }

    function validatePhone(input) {
      input.value = new AsYouType(code['short_name']).input(input.value);
      let phoneStatus = phoneValidation(input, code['short_name']);
      validityCount(phoneStatus, input.id, _this.validCount);
    }

    function validateEmail(input) {
      let emailStatus = emailValidation(input, `${input.id}_error_message`);
      validityCount(emailStatus, input.id, _this.validCount);
    }

    function validatePassword(input) {
      let passwordStatus = passwordValidation(input, `${input.id}_error_message`);
      validityCount(passwordStatus, input.id, _this.validCount);
      let passwordConfirmation = document.getElementById('register_password_confirmation');
      validatePasswordConfirmation(passwordConfirmation);
    }

    function validatePasswordConfirmation(input) {
      let password = document.getElementById('register_password').value;
      let passwordConfirmationStatus = passwordConfirmationValidation(input, password, `${input.id}_error_message`);
      validityCount(passwordConfirmationStatus, input.id, _this.validCount);
    }
  }

  member(event) {
    this.validCount = ['register_phone'];
    document.getElementById('register_submit_button').disabled = true;
    let optionalFields = document.querySelectorAll('.member_prefill');

    let allFields = document.querySelectorAll('.registration_field');
    for (let i = 0; i < allFields.length; i++) {
      allFields[i].value = '';
    }

    let errorMessages = document.querySelectorAll('.error_message');
    for (let i = 0; i < errorMessages.length; i++) {
      errorMessages[i].innerHTML = '';
    }

    if (document.getElementById('confirmation_check').checked) {
      document.getElementById('confirmation_check').checked = false;
    }

    if (document.getElementById('member_check_box').checked) {
      document.getElementById('member_checkbox_message').innerHTML = this.translate[window.currentLocale]['uncheck_member'];
      document.getElementById('register_member_information').classList.remove('hidden');
      document.getElementById('normal_registration').classList.add('hidden');
      this.requiredField = [
        'register_member_id',
        'register_phone',
        'register_email',
        'register_password',
        'register_password_confirmation',
        'confirmation_check'
      ];
      document.getElementById('member_id').removeAttribute('hidden');
      loopThroughAndClassList(optionalFields, 'add', 'hidden');

      document.getElementById('user_role_id').value = event.currentTarget.dataset.member;
    } else {
      document.getElementById('member_checkbox_message').innerHTML = this.translate[window.currentLocale]['confirm_member'];

      document.getElementById('register_member_information').classList.add('hidden');
      document.getElementById('normal_registration').classList.remove('hidden');
      this.requiredField = [
        'register_first_name',
        'register_last_name',
        'register_phone',
        'register_city',
        'register_zipcode',
        'register_street_name',
        'register_email',
        'register_password',
        'register_password_confirmation',
        'confirmation_check'
      ];
      document.getElementById('member_id').setAttribute('hidden', true);
      document.getElementById('register_member_id').value = '';
      loopThroughAndClassList(optionalFields, 'remove', 'hidden');
      document.getElementById('user_role_id').value = event.currentTarget.dataset.normal;
    }

    function loopThroughAndClassList(elements, action, className) {
      for (let i = 0; i < elements.length; i++) {
        if (action === 'add') {
          elements[i].classList.add(className);
        } else {
          elements[i].classList.remove(className);
        }
      }
    }
  }

  validateLength(e) {
    const lengthValid = validateLength(e.currentTarget);
    if (!lengthValid) e.stopImmediatePropagation();
  }
}
