import {Controller} from 'stimulus';
import {I18n} from "../translation";

export default class extends Controller {
  orderState(event) {
    let element = document.getElementById('orderslider');
    let orderFilterController = this.application.getControllerForElementAndIdentifier(element, 'order-filter');
    orderFilterController.resetFilterContent(event.currentTarget.dataset.state);
  }

  changeHeader(event){
     let text =  event.currentTarget.dataset.taxonName;
     document.querySelectorAll('.tab-count').forEach(element => {
         element.classList.add('hidden')
     });
     event.currentTarget.innerHTML = `${text} <span class="tab-count text-black/[0.6] text-[14px] ml-[4px]">
            (${event.currentTarget.dataset.productCount})
     </span>`;
  }
}
