import {Controller} from 'stimulus';

export default class extends Controller {
  inputSearchRetailer(event) {
    let query = event.currentTarget.value;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let url = `/admin/retailers/retailer_search?query=${query}`;
      fetch(url, {
        method: 'GET'
      }).then(response => response.text()).then(text => {
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        document.getElementById('retailer-listing').replaceWith(vDom.children[0]);
      });
    }, 500);
  }
}
