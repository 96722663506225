import {Controller} from 'stimulus';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {validityCount} from '../services/authentications/validation-count_service';
import {completeValidation} from '../services/authentications/complete-validation_service';
import {phoneValidation} from '../services/authentications/phone_service';
import {setMessage} from '../services/authentications/base_service';
import {countryCode} from '../services/authentications/country-code_service';
import {AsYouType} from 'libphonenumber-js';
import {zipcodeValidation} from '../services/authentications/zipcode_service';
import {streetNameValidation} from '../services/authentications/street-validation_service';
import {validateLength} from '../services/authentications/min-length_service';

export default class extends Controller {
  connect() {
    let code = countryCode('Switzerland');
    let validCount = ['shipping_phone', 'shipping_state'];
    let numberOfRequiredFields = 7;
    let inputGender = document.querySelectorAll('.gender_value');
    for (let i = 0; i < inputGender.length; i++) {
      inputGender[i].addEventListener('click', function () {
        validateGender(this);
      });
    }
    function validateGender(input) {
      document.getElementById('gender_desktop').value = input.dataset.select;
      document.getElementById('gender_mobile').value = input.dataset.select;
      document.getElementById('hidden_actual_gender').value = input.dataset.value;
    }

    document.getElementById('default_address_check_box').addEventListener('click', function () {
      document.getElementById('default_address_value').value = !!this.checked;
    });

    let functionMap = {
      'shipping_first_name': ValidateName,
      'shipping_last_name': ValidateName,
      'shipping_street_name': ValidateStreet,
      'shipping_zipcode': ValidateZipcode,
      'shipping_city': ValidateName
    };

    let inputs = document.querySelectorAll('.shipping_address_field');

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('input', function () {
        functionMap[this.id](this);
        completeValidation('shipping_address_submit_button', validCount.length !== numberOfRequiredFields);
      });
    }

    function ValidateName(input) {
      let freeFieldStatus = freeFieldValidation(input, `${input.id}_error_message`);
      validityCount(freeFieldStatus, input.id, validCount);
    }

    function ValidateStreet(input) {
      let streetFieldStatus = streetNameValidation(input, `${input.id}_error_message`);
      validityCount(streetFieldStatus, input.id, validCount);
    }

    function ValidateZipcode(input) {
      let zipcodeStatus = zipcodeValidation(input, `${input.id}_error_message`);
      validityCount(zipcodeStatus, input.id, validCount);
    }

    document.getElementById('shipping_phone').addEventListener('input', function () {
      ValidatePhone(this);
    });

    function ValidatePhone(input) {
      input.value = new AsYouType(code['short_name']).input(input.value);
      let phoneStatus = phoneValidation(input, code['short_name']);
      validityCount(phoneStatus, input.id, validCount);
      completeValidation('shipping_address_submit_button', validCount.length !== numberOfRequiredFields);
    }

    // document.getElementById('shipping_apartment').addEventListener('input', function () {
    //   ValidateOptionField(this, 'shipping_apartment_error_message' );
    // });

    function ValidateOptionField(input, errorMessageId) {
      if (input.value && input.value.trim().length < 1) {
        setMessage(errorMessageId, 'Invalid input');
        validityCount(false, input.id, validCount);
      } else {
        setMessage(errorMessageId);
        validityCount(true, input.id, validCount);
      }
      completeValidation('shipping_address_submit_button', validCount.length !== numberOfRequiredFields);
    }
  }

  setData(event){
    document.getElementById(event.currentTarget.dataset.actualData).value = event.currentTarget.value;
  }
  validateLength(e) {
    const lengthValid = validateLength(e.currentTarget);
    if (!lengthValid) e.stopImmediatePropagation();
  }
}
