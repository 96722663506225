import {Controller} from 'stimulus';
import {emailValidation} from '../services/authentications/email_service';
import {validateLength} from '../services/authentications/min-length_service';

export default class extends Controller {
  connect() {
    if (this.element.dataset.params === 'true') {
      document.getElementById('email_edit_button').click();
    }
  }

  validateEmail(e) {
    const input = e.currentTarget;
    let oldEmail = this.element.dataset.old_email;
    let emailStatus = emailValidation(input, `${input.id}_error_message`);
    document.getElementById('edit_email_submit').disabled = !(emailStatus && oldEmail !== input.value);
  }

  validateLength(e) {
    const lengthValid = validateLength(e.currentTarget);
    if (!lengthValid) e.stopImmediatePropagation();
  }
}
