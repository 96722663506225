import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';

export default class extends Controller {

  connect() {
    if(this.element.dataset.item){
      document.getElementById(`subscribe-id-${this.element.dataset.item}`).addEventListener('input', () => {
        this.checkEmailError(this.element.dataset.item);
      });
    }
  }

  checkBoxClicked(event) {
    document.getElementById('cart_item_subscribe_form').hidden = !event.currentTarget.checked;
  }

  subscribeCart(event) {
    if(document.getElementById(`subscribe-id-${event.target.dataset.itemId}`).value){
      const payload = {
        product_id: event.target.dataset.productId,
        variant_id: event.target.dataset.variantId,
        email: document.getElementById(`subscribe-id-${event.target.dataset.itemId}`).value
      };
      let emailRegex = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if(!payload.email.match(emailRegex)){
        popSnackbar('error', I18n[window.currentLocale]['invalid_email']);
        return;
      }
      this.subscribe(payload, event.target.dataset.itemId);
    } else {
      this.checkEmailError(event.target.dataset.itemId);
    }

  }

  checkEmailError(id){
    if (document.getElementById(`subscribe-id-${id}`).value) {
      document.getElementById(`email-error-${id}`).style.display = 'none';
    } else {
      document.getElementById(`email-error-${id}`).style.display = 'block';
    }
  }

  async subscribe(payload, id){
    const response = await fetch('/reminders/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(payload)
    });

    if(response.status == 200){
      document.getElementById(`cart-subscribe-${id}`).style.display = 'none';
      popSnackbar('notice', I18n[window.currentLocale]['successfully_subscribed']);
    } else {
      popSnackbar('error', 'Something went wrong');
    }
  }
  disconnect() {
    document.getElementById(`subscribe-id-${this.element.dataset.item}`)?.removeEventListener( 'input' , () => {
      this.checkEmailError(this.element.dataset.item);
    });
  }
}
