import {Controller} from 'stimulus';
import {SharedApiService} from '../services/api/shared-api_service';

export default class extends Controller {
  filters = {
    internalCategory: [],
    stock: {
      operator: '',
      value: ''
    }
  };

  setCheckboxFilter(event) {
    const dataset = event.currentTarget.dataset;
    event.currentTarget.checked ? this.addArrayFilter(dataset.value, dataset.type)
      : this.removeArrayFilter(dataset.value, dataset.type);
  }

  setOperationFilterOperator(event) {
    const dataset = event.currentTarget.dataset;
    const targetFilterDom = document.getElementById(dataset.targetInputId);
    targetFilterDom.value = event.currentTarget.innerText;
    this.filters[dataset.type].operator = dataset.value;
  }

  setOperationFilterValue(event) {
    const dataset = event.currentTarget.dataset;
    this.filters[dataset.type].value = event.currentTarget.value;
  }

  addArrayFilter(id, field) {
    !this.filters[field].includes(id) ? this.filters[field].push(id) : '';
  }

  removeArrayFilter(id, field) {
    this.filters[field].includes(id) ? this.filters[field].splice(this.filters[field].indexOf(id), 1) : '';
  }

  async showNestedFilters(event) {
    event.preventDefault();
    [...event.currentTarget.closest('ul').querySelectorAll('a')].forEach(element => element.classList.remove('bg-blue/[0.08]'));
    event.currentTarget.classList.add('bg-blue/[0.08]');
    const dataset = event.currentTarget.dataset;
    const url = `/admin/${dataset.type === 'internalCategory' ? 'internal-category-build' : 'options'}/add_child_filter/${dataset.id}?selected_ids=${this.filters[dataset.type]}&data_controller=inventory-filter`;
    new SharedApiService().fetchReplace(url, dataset.targetFrameId).then();
  }

  applyFilter() {
    let element = document.getElementById('inventory_listing_component');
    let orderSort = this.application.getControllerForElementAndIdentifier(element, 'inventory-sort');
    const params = `&filters=${JSON.stringify(this.filters)}&sort_type=${orderSort.sortType || ''}&sort_value=${orderSort.sortValue || ''}&state=${this.inventoryState() || ''}`;
    let url = `/admin/search/filter-inventory?${params}`;
    setTimeout(() => {
      fetch(url).then(response => {
        response.text().then(text => {
          document.getElementById('inventory_listing_container').innerHTML = text;
        });
      });
    },500);
  }

  resetFilterContent() {
    let url = '/admin/filter/filter-content?type=inventory';
    new SharedApiService().fetchReplace(url, 'filter_stock_component').then();
    this.resetFilters();
    this.applyFilter();
  }

  inventoryState() {
    const states = ['all', 'in_stock', 'threshold', 'out_of_stock'];
    for (let i in states) {
      if (window.location.href.includes(states[i])) return states[i];
    }
  }

  resetFilters() {
    this.filters = {
      internalCategory: [],
      stock: {
        operator: '',
        value: ''
      }
    };
  }
}
