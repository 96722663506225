import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    let element   = document.getElementById(this.element.dataset.targetId);
    if(element){
      element.innerHTML = this.element.dataset.value;
    }
  }
}
