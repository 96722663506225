import {Controller} from 'stimulus';
import {constructArrayParams} from '../services/construct_array_params';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';
import {cartPriceAndItemReplaceDom} from '../services/carts/replace_dom';

export default class extends Controller {
  appliedCouponId = [];

  addCoupon(event) {
    event.currentTarget.checked ? this.checkedCoupon(event) : this.uncheckedCoupon(event);
  }

  checkedCoupon(event) {
    this.addCouponId(event.currentTarget.dataset.couponId);
    this.couponApplicable();
  }

  uncheckedCoupon(event) {
    this.removeCouponId(event.currentTarget.dataset.couponId);
    this.couponApplicable();
  }

  addCouponId(id) {
    if (!this.appliedCouponId.includes(id)) {
      this.appliedCouponId.push(id);
    }
  }

  removeCouponId(id) {
    if (this.appliedCouponId.includes(id)) {
      this.appliedCouponId.splice(this.appliedCouponId.indexOf(id), 1);
    }
  }

  couponApplicable() {
    document.getElementById('add_promo_code_button').disabled = this.appliedCouponId.length <= 0;
  }

  inputPromoCode(event) {
    document.getElementById('promo_code_submit').disabled = event.currentTarget.value.length <= 0;
  }

  search(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let url = `/search/filter-promotion-coupon?q=${event.target.value}&cart=${event.target.dataset.cartId}&membership=${JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))}`;
      fetch(url).then(response => {
        response.text().then(text => {
          document.getElementById('promotion-coupon-listing').innerHTML = text;
        });
      });
    }, 500);
  }

  search_two(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let url = `/search/filter-promotion-coupon?q=${event.target.value}&cart=${event.target.dataset.cartId}&membership=${JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))}`;
      fetch(url).then(response => {
        response.text().then(text => {
          this.afterCouponSearch(text);
        });
      });
    }, 500);
  }

  afterCouponSearch(response) {
    let couponCode = document.getElementById('search_coupon_code_two').value;
    document.getElementById('hidden_coupon_id').value = JSON.parse(response).coupon_id;
    if (JSON.parse(response).coupon_id) {
      document.getElementById('promo_code_error_message_two').hidden = true;
      document.getElementById('apply_coupon_button').disabled = false;
    } else {
      if (couponCode) {
        document.getElementById('promo_code_error_message_two').hidden = false;
      } else {
        document.getElementById('promo_code_error_message_two').hidden = true;
      }
      document.getElementById('apply_coupon_button').disabled = true;
    }
  }

  applyCoupon(event) {
    event.preventDefault();
    this.addCouponId(document.getElementById('hidden_coupon_id').value);
    let url = `/promotion/apply?${constructArrayParams(this.appliedCouponId, 'coupons')}`;
    fetch(url).then(response => {
      response.text().then(text => {
        let response = JSON.parse(text);
        cartPriceAndItemReplaceDom(response);
        // eslint-disable-next-line no-undef
        Turbo.visit('/carts', { target: 'payment-means-details' });
        if(document.getElementById('applied-promotion-content')){
          document.getElementById('applied-promotion-content').innerHTML = response.coupon;
        }
        popSnackbar('notice', I18n[window.currentLocale]['promo_applied_successfully']);
      });
    });
  }
  changeIcon() {
    const content = document.getElementById('plus-minus-icon');
    content.innerHTML === 'add' ? content.innerHTML = 'remove' : content.innerHTML = 'add';
  }
}
