import {Controller} from 'stimulus';
import {colorComponent} from '../services/templates/products/options/color-component';
import {freeFieldComponent} from '../services/templates/products/options/free-field-component';
import {optionTypeFrame} from '../services/templates/products/options/option-type-frame';
import {pluck} from '../helpers/custom_methods';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';
import {FormValidationService} from '../services/authentications/form_validation_service';
import {LOCALES} from '../constants/constants';

export default class extends Controller {
  activeLocale = 'en';
  options = ['color', 'size'];
  optionParams = JSON.parse(this.element.dataset.productOptionTypes || '[]');
  LOCALES = ['en', 'de', 'fr'];
  COLOR_OPTION = ['Color', 'color', 'Farbe', 'farbe', 'Couleur', 'couleur'];
  FALLBACK_COLOR = '#000000';
  dataParam = {
    en: {},
    de: {},
    fr: {}
  };
  validatorService;
  firstErrorElement;
  optionsModal = document.getElementById('pcOptionModal');
  colorsPayload = [];
  colorsWithLocale = {
    en: [],
    de: [],
    fr: []
  };

  connect() {
    if (window.location.pathname.includes('edit') && this.element.dataset.hasVariants === 'true') {
      this.LOCALES.forEach(locale => {
        if(JSON.parse(this.element.dataset.optionValues)[locale]) {
          this.dataParam[locale] = JSON.parse(this.element.dataset.optionValues)[locale];
        }
      });

      document.getElementById('list_en_variant_option_name').firstElementChild.click(); // force one option to be selected to overcome the state management issue
      document.getElementById('add-options').click(); // force options display

      Object.keys(this.dataParam).forEach(key => {
        Object.keys(this.dataParam[key]).forEach(ot => {
          this.dataParam[key][ot].forEach(value => {
            let targetDom = document.getElementById(`${key}-option-type-${ot}`);
            let tmpVal = '';

            if (ot === 'Color') {
              tmpVal = this.colorOptionValue(ot);
            } else {
              tmpVal = this.customOptionValue(ot);
            }

            let dom = document.createElement('div');
            dom.innerHTML = tmpVal;
            dom.firstChild.getElementsByTagName('input')[0].value = value;
            dom.firstChild.getElementsByTagName('input')[0].disabled = true;
            targetDom.firstElementChild.insertAdjacentElement('afterend', dom.firstChild);
          });
        });
      });
    }
  }

  accessValidatorService() {
    if (!this.validatorService)
      this.validatorService = new FormValidationService([document.getElementById('variantOptions')]);
  }

  switchLocale(event) {
    let scope = event.currentTarget.dataset.scope;
    let localeChips = document.querySelectorAll(`.locale-chips-${scope}`);
    let previousLocale = this.activeLocale;
    this.activeLocale = event.currentTarget.dataset.locale;

    localeChips.forEach(chip => {
      if (chip.dataset.locale !== this.activeLocale) {
        chip.classList.remove('sk-language-active', 'active-locale');
        chip.classList.add('sk-language-inactive');
      }
    });

    event.currentTarget.classList.add('sk-language-active', 'active-locale');

    this.changeContent(previousLocale);
  }

  optionSelected(event) {
    this.currentOptionType = event.target.dataset.value;
    if (event.target.dataset.value == 'Color') {
      ['en', 'de', 'fr'].forEach(res => {
        document.getElementById(`${res}-option-type-Color`).classList.add('hidden');
        document.getElementById(`${res}_option_label`).classList.add('hidden');
        document.querySelector(`#${res}-option-type-Color .${res}-button`).classList.add('hidden');
      });
      document.getElementById('color-option-value').classList.remove('hidden');
      document.getElementById('add-option-value-ctrl').classList.add('hidden');
    } else {
      ['en', 'de', 'fr'].forEach(res => {
        document.getElementById(`${res}-option-type-Color`).classList.remove('hidden');
        document.getElementById(`${res}_option_label`).classList.remove('hidden');
        document.querySelector(`#${res}-option-type-Color .${res}-button`).classList.remove('hidden');
      });
      document.getElementById('color-option-value').classList.add('hidden');
      document.getElementById('add-option-value-ctrl').classList.remove('hidden');
    }
    this.accessValidatorService();
    this.validatorService.onInput(event);
    const _this = this;
    this.LOCALES.forEach(locale => {
      document.getElementById(`${locale}_option_values`).classList.remove('hidden');
      if (_this.optionParams.length === 0) _this.optionParams = JSON.parse(event.currentTarget.dataset.metadata || '[]');
      _this.optionParams.forEach(option => {
        if (event.currentTarget.dataset.value !== option) {
          document.getElementById(`${locale}-option-type-${option}`).classList.add('hidden');
        } else {
          document.getElementById(`${locale}_variant_option_name`).value = option;
          this.LOCALES.forEach(locale => {
            document.getElementById(`${locale}-option-type-${option}`).classList.remove('hidden');
          });
        }
      });
    });
  }

  changeContent(previousLocale) {
    document.getElementById(`${previousLocale}-options`).setAttribute('hidden', true);
    document.getElementById(`${this.activeLocale}-options`).removeAttribute('hidden');
  }

  newOptionType(event) {
    if (event.key === 'Shift' && !this.options.includes(event.currentTarget.value.toLowerCase())) {
      this.options.push(event.currentTarget.value.toLowerCase());
      this.optionParams.push(event.currentTarget.value);
      let parentDom = document.getElementById(`list_${event.currentTarget.dataset.targetId}`);
      let childDom = document.createElement('div');
      childDom.innerHTML = `<li class='sk-dropdown__content-list' 
              data-action="click->input-dropdown#selectOption click->pro-options#optionSelected"
              data-target-field="${event.currentTarget.dataset.targetId}" data-value="${event.currentTarget.value}">
            ${event.currentTarget.value}
          </li>`;
      parentDom.appendChild(childDom.children[0]);

      this.LOCALES.forEach(locale => {
        this.dataParam[locale][event.currentTarget.value] = [];
        let valueDom = document.createElement('div');
        valueDom.innerHTML = `<div id="${locale}-option-type-${event.currentTarget.value}" >
            <div class="border border-gray-400 cursor-pointer rounded py-[6px] px-[16px] mx-[24px]"
                id="add-option-value-ctrl"
                data-type="${event.currentTarget.value}"
                data-target-id="${locale}-option-type-${event.currentTarget.value}"
                data-action="click->pro-options#addOptionValue">
                Add value
            </div>
        </div>`;
        document.getElementById(`${locale}_option_values`).appendChild(valueDom.children[0]);
        if (this.activeLocale !== locale) {
          let parentDom = document.getElementById(`list_${locale}_variant_option_name`);
          let childDom = document.createElement('div');
          childDom.innerHTML = `<li class='sk-dropdown__content-list' 
              data-action="click->input-dropdown#selectOption click->pro-options#optionSelected"
              data-target-field="${locale}_variant_option_name" data-value="${event.currentTarget.value}">
            ${event.currentTarget.value}
          </li>`;
          parentDom.appendChild(childDom.children[0]);
          document.getElementById(`${locale}_variant_option_name`).value = event.currentTarget.value;
        }
        this.optionParams.forEach(option => {
          if (event.currentTarget.value !== option) {
            document.getElementById(`${locale}-option-type-${option}`).setAttribute('hidden', true);
          } else {
            document.getElementById(`${locale}-option-type-${option}`).removeAttribute('hidden');
          }
        });
      });
    }
  }

  addOptionValue(event) {
    let optionType = event.currentTarget.dataset.type;

    this.LOCALES.forEach(locale => {
      let targetId = `${locale}-option-type-${optionType}`;
      let dom = this.setOptionTypeElement(optionType, targetId);
      this.appendOptionValueDom(optionType, dom, targetId);
    });
  }

  setOptionTypeElement(optionType) {
    return this.COLOR_OPTION.includes(optionType) ? this.colorOptionValue(optionType) : this.customOptionValue(optionType);
  }

  colorOptionValue(optionType) {
    let randomNumber = crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
    return colorComponent(optionType, randomNumber);
  }

  customOptionValue(optionType) {
    let randomNumber = crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
    return freeFieldComponent(optionType, randomNumber);
  }

  appendOptionValueDom(optionType, dom, targetId) {
    let tmpDom = document.createElement('div');
    tmpDom.innerHTML = dom;
    document.getElementById(targetId).appendChild(tmpDom.firstChild);
  }

  punchOptionValue(event) {
    this.accessValidatorService();
    this.validatorService.onInput(event);
    let optionType = event.currentTarget.dataset.optionType;
    if (event.currentTarget.type === 'color' && this.dataParam[this.activeLocale][optionType]) {
      let optionValueIndex = event.currentTarget.dataset.optionValueIndex;
      let targetColorName = document.getElementById(`add_color_${optionValueIndex}_value_display`).value;
      let position = pluck(this.dataParam[this.activeLocale][optionType], 'name').indexOf(targetColorName);
      this.dataParam[this.activeLocale][optionType][position].hexcode = event.currentTarget.value;
      this.dataParam[this.activeLocale][optionType][position].value = `${targetColorName}, ${event.currentTarget.value}`;

      this.LOCALES.forEach(locale => {
        if (locale !== this.activeLocale) {
          this.dataParam[locale][optionType][position]['hexcode'] = event.currentTarget.value;
          this.dataParam[locale][optionType][position]['value'] = `${this.dataParam[locale][optionType][position].name}, ${event.currentTarget.value}`;

          document.getElementById(`${locale}-option-type-Color`)
            .querySelectorAll('[id$=\'_value\']')[position].value = event.currentTarget.value;
        }
      });
    }

    if (event.key === 'Enter' && event.currentTarget.value) {
      let targetOptionType = this.dataParam[this.activeLocale][optionType];
      if (targetOptionType) {
        if (optionType === 'Color') {
          var optionValueIndex = '';
          let optionValueDoms = document.getElementById(`${this.activeLocale}-option-type-Color`).querySelectorAll('[id$=\'_value_display\']');
          optionValueDoms.forEach(dom => {
            if (dom === event.currentTarget) {
              optionValueIndex = Array.from(optionValueDoms).indexOf(dom);
              return false;
            }
          });

          if (targetOptionType[optionValueIndex]?.name === '') {
            targetOptionType[optionValueIndex].name = event.currentTarget.value;
            targetOptionType[optionValueIndex].value = `${event.currentTarget.value}, ${targetOptionType[optionValueIndex].hexcode}`;
          } else {
            targetOptionType.push({
              name: event.currentTarget.value,
              hexcode: '#000000',
              value: `${event.currentTarget.value}, #000000`
            });

            this.LOCALES.forEach(locale => {
              if (locale !== this.activeLocale) {
                this.dataParam[locale][optionType].push({
                  name: '',
                  hexcode: event.currentTarget?.value || '#000000',
                  // eslint-disable-next-line quotes
                  value: "'', ''"
                });
              }
            });
          }
        } else {
          targetOptionType.push({name: event.currentTarget.value});
        }
      } else {
        if (optionType === 'Color') {
          this.dataParam[this.activeLocale][optionType] = Array(
            {
              name: event.currentTarget.value,
              hexcode: '#000000',
              value: `${event.currentTarget.value}, #000000`
            });

          this.LOCALES.forEach(locale => {
            if (locale !== this.activeLocale) {
              if (!this.dataParam[locale][optionType]) {
                this.dataParam[locale][optionType] = Array({
                  name: '',
                  hexcode: '#000000',
                  // eslint-disable-next-line quotes
                  value: "'', #000000"
                });
              }
            }
          });
        } else {
          this.dataParam[this.activeLocale][optionType] = Array({name: event.currentTarget.value});
        }
      }
      event.currentTarget.disabled = true;
    }
  }

  removeOptionValue(event) {
    let targetOptionValueId = event.currentTarget.dataset.optionValueDomId;
    let optionType = event.currentTarget.dataset.optionType;
    let children = document.getElementById(`${this.activeLocale}-option-type-${optionType}`).children;
    let index = 0;
    let valueIndex = '';
    document.getElementById(`${this.activeLocale}-option-type-${optionType}`).querySelectorAll('[id^=\'option_value_delete_\']').forEach((dom, index) => {
      if (dom.id === event.currentTarget.id) {
        valueIndex = index;
        return false;
      }
    });
    let uniq = event.currentTarget.dataset.index;
    for (let i = 0; i < children.length; i++) {
      if (children[i] === document.getElementById(targetOptionValueId)) {
        index = i;
        break;
      }
    }

    this.LOCALES.forEach(locale => {
      if (locale !== this.activeLocale) {
        let value = document.getElementById(`option_value_delete_${optionType}_${uniq}`)?.dataset?.value;
        if (this.dataParam[locale][optionType]) {
          this.dataParam[locale][optionType].slice(
            this.dataParam[locale][optionType].indexOf(value),
            1
          );
        }
        document.getElementById(`${locale}-option-type-${optionType}`).children[index].remove();
      }
    });

    document.getElementById(targetOptionValueId).remove();
    this.LOCALES.forEach(locale => {
      this.dataParam[locale][optionType]?.splice(valueIndex, 1);
    });
  }

  duplicateAll() {
    try {
      const activeLocale = this.optionsModal.querySelector('.active-locale').dataset.locale;
      let cloneSource = this.dataParam[activeLocale];
      let optionType = document.getElementById('en_variant_option_name').value;
      let fieldValue = document.getElementById(`${activeLocale}-option-type-${optionType}`).querySelectorAll('.ov-input-fld');

      if(cloneSource[optionType] == null) {
        ['en', 'de', 'fr'].forEach(locale => {
          if (locale !== activeLocale) {
            const target = document.getElementById(`${locale}-option-type-${optionType}`).querySelectorAll('.ov-input-fld');
            (this.dataParam[locale][optionType] || []).forEach((value, index) => {
              target[index].value = '';
              target[index].disabled = false;
            });
            this.dataParam[locale][optionType] = '';
          }
        });
      }

      // clone the values
      Object.keys(this.dataParam).forEach(locale => {
        this.dataParam[locale] = structuredClone(cloneSource);
        if (locale !== activeLocale) {
          Object.keys(this.dataParam[locale]).forEach(ot => {
            if (ot != 'Color') {
              const targetFields = document.getElementById(`${locale}-option-type-${ot}`).querySelectorAll('.ov-input-fld');
              fieldValue.forEach((value, index) => {
                targetFields[index].value = value.value;
                targetFields[index].disabled = true;
              });
            }
          });
        }
      });
      popSnackbar('notice', I18n[window.currentLocale]['content_duplication_success_notice']);
      this.removeCopyValidator();
    } catch (err) {
      popSnackbar('error', I18n[window.currentLocale]['content_duplication_success_error']);
    }
  }

  removeVariantOptionsError() {
    const optionsError = document.getElementById('add-option-value-ctrl_error_message');
    if (optionsError) optionsError.innerHTML = '';
  }

  checkVariantOptionAndNext(e) {
    if (!document.getElementById(`${this.activeLocale}_variant_option_name`).value || !this.colorsPayload.length) return;
    const optionDom = document.getElementById(`${this.activeLocale}_option_values`);
    const optionsPresent = !!optionDom.querySelector('input');
    if (optionsPresent || location.pathname.includes('edit') || this.colorsPayload.length) this.validateFormAndGenerateOptions();
    else {
      e.stopImmediatePropagation();
      // TODO: UI Translation
      document.getElementById('add-option-value-ctrl_error_message').innerHTML = 'Please add values for your variant';
    }
  }

  validateFormAndGenerateOptions() {
    this.accessValidatorService();
    const valid = this.validatorService.validateForm();
    this.firstErrorElement = this.validatorService.firstErrorElement;
    valid ? this.generateOptions() : this.validateCopyError();
  }

  validateCopyError() {
    const langSwitcher = this.optionsModal.querySelector('#language-switcher');
    if (!langSwitcher.querySelector('.text-error')) {
      langSwitcher.innerHTML +=
          `<i class="material-icons-outlined cursor-pointer pl-[8px] text-error"  id="language_error_message"
        data-cy="content_copy" data-object="" data-modalid="" title="Data not copied for other locales">info</i>`;
      if (this.activeLocale && !this.firstErrorElement.id.includes(this.activeLocale))
        this.firstErrorElement = langSwitcher.querySelector('#language_error_message');
    }
  }

  removeCopyValidator() {
    const errorInfo = this.optionsModal.querySelector('#language-switcher').querySelector('.text-error');
    if (errorInfo) errorInfo.remove();
  }

  generateOptions() {
    if(!Object.keys(this.dataParam[window.currentLocale]).length && this.colorsPayload.length){
      this.addOption('Color');
    } else if (Object.keys(this.dataParam[window.currentLocale]).length && this.colorsPayload.length) {
      Object.keys(this.dataParam[window.currentLocale]).forEach(optionType => {
        this.addOption(optionType);
      });
      this.addOption('Color');
    } else {
      Object.keys(this.dataParam[window.currentLocale]).forEach(optionType => {
        this.addOption(optionType);
      });
    }
    this.addOption('Color');
  }

  addOption(optionType) {
    let _this = this;
    let parentDom = document.getElementById('option-type-value-dom');
    parentDom.classList?.remove('hidden');
    let tmpDom = document.createElement('div');
    if(optionType == 'Color') {
      if (this.colorsPayload.length > 0) {
        tmpDom.innerHTML = optionTypeFrame(optionType);
      } else {
        tmpDom.innerHTML = optionTypeFrame('');
      }
    } else {
      tmpDom.innerHTML = optionTypeFrame(optionType);
    }

    parentDom.appendChild(tmpDom.firstChild);
    const colors = this.colorsPayload.map(res => {
      return {...res, value: res.name + ', ' + res.hexcode};
    });
    const payload = { ..._this.dataParam[window.currentLocale], Color: colors };
    // const payload = colors.length ?
    //   { ..._this.dataParam[window.currentLocale], Color: colors } : _this.dataParam[window.currentLocale];

    fetch(`/admin/option-values?option_type=${optionType}`, {
      method: 'POST',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(payload)
    }).then(response => response.text())
      .then(html => {
        this.dataParam.en = {...this.dataParam.en, Color: this.colorsWithLocale.en};
        this.dataParam.de = {...this.dataParam.de, Color: this.colorsWithLocale.de};
        this.dataParam.fr = {...this.dataParam.fr, Color: this.colorsWithLocale.fr};
        document.getElementById('pcOptionModal').click();
        // eslint-disable-next-line no-undef
        Turbo.renderStreamMessage(html);
      });
  }

  selectColors(e){
    let payload = JSON.parse(e.target.dataset.color);
    if(e.target.checked){
      this.colorsPayload.push({name: payload.en, hexcode: payload.hex});
      LOCALES.forEach(locale => {
        this.colorsWithLocale[locale].push({name: payload[locale], hexcode: payload.hex, value: payload[locale] + ', ' + payload.hex});
      });
    } else {
      this.colorsPayload.splice(this.colorsPayload.indexOf(payload), 1);
      LOCALES.forEach(locale => {
        this.colorsWithLocale[locale].splice(this.colorsPayload.indexOf(payload), 1);
      });
    }
  }
}
