import {setMessage} from './base_service';
import {I18n} from '../../translation';

export const zipcodeValidation = function (input, id, showError = true) {
  let code = /^\d{4}$/;
  if (input.value.match(code)) {
    setMessage(id);
    return true;
  } else {
    if(showError){
      if (input.value.length >= 20) {
        input.value = input.value.slice(0, 20);
        setMessage(id, `Maximum 20 characters`);
      }else if (input.value.trim()){
        setMessage(id, I18n[window.currentLocale]['invalid_zipcode']);
      }else {
        setMessage(id, I18n[window.currentLocale]['field_required']);
      }
    }
    setTimeout(() => {
      document.getElementById(id).innerText = '';
    }, 3000);
    return false;
  }
};
