import {Controller} from 'stimulus';

export default class extends Controller {

  connect() {
    let modal = document.getElementById(this.element.dataset.modalId);

    let video = document.getElementById(`video-${this.element.dataset.modalId}`);
    modal.addEventListener('hidden.bs.modal', function () {
      if(video.dataset.frame === 'true'){
        let ysrc = video.src;
        let newsrc = ysrc.replace("&autoplay=1", "");
        video.src = newsrc;
      }
      else {
        video.pause();
      }
    });
  }
}