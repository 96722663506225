export const customOptionValuesGroupDom = (optionType) => {
  let dom =
    `<div class="last:mt-[16px]">
       <p class="text-[18px] font-CeraPro_Bold" id="option-selectors-for-${optionType}"></p>
       <div class="flex space-x-[8px] sm:space-x-[16px] lg:space-x-[24px] mt-[16px]" id="preview-product-${optionType}">
       </div>
     </div>`;

  return dom;
};

export const customOptionValueDom = (optionValue) => {
  let dom = `<p class="text-center border border-gray-400 px-[16px] py-[2px]">${optionValue}</p>`;

  return dom;
};

export const colorOptionValueDom = (optionValue) => {
  let dom = `<p class="text-center rounded-full border flex items-center justify-center h-[30px] w-[30px] p-[16px]" 
             style="border-color:${optionValue}; ">
               <span class="rounded-full p-[12px]" style="background:${optionValue};"> </span>
             </p>`;

  return dom;
};

export const previewImage = (imgUrl, id) => {
  let dom = `<div class="aspect-w-4 aspect-h-3 p-[5vw] bg-gray-100 overflow-hidden border border-transparent hover:border-blue"
                data-action="click->preview-product#previewSelected" data-image-id="product_image_${id}">
                <img src="${imgUrl}" width="150" height="200" alt="product_img" class="object-center object-cover" id="product_image_${id}">
             </div>`;

  return dom;
};