export const constructArrayParams = (array, name) => {
  let params = '';
  if (array.length > 0) {
    array.forEach((value) => {
      params = `${params}&${name}[]=${value}`;
    });
  } else {
    params = `${params}&${name}[]`;
  }
  return params;
};
