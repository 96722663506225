class NumberToggleService {

  toggleNumberField(event) {
    const thresholdField = document.getElementById(event.currentTarget.dataset.targetInput);
    if (event.currentTarget.dataset.arrowToggle === 'up' && +thresholdField.max > +thresholdField.value) {
      thresholdField.value++;
    } else if (event.currentTarget.dataset.arrowToggle === 'down' && +thresholdField.value !== 0) {
      thresholdField.value--;
    }
  }

}

export { NumberToggleService };
