import {Controller} from 'stimulus';

export default class extends Controller {
  allTransitElement = document.querySelectorAll('.individual_transit_id');
  allTransitId = [];

  connect() {
    let _this = this;
    for (let i = 0; i < this.allTransitElement.length; i++) {
      this.allTransitElement[i].addEventListener('input', function () {
        _this.afterInput(this);
        _this.updatable();
      });
    }
  }

  afterInput(input) {
    if (input.value.trim()) {
      this.addId(input);
    } else {
      this.removeId(input);
    }
  }

  addId(input) {
    if (!this.allTransitId.includes(input.id)) {
      this.allTransitId.push(input.id);
    }
  }

  removeId(input) {
    if (this.allTransitId.includes(input.id)) {
      this.allTransitId.splice(this.allTransitId.indexOf(input.id), 1);
    }
  }

  updatable() {
    document.getElementById('transit_order_button').disabled = this.allTransitId.length !== this.allTransitElement.length;
  }
}