import {Controller} from 'stimulus';
import Cropper from 'cropperjs';

export default class extends Controller {

  connect() {
    const blobs = {};
    const imageElement = this.element;
    const aspectRatios = [21 / 4, 16 / 9, 3 / 4, 1];
    const mainButton = document.getElementById(this.element.dataset.actionButton);
    const nextButton = document.getElementById(this.element.dataset.nextButton);
    const loader = document.createElement('p');
    loader.className = 'loader mr-[8px] absolute z-10 top-[36%] left-[40%]';
    mainButton.disabled = true;
    if (this.element.dataset.hasOwnProperty('nextButton')) {
      nextButton.disabled = true;
    }
    imageElement.parentElement.classList.add('relative')
    imageElement.parentElement.insertBefore(loader, imageElement);

    const cropAndStore = (aspectRatio) => {
      return new Promise((resolve) => {
        const cropperInstance = new Cropper(imageElement, {
          ready: () => {
            cropperInstance.setAspectRatio(aspectRatio);
            const croppedCanvas = cropperInstance.getCroppedCanvas();
            blobs[aspectRatio] = croppedCanvas.toDataURL();
            cropperInstance.destroy();
            resolve();
          }
        });
      });
    };

    aspectRatios.reduce((promiseChain, aspectRatio) => {
      return promiseChain.then(() => cropAndStore(aspectRatio));
    }, Promise.resolve()).then(() => {
      this.uploadCroppedObj(blobs, imageElement.src).then(r => {
        this.element.dataset.urls = JSON.stringify(r);
        if (this.element.dataset.hasOwnProperty('nextButton')) {
          const scopeId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
          const mainForm = window.location.pathname.includes('new') ? document.getElementById('new_product') : document.getElementById(`edit_product_${scopeId}`);
          const multipleImageUpload = this.application.getControllerForElementAndIdentifier(mainForm, 'multiple-image-upload');
          multipleImageUpload.uploadComplete[imageElement.src] = true;
          const valid = Object.values(multipleImageUpload.uploadComplete).includes(false)
          mainButton.disabled = valid;
          nextButton.disabled = valid;
        }else{
          mainButton.disabled = false;
        }
        imageElement.parentElement.removeChild(loader);
      });
      this.element.dataset.autoCropped = JSON.stringify(blobs);
    });
  }

  async uploadCroppedObj(croppedImageObj, source) {
    const formData = new FormData();
    [21 / 4, 16 / 9, 3 / 4, 1].forEach(key => {
      formData.append(key.toString(), this.dataURIToBlob(croppedImageObj[key]));
    });
    formData.append('original_url', source);

    return fetch('/admin/attachments', {
      method: 'post',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => response.json())
      .then(data => {
        return data;
      });
  }


  dataURIToBlob(dataURI) {
    const parts = dataURI.split(',');
    const mimeType = parts[0].match(/:(.*?);/)[1];
    const byteString = atob(parts[1]);

    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([byteArray], {type: mimeType});
  }
}
