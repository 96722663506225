import {freeFieldValidation} from './authentications/free_field_service';

class VariantService {

  validateVariantForm(form) {
    const requiredFields = ['variant_option_value', 'variant-sku', 'variant_barcode'];
    let valid = true;
    requiredFields.forEach(id => {
      form.querySelectorAll(`input[id*=${id}]`).forEach(field => {
        const input = field;
        const errorElement = form.querySelector(`p[id='${input.id}_error_message']`);
        valid = freeFieldValidation(input, errorElement.id) && valid;
      });
    });

    return valid;
  }

  resetFormValidations(form) {
    const requiredFields = ['variant_option_value', 'variant-sku', 'variant_barcode'];

    requiredFields.forEach(id => {
      form.querySelectorAll(`input[id*=${id}]`).forEach(field => {
        document.getElementById(`${field.id}_error_message`).innerHTML = '';
      });
    });
  }
}

export { VariantService };
