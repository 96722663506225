import {Controller} from 'stimulus';
import {emailValidation} from '../services/authentications/email_service';
import {validateLength} from '../services/authentications/min-length_service';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {streetNameValidation} from '../services/authentications/street-validation_service';
import {zipcodeValidation} from '../services/authentications/zipcode_service';
import {AsYouType} from 'libphonenumber-js';
import {phoneValidation} from '../services/authentications/phone_service';
import {validityCount} from '../services/authentications/validation-count_service';
import {completeValidation} from '../services/authentications/complete-validation_service';
import {countryCode} from '../services/authentications/country-code_service';
import {setMessage} from '../services/authentications/base_service';
import {I18n} from '../translation';
import flatpickr from "flatpickr";
import {passwordValidation} from "../services/authentications/password_service";


export default class extends Controller {
  validity = {
    email: { func: (dom, errorId, showError) => emailValidation(dom, errorId, showError), value: false},
    first_name: { func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    last_name: { func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    street_name: { func: (dom, errorId, showError) => streetNameValidation(dom, errorId, showError), value: false},
    city: { func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    zipcode: { func: (dom, errorId, showError) => zipcodeValidation(dom, errorId, showError), value: false},
    agree_terms: { value: false}
  };
  additionalValidity = {
    first_name: { func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    last_name: { func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    street_name: { func: (dom, errorId, showError) => streetNameValidation(dom, errorId, showError), value: false},
    city: { func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    zipcode: { func: (dom, errorId, showError) => zipcodeValidation(dom, errorId, showError), value: false},
  };
  billingValidity = {
    first_name: { func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    last_name: { func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    street_name: { func: (dom, errorId, showError) => streetNameValidation(dom, errorId, showError), value: false},
    city: { func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false},
    zipcode: { func: (dom, errorId, showError) => zipcodeValidation(dom, errorId, showError), value: false},
  };
  authenticatedValidity = {
    email: false,
    shipping_address: false,
    agree_terms: false
  };
  isAuthenticated = JSON.parse(this.element.dataset.authenticated);
  code = countryCode('Switzerland');
  validCount = ['guest_shipping_phone'];
  phoneRequiredCount = 1;
  additionalAddress = false;
  differentBilling = false;
  registration = false;

  connect() {
    if (this.isAuthenticated) {
      this.authenticatedValidity.email = true;
      let radio = document.getElementById('user_default_address');
      if (radio) {
        const radioId = radio.dataset.id;
        let formDiv = document.getElementById(`div-address-form-${radioId}`);
        if (formDiv) {
          this.authenticatedValidity.shipping_address = true;
        }
      }
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
        this.authenticatedValidity['membership_terms'] = false;
        this.authenticatedValidity['dob'] = false;
        this.authenticatedValidity['gender'] = false;
      }
    }
    if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
      this.addGuestMemberValidations();
    }
    this.switchContinueButton()
    this.dateTimeAddress();
    this.dateTimeUser();
    this.removeEmailInput();
  }

  removeEmailInput(){
    if(document.getElementById('guest_checkout_email')?.value == ''){
      document.getElementById('add-registration-guest').classList.add('hidden');
    }
  }

  reValidate(){
    let guestMemberValidityFields = [
      'checkout_email',
      'guest_shipping_first_name',
      'guest_shipping_last_name',
      'guest_shipping_street_name',
      'guest_address_shipping_zipcode',
      'guest_shipping_city'
    ]
    let validityFields = [
      'guest_checkout_email',
      'guest-shipping_first_name',
      'guest-shipping_last_name',
      'guest-shipping_street_name',
      'guest-shipping_zipcode',
      'guest-shipping_city'
    ]
    let guestMembershipValidityFields = [
        'guest_shipping_gender',
        'address-dob'
    ]
    let userMembershipValidityFields = [
      'user_gender',
      'user-dob'
    ]
    if (this.isAuthenticated) {
      this.authenticatedValidity.email = true;
      let radio = document.getElementById('user_default_address');
      if (radio) {
        const radioId = radio.dataset.id;
        let formDiv = document.getElementById(`div-address-form-${radioId}`);
        if (formDiv) {
          this.authenticatedValidity.shipping_address = true;
        }
      }
      this.authenticatedValidity['agree_terms'] = document.getElementById('agree_terms').checked;
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
        this.authenticatedValidity['membership_terms'] = document.getElementById('membership_terms').checked;
        userMembershipValidityFields.forEach(field => {
          this.validateAuthenticatedPresence(document.getElementById(field), false);
        });
      }else {
        this.authenticatedValidity['membership_terms'] = true;
        this.authenticatedValidity['dob'] = true;
        this.authenticatedValidity['gender'] = true;
      }
      this.authenticatedCheckoutButton();
    } else {
      this.validity['agree_terms'] = {value: document.getElementById('agree_terms').checked};
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
        this.validity['membership_terms'] = { value: document.getElementById('membership_terms').checked};
        guestMemberValidityFields.forEach(field => {
          this.fieldValidation(document.getElementById(field), false);
        });
        guestMembershipValidityFields.forEach(field => {
          this.validateAuthenticatedPresence(document.getElementById(field), false);
        });
        this.validatePassword()
      } else {
        validityFields.forEach(field => {
          this.fieldValidation(document.getElementById(field), false);
        });
        this.validity['password'] = { value: true};
        this.validity['dob'] = { value: true};
        this.validity['gender'] = { value: true};
        this.validity['membership_terms'] = { value: true};
      }
      this.checkButton();
    }
  }

  dateTimeAddress() {
    flatpickr('#address-dob', {
      dateFormat: 'd.m.Y',
      maxDate: new Date().fp_incr(0),
      disableMobile: true
    });
  }
  dateTimeUser() {
    flatpickr('#user-dob', {
      dateFormat: 'd.m.Y',
      maxDate: new Date().fp_incr(0),
      disableMobile: true
    });
  }


  switchContinueButton(){
    let dom= document.getElementById('guest');
    if(dom) {
      if(JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
        dom.innerText = I18n[window.currentLocale]['its_my_first_time'];
      }else{
        dom.innerText = I18n[window.currentLocale]['continue_as_guest'];
      }
    }
  }

  validateAuthenticatedPresence(event, showError= true){
    let dom = event.currentTarget || event
    this.authenticatedValidity[dom.name] = !!dom.value.trim();
    if(this.validity[dom.name]){
      this.validity[dom.name] = { func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: !!dom.value.trim()};
    }
    if(showError){
      setMessage(
          `${dom.id}_error_message`,
          !dom.value.trim() ? I18n[window.currentLocale]['field_required'] : ''
      );
    }
    this.authenticatedCheckoutButton();
  }

  addGuestMemberValidations(){
    this.validity['membership_terms'] = { value: false};
    this.validity['password'] = { value: false};
    this.validity['dob'] = { func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false};
    this.validity['gender'] = { func: (dom, errorId, showError) => freeFieldValidation(dom, errorId, showError), value: false};
  }

  removeMembershipValidation(event){
    if(!this.isAuthenticated){
      if(event.currentTarget.checked) {
        this.addGuestMemberValidations();
      } else {
        this.validity['membership_terms'] = { value: true};
        this.validity['password'] = { value: true};
        this.validity['dob'] = { value: true};
        this.validity['gender'] = { value: true};
      }
      this.checkButton();
    } else {
      this.authenticatedValidity['membership_terms'] = !event.currentTarget.checked;
      this.authenticatedValidity['dob'] = !event.currentTarget.checked;
      this.authenticatedValidity['gender'] = !event.currentTarget.checked;
      this.authenticatedCheckoutButton();
    }
  }

  displayAddressDynamically(event){
    this.registration = JSON.parse(event.currentTarget.dataset.registration)
    if(!this.isAuthenticated){
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
        document.getElementById('normal-address').classList.add('hidden');
        document.getElementById('membership-checked').classList.remove('hidden');
      } else {
        document.getElementById('membership-checked').classList.add('hidden');
        document.getElementById('normal-address').classList.remove('hidden');
      }
    } else {
      document.getElementById('normal-address').classList.remove('hidden');
      document.getElementById('membership-checked').classList.add('hidden');
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
        document.getElementById('membership-additional-field').classList.remove('hidden');
      } else {
        document.getElementById('membership-additional-field').classList.add('hidden');
      }
    }
    this.reValidate();
  }

  togglePasswordVisibility(event) {
    let element = document.getElementById(event.currentTarget.dataset.targetId);
    let typeField = element.type;
    element.type = typeField === 'text' ? 'password' : 'text';
    event.currentTarget.innerHTML = typeField === 'text' ? 'visibility_off' : 'visibility';
  }

  setGender(event){
    let error_id = ''
    if(this.isAuthenticated){
      document.getElementById('user_gender_actual').value = event.currentTarget.dataset.gender;
      document.getElementById('user_gender').value = event.currentTarget.dataset.trGender;
      error_id = 'user_gender'
      this.authenticatedValidity['gender'] = true;
      this.authenticatedCheckoutButton();
    }else{
      document.getElementById('guest_shipping_actual_gender').value = event.currentTarget.dataset.gender;
      document.getElementById('guest_shipping_gender').value = event.currentTarget.dataset.trGender;
      error_id = 'guest_shipping_gender'
      this.validity['gender'] = { value: true};
      this.checkButton();
    }
    if (document.getElementById(`${error_id}_error_message`)){
      setMessage(
        `${error_id}_error_message`,
        !document.getElementById(error_id).value.trim() ? I18n[window.currentLocale]['field_required'] : ''
      );
    }
  }

  fieldValidation(event, showError = true) {
    this.validateLength(event);
    const dom = event.currentTarget || event;
    if(this.additionalAddress && dom.id.includes('guest_additional')){
      const type = Object.keys(this.additionalValidity).find((key) => dom.id.includes(key));
      this.additionalValidity[type].value = this.additionalValidity[type].func(dom, `${dom.id}_error_message`, showError);
      this.checkAdditional();
    } else if (this.differentBilling && dom.id.includes('guest_billing')) {
      const type = Object.keys(this.billingValidity).find((key) => dom.id.includes(key));
      this.billingValidity[type].value = this.billingValidity[type].func(dom, `${dom.id}_error_message`, showError);
      this.checkBilling();
    } else {
      if (this.isAuthenticated) {
        let radio = document.getElementById('user_default_address');
        if (radio) {
          const radioId = radio.dataset.id || dom.id;
          let formDiv = document.getElementById(`div-address-form-${radioId}`);
          if (formDiv) {
            this.authenticatedValidity.shipping_address = true;
          }
        }
        this.authenticatedCheckoutButton();
      } else {
        const type = Object.keys(this.validity).find((key) => dom.id.includes(key));
        if(this.validity[type]){
          this.validity[type].value = this.validity[type].func(dom, `${dom.id}_error_message`, showError);
        }
        this.checkButton();
      }
    }
  }

  checkTerms(event) {
    if (this.isAuthenticated) {
      this.authenticatedValidity.agree_terms = event.currentTarget.checked;
      this.authenticatedCheckoutButton();
    } else {
      this.validity.agree_terms = {value: event.currentTarget.checked};
      this.checkButton();
    }
  }

  showBillingAddress(event){
    this.differentBilling = event.currentTarget.checked;
    if(event.currentTarget.checked) {
      this.checkBilling();
      document.getElementById('billing-address').removeAttribute('hidden')
      if (!this.validCount.includes('guest_billing_phone')) {
        this.validCount.push('guest_billing_phone');
      }
      this.phoneRequiredCount += 1;
    }
    else{
      document.getElementById('billing-address').setAttribute('hidden', true)
      this.validity['billing'] = { value: true };
      this.checkButton();
      if (this.validCount.includes('guest_billing_phone')) {
        this.validCount.splice(this.validCount.indexOf('guest_billing_phone'), 1);
      }
      this.phoneRequiredCount -= 1;
    }
  }

  validatePassword(){
    let valid = false;
    let validPassword = false;
    let password;
    let confirmPassword;
    if(window.location.search.includes('register=true') && !(JSON.parse(sessionStorage.getItem('purchaseMembershipOn')))){
      password = document.getElementById('guestPassword')
      confirmPassword = document.getElementById('passwordConfirm')
    }else {
      password = document.getElementById('password')
      confirmPassword = document.getElementById('confirm-password')
    }
    if (password){
      validPassword = passwordValidation(password,`${password.id}_error_message`);
    }
    if (password.value && confirmPassword.value){
      valid = password.value === confirmPassword.value;
    }
    this.validity['password'] = {value: valid && validPassword};
    if(password.value && confirmPassword.value){
      if(valid){
        setMessage(`${confirmPassword.id}_error_message`, '');
      } else{
        setMessage(`${confirmPassword.id}_error_message`, I18n[window.currentLocale]['password_should_match']);
      }
    }
    this.checkButton();
  }

  checkMembershipTerms(event) {
    if (this.isAuthenticated) {
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
        this.authenticatedValidity['membership_terms'] = event.currentTarget.checked;
      }
      this.authenticatedCheckoutButton();
    } else {
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) {
        this.validity.membership_terms = {value: event.currentTarget.checked};
      }
      this.checkButton();
    }
  }

  selectPaymentMethod(event) {
    this.validity.payment_method = event.currentTarget.checked;
    this.checkButton();
  }

  ValidatePhone(event) {
    this.validateLength(event);
    const input = event.currentTarget;
    input.value = new AsYouType(this.code['short_name']).input(input.value);
    let phoneStatus = phoneValidation(input, this.code['short_name']);
    validityCount(phoneStatus, input.id, this.validCount);
    completeValidation('checkout_button', this.validCount.length !== this.phoneRequiredCount);
    if(this.validCount.length === this.phoneRequiredCount){
      if(this.isAuthenticated) {
        this.authenticatedCheckoutButton();
      } else {
        this.checkButton();
      }
    }
  }

  validateLength(e) {
    let lengthValid;
    if(e.currentTarget){
      lengthValid = validateLength(e.currentTarget);
    }else{
      lengthValid = validateLength(e);
    }
    if (!lengthValid) e.stopImmediatePropagation();
  }

  additionAddress(event){
    if(event.currentTarget.checked) {
      document.getElementById('additional-address').classList.remove('hidden');
      this.checkAdditional();
      if (!this.validCount.includes('guest_additional_phone')) {
        this.validCount.push('guest_additional_phone');
      }
      this.phoneRequiredCount += 1;
    }
    else{
      document.getElementById('additional-address').classList.add('hidden');
      this.validity['additional'] = { value: true };
      this.checkButton();
      if (this.validCount.includes('guest_additional_phone')) {
        this.validCount.splice(this.validCount.indexOf('guest_additional_phone'), 1);
      }
      this.phoneRequiredCount -= 1;
    }
    this.additionalAddress = event.currentTarget.checked;
  }

  checkButton() {
    let keys = Object.keys(this.validity);
    let valid = false;
    for (let key of keys) {
      valid = this.validity[key].value;
      if (!valid) {
        break;
      }
    }
    document.getElementById('checkout_button').disabled = !valid;
  }

  checkAdditional() {
    let keys = Object.keys(this.additionalValidity);
    let valid = false;
    for (let key of keys) {
      valid = this.additionalValidity[key].value;
      if (!valid) {
        break;
      }
    }
    this.validity['additional'] = { value: valid };
    this.checkButton();
  }

  checkBilling() {
    let keys = Object.keys(this.billingValidity);
    let valid = false;
    for (let key of keys) {
      valid = this.billingValidity[key].value;
      if (!valid) {
        break;
      }
    }
    this.validity['billing'] = { value: valid };
    this.checkButton();
  }

  authenticatedCheckoutButton() {
    let keys = Object.keys(this.authenticatedValidity);
    let valid = false;
    for (let key of keys) {
      valid = this.authenticatedValidity[key];
      if (!valid) {
        break;
      }
    }
    document.getElementById('checkout_button').disabled = !valid;
  }

  setGenderShipping(event){
    document.getElementById('add_gender_shipping').value = event.currentTarget.dataset.select;
    document.getElementById('hidden_actual_gender').value = event.currentTarget.dataset.value;
  }

  checkEmailExist(event){
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const valid = emailPattern.test(event.currentTarget.value.toLowerCase());
    if (valid) {
      fetch('/users/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({email: event.currentTarget.value.toLowerCase()})
      }).then(response => {
        if (response.ok) {
          response.json().then( value => {
            if(value['present']){
              document.getElementById('checkout_email_error_message').innerHTML = `<p> ${I18n[window.currentLocale]['email_exist']}. <a style="text-decoration: underline" data-controller="login"  data-action="click->login#login">${I18n[window.currentLocale]['please_login']}</a></p>`;
              document.getElementById('add-registration').classList.add('hidden');
              if(window.location.search.includes('register=true') && this.registration && !(JSON.parse(sessionStorage.getItem('purchaseMembershipOn')))){
                document.getElementById('guest_checkout_email_error_message').innerHTML = `<p> ${I18n[window.currentLocale]['email_exist']}. <a style="text-decoration: underline" data-controller="login" data-action="click->login#login">${I18n[window.currentLocale]['please_login']}</a></p>`;
                document.getElementById('add-registration-guest').classList.add('hidden');
              }
            } else {
              document.getElementById('add-registration').classList.remove('hidden');
              document.getElementById('checkout_email_error_message').innerText = '';
              if(window.location.search.includes('register=true') && this.registration && !(JSON.parse(sessionStorage.getItem('purchaseMembershipOn')))){
                document.getElementById('add-registration-guest').classList.remove('hidden');
                document.getElementById('guest_checkout_email_error_message').innerText = '';
              }
            }
          });
        } else { /* empty */ }
      });
    }
  }

  invoicePayment(event){
    if(event.currentTarget.checked){
      document.getElementById('checkout_button').innerText = I18n[window.currentLocale]['place_order']
    }else{
      document.getElementById('checkout_button').innerText = I18n[window.currentLocale]['proceed_to_payment']
    }
  }
}
