export const pluck = function (array, value) {
  return array.map(o => o[value]);
};

export const blank = function (obj) {
  let cache;

  if ((cache = typeof obj) !== 'boolean' && (cache !== 'number' || isNaN(obj)) && !obj)
    return true;
  if (cache === 'string' && obj.replace(/\s/g, '').length === 0)
    return true;
  if (cache === 'object') {
    if ((cache = toString.call(obj)) === '[object Array]' && obj.length === 0)
      return true;
    if (cache === '[object Object]') {
      for (cache in obj) {
        return false;
      }
      return true;
    }
  }

  return false;
};

export const exists = function (array, obj, key) {
  return array.some(element => element[key] === obj[key]);
};
