import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {newSubCategoryField} from '../services/templates/products/categories/new-sub-category-field';
import {createTaxons} from '../services/templates/api';
import {I18n} from '../translation';
import {LOCALES} from '../constants/constants';

export default class extends Controller {

  static targets = ['subCategoryDom', 'newSubCategoryDom'];
  categoryImage;
  formData = {
    categoryName: {
      en: '',
      fr: '',
      de: '',
    },
    subCategoryNames: {
      en: [],
      de: [],
      fr: []
    },
    description:{
      en: '',
      fr: '',
      de: '',
    }
  };

  subCategoryCounter = 0;
  localeChips = document.getElementById('addSubCategory').querySelectorAll('[class*="locale-chip"]');
  currentLocale = 'en';
  languageErrorTriggered = false;

  copyToAllLocale() {
    document.getElementById('copyCategoryContent').removeAttribute('disable');
  }

  categoryInput(event) {
    // setValue in formData
    this.formData['categoryName'][this.currentLocale] = event.target.value;
    freeFieldValidation(event.target, `${event.target.id}_error_message`);
  }

  categoryDescriptionInput(event) {
    this.formData['description'][this.currentLocale] = event.target.value;
  }

  switchLocale(event) {
    this.currentLocale = event.currentTarget.dataset.locale;

    let name = this.formData['categoryName'][event.target.dataset.locale]; // prefill if data already present.
    document.getElementById('category_name').value = name || ''; // clear field for other locale.
    let description = this.formData['description'][event.target.dataset.locale]; // prefill if data already present.
    document.getElementById('category_description').value = description || '';


    document.querySelectorAll('.sc-fields').forEach(field => {
      field.value = this.formData.subCategoryNames[this.currentLocale][+field.dataset.index - 1]?.name || '';
    }, this);

    this.localeChips = document.getElementById('language-switcher_add-category').querySelectorAll('[class*="locale-chip"]');

    let targetLocale = event.currentTarget.dataset.locale;

    this.localeChips.forEach(chip => {
      if (chip.dataset.locale !== targetLocale) {
        chip.classList.remove('sk-language-active', 'active-locale');
        chip.classList.add('sk-language-inactive');
      }else{
        chip.classList.add('sk-language-active', 'active-locale');
        chip.classList.remove('sk-language-inactive');
      }
    });
  }
  duplicateAll() {
    this.copyCategoryName();
    this.copySubCategoryNames();
    this.copyCategoryDescription();

    // validate if duplicate successful
    if (this.duplicated(this.currentLocale)) {
      popSnackbar('notice', I18n[window.currentLocale]['content_duplication_success_notice']);
      this.removeCopyValidator();
    } else {
      popSnackbar('error', I18n[window.currentLocale]['content_duplication_success_error']);
    }
  }

  copyCategoryName() {
    Object.keys(this.formData.categoryName).forEach(locale => {
      if (locale !== this.currentLocale) {
        this.formData.categoryName[locale] = this.formData.categoryName[this.currentLocale];
      }
    }, this);
  }

  copyCategoryDescription() {
    Object.keys(this.formData.description).forEach(locale => {
      if (locale !== this.currentLocale) {
        this.formData.description[locale] = this.formData.description[this.currentLocale];
      }
    }, this);
  }

  copySubCategoryNames() {
    Object.keys(this.formData.subCategoryNames).forEach(locale => {
      if (locale !== this.currentLocale) {
        this.formData.subCategoryNames[locale] = this.formData.subCategoryNames[this.currentLocale].map(subCategory => ({...subCategory}));
      }
    }, this);
  }

  duplicated(currentLocale) {
    return Object.keys(this.formData.categoryName).every((locale) => {
      return this.formData.categoryName[locale] === this.formData.categoryName[currentLocale];
    }, this);
  }

  saveCategory(imagedom,image,taxonomyId) {
    let data = [];
    const id = this.element.dataset.uniqId;
    this.getCategoryImage(id)
    Object.keys(this.formData.categoryName).forEach(locale => {
      let tmp = {
        locale: locale,
        categoryName: this.formData.categoryName[locale],
        subCategoryNames: this.formData.subCategoryNames[locale],
        description: this.formData.description[locale],
        taxonomyId:taxonomyId,
        images: this.categoryImage.length === 0 ? "" :  JSON.stringify(this.categoryImage[`category-image-${id}`]),
        image: this.croppedImage(imagedom)
      };
      data.push(tmp);
    }, this);
    createTaxons(data);
    this.resetFormData();
    this.resetImageform(imagedom,image)
  }

  croppedImage(imagedom){
    return JSON.parse(document.getElementById(imagedom).dataset.hasOwnProperty('urls') ? document.getElementById(imagedom).dataset.urls : '{}')
  }

  getCategoryImage(id) {
    let multipleImageUploadController = this.application.getControllerForElementAndIdentifier(document.getElementById(id), 'multiple-image-upload');
    this.categoryImage = multipleImageUploadController.variantImages.length === 0 ? "" : multipleImageUploadController.variantImages[0];
  }

  validateFormAndSave(event) {
    // if (!this.subCategoryCounter) {
    //   document.getElementById('missing_subcategory_error_message').innerText =
    //       'At least one subcategory required';
    //   return;
    // }
    let eventData = event.currentTarget.dataset;
    const form = document.getElementById('category-form');
    let valid = true;
    // form.querySelectorAll('input').forEach(input => {
    //   const errorElementId = input.parentElement.querySelector('p[class*=\'error_message\']').id;
    //   valid = freeFieldValidation(input, errorElementId) && valid;
    // });
    (LOCALES.every(locale => !!this.formData.categoryName[locale]) && (this.subCategoryCounter === 0 ||
      LOCALES.every(locale => (this.formData.subCategoryNames[locale] || []).length) ||
      LOCALES.every(locale => (this.formData.description[locale] || ''))) && valid)?
      this.saveCategory(eventData.imageDom,eventData.defaultImg,eventData.taxonomyId) : this.validateCopyError();
  }

  validateCopyError() {
    if (!this.languageErrorTriggered) {
      this.languageErrorTriggered = !this.languageErrorTriggered;
      document.getElementById('language-switcher_add-category').innerHTML +=
        `<i class="material-icons-outlined cursor-pointer pl-[8px] text-error" 
        data-cy="content_copy" data-object="" data-modalid="" title="Data not copied for other locales">info</i>`;
    }
  }

  removeCopyValidator() {
    this.languageErrorTriggered = false;
    const errorInfo = document.getElementById('language-switcher').querySelector('.text-error');
    if (errorInfo) {
      errorInfo.remove();
    }
  }

  cancelAndResetModal() {
    this.resetFormData();
    // this.removeAllSubcategories();
    this.removeValidations();
  }

  removeAllSubcategories() {
    const subCategories = document.querySelectorAll('[id^=\'sc_dom_\']');
    subCategories.forEach(categoryDom => categoryDom.remove());
    this.subCategoryCounter = 0;
    this.subCategoryDomTarget.classList.add('hidden');
  }

  removeValidations() {
    this.removeCopyValidator();
    document.getElementById('category_name_error_message').innerHTML = '';
  }

  resetFormData() {
    this.formData = {
      categoryName: {
        en: '',
        fr: '',
        de: '',
      },
      subCategoryNames: {
        en: [],
        de: [],
        fr: []
      },
      description:{
        en: '',
        fr: '',
        de: '',
      }
    };
    this.languageErrorTriggered = false;
  }
  resetImageform(imagedom,image){
    document.getElementById(imagedom).src = image;
  }
}
