import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['form'];

  search(query) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let url = `/admin/search/filter-inventory?q=${query}&state=all`;
      fetch(url).then(response => {
        response.text().then(text => {
          document.getElementById('inventory_listing_container').innerHTML = text;
        });
      });
    }, 500);
  }

  inputSearch(event) {
    let query = event.currentTarget.value;
    this.search(query);
  }

  buttonSearch() {
    let query = document.getElementById('search_input').value;
    this.search(query);
  }
}