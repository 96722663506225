import {Controller} from 'stimulus';
import flatpickr from 'flatpickr';

export default class extends Controller {
  index;
  preOrderConfig;
  connect() {
    this.dateTime();
  }

  dateTime() {
    flatpickr( '#address-date-picker', {
      dateFormat: 'd.m.Y',
      defaultDate: [],
      onChange: () => {
        document.getElementById('address-date-picker_error_message').innerHTML = '';
      }
    });
  }


}
