import {Controller} from 'stimulus';
import {UiService} from '../services/ui_service';

export default class extends Controller {
  connect() {
    let product = JSON.parse(this.element.dataset.product);

    // eslint-disable-next-line no-undef
    gtag('event', 'select_item', {
      item_list_id: 'product-details',
      item_list_name: 'Product Details',
      items: product
    });

    // eslint-disable-next-line no-undef
    gtag('event', 'view_item', {
      currency: 'CHF',
      value: 30.00,
      items: product
    });

    this.checkAndInitDisplayToggle();
  }
  changeIcon() {
    const content = document.getElementById('product-detail-icon');
    content.innerHTML === 'remove' ? content.innerHTML = 'add' : content.innerHTML = 'remove';
  }

  checkAndInitDisplayToggle() {
    const content = document.getElementById('truncated-text');
    if (content && content.innerText.length > 100) {
      document.getElementById('show-more').hidden = false;
      new UiService().initDisplayToggleButton();
    } else {
      document.getElementById('show-more').hidden = true;
    }
  }
}
