import {Controller} from 'stimulus';
import {FormValidationService} from '../services/authentications/form_validation_service';
import {validateLength} from '../services/authentications/min-length_service';
import {freeFieldValidation} from '../services/authentications/free_field_service';

export default class extends Controller {

  currentStepId;
  firstErrorElement;
  form;
  validatorService;
  ribbon_ids = [];

  connect() {
    this.setCurrentStep();
    if(window.location.pathname.includes('edit')) {
      if (this.element.dataset.hasVariants === 'true') {
        document.getElementById('step-three-next-btn').click();
        document.getElementById('step-four-previous-btn').click();
        document.getElementById('step-three-previous-btn').click();
        document.getElementById('step-two-previous-btn').click();
      }
      document.getElementById('auto-recommendation-switch').checked
          = JSON.parse(this.element.dataset.autoRecommended || 'false');
      this.ribbon_ids = JSON.parse(this.element.dataset.tags);
      this.displayTags();
    }
  }

  setCurrentStep(id) {
    if (!id) {
      const stepperIcons = [ ...document.getElementById('pc-stepper-list').querySelectorAll('i') ];
      const activeClassList = [ ...stepperIcons.find(i => i.textContent === 'edit').closest('li').classList ];
      this.currentStepId = activeClassList[activeClassList.findIndex((c) => c.includes('pc-step'))];
    } else this.currentStepId = id;
    let forms = [];
    this.form = document.getElementById(this.currentStepId);
    if (this.currentStepId === 'pc-step-one') {
      this.form = document.getElementById('basic-details-dom');
      forms.push(document.getElementById('product-codes'));
    }
    forms.push(this.form);
    this.validatorService = new FormValidationService(forms);
    this.updatePublishBtnStatus();
    this.firstErrorElement = undefined;
  }

  updatePublishBtnStatus() {
    const publishBtn = document.getElementById('pc-form-submit-btn').parentElement;
    (this.currentStepId !== 'pc-step-six' && !window.location.pathname.includes('edit'))? publishBtn.setAttribute('disabled', 'true')
      : publishBtn.removeAttribute('disabled');
  }

  nextStep(event) {
    let currentStepId = event.target.dataset.currentStep;
    let nextStepId = event.target.dataset.nextStep;

    this.stepForward(currentStepId, nextStepId);
    this.updateNavigator(currentStepId, nextStepId, 'next');
    this.setCurrentStep(nextStepId);
  }

  onFieldChange(event) {
    this.validatorService.onInput(event);
  }

  validateDecimals(event) {
    this.validatorService.validateDecimals(event);
  }

  validateLength(e) {
    const lengthValid = validateLength(e.currentTarget);
    if (!lengthValid) e.stopImmediatePropagation();
  }

  validateFormAndNext(event) {
    const forms = [];
    let valid;
    if (this.currentStepId === 'pc-step-one' && document.getElementById('product_barcode').offsetParent === null) {
      forms.push(this.form);
      forms.push(document.getElementById('sku-code'));
      valid = this.validatorService.validateForm(forms);
    } else valid = this.validatorService.validateForm();
    this.firstErrorElement = this.validatorService.firstErrorElement;
    valid? this.nextStep(event) : this.validateCopyError();
  }

  validateCopyError() {
    const langSwitcher = document.getElementById('language-switcher');
    if (!langSwitcher.querySelector('.text-error')) {
      langSwitcher.innerHTML +=
          `<i class="material-icons-outlined cursor-pointer pl-[8px] text-error"  id="language_error_message"
        data-cy="content_copy" data-object="" data-modalid="" title="Data not copied for other locales">info</i>`;
      if (this.currentLocale && !this.firstErrorElement.id.includes(this.currentLocale))
        this.firstErrorElement = langSwitcher.querySelector('#language_error_message');
    }
    this.scrollToError();
  }

  validateVariantSettings(event) {
    let valid = true;

    const variantConfElements = document.querySelectorAll('[id*=\'variant-pre-order-conf\']');
    [...variantConfElements]
      .filter(element => !element.classList.contains('hidden'))
      .forEach(element => {
        const dateElement = element.querySelector('[id^=\'select_date_range\']');
        valid = freeFieldValidation(dateElement, `${dateElement.id}_error_message`);
      });

    if (!valid) event.stopImmediatePropagation();
  }

  scrollToError() {
    if (this.firstErrorElement) this.firstErrorElement
      .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }

  checkOptionAndNext(event) {
    const optionDom = this.form.querySelector('#option-type-value-dom');
    const optionsPresent = optionDom && optionDom.childElementCount;
    if (optionsPresent || location.pathname.includes('edit')) this.nextStep(event);
    else {
      event.stopImmediatePropagation();
      // TODO: UI Translation
      this.form.querySelector('#option_error_message').innerHTML = 'Please add options for your variants';
    }
  }

  removeOptionsError() {
    const optionsError = this.form.querySelector('#option_error_message');
    if (optionsError) optionsError.innerHTML = '';
  }

  previousStep(event) {
    let currentStepId = event.target.dataset.currentStep;
    let previousStepId = event.target.dataset.previousStep;

    this.stepBackward(currentStepId, previousStepId);
    this.updateNavigator(currentStepId, previousStepId, 'prev');
    this.setCurrentStep(previousStepId);
  }

  stepForward(currentStepId, nextStepId) {
    nextStepId === 'pc-step-five' ? this.getTargetDom(nextStepId).style.display = 'block' : this.getTargetDom(nextStepId).classList?.remove('hidden');
    nextStepId === 'pc-step-six' ? this.getTargetDom(currentStepId).classList.add('hidden') : null;
    currentStepId === 'pc-step-five' ? this.getTargetDom(currentStepId).style.display = 'none' : null;

    this.getTargetDom(currentStepId).classList.add('hidden');
  }

  stepBackward(currentStepId, previousStepId) {
    currentStepId === 'pc-step-five' ? this.getTargetDom(currentStepId).style.display = 'none' : this.getTargetDom(currentStepId).classList.add('hidden');

    if (this.variantsPresence() === 'true') {
      previousStepId === 'pc-step-five' ? this.getTargetDom(previousStepId).style.display = 'none' : this.getTargetDom(previousStepId).classList?.remove('hidden');
    } else {
      previousStepId === 'pc-step-five' ? this.getTargetDom(previousStepId).style.display = 'block' : this.getTargetDom(previousStepId).classList?.remove('hidden');
    }
  }

  updateNavigator(currentStepId, targetStepId, type) {
    let currentNavigator = document.querySelector(`.${currentStepId}`);
    let targetNavigator = document.querySelector(`.${targetStepId}`);

    if (type === 'next') {
      currentNavigator.querySelector('.state-icon').innerHTML = 'done';
      targetNavigator.classList.add('sk-stepper__step--active');
      currentNavigator.style.setProperty('--active-color', '#39c3d4');
    } else {
      currentNavigator.querySelector('.state-icon').innerHTML = 'edit';
      currentNavigator.classList?.remove('sk-stepper__step--active');
      targetNavigator.style.removeProperty('--active-color', '#39c3d4');
    }

    targetNavigator.querySelector('.state-icon').innerHTML = 'edit';
  }

  change(e) {
    let dom = e.currentTarget.dataset.stepperTarget;
    document.getElementById(dom).click();
  }

  getTargetDom(id) {
    return document.getElementById(id);
  }

  variantsPresence() {
    return document.getElementById('has-variants').value;
  }

  get currentLocale() {
    const languageSwitcher = document.getElementById('language-switcher');
    return languageSwitcher? languageSwitcher.querySelector('div.active-locale').id : '';
  }

  onTagChange(event) {
    document.getElementById('tags').value = '';
    if(!this.ribbon_ids.some( value => value.id == event.target.dataset.optionId)){
      this.ribbon_ids.push({id: +event.target.dataset.optionId, name: event.target.dataset.value});
      this.displayTags();
    }
  }

  displayTags() {
    document.getElementById('selected-tag-list').innerHTML = '';
    for(let i= 0; i < this.ribbon_ids.length; i++) {
      document.getElementById('selected-tag-list').innerHTML += `
             <div class="flex items-center space-x-[8px] border rounded-full cursor-pointer px-[12px] py-[6px] mr-[16px] mt-[16px]"
             data-cy="productTag" data-mdb-ripple="true">
          <p class="text-[14px] pt-[2px]">${this.ribbon_ids[i].name}</p>
          <i class="material-icons-outlined !text-[16px] text-black/[0.6]" data-action="click->product-configurator-stepper#removeTag" data-id="${this.ribbon_ids[i].id}">close</i>
        </div>
            `;
    }
  }

  removeTag(event){
    let indexTag = -1;
    this.ribbon_ids.forEach((value, index) => {
      if(value.id == event.target.dataset.id){
        indexTag = index;
      }
    });
    this.ribbon_ids.splice( indexTag, 1);
    this.displayTags();
  }
}
