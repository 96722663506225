import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {percentageFieldValidation} from '../services/authentications/percentage_service';
import flatpickr from 'flatpickr';
import {locales} from '../constant/locale_constant';

export default class extends Controller {
  promotionId;
  initialData = {
    promotionNameEn: '',
    promotionNameDe: '',
    promotionNameFr: '',
    promotionStartDate: '',
    promotionStartTime: '',
    promotionEndDate: '',
    promotionEndTime: '',
    promotionDiscountType: '',
    promotionDiscountValue: '',
    promotionCustomerEligibility: '',
    promotionShippingServiceApplicable: '',
    promotionCouponQuantityApplicable: ''
  };
  editedData = {};
  present_locale;
  invalidCount = [];

  connect() {
    this.promotionId = this.element.dataset.promotionId;
    this.promotion_type = this.element.dataset.promotionType;

    Object.keys(this.initialData).forEach(key => {
      this.initialData[key] = this.element.dataset[key];
      this.editedData[key] = this.element.dataset[key];
    });
    document.getElementById(`edit_${this.initialData.promotionCustomerEligibility}_eligibility_${this.promotionId}`).checked = true;
    document.getElementById(`edit_promotion_discount_type_${this.initialData.promotionDiscountType}_button_${this.promotionId}`).click();

    if (this.initialData.promotionShippingServiceApplicable == 'true') {
      let x = document.getElementById(`edit_shipping_charges_applicable_${this.promotionId}`);
      if (x) {
        x.checked = true;
      }
    } else {
      let y = document.getElementById(`edit_shipping_charges_not_applicable_${this.promotionId}`);
      if (y) {
        y.checked = true;
      }
    }

    if (this.promotion_type === 'discount') {
      this.activateLocale('en');
    }

    this.dateTime();
  }

  dateTime() {
    flatpickr(`#edit_promotion_start_date_${this.promotionId}`, {
      dateFormat: 'd.m.Y',
      minDate: Date.now()
    });

    flatpickr(`#edit_promotion_end_date_${this.promotionId}`, {
      dateFormat: 'd.m.Y',
      minDate: Date.now()
    });

    flatpickr(`#edit_promotion_start_time_${this.promotionId}`,
      {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i'
      }
    );

    flatpickr(`#edit_promotion_end_time_${this.promotionId}`,
      {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i'
      }
    );
  }

  discountType(event) {
    event.currentTarget.classList.remove('bg-white', 'text-black/[0.87]');
    event.currentTarget.classList.add('bg-blue', 'text-white');
    let discountAmountElement = document.getElementById(`edit_promotion_discount_value_${this.promotionId}`);
    if (event.currentTarget.id === `edit_promotion_discount_type_percentage_button_${this.promotionId}`) {
      this.discountTypePercentage(discountAmountElement);
    } else {
      this.discountTypeFixedAmount(discountAmountElement);
    }
    this.submittable();
  }

  discountTypePercentage(discountAmountElement) {
    document.getElementById(`edit_promotion_discount_type_fixed_amount_button_${this.promotionId}`).classList.remove('bg-blue', 'text-white');
    document.getElementById(`edit_promotion_discount_type_fixed_amount_button_${this.promotionId}`).classList.add('text-black/[0.87]', 'bg-white');
    this.editedData.promotionDiscountType = 'percentage';
    document.getElementById(`edit_promotion_discount_value_label_${this.promotionId}`).innerHTML = '%';
    document.getElementById(`edit_promotion_discount_type_${this.promotionId}`).value = 'percentage';
    this.validPercentage(discountAmountElement.value, discountAmountElement.id);
  }

  discountTypeFixedAmount(discountAmountElement) {
    document.getElementById(`edit_promotion_discount_type_percentage_button_${this.promotionId}`).classList.remove('bg-blue', 'text-white');
    document.getElementById(`edit_promotion_discount_type_percentage_button_${this.promotionId}`).classList.add('text-black/[0.87]', 'bg-white');
    this.editedData.promotionDiscountType = 'fixed_amount';
    document.getElementById(`edit_promotion_discount_value_label_${this.promotionId}`).innerHTML = 'CHF';
    document.getElementById(`edit_promotion_discount_type_${this.promotionId}`).value = 'fixed_amount';
    this.validFixedAmount(discountAmountElement, discountAmountElement.id);
  }

  switchLocale(event) {
    this.activateLocale(event.currentTarget.dataset.locale);
  }

  activateLocale(selected_locale) {
    locales.forEach(locale => {
      if (selected_locale === locale) {
        document.getElementById(`${selected_locale}_local_edit_promotion_${this.promotionId}`).classList.add('bg-blue', 'text-white', 'active-locale');
        document.getElementById(`${selected_locale}_edit_promotion_name_container_${this.promotionId}`).classList.remove('hidden');
        this.present_locale = selected_locale;
      } else {
        document.getElementById(`${locale}_local_edit_promotion_${this.promotionId}`).classList.remove('bg-blue', 'text-white', 'active-locale');
        document.getElementById(`${locale}_edit_promotion_name_container_${this.promotionId}`).classList.add('hidden');
      }
    });
  }

  duplicateAll() {
    let master_value = document.getElementById(`${this.present_locale}_edit_promotion_name_${this.promotionId}`).value;
    locales.forEach(locale => {
      document.getElementById(`${locale}_edit_promotion_name_${this.promotionId}`).value = master_value;
      master_value ? this.removeInvalidCount(`${locale}_edit_promotion_name_${this.promotionId}`) : this.addInvalidCount(`${locale}_edit_promotion_name_${this.promotionId}`);
    });
    this.editedData.promotionNameEn = master_value;
    this.editedData.promotionNameFr = master_value;
    this.editedData.promotionNameDe = master_value;
    popSnackbar('notice', I18n[window.currentLocale]['successful_copy']);
    this.duplicable();
    this.submittable();
  }

  promotionName(event) {
    let locale = event.currentTarget.dataset.locale;
    let freeFieldStatus = freeFieldValidation(event.currentTarget, `${event.currentTarget.id}_error_message`);
    freeFieldStatus ? this.removeInvalidCount(event.currentTarget.id) : this.addInvalidCount(event.currentTarget.id);
    if (locale === 'en') {
      this.editedData.promotionNameEn = event.currentTarget.value;
    } else if (locale === 'fr') {
      this.editedData.promotionNameFr = event.currentTarget.value;
    } else {
      this.editedData.promotionNameDe = event.currentTarget.value;
    }
    this.duplicable();
    this.submittable();
  }

  duplicable() {
    let enName = document.getElementById(`en_edit_promotion_name_${this.promotionId}`).value;
    let deName = document.getElementById(`de_edit_promotion_name_${this.promotionId}`).value;
    let frName = document.getElementById(`fr_edit_promotion_name_${this.promotionId}`).value;
    if (enName === deName && deName === frName) {
      document.getElementById(`edit_promotion_name_copy_button_${this.promotionId}`).classList.add('pointer-events-none');
    } else {
      document.getElementById(`edit_promotion_name_copy_button_${this.promotionId}`).classList.remove('pointer-events-none');
    }
  }

  discountValue(event) {
    if (this.editedData.promotionDiscountType === 'percentage') {
      this.validPercentage(event.currentTarget.value, event.currentTarget.id);
    } else {
      this.validFixedAmount(event.currentTarget, event.currentTarget.id);
    }
    this.submittable();
  }

  validFixedAmount(input, id) {
    let fixedAmountStatus = freeFieldValidation(input, `${id}_error_message`);
    fixedAmountStatus ? this.removeInvalidCount(id) : this.addInvalidCount(id);
    this.editedData.promotionDiscountValue = input.value;
  }

  validPercentage(input, id) {
    let percentageStatus = percentageFieldValidation(input, `${id}_error_message`, this.promotion_type === 'coupon');
    percentageStatus ? this.removeInvalidCount(id) : this.addInvalidCount(id);
    this.editedData.promotionDiscountValue = input;
  }

  addInvalidCount(id) {
    if (!this.invalidCount.includes(id)) {
      this.invalidCount.push(id);
    }
  }

  removeInvalidCount(id) {
    if (this.invalidCount.includes(id)) {
      this.invalidCount.splice(this.invalidCount.indexOf(id), 1);
    }
  }

  customerEligibility(event) {
    this.editedData.promotionCustomerEligibility = event.currentTarget.value;
    this.submittable();
  }

  shippingServiceApplicable(event) {
    this.editedData.promotionShippingServiceApplicable = event.currentTarget.value;
    this.submittable();
  }

  couponQuantityApplicable(event) {
    this.editedData.promotionCouponQuantityApplicable = event.currentTarget.value;
    this.submittable();
  }

  submittable() {
    let editedCount = 0;
    Object.keys(this.initialData).forEach(key => {
      if (this.initialData[key] !== this.editedData[key]) {
        editedCount += 1;
      }
    });
    if (editedCount > 0 && this.validDate() && this.invalidCount.length === 0) {
      document.getElementById(`edit_promotion_submit_button_${this.promotionId}`).disabled = false;
    } else {
      document.getElementById(`edit_promotion_submit_button_${this.promotionId}`).disabled = true;
    }
  }

  startDate(event) {
    this.editedData.promotionStartDate = event.target.value;
    this.submittable();
  }

  endDate(event) {
    this.editedData.promotionEndDate = event.currentTarget.value;
    this.submittable();
  }

  startTime(event) {
    this.editedData.promotionStartTime = event.currentTarget.value;
    this.submittable();
  }

  endTime(event) {
    this.editedData.promotionEndTime = event.currentTarget.value;
    this.submittable();
  }

  validDate() {
    let errorMessages = document.getElementById(`edit_promotion_timing_error_message_${this.promotionId}`);
    if (this.editedData.promotionStartDate && this.editedData.promotionEndDate && this.editedData.promotionStartTime && this.editedData.promotionEndTime) {
      let start_time = this.dateParseFormat(this.editedData.promotionStartDate, this.editedData.promotionStartTime);
      let end_time = this.dateParseFormat(this.editedData.promotionEndDate, this.editedData.promotionEndTime);
      if (start_time < end_time) {
        errorMessages.classList.add('hidden');
        return true;
      } else {
        errorMessages.classList.remove('hidden');
        return false;
      }
    } else {
      return false;
    }
  }

  dateParseFormat(date, time) {
    let splitDate = date.split('.');
    let splitTime = time.split(':');
    return Date.parse(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}T${splitTime[0]}:${splitTime[1]}`);
  }
}
