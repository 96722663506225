export const openSnackbar = function (msg, status) {

  document.getElementById('snack-bar').innerHTML = `
	<div id="snackbar" class="sk-snack-bar flex items-center justify-between shadow rounded-[4px] p-[8px] !z-[99999]" data-controller="snackbar">
    <div class="flex items-center">
      <div class="${ status === 'error' ? 'bg-error' : 'bg-success' } w-[4px] h-[36px] rounded-[10px]"></div>
      <p class="pl-[16px]">${msg}</p>
    </div>
    <div class="sk-snack-bar--loader ${ status === 'error' ? '!border-b-error' : '!border-b-success' } flex items-center justify-center rounded-full border-2 border-primary h-[26px] w-[26px] p-[14px]">
    </div>
    <i id="close_snackbar" class="material-icons-outlined cursor-pointer absolute right-[15px] ${ status === 'error' ? 'text-error' : 'text-success' }" data-action="click->snackbar#close">close</i>
  </div>`;
};

export const message = function (message, status) {
  window.alert = openSnackbar(message, status);
};
