import { Controller } from 'stimulus';
import {popSnackbar} from '../services/snackbar';

export default class extends Controller {
  file;
  fileUpload(event) {
    this.fileUploadId.style.display = 'none';
    this.uploadedFileId.style.display = 'block';
    this.file = event.target.files[0];
    document.getElementById('filename').innerText = this.file.name || '';
    document.getElementById('file').value = '';
    this.submitButton.disabled = false;
  }

  get fileUploadId() {
    return document.getElementById('emptyFile');
  }

  get uploadedFileId() {
    return document.getElementById('uploadedFile');
  }
  get submitButton() {
    return document.getElementById('submitButton');
  }

  removeUpload() {
    this.fileUploadId.style.display = 'block';
    this.uploadedFileId.style.display = 'none';
    this.file = '';
    document.getElementById('file').value = '';
    this.submitButton.disabled = true;
  }

  submitUpload() {
    const formData = new FormData();
    formData.append('file', this.file);
    let url;
    if(window.location.pathname === '/admin/users') {
      url = '/admin/import_users';
    } else {
      url = '/admin/import_products';
    }
    fetch( url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: formData
    }).then((response) => response.json())
      .then((data) => {
        this.removeUpload();
        if (data.done) {
          popSnackbar('notice', data.message);
        } else {
          popSnackbar('error', data.message);
        }
      }).catch( () => {
        popSnackbar('error', 'Something went wrong');
        this.removeUpload();
      });
  }

  exportFile() {
    if(window.location.pathname === '/admin/users'){
      const url = '/admin/export_users';
      window.open(`${location.origin}${url}`, '_blank');
    } else {
      const url = '/admin/export_products';
      window.open(`${location.origin}${url}`, '_blank');
    }
  }
}
