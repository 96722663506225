import {Controller} from 'stimulus';
import {I18n} from '../translation';

export default class extends Controller {
  static targets = ['selectedCount', 'variantRowHeader', 'variantCount', 'thresholdHeader'];

  variantDropdownOptions = document.getElementById('variants-dropdown-options');
  variantOptionsCheckbox = document.querySelector('.variant-options-check');
  allVariants = document.querySelectorAll('.variants');
  selectedVariants = [];

  initialize() {
    this.variantCount();
  }

  stockMonitorCtrl(event) {
    event.currentTarget.checked ? this.enableStockMonitor() : this.disableStockMonitor();
  }

  preOrderCtrl(event) {
    event.currentTarget.value = event.currentTarget.checked;
    event.currentTarget.checked ? this.enablePreOrder() : this.disablePreOrder();
  }

  limitStockCtrl(event) {
    let dom = document.getElementById('stock-limit-input');
    event.currentTarget.value = event.currentTarget.checked;
    dom.disabled = !event.currentTarget.checked;
    if(!event.currentTarget.checked){
      dom.value = 0;
    }
  }

  clickVariantPreOrderSwitch(event){
    let dom = document.getElementById(`${event.currentTarget.dataset.index}-variant-pre-order-conf`);
    event.currentTarget.value = event.currentTarget.checked;
    event.currentTarget.checked ? dom.classList.remove('hidden') : dom.classList.add('hidden');
  }

  enablePreOrder() {
    let preOrderFields = document.querySelectorAll('.pre-order-fields');
    preOrderFields.forEach(field => {
      field.classList.remove('hidden');
    });
  }

  disablePreOrder() {
    let preOrderFields = document.querySelectorAll('.pre-order-fields');
    preOrderFields.forEach(field => {
      field.classList.add('hidden');
    });
  }

  enableStockMonitor() {
    this.displayExtraFields();
  }

  disableStockMonitor() {
    this.hideExtraFields();
  }

  selectVariant(event) {
    let target = event.currentTarget;
    target.checked ? this.select(target) : this.deselect(target);
  }

  selectAllVariants(event) {
    this.hideVariantTableHeader();

    this.allVariants = document.querySelectorAll('.variants'); // needs reassigning cuz of variant table generation on fly.
    this.allVariants.forEach(variant => {
      if (event.currentTarget.checked) {
        let target = variant.querySelector('.variant-item');
        target.checked = true;
        this.select(target);
      }
    });
  }

  deselectAllVariants() {
    this.showVariantTableHeader();

    this.allVariants.forEach(variant => {
      let target = variant.querySelector('.variant-item');
      target.checked = false;
      this.deselect(target);
    });
  }

  select(target) {
    this.hideVariantTableHeader();

    this.selectedVariants.push(target.dataset.variantIndex);
    this.variantRow(target.dataset.index).forEach(ele => {
      ele.classList.add('bg-blue/[0.08]');
    });
    this.selectedCountTarget.innerHTML = `${this.selectedVariants.length} ${I18n[window.currentLocale]['selected']}`;
  }

  deselect(target) {
    this.selectedVariants = this.selectedVariants.filter(item => item !== target.dataset.variantIndex);

    if (this.selectedVariants.length < 1) {
      this.variantDropdownOptions.classList.add('hidden');
      this.showVariantTableHeader();
    }

    this.selectedCountTarget.innerHTML = `${this.selectedVariants.length} ${I18n[window.currentLocale]['selected']}`;
    this.variantRow(target.dataset.index).forEach(ele => {
      ele.classList.remove('bg-blue/[0.08]');
    });
    document.getElementById('variant-row-header').checked = false;
  }

  variantRow(index) {
    return document.querySelectorAll(`.variant-${index}-row`);
  }

  hideVariantTableHeader() {
    this.variantDropdownOptions.classList.remove('hidden');
    this.variantOptionsCheckbox.checked = true;
    this.variantRowHeaderTarget.classList.contains('hidden') ? null : this.variantRowHeaderTarget.classList.add('hidden');
  }

  showVariantTableHeader() {
    this.variantRowHeaderTarget.classList.remove('hidden');
  }

  displayExtraFields() {
    this.thresholdHeaderTarget.classList.remove('hidden');
    document.getElementById('edit-threshold-option').style.display = 'block';

    let thresholdFields = document.querySelectorAll('.threshold-fields');
    thresholdFields.forEach(field => {
      field.classList.remove('hidden');
    });
  }

  hideExtraFields() {
    this.thresholdHeaderTarget.classList.add('hidden');
    document.getElementById('edit-threshold-option').style.display = 'none'; // show threshold edit option

    let thresholdFields = document.querySelectorAll('.threshold-fields');
    thresholdFields.forEach(field => {
      field.querySelector('.threshold-input').value = 0; // might or might not be needed
      field.classList.add('hidden');
    });
  }

  variantCount() {
    this.variantCountTarget.innerHTML = `${I18n[window.currentLocale]['variant']} (${this.allVariants.length})`;
  }

  preOrderToggle(event){
    if(event.target.checked){
      document.getElementById('preorder-novariant').style.display = 'block';
    } else {
      document.getElementById('preorder-novariant').style.display = 'none';
    }
  }

  stockToggle(event){
    document.getElementById('stock-novariant').disabled = !event.target.checked;
  }
}