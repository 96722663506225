import {Controller} from 'stimulus';
import {constructArrayParams} from '../services/construct_array_params';
import {cartPriceAndItemReplaceDom} from '../services/carts/replace_dom';

export default class extends Controller {
  coupon_ids;

  connect() {
    this.coupon_ids = JSON.parse(this.element.dataset.coupon_ids);
  }

  close(event) {
    this.coupon_ids.splice(this.coupon_ids.indexOf(event.currentTarget.dataset.coupon_id), 1);
    let membership = JSON.parse(sessionStorage.getItem('purchaseMembershipOn')) === 'true';
    let url = `/promotion/apply?${constructArrayParams(this.coupon_ids, 'coupons')}&membership=${membership}`;
    fetch(url).then(response => {
      response.text().then(text => {
        let response = JSON.parse(text);
        cartPriceAndItemReplaceDom(response);
        // eslint-disable-next-line no-undef
        Turbo.visit('/carts', { target: 'payment-means-details' });
        document.getElementById('applied-promotion-content').innerHTML = response.coupon;
      });
    });
  }
}
