class InternalCategoryService {
  get filteredIds() {
    if (location.pathname.includes('internal_categories')) return [];

    const createField = document.getElementById('internal-category-fields');
    const editField = document.getElementById('internal-category-edit-fields');
    let creationIds = [];
    let editIds = [];
    if (createField) {
      creationIds = [...createField.querySelectorAll('input[id*=\'internal\']')].map(input => input.id);
    }
    if (editField) {
      editIds = [...editField.querySelectorAll('input[id*=\'internal\']')].map(input => input.id);
    }
    return creationIds.concat(editIds);
  }

  get allFilteredIds() {
    return this.filteredIds.concat(['ribbons', 'tags']);
  }
}

export { InternalCategoryService };
