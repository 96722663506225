export const validateLength = function(input) {
  const errorElement = document.getElementById(`${input.id}_error_message`);
  const maxLength = +input.dataset.lengthValidation;
  if (input.value.length > maxLength) {
    errorElement.innerText = `Maximum ${maxLength} characters`;
    input.value = input.value.slice(0, maxLength);
    setTimeout(() => {
      errorElement.innerText = '';
    }, 3000);
    return false;
  }
  return true;
};
