import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {FormValidationService} from '../services/authentications/form_validation_service';

export default class extends Controller {
  brandImage;
  validator;

  static targets = ['brandNameField'];

  connect() {
    this.initValidator();
  }

  initValidator() {
    this.validator = new FormValidationService([this.brandNameFieldTarget]);
  }

  createBrand(event) {
    const valid = this.validator.validateForm();
    if (!valid) return;

    const brandFrom = document.getElementById('new_brand');
    const id = this.element.dataset.uniqId;
    let loader = document.getElementById(`brand-loader-${id}`);
    loader.classList.remove('hidden');
    loader.parentElement.disabled = true;

    this.getBrandImage(id);

    let formData = new FormData(brandFrom);

    if (this.brandImage === undefined) {
      formData.append('brand[image_urls]', JSON.stringify([]));
    } else {
      if (this.brandImage[`brand-image-${id}`])
        formData.append('brand[image_urls]', JSON.stringify([this.brandImage[`brand-image-${id}`]]));
    }
    formData.append(
      'brand[custom_images]',
      document.getElementById(event.currentTarget.dataset.imageId).dataset.urls
    )

    fetch('/admin/brands', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: formData
    }).then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          popSnackbar('notice', data.message);
          setTimeout(() => {
            window.location.href = '/admin/brands';
          }, 1500);
        } else {
          popSnackbar('error', data.message);
          loader.classList.add('hidden');
          loader.parentElement.disabled = false;
        }
      });
  }

  updateBrand(event) {
    const valid = this.validator.validateForm();
    if (!valid) return;

    let variantId = this.element.dataset.variantId;
    let brandFrom = document.getElementById(`edit_brand_${variantId}`);
    const id = this.element.dataset.uniqId;
    let loader = document.getElementById(`brand-loader-${id}`);
    loader.classList.remove('hidden');
    loader.parentElement.disabled = true;

    this.getBrandImage(id);

    let formData = new FormData(brandFrom);

    formData.append('brand[croppedimage]', false);
    if (this.brandImage) {
      formData.append('brand[image_urls]', JSON.stringify([this.brandImage[`brand-image-${id}`] ? this.brandImage[`brand-image-${id}`] : '[]']));
      formData.append(
        'brand[custom_images]',
        document.getElementById(event.currentTarget.dataset.imageId).dataset.urls
      )
    }
    fetch(`/admin/brands/${variantId}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: formData
    }).then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          popSnackbar('notice', data.message);
          setTimeout(() => {
            window.location.href = '/admin/brands';
          }, 1500);
        } else {
          popSnackbar('error', data.message);
          loader.classList.add('hidden');
          loader.parentElement.disabled = false;
        }
      });
  }

  onFieldChange(event) {
    this.validator.onInput(event);
  }

  resetForm(e) {
    this.validator.resetForm();
    let id = e.currentTarget.dataset.imageId
    document.querySelector('p[id^=\'brand_logo\']').value = '';
    document.getElementById(`brand-image-${id}`).src = e.currentTarget.dataset.imageUrl;
  }

  getBrandImage(id) {
    let multipleImageUploadController = this.application.getControllerForElementAndIdentifier(document.getElementById(id), 'multiple-image-upload');

    this.brandImage = multipleImageUploadController.variantImages[0];
  }
}