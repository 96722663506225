import {Controller} from "stimulus";
import {constructArrayParams} from "../services/construct_array_params";

export default class extends Controller {
  allCheckBox = document.querySelectorAll('.promotion-check-box');
  checkedPromotionCount = 0;
  promotionCount;
  promotionSelected = [];

  connect() {
    let _this = this;
    document.getElementById('uncheck_promotion').indeterminate = true;
    this.promotionCount = this.element.dataset.promotionCount;
    for (let i = 0; i < this.allCheckBox.length; i++) {
      this.allCheckBox[i].addEventListener('click', function () {
        if (this.checked) {
          _this.addPromotionId(parseInt(this.dataset.promotionId));
          _this.highlightPromotion(`individual_promotion_container_${i}`);
          _this.checkedPromotionCount += 1;
        } else {
          _this.removePromotionId(parseInt(this.dataset.promotionId));
          _this.removeHighlight(`individual_promotion_container_${i}`);
          _this.checkedPromotionCount -= 1;
        }
        _this.updateContainer();
        _this.selectAllButton();
      })
    }
  }

  selectAll(event) {
    if (event.currentTarget.checked) {
      for (let i = 0; i < this.allCheckBox.length; i++) {
        this.addPromotionId(parseInt(this.allCheckBox[i].dataset.promotionId));
        this.allCheckBox[i].checked = true;
        this.highlightPromotion(`individual_promotion_container_${i}`);
      }
      this.checkedPromotionCount = this.promotionCount;
    } else {
      for (let i = 0; i < this.allCheckBox.length; i++) {
        console.log(i);
        this.removePromotionId(parseInt(this.allCheckBox[i].dataset.promotionId));
        this.allCheckBox[i].checked = false;
        this.removeHighlight(`individual_promotion_container_${i}`);
      }
      this.checkedPromotionCount = 0;
    }
    this.updateContainer();
  }

  uncheckPromotion(event) {
    if (event.currentTarget.checked) {
      for (let i = 0; i < this.allCheckBox.length; i++) {
        this.allCheckBox[i].checked = false;
        this.removeHighlight(`individual_promotion_container_${i}`);
        this.removePromotionId(parseInt(this.allCheckBox[i].dataset.promotionId));
      }
      this.checkedPromotionCount = 0;
    }
    this.updateContainer();
    this.selectAllButton();
    event.currentTarget.click();
    document.getElementById('uncheck_promotion').indeterminate = true;
  }

  addPromotionId(id) {
    if (!this.promotionSelected.includes(id)) {
      this.promotionSelected.push(id);
    }
  }

  removePromotionId(id) {
    if (this.promotionSelected.includes(id)) {
      this.promotionSelected.splice(this.promotionSelected.indexOf(id), 1);
    }
  }

  updateContainer() {
    this.checkedPromotionCount > 0 ? this.viewUpdateContainer() : this.hideUpdateContainer();
  }

  viewUpdateContainer() {
    document.getElementById('promotion_update_container').classList.remove('hidden');
    document.getElementById('promotion_selected_count').innerHTML = `${this.checkedPromotionCount} selected`;
  }

  hideUpdateContainer() {
    document.getElementById('promotion_update_container').classList.add('hidden');
  }

  highlightPromotion(id) {
    document.getElementById(id).classList.add('bg-blue/[0.08]');
  }

  removeHighlight(id) {
    document.getElementById(id).classList.remove('bg-blue/[0.08]');
  }

  selectAllButton() {
    this.checkedPromotionCount === parseInt(this.promotionCount) ? this.checkSelectAllButton() : this.uncheckSelectAllButton();
  }

  checkSelectAllButton() {
    document.getElementById('select_all_promotion').checked = true;
  }

  uncheckSelectAllButton() {
    document.getElementById('select_all_promotion').checked = false;
  }

  callAction(event) {
    fetch(`/admin/promotions/select_promotion?state=${event.currentTarget.dataset.state}${constructArrayParams(this.promotionSelected, 'promotions')}`).then(response => {
      response.text().then(text => {
        document.getElementById('selected_promotion_for_destroy').innerHTML = text;
      })
    });
  }
}
