import {setMessage} from './base_service';
import {I18n} from '../../translation';

export const percentageFieldValidation = function (input, id, fullDiscount = false) {
  if (input) {
    if (input <= (fullDiscount ? 100 : 99) && input > 0) {
      setMessage(id);
      return true;
    } else {
      setMessage(id, I18n[window.currentLocale]['invalid_value']);
      return false;
    }
  } else {
    setMessage(id, I18n[window.currentLocale]['field_required']);
    return false;
  }
};
