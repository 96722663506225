import {Controller} from 'stimulus';
import {colorComponent} from '../services/templates/products/options/color-component';
import {freeFieldComponent} from '../services/templates/products/options/free-field-component';
import {hiddenFieldComponent} from '../services/templates/products/options/hidden-field-component';

export default class extends Controller {
  optionTypeElement = '';
  targetOptionTypeDom = '';
  COLOR_OPTION = ['Color', 'color'];
  FALLBACK_COLOR = '#000000';
  optionsCounter = {};
  optionTypes = {};
  mainForm = document.getElementById('new_product');

  newOptionType(event) {
    if (event.key === 'Enter') {
      let parentDom = document.getElementById(`list_${event.currentTarget.dataset.targetId}`);
      let childDom = document.createElement('div');
      childDom.innerHTML = `<li class='sk-dropdown__content-list' data-action="click->input-dropdown#selectOption"
              data-target-field="${event.currentTarget.dataset.targetId}" data-value="${event.currentTarget.value}">
            ${event.currentTarget.value}
          </li>`;
      parentDom.appendChild(childDom.children[0]);
    }
  }

  addOptionValue(event) {
    let optionType = event.currentTarget.dataset.optionTypeTarget;

    this.setOptionTypeElement(optionType);
    this.appendOptionValueDom(optionType);
  }

  setOptionTypeElement(optionType) {
    optionType in this.optionsCounter ? this.optionsCounter[optionType]++ : this.optionsCounter[optionType] = 0;
    this.COLOR_OPTION.includes(optionType) ? this.colorOptionValue(optionType) : this.customOptionValue(optionType);
  }

  colorOptionValue(optionType) {
    this.optionTypeElement = colorComponent(optionType, this.optionsCounter[optionType]);
    this.targetOptionTypeDom = document.getElementById(`option-type-${optionType}`);
  }

  customOptionValue(optionType) {
    this.optionTypeElement = freeFieldComponent(optionType, this.optionsCounter[optionType]);
    this.targetOptionTypeDom = document.getElementById(`option-type-${optionType}`);
  }

  appendOptionValueDom(optionType) {
    let tmpDom = document.createElement('div');
    tmpDom.innerHTML = this.optionTypeElement;
    this.targetOptionTypeDom?.classList.remove('hidden');
    this.targetOptionTypeDom?.appendChild(tmpDom.firstChild);

    // add to main form
    if (this.targetOptionTypeDom) {
      let tmp = document.createElement('div');
      tmp.innerHTML = hiddenFieldComponent(optionType, this.optionsCounter[optionType]);
      [...tmp.children].forEach(child => {
        this.mainForm?.appendChild(child);
      });
    }
  }

  removeOptionValue(event) {
    let targetOptionValueId = event.currentTarget.dataset.optionValueDomId;
    let optionType = event.currentTarget.dataset.optionType;
    document.getElementById(targetOptionValueId).remove();
    this.optionsCounter[optionType]--;

    if (this.targetOptionTypeDom.children.length < 1) {
      this.targetOptionTypeDom.classList.add('hidden');
    }
  }

  addOptionValueChips() {
    // TODO
  }

  punchOptionValue(event) {
    let optionType = event.currentTarget.dataset.optionType;
    let optionValueIndex = event.currentTarget.dataset.optionValueIndex;

    let optionTypeField = document.getElementById(`${optionType}_${optionValueIndex}`);
    let optionTypeValueField = document.getElementById(`${optionType}_${optionValueIndex}_value`);

    optionTypeField.value = optionType;

    if (event.currentTarget.dataset.colorName) {
      document.getElementById(`add_color_${optionValueIndex}_value_display`).value = event.currentTarget.value;
    } else {
      optionTypeValueField.value = event.currentTarget.value;
    }
  }
}