import {popSnackbar} from "../../../snackbar";

export const productCard = (image, index, type, imageFor, imageRemoved, productId, newExistingImage ) => {
  const imageId = `gallery-${generateRandomString(6)}.${image.content_type?.split('/')[1] || 'jpg'}`;
  const data = {
    url: image.url,
    blob: image.blob,
    content_type: image.content_type,
    // crop_image: image.crop_image,
    index: index,
    imageId: imageId,
    type: type,
    for: imageFor,
    imageRemoved: imageRemoved,
    productId: productId,
    newExistingImage: newExistingImage
  };
  const url = `/admin/attachments/product_image_display?image=${JSON.stringify(data)}`;
  return fetch(url).then((response) => response.text())
    .then((image_dom) => {
      let data = JSON.parse(image_dom);
      let dom = data.image_dom;
      return dom
    });
};

export const renderProductCard = async (image, index, tmpDom, type, imageFor, imageRemoved, productId, newExistingImage = false) => {
  try {
    tmpDom.innerHTML = await productCard(image, index, type, imageFor, imageRemoved, productId || null, newExistingImage);
  } catch (error) {
    popSnackbar(error, "Image render unsuccessful")
  }
};

function generateRandomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const generateRandomStringPublic = (length) => {
  return generateRandomString(length);
}
