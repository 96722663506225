import {setMessage} from './base_service';
import {I18n} from '../../translation';

export const memberIdValidation = function (input, id) {
  let memberIdCode = /^[0-9]{4,6}$/;
  if (input.value.match(memberIdCode)) {
    setMessage(id);
    return true;
  } else {
    if (input.value) {
      setMessage(id, I18n[window.currentLocale]['invalid_member_id']);
      return false;
    } else {
      setMessage(id);
      return true;
    }
  }
};
