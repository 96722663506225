import {Controller} from 'stimulus';
import {popSnackbar} from "../services/snackbar";
import {I18n} from "../translation";

export default class extends Controller {
  inputId;

  connect() {
    let initialValue = this.element.dataset.initialValue;
    this.inputId = this.element.dataset.inputid;
    document.getElementById(this.inputId).value = initialValue;

  }

  selectOption(event) {
    document.getElementById(this.inputId).value = event.currentTarget.dataset.value;
    document.getElementById('retailer_id').value = event.currentTarget.dataset.id;

    document.getElementById('select_retailer').disabled = false;
  }

  selected(){
    popSnackbar('error', I18n[window.currentLocale]['retailer_selected']);
  }
}
