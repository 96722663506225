import {Controller} from 'stimulus';
import {
  passwordConfirmationValidation,
  passwordValidation,
  passwordVisibility
} from '../services/authentications/password_service';
import {validityCount} from '../services/authentications/validation-count_service';

export default class extends Controller {
  validCount = [];
  newPassword = document.getElementById('password');
  confirmPassword = document.getElementById('password_confirmation');
  passwordVisibilities = document.querySelectorAll('.set_password_visibility');
  policyAgreementCheckBox = document.getElementById('confirmation_check');
  btnSend = document.getElementById('accept-invite-btn');

  connect() {
    this.newPassword.addEventListener('input', function () {
      ValidatePassword(this);
    });

    this.confirmPassword.addEventListener('input', function () {
      ValidatePasswordConfirmation(this);
    });

    passwordVisibility(this.passwordVisibilities);

    let _this = this;

    function ValidatePassword(input) {
      let passwordStatus = passwordValidation(input, 'new_password_error_message');
      validityCount(passwordStatus, input.id, _this.validCount);
      ValidatePasswordConfirmation(_this.confirmPassword);
    }

    function ValidatePasswordConfirmation(input) {
      let password = _this.newPassword.value;
      let passwordConfirmationStatus = passwordConfirmationValidation(input, password, 'password_confirmation_error_message');
      validityCount(passwordConfirmationStatus, input.id, _this.validCount);

      if (_this.validCount.length === 2) {
        _this.policyAgreement();
      } else {
        _this.btnSend.disabled = true;
      }
    }
  }

  policyAgreement() {
    this.btnSend.disabled = !(this.policyAgreementCheckBox.checked && this.validCount.length === 2);
  }
}
