import {Controller} from 'stimulus';
import {message} from '../services/shared';
import {I18n} from '../translation';
import {cartPriceAndItemReplaceDom} from '../services/carts/replace_dom';
import {setQuantity} from '../services/carts/update_quantity'; // Note:Remove the count for membership as 1 in cart as client change request

export default class extends Controller {
  freeGiftVariant = document.getElementById('free-product-0');
  variantId;
  retailerId = [];
  connect() {
    if(JSON.parse(this.element.dataset.checkInWarehouse || 'false')){
      this.checkInWarehouse();
    }
    if(JSON.parse(this.element.dataset.checkToggle || 'false')){
      this.checkMemberToggle();
    }
    let toggle = document.getElementById('main-div');
    if (JSON.parse(toggle?.dataset?.cart || 'false') || JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
      this.checkCartMembership(false)
    }else{
      this.checkCartMembership(true)
    }
    this.selectProduct()
    this.buttonConditions()
  }

  buttonConditions(){
    if(JSON.parse(sessionStorage.getItem('purchaseMembershipOn')) && (window.location.search.includes('register=true'))){
      document.getElementById('continue-registration')?.classList.add('hidden');
      document.getElementById('login')?.classList.remove('hidden');
    }else if(!(window.location.search.includes('register=true'))){
      document.getElementById('continue-registration')?.classList.add('hidden');
      document.getElementById('login')?.classList.remove('hidden');
    }
    else{
      document.getElementById('continue-registration')?.classList.remove('hidden');
      document.getElementById('login')?.classList.add('hidden');
    }
  }
  checkCartMembership(disable){
    if(disable){
      document.getElementById('login')?.setAttribute("disabled","true");
      document.getElementById('guest')?.setAttribute("disabled","true");
      document.getElementById('continue')?.setAttribute("disabled","true");
      document.getElementById('continue-registration')?.setAttribute("disabled","true");
      document.getElementById('guest')?.classList.add("!border-none");
      document.getElementById('continue')?.classList.add("!border-none");
    }else{
      document.getElementById('login')?.removeAttribute("disabled");
      document.getElementById('guest')?.removeAttribute("disabled");
      document.getElementById('continue')?.removeAttribute("disabled");
      document.getElementById('continue-registration')?.removeAttribute("disabled");
      document.getElementById('guest')?.classList.remove("!border-none");
      document.getElementById('continue')?.classList.remove("!border-none");
    }
  }

  checkInWarehouse(){
    const dataset = this.element.dataset;
    const preSelectVariant = JSON.parse(dataset.preSelectVariant);
    if (dataset.nextIndex && preSelectVariant[+dataset.nextIndex]) {
      document.getElementById(preSelectVariant[+dataset.nextIndex])?.click();
    }
  }

  checkMemberToggle(){
    if(JSON.parse(this.element.dataset.memberRole) || this.element.dataset.user === 'b2b_customer'){
      sessionStorage.setItem('purchaseMembershipOn', 'false');
    }
    if (JSON.parse(this.element.dataset.membershipToggle || 'false') && !JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
      sessionStorage.setItem('purchaseMembershipOn', 'true');
      // setQuantity('add', 0);
    }
    if(JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
      this.checkCartMembership(false);
      if(document.getElementById('free-product-list')){
        document.getElementById('free-product-list').style.display = 'block';        
      }
      if(document.getElementById('membership_div')) {
        document.getElementById('membership_div').classList.remove('hidden');
        document.getElementById('grand_total_amount').classList.remove('hidden');
      }
      if(document.getElementById('membership_terms_check')){
        document.getElementById('membership_terms_check')?.classList.remove('hidden');
      }
      if(document.getElementById('magazine_newsletter_check')){
        document.getElementById('magazine_newsletter_div')?.classList.remove('hidden');
        document.getElementById('magazine_newsletter_check').innerText = I18n[window.currentLocale]['snowactive'];
      }
      if(document.getElementById('purchase_membership_toggle')){
        document.getElementById('purchase_membership_toggle').checked = true;
      }
    } else {
      this.checkCartMembership(true);
      if(document.getElementById('free-product-list')) {
        document.getElementById('free-product-list').style.display = 'none';
      }
      if(document.getElementById('membership_div')) {
        document.getElementById('membership_div').classList.add('hidden');
        document.getElementById('grand_total_amount').classList.add('hidden');

      }
      if(document.getElementById('membership_terms_check')){
        document.getElementById('membership_terms_check')?.classList.add('hidden');
      }
      if(document.getElementById('magazine_newsletter_check')){
        if(this.element.dataset.user === 'registered_user'){
          document.getElementById('magazine_newsletter_check').innerText = I18n[window.currentLocale]['snow_active_newsletter'];
        }else {
          document.getElementById('magazine_newsletter_div')?.classList.add('hidden');
        }
      }
      if(document.getElementById('purchase_membership_toggle')) {
        document.getElementById('purchase_membership_toggle').checked = true;
        document.getElementById('purchase_membership_toggle').dataset.subtract = 'false';
        document.getElementById('purchase_membership_toggle').click();
        document.getElementById('purchase_membership_toggle').dataset.subtract = 'true';
      }
      this.variantId = null;
    }
  }

  input(event) {
    let quantity = event.currentTarget.value;
    let valueDom = event.currentTarget;
    if (!event.currentTarget.value || parseInt(event.currentTarget.value) < 1) {
      event.currentTarget.value = 1;
      message(I18n[window.currentLocale]['minimum_order_quantity'], 'error');
    }
    fetch(`/items/${event.currentTarget.dataset.itemId}`, {
      method: 'PUT',
      headers: this.headers(),
      body: JSON.stringify({
        item: {
          quantity: parseInt(quantity),
          membership: JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))
        }
      })
    }).then((response) => response.text())
      .then((data) => {
        let response = JSON.parse(data);
        if (!response.error) {
          cartPriceAndItemReplaceDom(response);
          document.getElementById('cart_quantity').innerHTML = response.total_quantity;
          document.getElementById('cart_quantity_mobile').innerHTML = response.total_quantity;
          // setQuantity('add', 0);
        } else {
          valueDom.value = response.quantity;
          document.getElementById('cart_quantity').innerHTML = response.total_quantity;
          document.getElementById('cart_quantity_mobile').innerHTML = response.total_quantity;
          // setQuantity('add', 0);
          message(response.message, 'error');
        }
      });
  }

  reduce(event) {
    let id = event.currentTarget.dataset.id;
    let quantity = document.getElementById(`${id}_value`);
    if (parseInt(quantity.value) === 1) {
      return message(I18n[window.currentLocale]['minimum_order_quantity'], 'error');
    }

    if (parseInt(quantity.value) > 0) {
      fetch(`/items/${event.currentTarget.dataset.itemId}`, {
        method: 'PUT',
        headers: this.headers(),
        body: JSON.stringify({
          item: {
            quantity: parseInt(quantity.value) - 1,
            membership: JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))
          }
        })
      }).then((response) => response.text())
        .then((data) => {
          let response = JSON.parse(data);
          if (!response.error) {
            this.quantityChangeResponder(response);
            // setQuantity('add', 0);
          }
        });
    }
  }

  increase(event) {
    let id = event.currentTarget.dataset.id;
    let quantity = document.getElementById(`${id}_value`);
    fetch(`/items/${event.currentTarget.dataset.itemId}`, {
      method: 'PUT',
      headers: this.headers(),
      body: JSON.stringify({
        item: {
          quantity: parseInt(quantity.value) + 1,
          membership: JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))
        }
      })
    }).then((response) => response.text())
      .then((data) => {
        let response = JSON.parse(data);
        if (!response.error) {
          this.quantityChangeResponder(response);
          // setQuantity('add', 0);
        } else {
          message(response.message, 'error');
        }
      });
  }

  quantityChangeResponder(response) {
    // const initialPrice = +document.getElementById('total_amount').innerText.split(' ')[1];
    cartPriceAndItemReplaceDom(response);
    document.getElementById('cart_quantity').innerHTML = response.total_quantity;
    document.getElementById('cart_quantity_mobile').innerHTML = response.total_quantity;
    // const updatedPrice = +document.getElementById('total_amount').innerText.split(' ')[1];
    // if ((initialPrice !== 0 && updatedPrice === 0) || (initialPrice === 0 && updatedPrice !== 0)) {
    //   // eslint-disable-next-line no-undef
    //   Turbo.visit('/carts', { target: 'payment-means-details' });
    // }
  }

  clear(event) {
    let item = JSON.parse(event.currentTarget.dataset.item);
    // eslint-disable-next-line no-undef
    gtag('event', 'remove_from_cart', {
      currency: 'CHF',
      value: 30.00, // custom metric
      items: item
    });
    fetch(`/items/${event.currentTarget.dataset.itemId}`, {
      method: 'DELETE',
      headers: this.headers(),
      body: JSON.stringify({
        item: {
          membership: JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))
        }
      })
    }).then((response) => response.text())
      .then((data) => {
        let response = JSON.parse(data);
        if (!response.error) {
          if (response.empty) {
            document.getElementById('cart_items')?.remove();
            document.getElementById('cart_quantity').innerHTML = response.total_quantity;
            document.getElementById('cart_quantity_mobile').innerHTML = response.total_quantity;
            // setQuantity('add', 0);
            cartPriceAndItemReplaceDom(response);
            this.checkCartMembership(!(JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))) && true)
            return;
          }
          this.checkCartMembership(false)
          cartPriceAndItemReplaceDom(response);
          document.getElementById('cart_quantity').innerHTML = response.total_quantity;
          document.getElementById('cart_quantity_mobile').innerHTML = response.total_quantity;
          // setQuantity('add', 0);
        }
      });
  }

  addToCart(event) {
    let item = JSON.parse(event.currentTarget.dataset.item);
    // eslint-disable-next-line no-undef
    gtag('event', 'add_to_cart', {
      currency: 'CHF',
      value: 30.00, // custom metric
      items: item
    });

    const itemQuantityInput = document.getElementById('item_quantity_input');
    fetch(`/carts?variant_id=${event.currentTarget.dataset.id}&quantity=${itemQuantityInput ? itemQuantityInput.value : 1}`, {
      method: 'PUT',
      headers: this.headers(),
      body: JSON.stringify({})
    }).then((response) => response.text())
      .then((data) => {
        let response = JSON.parse(data);
        if (response.limit_stock){
          message(I18n[window.currentLocale]['limit_stock_reached'], 'error');
          return;
        }
        if (response.in_cart) {
          message(I18n[window.currentLocale]['already_in_cart'], 'error');
        } else {
          let quantity = JSON.parse(sessionStorage.getItem('purchaseMembershipOn')) ? 0 : 1;
          document.getElementById("cart-dom").innerHTML=response.items
          setQuantity('add', quantity);
          message(I18n[window.currentLocale]['product_added_cart'], 'success');
        }
      });
  }

  headers() {
    return {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    };
  }

  onItemDetailQuantityChange(event) {
    ['member-discount-price', 'original_price', 'applicable_price']
      .map(id => document.getElementById(id))
      .filter(dom => !!dom)
      .forEach(dom => dom.innerHTML
          = `CHF ${(+dom.dataset.singlePrice * +event.currentTarget.value || +dom.dataset.singlePrice)
          .toFixed(2)}`);
  }

  unselectProduct(){
    const freeProductsElement = document.querySelectorAll('.free-product');
    freeProductsElement.forEach(value => {
      value.classList.remove('border', 'border-blue');
    });
  }
  selectProduct(event){
    this.variantId = event?.currentTarget?.dataset?.variantId || this.freeGiftVariant?.dataset?.variantId;
    this.unselectProduct();
    const id = event?.currentTarget.getAttribute('id') || 'free-product-0' ;
    document.getElementById(id)?.classList?.add('border', 'border-blue');
    if(document.getElementById('selectVariant')){
      document.getElementById('selectVariant').innerText = event?.currentTarget?.dataset?.type || this.freeGiftVariant?.dataset?.type;
    }
    if(document.getElementById('gift_option')){
      document.getElementById('gift_option').checked = false
    }
  }

  noGiftSelected(event){
    if(event.target.checked){
      document.getElementById('selectVariant').innerText = I18n[window.currentLocale]['No_gift_product_selected']
      this.unselectProduct();
      this.variantId = " "
    }else{
      document.getElementById('selectVariant').innerText = " "
      this.selectProduct()
    }
  }

  selectRetailer(event){
    let selectedValue = event.currentTarget.dataset.value;
    let variantId = event.currentTarget.dataset.variant_id;
    let actualValue = event.currentTarget.dataset.actual_value;
    this.updateOrPush(variantId, actualValue)
    document.getElementById(event.currentTarget.dataset.inputId).value = selectedValue;
  }

  updateOrPush(variantId, actualValue) {
    let existingObject = this.retailerId.find(obj => obj.variant_id === variantId);

    existingObject ? existingObject.retailer_id = actualValue :
      this.retailerId.push({ variant_id: variantId, retailer_id: actualValue });
  }

  showFreeProducts(event){
    this.checkCartMembership(!(event.currentTarget.checked || [true, 'true'].includes(document.getElementById('cart_items')?.dataset?.cart)))
    sessionStorage.setItem('purchaseMembershipOn', event.currentTarget.checked);
    if(event.currentTarget.checked){
      document.getElementById('free-product-list').style.display = 'block';
      document.getElementById('membership_div').classList.remove('hidden');
      document.getElementById('grand_total_amount').classList.remove('hidden');
      document.getElementById('membership_terms_check')?.classList.remove('hidden');
      if(document.getElementById('magazine_newsletter_check')){
        document.getElementById('magazine_newsletter_div')?.classList.remove('hidden');
        document.getElementById('magazine_newsletter_check').innerText = I18n[window.currentLocale]['snowactive'];
      }
      if(document.getElementById('guest')){
        document.getElementById('guest').innerText = I18n[window.currentLocale]['its_my_first_time'];
      }
      // setQuantity('add', 0);
    } else {
      document.getElementById('free-product-list').style.display = 'none';
      document.getElementById('membership_div').classList.add('hidden');
      document.getElementById('grand_total_amount').classList.add('hidden');
      document.getElementById('membership_terms_check')?.classList.add('hidden');
      if(document.getElementById('magazine_newsletter_check')){
        if(this.element.dataset.user === 'registered_user'){
          document.getElementById('magazine_newsletter_check').innerText = I18n[window.currentLocale]['snow_active_newsletter'];
        }else {
          document.getElementById('magazine_newsletter_div')?.classList.add('hidden');
        }
      }
      if(document.getElementById('guest')) {
        document.getElementById('guest').innerText = I18n[window.currentLocale]['continue_as_guest'];
      }
      this.variantId = null;
      // if(JSON.parse(document.getElementById('purchase_membership_toggle').dataset.subtract)){
        // setQuantity('subtract', 1);
      // }
    }
    fetch(`/items/membership?membership=${JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))}`, {
      method: 'GET',
      headers: this.headers()
    }).then((response) => response.text()
      .then((data) => {
        let response = JSON.parse(data);
        if (!response.error) {
          cartPriceAndItemReplaceDom(response);
        } else {
          message(response.message, 'error');
        }
      }));
  }
}
