export const countryCode = function (country) {
  let countryCodeShortName = {
    'Albania' : { 'short_name' : 'AL', 'code' : '355' },
    'Andorra' : { 'short_name' : 'AD', 'code' : '376' },
    'Armenia' : { 'short_name' : 'AM', 'code' : '374' },
    'Austria' : { 'short_name' : 'AT', 'code' : '43' },
    'Azerbaijan' : { 'short_name' : 'AZ', 'code' : '994' },
    'Belarus' : { 'short_name' : 'BY', 'code' : '375' },
    'Belgium' : { 'short_name' : 'BE', 'code' : '32' },
    'Bosnia and Herzegovina' : { 'short_name' : 'BA', 'code' : '387' },
    'Bulgaria' : { 'short_name' : 'BG', 'code' : '359' },
    'Croatia' : { 'short_name' : 'HR', 'code' : '385' },
    'Cyprus' : { 'short_name' : 'CY', 'code' : '357' },
    'Czech Republic' : { 'short_name' : 'CZ', 'code' : '420' },
    'Denmark' : { 'short_name' : 'DK', 'code' : '45' },
    'Estonia' : { 'short_name' : 'EE', 'code' : '372' },
    'Finland' : { 'short_name' : 'FI', 'code' : '358' },
    'France' : { 'short_name' : 'FR', 'code' : '33' },
    'Georgia' : { 'short_name' : 'GE', 'code' : '995' },
    'Germany' : { 'short_name' : 'DE', 'code' : '49' },
    'Greece' : { 'short_name' : 'GR', 'code' : '30' },
    'Hungary' : { 'short_name' : 'HU', 'code' : '36' },
    'Iceland' : { 'short_name' : 'IS', 'code' : '354' },
    'Ireland' : { 'short_name' : 'IE', 'code' : '353' },
    'Italy' : { 'short_name' : 'IT', 'code' : '39' },
    'Kazakhstan' : { 'short_name' : 'KZ', 'code' : '7' },
    'Kosovo' : { 'short_name' : 'XK', 'code' : '381' },
    'Latvia' : { 'short_name' : 'LV', 'code' : '371' },
    'Liechtenstein' : { 'short_name' : 'LI', 'code' : '423' },
    'Lithuania' : { 'short_name' : 'LT', 'code' : '370' },
    'Luxembourg' : { 'short_name' : 'LU', 'code' : '352' },
    'Macedonia' : { 'short_name' : 'MK', 'code' : '389' },
    'Malta' : { 'short_name' : 'MT', 'code' : '356' },
    'Moldova' : { 'short_name' : 'MD', 'code' : '373' },
    'Monaco' : { 'short_name' : 'MC', 'code' : '377' },
    'Montenegro' : { 'short_name' : 'ME', 'code' : '382' },
    'Netherlands' : { 'short_name' : 'NL', 'code' : '31' },
    'Norway' : { 'short_name' : 'NO', 'code' : '47' },
    'Poland' : { 'short_name' : 'PL', 'code' : '48' },
    'Portugal' : { 'short_name' : 'PT', 'code' : '351' },
    'Romania' : { 'short_name' : 'RO', 'code' : '40' },
    'Russia' : { 'short_name' : 'RU', 'code' : '7' },
    'San Marino' : { 'short_name' : 'SM', 'code' : '378' },
    'Serbia' : { 'short_name' : 'RS', 'code' : '381' },
    'Slovakia' : { 'short_name' : 'SK', 'code' : '421' },
    'Slovenia' : { 'short_name' : 'SI', 'code' : '386' },
    'Spain' : { 'short_name' : 'ES', 'code' : '34' },
    'Sweden' : { 'short_name' : 'SE', 'code' : '46' },
    'Switzerland' : { 'short_name' : 'CH', 'code' : '41' },
    'Turkey' : { 'short_name' : 'TR', 'code' : '90' },
    'Ukraine' : { 'short_name' : 'UA', 'code' : '380' },
    'United Kingdom' : { 'short_name' : 'GB', 'code' : '44' },
    'Vatican City' : { 'short_name' : 'VA', 'code' : '379' }
  };
  return countryCodeShortName[country];
};
