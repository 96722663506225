import {Controller} from "stimulus";
import {constructArrayParams} from "../services/construct_array_params";

export default class extends Controller {
  selectedTaxonPromotion = [];

  collectionCheck(event) {
    let currentCheckID = event.currentTarget.dataset.taxonPromotionId;
    event.currentTarget.checked ? this.addProduct(currentCheckID) : this.removeProduct(currentCheckID);
    this.submittable();
    document.getElementById('discount_detail_collection_selected_count').innerHTML = `${this.selectedTaxonPromotion.length} collection selected.`;
  }

  addProduct(taxon_promotion_id) {
    if (!this.selectedTaxonPromotion.includes(taxon_promotion_id)) {
      this.selectedTaxonPromotion.push(taxon_promotion_id);
    }
  }

  removeProduct(taxon_promotion_id) {
    if (this.selectedTaxonPromotion.includes(taxon_promotion_id)) {
      this.selectedTaxonPromotion.splice(this.selectedTaxonPromotion.indexOf(taxon_promotion_id), 1);
    }
  }

  submittable() {
    this.selectedTaxonPromotion.length > 0 ? this.enableSubmitButton() : this.disableSubmitButton();
  }

  enableSubmitButton() {
    document.getElementById('discount_detail_collection_remove_div').classList.remove('hidden');
  }

  disableSubmitButton() {
    document.getElementById('discount_detail_collection_remove_div').classList.add('hidden');
  }

  removeDiscount(event) {
    let url = `/admin/discounts/detail_collection_selection?${constructArrayParams(this.selectedTaxonPromotion, 'taxon_promotions')}`;
    fetch(url).then(response => {
      response.text().then(text => {
        document.getElementById('discount-remove').innerHTML = text;
      });
    });
  }
}
