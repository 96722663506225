import {Controller} from 'stimulus';
import Cropper from 'cropperjs';
import {popSnackbar} from "../services/snackbar";
import {editTaxonomy, editTaxon} from "../services/templates/api";

export default class extends Controller {

  imageSrc;
  productImages = [];
  cancelButton;
  submit;
  currentCropper = null;
  loaderId;
  from;


  crop(event) {
    const image = document.getElementById(`${event.currentTarget.dataset.imageId}`);
    this.imageSrc = image.src;
    if (this.currentCropper) {
      this.currentCropper.destroy();
      this.currentCropper = null;
    }
    this.cropImage(image, event);
    this.highlightSelected(event)
  }

  highlightSelected(event) {
    let buttonDom = document.getElementById(event.currentTarget.dataset.buttonDiv);
    let buttons = buttonDom.querySelectorAll(".ratioButton");
    this.removehighlight(buttons)
    event.currentTarget.classList.add("bg-blue/[0.08]");
  }

  removehighlight(buttons) {
    buttons.forEach(button => {
      button.classList.remove("bg-blue/[0.08]");
    });
  }


  cropImage(image, event) {
    let _this = this
    image.src = this.imageSrc;
    let buttonDom = document.getElementById(event.currentTarget.dataset.buttonDiv);
    let renderButton = event.currentTarget.dataset.renderButton;
    let ratio;
    let dom;
    switch (event.currentTarget.dataset.ratio) {
      case '21:4':
        ratio = 21 / 4
        dom = `${event.currentTarget.dataset.imageId}-21-4`
        break;
      case '16:9':
        ratio = 16 / 9
        dom = `${event.currentTarget.dataset.imageId}-16-9`
        break;
      case '3:4':
        ratio = 3 / 4
        dom = `${event.currentTarget.dataset.imageId}-3-4`
        break;
      case '1:1':
        ratio = 1 / 1
        dom = `${event.currentTarget.dataset.imageId}-1-1`
        break;
    }
    const cropper = new Cropper(image, {
      aspectRatio: ratio,
      restore: true,
      checkCrossOrigin: true,
      zoomable: false,
      viewMode: 1,
      ready() {
        document.getElementById(renderButton).addEventListener('click', () => {
          const canvas = cropper.getCroppedCanvas();
          const image = document.getElementById(dom);
          if (canvas) {
            image.src = canvas?.toDataURL('');
            image.onload = () => {
              cropper.destroy();
            };
          }
          let buttons = buttonDom.querySelectorAll(".ratioButton");
          _this.removehighlight(buttons)
        });
      },
    });
    this.currentCropper = cropper;
  }

  saveUpdateBrand(event) {
    let brandId = event.currentTarget.dataset.refId;
    this.updateBrand(brandId)
  }

  updateBrand(brandId) {
    let formData = new FormData();
    formData.append('brand[custom_image]', JSON.stringify(this.productImages));
    formData.append('brand[croppedimage]', true);
    fetch(`/admin/brands/${brandId}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: formData
    }).then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          popSnackbar('notice', data.message);
          setTimeout(() => {
            window.location.href = '/admin/brands';
          }, 1500);
        } else {
          popSnackbar('error', data.message);
          loader.classList.add('hidden');
          loader.parentElement.disabled = false;
        }
      });
  }


  saveUpdateCategory(event) {
    let data = []
    let taxonomyId = event.currentTarget.dataset.refId;
    let tmp = {
      edit: false,
      custom_images: this.productImages
    };
    data.push(tmp)
    editTaxonomy(data, taxonomyId);
  }

  saveUpdateSubCategory(event) {
    let data = []
    let taxonId = event.currentTarget.dataset.refId;
    let tmp = {
      cropped_image: true,
      custom_images: this.productImages
    };
    data.push(tmp)
    editTaxon(data, taxonId, "taxon");
  }


  uploadCroppedImage(event) {
    this.imageSrc = document.getElementById(`${event.currentTarget.dataset.imageIndexSrc}`).src;
    this.loaderId = `loader-${event.currentTarget.dataset.imageIndex}`
    this.showLoader()
    let _this = this
    _this.cancelButton = `cancelButton-${event.currentTarget.dataset.imageIndex}`;
    this.from = event.currentTarget.dataset.from;
    if (event.currentTarget.dataset.from) {
      _this.submit = `submitbutton-${event.currentTarget.dataset.id}`
    }
    this.getCroppedImageUrl(event).then((croppedImageObj) => {
      this.uploadCroppedObj(croppedImageObj).then(result => {
      })
    }).catch(error => {
      popSnackbar(error, "Cropping image unsuccessful")
    });
  }

  showLoader() {
    document.getElementById(this.loaderId).style.display = "block";
  }

  hideLoader() {
    document.getElementById(this.loaderId).style.display = "none";
  }

  async uploadCroppedObj(croppedImageObj) {
    const formData = new FormData();
    let _this = this;
   ['blob 0','blob 1','blob 2','blob 3'].forEach(key => {
      formData.append(key, croppedImageObj[key]);
    });
    formData.append("original_url", this.imageSrc);
    fetch('/admin/attachments', {
      method: 'post',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => response.json())
      .then(data => {
        _this.productImages.push(data);
        if (!(_this.from === 'product')) {
          document.getElementById(_this.submit).click();
        }
        document.getElementById(_this.cancelButton).click();
        _this.hideLoader();
        popSnackbar("notice", "Image cropping Successful");

      })
  }

  async getCroppedImageUrl(event) {
    const croppedDiv = document.getElementById(`${event.currentTarget.dataset.domId}`);
    const cropImages = croppedDiv.querySelectorAll('.cropImage');

    const croppedImageObj = {};
    await Promise.all(Array.from(cropImages).map(async (cropImage, index) => {
      const imgBlob = await this.loadXHR(cropImage.src);
      imgBlob.filename = `${cropImage.dataset.ratio}.${imgBlob.type.split('/')[1]}`;
      croppedImageObj[`blob ${index}`] = imgBlob;
    }));
    return croppedImageObj;
  }

  loadXHR(url) {
    return new Promise(function (resolve, reject) {
      try {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.onerror = function () {
          reject('Network error.');
        };
        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject('Loading error:' + xhr.statusText);
          }
        };
        xhr.send();
      } catch (err) {
        reject(err.message);
      }
    });
  }
}
