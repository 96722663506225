import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';

export default class extends Controller {
  connect() {
    if (this.element.dataset.notice === 'successful') {
      popSnackbar('notice', `${I18n[window.currentLocale]['order_state_updated_successful']}`);
    } else if(this.element.dataset.notice === 'error') {
      popSnackbar('error', `${I18n[window.currentLocale]['order_state_updated_unsuccessful']}`);
    }
  }
}