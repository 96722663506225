import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {I18n} from '../translation';
import {LOCALES} from '../constants/constants';

export default class extends Controller {

  formData = {
    ribbonLocales: {
      en: '',
      fr: '',
      de: '',
    },
    colour: ''
  };

  modalId = '';
  localeChips = [];
  currentLocale = 'en';
  languageErrorTriggered = false;

  connect() {
    const ribbonData = JSON.parse(this.element.dataset.ribbonData);
    this.formData.ribbonLocales = { en: ribbonData.en, de: ribbonData.de, fr: ribbonData.fr };
    this.formData.colour = ribbonData.color;
    this.modalId = ribbonData.id;
    this.localeChips = document.getElementById(`edit_ribbon_${this.modalId}`).querySelectorAll('[class*="locale-chip"]');
  }

  copyToAllLocale() {
    document.getElementById('copyCategoryContent').removeAttribute('disable');
  }

  handleInput(event) {
    this.formData.ribbonLocales[this.currentLocale] = event.target.value;
    freeFieldValidation(event.target, `${event.target.id}_error_message`);
  }

  switchLocale(event) {
    this.currentLocale = event.currentTarget.dataset.locale;

    let data = this.formData.ribbonLocales[this.currentLocale]; // prefill if data already present.
    document.getElementById(`ribbon_name_${this.modalId}`).value = data || ''; // clear field for other locale.

    this.updateStyles(this.currentLocale);
  }

  setColour(event) {
    const color = JSON.parse(event.currentTarget.dataset.ribbonColor);
    this.formData.colour = color.hex;
    document.getElementById(`ribbon_color_${this.modalId}`).value = color.name;
    event.currentTarget.closest('form').querySelector('#ribbon-color-display').style.backgroundColor = color.hex;
  }

  updateStyles(locale) {
    this.localeChips.forEach(res => {
      if(res.id === locale){
        res.classList.remove('sk-language-inactive');
        res.classList.add('sk-language-active');
      } else {
        res.classList.remove('sk-language-active');
        res.classList.add('sk-language-inactive');
      }
    });
  }

  duplicateAll() {
    this.copyCategoryName();
    if (this.duplicated(this.currentLocale)) {
      popSnackbar('notice', I18n[window.currentLocale]['content_duplication_success_notice']);
      this.removeCopyValidator();
    } else {
      popSnackbar('error', I18n[window.currentLocale]['content_duplication_success_error']);
    }
  }

  copyCategoryName() {
    Object.keys(this.formData.ribbonLocales).forEach(locale => {
      if (locale !== this.currentLocale) {
        this.formData.ribbonLocales[locale] = this.formData.ribbonLocales[this.currentLocale];
      }
    }, this);
  }

  duplicated(currentLocale) {
    return Object.keys(this.formData.ribbonLocales).every((locale) => {
      return this.formData.ribbonLocales[locale] === this.formData.ribbonLocales[currentLocale];
    }, this);
  }

  saveRibbon(event) {
    const id = event.currentTarget.dataset.id;
    this.updateRibbon(id);
  }

  updateRibbon(id){
    fetch(`/admin/ribbons/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ ...this.formData.ribbonLocales, color: this.formData.colour })
    }).then(response => {
      response.text().then(text => {
        let vDom = document.createElement('div');
        vDom.innerHTML = text;

        document.getElementById(`edit_ribbon_${this.modalId}`).click();

        document.getElementById('product_labels').replaceWith(vDom.children[0]);
        popSnackbar('notice', 'Ribbon Updated');
      });
    }).catch(() => {
      popSnackbar('error', 'Ribbon Update Failed');
    });
  }

  validateFormAndSave(event) {
    const form = document.getElementById('edit-ribbon-form');
    let valid = true;
    form.querySelectorAll('input').forEach(input => {
      const errorElementId = input.parentElement.querySelector('p[class*=\'error-message\']').id;
      valid = freeFieldValidation(input, errorElementId) && valid;
    });

    LOCALES.every(locale => !!this.formData.ribbonLocales[locale]) && valid ? this.saveRibbon(event) : this.validateCopyError();
  }

  validateCopyError() {
    Object.keys(this.formData.ribbonLocales).forEach(res => {
      if(!this.formData.ribbonLocales[res]){
        popSnackbar('error', 'You need to fill for all Locales!');
      }
    });
  }

  removeCopyValidator() {
    this.languageErrorTriggered = false;
    const errorInfo = document.getElementById(`language-switcher_edit_ribbon_${this.modalId}`).querySelector('.text-error');
    if (errorInfo) {
      errorInfo.remove();
    }
  }

  cancelAndResetModal() {
    this.resetFormData();
    this.removeValidations();
  }

  removeValidations() {
    this.removeCopyValidator();
    document.getElementById(`ribbon_name_${this.modalId}_error_message`).innerHTML = '';
    this.updateStyles('en');
  }

  resetFormData() {
    let controllerElement = document.getElementById(`edit_ribbon_${this.modalId}`);
    const ribbonData = JSON.parse(controllerElement.dataset.ribbonData);
    this.formData.ribbonLocales = { en: ribbonData.en, de: ribbonData.de, fr: ribbonData.fr };
    this.languageErrorTriggered = false;
  }
}
