import {Controller} from 'stimulus';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {validityCount} from '../services/authentications/validation-count_service';
import {completeValidation} from '../services/authentications/complete-validation_service';
import {phoneValidation} from '../services/authentications/phone_service';
import {countryCode} from '../services/authentications/country-code_service';
import {AsYouType} from 'libphonenumber-js';
import {zipcodeValidation} from '../services/authentications/zipcode_service';
import {streetNameValidation} from '../services/authentications/street-validation_service';
import {validateLength} from '../services/authentications/min-length_service';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';

export default class extends Controller {
  connect() {
    let code = countryCode('Switzerland');
    let validCount = ['shipping_phone', 'shipping_state'];
    let numberOfRequiredFields = 7;

    document.getElementById('default_address_check_box').addEventListener('click', function () {
      document.getElementById('default_address_value').value = !!this.checked;
    });

    let functionMap = {
      'shipping_first_name': ValidateName,
      'shipping_last_name': ValidateName,
      'shipping_street_name': ValidateStreet,
      'shipping_zipcode': ValidateZipcode,
      'shipping_city': ValidateName
    };

    let inputs = document.querySelectorAll('.shipping_address_field');

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('input', function () {
        functionMap[this.id](this);
        completeValidation('shipping_address_submit_button', validCount.length !== numberOfRequiredFields);
      });
    }

    function ValidateName(input) {
      let freeFieldStatus = freeFieldValidation(input, `${input.id}_error_message`);
      validityCount(freeFieldStatus, input.id, validCount);
    }

    function ValidateStreet(input) {
      let streetFieldStatus = streetNameValidation(input, `${input.id}_error_message`);
      validityCount(streetFieldStatus, input.id, validCount);
    }

    function ValidateZipcode(input) {
      let zipcodeStatus = zipcodeValidation(input, `${input.id}_error_message`);
      validityCount(zipcodeStatus, input.id, validCount);
    }

    document.getElementById('shipping_phone').addEventListener('input', function () {
      ValidatePhone(this);
    });

    function ValidatePhone(input) {
      input.value = new AsYouType(code['short_name']).input(input.value);
      let phoneStatus = phoneValidation(input, code['short_name']);
      validityCount(phoneStatus, input.id, validCount);
      completeValidation('shipping_address_submit_button', validCount.length !== numberOfRequiredFields);
    }
  }

  formSubmit(event) {
    event.preventDefault();
    let form = document.getElementById('user_new_address_form');
    if(!form) {
      let newForm = document.createElement('form');
      let formDiv= document.getElementById('new_user_address_form_div');
      while (formDiv.children.length > 0) {
        newForm.appendChild(formDiv.children[0]);
      }
      form = newForm;
    }
    let formData = new FormData(form);

    fetch('/shipping_address', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: formData
    }).then(response => {
      if (response.ok) {
        response.json().then( value => {
          let targetDom= document.getElementById('payment-address');
          let content = document.createElement('div');
          content.innerHTML = value.content;
          targetDom.appendChild(content.children[0]);
          form.reset();
          let defaultAddressDom  = document.getElementById('user_default_address')
          if (defaultAddressDom){
            defaultAddressDom.dataset.id = value.address.id;
          }
          setTimeout(() => {
            document.getElementById(value.address.id).click();
          }, 100);
        });
      } else {
        popSnackbar('error', `${I18n[window.currentLocale]['something_went_wrong']}`);
      }
    });
  }

  validateLength(e) {
    const lengthValid = validateLength(e.currentTarget);
    if (!lengthValid) e.stopImmediatePropagation();
  }
}
