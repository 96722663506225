import {Controller} from 'stimulus';

export default class extends Controller {
  close() {
    document.body.style.overflow = 'hidden';
  }

  open() {
    document.body.style.overflow = 'auto';
  }
}
