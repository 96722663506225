import {setMessage} from './base_service';
import {I18n} from '../../translation';

export const passwordVisibility = function (element) {
  for (let i = 0; i < element.length; i++) {
    element[i].addEventListener('click', function () {
      let typeField = this.parentElement.children[0].type;
      this.parentElement.children[0].type = typeField === 'text' ? 'password' : 'text';
      this.innerHTML = typeField === 'text' ? 'visibility_off' : 'visibility';
    });
  }
};

export const passwordValidation = function (input, id) {
  let translate = I18n;
  let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');

  if (input.value.match(strongPassword)) {
    setMessage(id, '');
    return true;
  } else if (input.value === '') {
    setMessage(id, '');
    return false;
  } else {
    setMessage(id, translate[window.currentLocale]['password_requirement']);
    return false;
  }
};

export const passwordConfirmationValidation = function (input, password, id) {
  if (password === input.value) {
    setMessage(id);
    return true;
  } else {
    if (input.value) {
      setMessage(id, I18n[window.currentLocale]['password_should_match']);
    } else {
      setMessage(id, I18n[window.currentLocale]['field_required']);
    }
    return false;
  }
};
