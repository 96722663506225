class PromotionService {

  updateCouponCountDisplay() {
    const productCount = document.getElementById('promotion_selected_modal').childElementCount;
    const couponQuantityField = document.getElementById('coupon_quantity_field');
    couponQuantityField.classList[productCount > 1 ? 'add' : 'remove']('hidden');
    if (productCount > 1) {
      couponQuantityField.querySelector('input').value = '';
      let element = document.getElementById('coupon_quantity_applicable_for');
      element.innerHTML = 'Applicable for all products';
    }
  }
}

export { PromotionService };
