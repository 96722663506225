import {Controller} from 'stimulus';
import {SharedApiService} from '../services/api/shared-api_service';
import {SharedService} from '../services/shared_service';
import {FormValidationService} from '../services/authentications/form_validation_service';

export default class extends Controller {
  sharedApiService = new SharedApiService();
  sharedService = new SharedService();

  search(event) {
    const query = event.currentTarget.value;
    const internalCategoryId = this.isLanding ? '' : `&id=${event.currentTarget.dataset.scopeId}`;
    const rootStatus = this.isLanding ? '&root=true' : '';
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const url = `/admin/search/filter-internal-categories?query=${query}${rootStatus}${internalCategoryId}`;
      this.sharedApiService.fetchReplace(url, this.isLanding ? 'internal_category_listing' : 'sub_category_table_body').then();
    }, 500);
  }

  showSubcategoryList(event) {
    const dataset = event.currentTarget.dataset;
    const url = `/admin/internal_categories/sub_categories_table?id=${dataset.internalCategoryId}&descendant_type=${dataset.descendantType}`;
    this.sharedApiService.fetchReplace(url, 'sub_categories_table').then();
  }

  async addChildCategory(event) {
    const targetFrameId = event.currentTarget.dataset.targetFrameId;
    const frame = document.getElementById(targetFrameId);
    const subFrameUniqueId = event.currentTarget.dataset.subFrameUniqueId;
    const subNestedFrameUniqueId = frame.childElementCount + 1;
    const counterId = targetFrameId.includes('nested') ? `${subFrameUniqueId}_${subNestedFrameUniqueId}` : this.subFieldFrameCount + 1;
    const response = await fetch(`/admin/internal-category-build/add-child-field?nested=${targetFrameId.includes('nested')}&id=${counterId}`);
    const text = await response.text();
    frame.insertAdjacentHTML( 'beforeend', text );
    this.updateFrameCounter(counterId);
  }

  removeChildCategory(event) {
    event.preventDefault();
    const subField = event.target.closest('#sub-field-dom');
    subField.remove();
    this.updateFrameCounter();
  }

  updateFrameCounter(customCount) {
    let frameId;
    if (customCount)
      frameId = (typeof customCount === 'number' ? '#sub-field-dom' : '#sub-nested-field-dom');
    else if (this.subFieldFrameCount) frameId = ('#sub-field-dom');
    else return;

    const counterId = frameId.replace('dom', 'counter');
    if (frameId.includes('nested')) {
      const parentFrameCount = +customCount.split('_')[0];
      const nestedFrameCount = +customCount.split('_')[1];
      const parentFrame = this.element.querySelectorAll('#sub-field-dom')[parentFrameCount - 1];
      parentFrame.querySelectorAll(counterId)[nestedFrameCount - 1].textContent =
        this.sharedService.romanize(nestedFrameCount).toString();
    } else {
      this.element.querySelectorAll(frameId).forEach((dom, i) => {
        dom.querySelector(counterId).textContent = (i + 1).toString();
      });
    }
  }

  async createInternalCategory(event) {
    event.preventDefault();
    const form = this.element.querySelector('#create-internal-category-form');
    const validator = new FormValidationService([form]);
    const valid = validator.validateForm();
    if (!valid) return;

    const formData = new FormData(form);
    const url = '/admin/internal_categories';
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    });
    event.target.previousElementSibling.click();
    document.getElementById(this.isLanding ? 'internal_category_listing' : 'sub_categories_table').innerHTML = await response.text();
  }

  async createChildCategory(event) {
    event.preventDefault();
    const form = this.element.querySelector('#internal-category-create');
    const validator = new FormValidationService([form]);
    const valid = validator.validateForm();
    if (!valid) return;

    const formData = new FormData(form);
    const url = `/admin/internal_categories/${event.currentTarget.dataset.id}/create_sub_category`;
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    });
    event.target.previousElementSibling.click();
    document.getElementById(this.isLanding ? 'internal_category_listing' : 'sub_categories_table').innerHTML = await response.text();
  }

  async updateInternalCategory(event) {
    event.preventDefault();
    const internalCategoryId = event.currentTarget.dataset.id;
    const form = this.element.querySelector(`#edit-internal-category-${internalCategoryId}`);
    const validator = new FormValidationService([form]);
    const valid = validator.validateForm();
    if (!valid) return;

    const formData = new FormData(form);
    const url = `/admin/internal_categories/${internalCategoryId}`;
    const response = await fetch(url, {
      method: 'PUT',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    });
    event.target.previousElementSibling.click();
    document.getElementById(this.isLanding ? 'internal_category_listing' : 'sub_categories_table').innerHTML = await response.text();
  }

  async deleteInternalCategory(event) {
    event.preventDefault();
    const internalCategoryId = event.currentTarget.dataset.id;
    const url = `/admin/internal_categories/${internalCategoryId}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    });
    document.getElementById(this.isLanding ? 'internal_category_listing' : 'sub_categories_table').innerHTML = await response.text();
  }

  resetForm() {
    document.getElementById('sub-field-frame').innerHTML = '';
  }

  selectInternalCategory(event) {
    event.preventDefault();
    const dataset = event.currentTarget.dataset;
    if (this.deselectBulkFields(dataset.optionId)) return;

    const type = dataset.dynamicFrameId.includes('edit') ? 'edit'
      : (dataset.dynamicFrameId.includes('bulk') ? 'bulk' : 'new');
    const url = `/admin/internal-category-build/category-selection/${dataset.optionId}?type=${type}`;
    if (!dataset.dynamicFrameId.includes('nested')) {
      const nestedFrame = document.getElementById(`sub_nested_internal_category_frame_${type}`);
      if (nestedFrame) document.getElementById(`sub_nested_internal_category_frame_${type}`).innerHTML = '';
    }
    new SharedApiService().fetchReplace(url, dataset.dynamicFrameId).then();
  }

  deselectBulkFields(id) {
    if (JSON.parse(id) === 0) {
      ['sub_internal_category_frame_bulk', 'sub_nested_internal_category_frame_bulk'].forEach(id => {
        document.getElementById(id).innerHTML = '';
      });
      return true;
    } else return false;
  }

  get subFieldFrameCount() {
    return this.element.querySelectorAll('#sub-field-dom').length;
  }

  get subNestedFieldFrameCount() {
    return this.element.querySelectorAll('#sub-nested-field-dom').length;
  }

  get isLanding() {
    const locationArray = location.pathname.split('/').filter(val => !!val);
    return locationArray[locationArray.length - 1].includes('internal_categories');
  }
}
