import {Controller} from 'stimulus';

export default class extends Controller {
  selectedChips = [];

  static targets = ['selected'];

  selectChip(e) {
    let targetFormField = document.getElementById('sport_products');

    let selectedChip = e.currentTarget.dataset.sportId;
    this.selectedChips.includes(selectedChip) ? this.removeSelection(selectedChip, e) : this.addSelection(selectedChip, e);

    targetFormField.value = this.selectedChips;
  }

  addSelection(arg, e) {
    let checkIcon = document.getElementById(arg);
    this.selectedChips.push(arg);
    checkIcon.style.display = 'block';
    e.currentTarget.classList.remove('sk-chip-inactive');
    e.currentTarget.classList.add('sk-chip-active');
  }

  removeSelection(arg, e) {
    let checkIcon = document.getElementById(arg);
    this.selectedChips = this.selectedChips.filter(chip => chip !== arg);
    checkIcon.style.display = 'none';
    e.currentTarget.classList.remove('sk-chip-active');
    e.currentTarget.classList.add('sk-chip-inactive');
  }
}