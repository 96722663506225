import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'banner' ];

  connect() {
    if (sessionStorage.getItem('membershipBannerClosed') === 'true') {
      this.bannerTarget.classList.add('hidden');
      document.getElementById('membership-close').classList.add('hidden');
      document.getElementById('star').classList.add('mr-[80px]');
    } else {
      this.bannerTarget.classList.remove('hidden');
      document.getElementById('membership-close').classList.remove('hidden');
      document.getElementById('star').classList.remove('mr-[80px]');

    }
  }

  toggleBanner(e) {
    if(this.bannerTarget.classList.contains('hidden')){
      if(e.currentTarget.getAttribute('id') === 'membership-mountain-banner'){
        this.bannerTarget.classList.remove('hidden');
        sessionStorage.setItem('membershipBannerClosed', 'false');
        document.getElementById('membership-close').classList.remove('hidden');
        document.getElementById('star').classList.remove('mr-[74px]');
      }
    } else {
      if (e.currentTarget.getAttribute('id') === 'membership-close') {
        this.bannerTarget.classList.add('hidden');
        sessionStorage.setItem('membershipBannerClosed', 'true');
        document.getElementById('membership-close').classList.add('hidden');
        document.getElementById('star').classList.add('mr-[80px]');
      }
    }

  }
}
