import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    let selected = JSON.parse(this.element.dataset.selectedLanguage);
    if(selected.length === 1){
      document.getElementById('language_input').value = selected[0].name;
    }
  }

  selectDropdown(e) {
    document.getElementById(e.currentTarget.dataset.targetField).value = e.currentTarget.dataset.value;
  }
}
