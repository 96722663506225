import {I18n} from '../../../../translation';

export const newSubCategoryField = function (index, controller) {
  let dom = `<div class="flex my-[16px]" id="sc_dom_${index}">
               <div class="flex items-center font-CeraPro_Medium bg-blue/[0.08] text-blue rounded px-[14px] mr-[16px]">
                 ${index > 9 ? '' : 0}${index}
               </div>
                 <div class="w-full sk-form-field" data-cy="sub-categoryName" data-index="${index}">
                   <input placeholder=" " class="sk-form-field__input sc-fields" autocomplete="off" id="sc_${index}"
                     data-action="keyup->${controller}#subCategoryInput" data-index="${index}"/>
                   <label name="Subcategory name*" for="sc_${index}" class="sk-form-field__label">${I18n[window.currentLocale]['sub_category']}</label>
                   <p id='sc_${index}_error_message' class="sk-error-message error_message"></p>
                   <i class="sk-form-field__icons material-icons-outlined align-middle pr-[8px]" 
                      title="${I18n[window.currentLocale]['remove_subcategory']}"
                      data-index="${index}" data-action="click->${controller}#removeSubCategory">close</i>
                 </div>
               </div>
             </div>`;
  return dom;
};
