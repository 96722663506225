import {Controller} from 'stimulus';
import {NumberToggleService} from '../services/number-toggle_service';
import {SharedService} from '../services/shared_service';
import {popSnackbar} from '../services/snackbar';

export default class extends Controller {
  numberToggleService = new NumberToggleService();
  sharedService = new SharedService();

  connect() {
    [...document.querySelectorAll('[id^=\'deduct-amount-\']')].forEach(element=> {
      this.element.dataset.state === 'returned' ? element.setAttribute('disabled', true)
        : element.removeAttribute('disabled');
    });
  }

  toggleEdit(event) {
    const returnItemDom = document.getElementById(`refund-detail-${event.currentTarget.dataset.itemId}`);
    returnItemDom.classList[returnItemDom.classList.contains('hidden') ? 'remove' : 'add']('hidden');
    event.currentTarget.innerText = `expand_${event.currentTarget.innerText.includes('more') ? 'less' : 'more'}`;
  }

  toggleNumberField(event) {
    this.numberToggleService.toggleNumberField(event);
    this.updateRefundAmount(event);
  }

  updateRefundAmount(event) {
    const returnItemDom = event.currentTarget.closest('[id^=refund-detail]');
    const splitId = returnItemDom.id.split('-');
    const itemId = splitId[splitId.length - 1];
    const totalAmount = +document.getElementById(`deduct-amount-${itemId}`).max;
    const deductedAmount = document.getElementById(`deduct-amount-${itemId}`).value;
    if (totalAmount < +deductedAmount) {
      document.getElementById(`deduct-amount-${itemId}`).value = deductedAmount.slice(0, deductedAmount.length - 1);
      return;
    }

    let updatedAmount;
    [`refund-amount-${itemId}`, `refund-amount-display-${itemId}`, `return-item-price-${itemId}`].forEach(id => {
      const displayDom = document.getElementById(id);
      const key = displayDom.value ? 'value' : 'innerText';
      updatedAmount = (totalAmount - +deductedAmount).toFixed(2);
      displayDom[key] = updatedAmount;
    });
    this.updateTotalRefund();
    this.saveReturnItem({ return_price: updatedAmount }, itemId);
  }

  updateTotalRefund() {
    const totalRefundDom = document.getElementById('total-refund-display');
    totalRefundDom.innerText =
      [...document.querySelectorAll('[id^=\'refund-amount-display\']')]
        .map(dom => +dom.innerText)
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0).toFixed(2);

    const refundTriggerBtn = document.getElementById('refund-trigger-btn');
    (+totalRefundDom.innerText === 0) ?  refundTriggerBtn.setAttribute('disabled', true)
      : refundTriggerBtn.removeAttribute('disabled');
  }

  updateReturnNote(event) {
    const returnItemId = event.currentTarget.dataset.id;
    this.saveReturnItem({ additional_information: event.currentTarget.value }, returnItemId);
  }

  genericRefundReasonTrigger(event) {
    if (!event.currentTarget.checked) document.getElementById('refund-reason').value = '';
    [...document.querySelectorAll('[id^=\'specific-return-reason\']')].forEach(textArea => {
      textArea.readOnly = event.currentTarget.checked;
      textArea.parentElement.classList[event.currentTarget.checked ? 'add' : 'remove']('hidden');
    });
  }

  updateGenericReason(event) {
    [...document.querySelectorAll('[id^=\'specific-return-reason\']')].forEach(textArea => {
      textArea.value = event.currentTarget.value;
      this.saveReturnItem({ additional_information: textArea.value }, textArea.dataset.id);
    });
  }

  async triggerReturn(event) {
    event.currentTarget.setAttribute('disabled', true);
    const splitPath = location.pathname.split('/');
    const orderReturnId = splitPath[splitPath.length - 1];

    const response = await fetch(`/admin/order_returns/${orderReturnId}/trigger_return`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ return_amount: document.getElementById('total-refund-display').innerText })
    });
    const json = await response.json();
    if(json.success) {
      popSnackbar('notice', json.message);
      location.href = `${location.origin}${json.redirect_url}`;
    } else {
      popSnackbar('error', json.message);
    }
  }

  saveReturnItem(data, id) {
    const triggerBtn = document.getElementById('refund-trigger-btn');
    triggerBtn.setAttribute('disabled', true);
    this.sharedService.debounceAction(() => {
      fetch(`/admin/return_items/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify(data)
      }).then(response => {
        (+response.status === 204) ? triggerBtn.removeAttribute('disabled')
          : popSnackbar('error', 'Error with saving value. Please refresh and try again');
      });
    },
    1000);
  }
}
