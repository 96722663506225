import {Controller} from 'stimulus';
import {constructArrayParams} from "../services/construct_array_params";

export default class extends Controller {
  search(event) {
    let element = document.getElementById('trigger_promotion-product-selection_controller');
    let selectedProduct = this.application.getControllerForElementAndIdentifier(element, "promotion-product-selection").selectedProduct;
    let query = event.currentTarget.value;
    let promotion = event.currentTarget.dataset.promotion;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let url = `/admin/search/filter-promotion-products?q=${query}${constructArrayParams(selectedProduct, 'selectedProduct')}&promotion=${promotion}`;
      fetch(url).then(response => {
        response.text().then(text => {
          document.getElementById('discount_products_listing').innerHTML = text;
        });
      });
    }, 500);
  }
}
