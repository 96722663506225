import Carousel from 'stimulus-carousel';

export default class extends Carousel {
  connect() {
    super.connect();
    this.defaultOptions;
    this.swiper;
  }

  get defaultOptions() {
    return {
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        bulletActiveClass: '!bg-white swiper-pagination-bullet-active'
      },
    };
  }
}