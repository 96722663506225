import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';

export default class extends Controller {
  allCheckBox = document.querySelectorAll('.order-check-box');
  checkedOrderCount = 0;
  orderCount;
  orderSelected = [];
  selected_all = false;
  completeSelection = false;

  connect() {
    let _this = this;
    this.orderCount = this.element.dataset.orderCount;
    for (let i = 0; i < this.allCheckBox.length; i++) {
      this.allCheckBox[i].addEventListener('click', function () {
        if (_this.orderSelected.length === +_this.orderCount) {
          _this.orderSelected = [];
          _this.selectAll(false);
        }
        if (this.checked) {
          _this.addOrderId(parseInt(this.dataset.orderId));
          _this.highlightOrder(`individual_order_container_${i}`);
          _this.checkedOrderCount += 1;
        } else {
          _this.removeOrderId(parseInt(this.dataset.orderId));
          _this.removeHighlight(`individual_order_container_${i}`);
          if (_this.checkedOrderCount > 0) _this.checkedOrderCount -= 1;
        }
        _this.updateContainer();
        _this.selectAllButton();
      });
    }
  }

  selectAll(event) {
    if (event === true || (event && event.currentTarget.checked)) {
      this.completeSelection = true;
      const connectDom = document.getElementById('order-selection-controller-element');
      this.orderSelected = JSON.parse(connectDom.dataset.orderIds);
      this.selected_all = true
      for (let i = 0; i < this.allCheckBox.length; i++) {
        this.allCheckBox[i].checked = true;
        this.highlightOrder(`individual_order_container_${i}`);
      }
      this.checkedOrderCount = this.orderCount;
    } else {
      this.orderSelected = [];
      for (let i = 0; i < this.allCheckBox.length; i++) {
        this.allCheckBox[i].checked = false;
        this.removeHighlight(`individual_order_container_${i}`);
      }
      this.selected_all = false
      this.checkedOrderCount = 0;
    }
    this.updateContainer();
  }

  uncheckOrder(event) {
    if (event.currentTarget.checked) {
      for (let i = 0; i < this.allCheckBox.length; i++) {
        this.allCheckBox[i].checked = false;
        this.removeHighlight(`individual_order_container_${i}`);
        this.removeOrderId(parseInt(this.allCheckBox[i].dataset.orderId));
      }
      this.checkedOrderCount = 0;
    }
    this.updateContainer();
    this.selectAllButton();
    event.currentTarget.click();
    document.getElementById('uncheck_orders').indeterminate = true;
  }

  addOrderId(id) {
    if (!this.orderSelected.includes(id)) {
      this.orderSelected.push(id);
    }
  }

  removeOrderId(id) {
    if (this.orderSelected.includes(id)) {
      this.orderSelected.splice(this.orderSelected.indexOf(id), 1);
    }
  }

  updateContainer() {
    this.checkedOrderCount > 0 ? this.viewUpdateContainer() : this.hideUpdateContainer();
  }

  viewUpdateContainer() {
    document.getElementById('order_update_container').classList.remove('hidden');
    document.getElementById('order_selected_count').innerHTML = `${this.checkedOrderCount} selected`;
  }

  hideUpdateContainer() {
    document.getElementById('order_update_container').classList.add('hidden');
  }

  highlightOrder(id) {
    document.getElementById(id)?.classList.add('bg-blue/[0.08]');
  }

  removeHighlight(id) {
    document.getElementById(id)?.classList.remove('bg-blue/[0.08]');
  }

  selectAllButton() {
    this.checkedOrderCount === parseInt(this.orderCount) ? this.checkSelectAllButton() : this.uncheckSelectAllButton();
  }

  checkSelectAllButton() {
    document.getElementById('select_all_order').checked = true;
  }

  uncheckSelectAllButton() {
    document.getElementById('select_all_order').checked = false;
  }

  callAction(event) {
    fetch(`/admin/order/selection?state=${event.currentTarget.dataset.state}&orders=${this.orderSelected}`).then(response => {
      response.text().then(text => {
        document.getElementById('modal_replace').innerHTML = text;
      });
    });
  }

  download(event) {
    let orderIds = this.selected_all ? JSON.parse(event.currentTarget.dataset.allOrders) : this.orderSelected;

    const url = `/admin/orders/download?order_ids=${JSON.stringify(orderIds)}`;
    window.open(`${location.origin}${url}`, '_blank');
  }

  expandChildOrders(event) {
    event.currentTarget.classList.add('!hidden');
    document.getElementById(`expand_less_${event.currentTarget.dataset.index}`).classList.remove('!hidden');
    document.getElementById(`child_orders_${event.currentTarget.dataset.index}`).classList.remove('hidden');
  }
  collapseChildOrders(event) {
    event.currentTarget.classList.add('!hidden');
    document.getElementById(`expand_more_${event.currentTarget.dataset.index}`).classList.remove('!hidden');
    document.getElementById(`child_orders_${event.currentTarget.dataset.index}`).classList.add('hidden');
  }
}
