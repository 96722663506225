import {Controller} from 'stimulus';
import {emailValidation} from '../services/authentications/email_service';
import {memberIdValidation} from '../services/authentications/member_id_service';
import {validityCount} from '../services/authentications/validation-count_service';
import {completeValidation} from '../services/authentications/complete-validation_service';

export default class extends Controller {
  connect() {
    let validCount = [];

    let memberId = document.getElementById('become_member_id');
    memberId.addEventListener('input', function () {
      ValidateMemberId(this);
    });

    let email = document.getElementById('become_member_email');
    email.addEventListener('input', function () {
      ValidateEmail(this);
    });

    let confirmation = document.getElementById('become_member_confirmation_check');
    confirmation.addEventListener('click', function () {
      validityCount(this.checked, this.id, validCount);
      completeValidation('become_member_submit_button', validCount.length !== 3);
    });

    function ValidateMemberId(input) {
      let memberIdStatus = memberIdValidation(input, 'become_member_id_error_message');
      validityCount(memberIdStatus, input.id, validCount);
      completeValidation('become_member_submit_button', validCount.length !== 3);
    }

    function ValidateEmail(input) {
      let emailStatus = emailValidation(input, 'become_member_email_error_message');
      validityCount(emailStatus, input.id, validCount);
      completeValidation('become_member_submit_button', validCount.length !== 3);
    }
  }
}
