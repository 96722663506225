import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';

export default class extends Controller {

  updatePermission(event) {
    let pid = event.currentTarget.dataset.pid;
    let actionName = event.currentTarget.dataset.actionName;
    let value = event.currentTarget.value;

    const formData = new FormData();
    formData.append('action_name', actionName);
    formData.append('value', value);

    fetch(`/admin/permissions/${pid}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: formData
    }).then(response => {
      response.text().then(text => {
        if (response.ok) {
          let vDom = document.createElement('div');
          vDom.innerHTML = text;
          document.getElementById('permission_list').replaceWith(vDom.children[0]);
          popSnackbar('notice', `${I18n[window.currentLocale]['permission_changed']}`);
        } else {
          popSnackbar('error', `${I18n[window.currentLocale]['something_went_wrong']}`);
        }
      });
    });
  }
}