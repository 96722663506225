class UiService {

  initDisplayToggleButton() {
    document.querySelectorAll('button[id=\'show-more\']').forEach(btn=>{
      btn.dataset.state = '0';
      btn.addEventListener('click', function () {
        let detailsDom = this.previousElementSibling;
        detailsDom.dataset.tmp = detailsDom.textContent;
        detailsDom.textContent = detailsDom.dataset.content;
        detailsDom.dataset.content = detailsDom.dataset.tmp;

        this.innerHTML = this.dataset.state === '1' ? this.dataset.showMoreLocale : this.dataset.showLessLocale;
        this.dataset.state = (1 - this.dataset.state).toString();
      });
    });

    document.querySelectorAll('span[id=\'truncated-text\']').forEach(span=>{
      span.dataset.content = span.textContent;
      span.textContent = span.textContent.substring(0,100) + '...';
    });
  }

}

export { UiService };
