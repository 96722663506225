export const I18nFR = {
  confirm_member: 'Merci de cocher si tu es membre officiel',
  uncheck_member: 'Merci de désélectionner si tu n’es pas membre officiel.',
  password_requirement:
    'Requis : majuscule, minuscule, chiffre, caractère spécial et au minimum 8 caractères.',
  name: 'Name: ',
  phone: 'Phone: ',
  state: 'State: ',
  country: 'Country: ',
  apartment: 'Numéro de maison: ',
  zipcode: 'Code postal: ',
  permission_changed: 'Permission update successful.',
  something_went_wrong: 'Quelque chose a mal tourné.',
  required_field: 'Please fill up the input fields.',
  role_update_success: 'New role assigned.',
  role_update_failure: 'Error assigning role.',
  snack_msg_on_user_deletion: 'Selected user(s) successfully deleted.',
  snack_msg_on_user_removal:
    'Selected user(s) successfully removed from the current role.',
  snack_msg_on_subcategory_removal:
    'Selected subcategory(s) successfully removed from the current category.',
  admin_invitation: 'User invite successfully sent.',
  admin_invitation_failure: 'Error sending invite.',
  people: 'People',
  cms: 'Web CMS',
  retailers: 'Retailers',
  successfully_subscribed: 'Abonné avec succès',
  product_configurator: 'Product Configurator',
  inventory_management: 'Inventory Management',
  order_management: 'Order Management',
  seo_management: 'SEO Management',
  gift_card_detail: 'Gift Card',
  product_tags: 'Product Tags',
  products: 'Produits',
  reports: 'Reports',
  promotion: 'Promotion',
  remove_subcategory: 'Remove Subcategory',
  sub_category: 'Subcategory name',
  product_type_creation_notice: 'Product type successfully created.',
  category_creation_notice: 'Category successfully created.',
  sub_category_creation_notice: 'Subcategory successfully created.',
  content_duplication_success_notice: 'Copied',
  content_duplication_success_error: 'Error Copying.',
  add_option_value: 'Add value',
  selected: 'Selected',
  variant: 'Variant',
  stock: 'Stock',
  continue_as_guest: "Continuer en tant qu'invite",
  its_my_first_time: "C'est la première fois que je suis au départ",
  continue_with_registration: "Continuer avec l'enregistrement",
  initial_stock_quantity: 'Initial Stock Quantity',
  product_type_edition_notice: 'Product type successfully updated.',
  category_edition_notice: 'Category successfully updated.',
  subcategory_edition_notice: 'Subcategory successfully updated.',
  variant_creation_success_notice: 'Variant successfully created.',
  variant_updation_success_notice: 'Variant successfully updated.',
  order_state_updated_successful: 'Order state updated successfully',
  order_state_updated_unsuccessful: 'Order state updated unsuccessful',
  active_product: 'Product visibility on',
  inactive_product: 'Product visibility off',
  product_added_cart: 'Produit ajouté au panier.',
  field_required: 'Champ obligatoire',
  invalid_email: 'Adresse e-mail invalide',
  password_should_match: 'Le mot de passe doit correspondre.',
  invalid_member_id: 'Identifiant membre invalide',
  invalid_phone_example: 'N° de téléphone invalide - Exemple :',
  already_in_cart:
    'Le produit sélectionné se trouve déjà dans le panier, veuillez augmenter la quantité dans le panier.',
  limit_stock_reached: 'Limit Stock Reached for the Product.',
  minimum_order_quantity:
    'Cannot order item quantity less than 1, please remove the item by pressing the cross button',
  brands: 'Brands',
  color: 'Color',
  size: 'Size',
  first_name: 'Prénom: ',
  last_name: 'Nom: ',
  street_name: 'Rue: ',
  city: 'Ville: ',
  change: 'Changer',
  invalid_zipcode: 'code postal non valide',
  successful_copy: 'Successfully Copied.',
  discount_deleted: 'Discount Deleted Successfully',
  invalid_value: 'Invalid Value',
  invalid_timing: 'Invalid Timing',
  promo_applied_successfully: 'Code promotionnel appliqué avec succès',
  gift_card_applied_successfully: 'Gift card applied successfully',
  gift_card_removed_successfully: 'Gift card removed successfully',
  for_specific_product: 'For Specific Product',
  for_specific_collection: 'For Specific Collection',
  for_all_product: 'For All Product',
  invalid_street: 'Adresse non valide.- Exemple: Altweg 47',
  internal_categories: 'Internal Categories',
  categories: 'Categories',
  email_exist: 'Email déjà existante',
  please_login: 'Veuillez vous connecter',
  payment: 'Paiement',
  No_gift_product_selected: "Aucun produit cadeau n'a été sélectionné",
  for_membership: 'For Membership',
  snowactive:
    'Snowactive magazine gratuit (4x par an par courrier) / Newsletter',
  snow_active_newsletter: 'Newsletter',
  fill_required_fields: 'Veuillez remplir le(s) champ(s) requis',
  stay_up_to_date: 'Stay up to date about the swiss-ski teams',
  subscribe_now: 'Subscribe Now',
  for_you: 'For you',
  members_benefit: 'Members Benefit',
  place_order: 'Commander',
  proceed_to_payment: 'Procéder au paiement',
  retailer_selected: 'Revendeur déjà choisi',
  retailer_success: 'Revendeur sélectionné avec succès.',
  retailer_unsuccessful: 'Le revendeur n\'a pas pu être sélectionné.',
};
