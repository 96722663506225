import {Controller} from "stimulus";

export default class extends Controller {
  scrollElement = document.getElementById('notifications-scroll-view');
  allTab = document.getElementById('tabs-all');
  unreadTab = document.getElementById('tabs-unread');
  activeTab

  connect() {
    this.scrollElement.addEventListener('scroll', (e) => {
      if (this.scrollElement.scrollHeight - this.scrollElement.scrollTop === this.scrollElement.clientHeight) {
        if (e.currentTarget.querySelector('.active').id === 'tabs-all' || e.currentTarget.querySelector('.active').id === 'tabs-home-tab') {
          this.activeTab = 'tabs-all'
        } else {
          this.activeTab = 'tabs-unread'
        }
        this.loadMore();
      }
    })
  }

  loadMore() {
    let nextPage = this.activeTab === 'tabs-all' ? this.allTab.dataset.nextPage : this.unreadTab.dataset.nextPage;
    if ([null, 'undefined', ''].includes(nextPage)) return

    fetch(`/admin/user_notifications?&page=${nextPage}`)
      .then(response => {
        response.text().then(text => {
          let tmpDom = document.createElement('div');
          tmpDom.innerHTML = text;

          let targetElement = document.getElementById(`${this.activeTab}`);
          targetElement.dataset.nextPage = tmpDom.querySelector(`[id="${this.activeTab}"]`).dataset.nextPage;
          targetElement.insertAdjacentHTML('beforeend', tmpDom.querySelector(`[id="${this.activeTab}"]`).innerHTML);
        });
      });
  }
}