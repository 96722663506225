import {Controller} from 'stimulus';
export default class extends Controller {
    timeout= null;
  checkTotal(){
      clearTimeout(this.timeout);  // Clear the timeout if it exists
      if(document.getElementById('hover-cart-total')){
          this.timeout = setTimeout(() => {
              fetch(
                  `/carts/total_amount?membership=${JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))}`
              ).then(response => {
                  response.json().then(data => {
                      document.getElementById('hover-cart-total').innerHTML = data.total;
                  });
              });
          }, 1000);
      }
  }

  clearTimeoutWait(){
      clearTimeout(this.timeout);
  }
}
