import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    let index = this.element.dataset.index;
    let imageElement = document.getElementById(`image_container_${index}`);

    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === 'data-auto-cropped') {
              let images = JSON.parse(mutation.target.dataset.autoCropped);
              [
                {id: `image-${index}-21-4`, value: 21 / 4},
                {id: `image-${index}-16-9`, value: 16 / 9},
                {id: `image-${index}-3-4`, value: 3 / 4},
                {id: `image-${index}-1-1`, value: 1}
              ].forEach((ratio) => {
                if (document.getElementById(ratio.id)) {
                  document.getElementById(ratio.id).src = images[ratio.value];
                }
              })
            } else {
              let element1 = document.getElementById('image_uploader');
              let cropperController = this.application.getControllerForElementAndIdentifier(element1, 'product-image-cropper');
              cropperController.productImages.push(JSON.parse(imageElement.dataset.urls))
            }
          }
        )
        // observer.disconnect();
      }
    );
    if (imageElement) {
      observer.observe(imageElement, {attributes: true, attributeFilter: ['data-auto-cropped', 'data-urls']});
    }
  }
}
