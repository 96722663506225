import {Controller} from 'stimulus';
import {emailValidation} from '../services/authentications/email_service';
import {completeValidation} from '../services/authentications/complete-validation_service';
import {validityCount} from '../services/authentications/validation-count_service';

export default class extends Controller {
  connect() {
    let validCount = [];
    let passwordVisibility = document.getElementById('sign_in_password_visibility');
    passwordVisibility.addEventListener('click', function () {
      let typeField = this.parentElement.children[0].type;
      this.parentElement.children[0].type = typeField === 'text' ? 'password' : 'text';
      this.innerHTML = typeField === 'text' ? 'visibility_off' : 'visibility';
    });

    let email = document.getElementById('sign_in_email');
    if (email.value.trim()){
      ValidateEmail(email);
    }
    email.addEventListener('input', function () {
      ValidateEmail(this);
      completeValidation('sign_in_submit_button', validCount.length !== 2);
    });

    function ValidateEmail(input) {
      let emailStatus = emailValidation(input, 'sign_in_email_error_message');
      validityCount(emailStatus, input.id, validCount);
    }

    let password = document.getElementById('sign_in_password');
    password.addEventListener('input', function () {
      ValidatePassword(this);
      completeValidation('sign_in_submit_button', validCount.length !== 2);
    });

    function ValidatePassword(input) {
      validityCount(input.value.length > 0, input.id, validCount);
    }
  }
}
