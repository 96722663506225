import {Controller} from 'stimulus';

export default class extends Controller {
  mainForm;
  activeLocale;

  connect() {
    if (window.location.pathname.includes('new')) {
      this.mainForm = document.getElementById('new_product');
    } else {
      const productId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
      this.mainForm = document.getElementById(`edit_product_${productId}`);
    }

    this.activeLocale = this.mainForm.querySelector('.active-locale').dataset.locale;
    let productName = this.mainForm.querySelector(`#product_name_${this.activeLocale}`);
    let productDescription = this.mainForm.querySelector(`#product_description_${this.activeLocale}`);
    let normalPrice = document.getElementById('normal_product_price');
    let officialMemberPrice = document.getElementById('official_member_product_price');

    if (window.location.pathname.includes('edit')) {
      this.mainForm.querySelector('#pc-step-two-banner-top').innerHTML = `${productName.value} | ${productDescription.value}`;
      this.mainForm.querySelector('#pc-step-three-banner-top').innerHTML = `CHF ${normalPrice.value} | CHF ${officialMemberPrice.value}`;
    }

    // product name
    this.mainForm.querySelector(`#product_name_${this.activeLocale}`).addEventListener('input', (event) => {
      productName.innerHTML = event.target.value;

      if (event.target.value !== '') {
        this.mainForm.querySelector('#pc-step-two-banner-top').innerHTML = `${productName.value} | ${productDescription.value}`;
      }
    });

    // product description
    this.mainForm.querySelector(`#product_description_${this.activeLocale}`).addEventListener('input', (event) => {
      productDescription.innerHTML = event.target.value;

      if (event.target.value !== '') {
        this.mainForm.querySelector('#pc-step-two-banner-top').innerHTML = `${productName.value} | ${productDescription.value}`;
      }
    });

    normalPrice.addEventListener('input', (event) => {
      if (event.target.value !== '') {
        this.mainForm.querySelector('#pc-step-three-banner-top').innerHTML = `CHF ${normalPrice.value} | CHF ${officialMemberPrice.value}`;
        this.mainForm.querySelector('#pc-step-five-banner-top').innerHTML = `CHF ${normalPrice.value} | CHF ${officialMemberPrice.value}`;
      }
    });

    // product official member price
    officialMemberPrice.addEventListener('input', (event) => {
      if (event.target.value !== '') {
        this.mainForm.querySelector('#pc-step-three-banner-top').innerHTML = `CHF ${normalPrice.value} | CHF ${officialMemberPrice.value}`;
        this.mainForm.querySelector('#pc-step-five-banner-top').innerHTML = `CHF ${normalPrice.value} | CHF ${officialMemberPrice.value}`;
      }
    });

    document.getElementById('step-three-next-btn').addEventListener('click', () => {
      const optionValuesController = this.application.getControllerForElementAndIdentifier(this.mainForm, 'pro-options');
      let optionTypes = optionValuesController.dataParam[this.activeLocale];

      if(Object.keys(optionTypes).length > 0) {
        document.getElementById('pc-step-four-banner-top').innerHTML = Object.keys(optionTypes).join(' | ');
      } else {
        document.getElementById('pc-step-four-banner-top').innerHTML = 'No options yet.';
      }
    });

    document.getElementById('step-four-next-btn').addEventListener('click', () => {
      let variants = document.getElementById('product-variants').children.length / 2;
      document.getElementById('pc-step-six-banner-top-with-variants').innerHTML = `${variants} variants`;
    });

    document.getElementById('step-five-next-btn').addEventListener('click', () => {
      let stockThreshold = document.getElementById('stock_threshold').value;
      document.getElementById('pc-initial-stock-management').innerHTML = `Threshold Value: ${stockThreshold}`;
    });
  }
}