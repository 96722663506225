import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['form'];

  search(event) {
    let radix = event.currentTarget.dataset.radix;
    let query = event.currentTarget.value;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if(radix === 'role-fltr') {
        let url = ` /admin/search/filter-roles?q=${query}`;
        fetch(url).then(response => {
          response.text().then(text => {
            let vDom = document.createElement('div');
            vDom.innerHTML = text;
            document.getElementById('role_list').replaceWith(vDom.children[0]);
          });
        });
      } else {
        let url = `/admin/search/filter?q=${query}`;
        fetch(url).then(response => {
          response.text().then(text => {
            let vDom = document.createElement('div');
            vDom.innerHTML = text;
            document.getElementById('user_list').replaceWith(vDom.children[0]);
          });
        });
      }
    }, 500);
  }

  filter(event) {
    let roles_checkboxes = this.formTarget.getElementsByClassName('sk-form-field__checkbox roles');
    let statuses_checkboxes = this.formTarget.getElementsByClassName('sk-form-field__checkbox statuses');
    let role_params =  [], status_params = [];

    for(let i=0; i < roles_checkboxes.length; i++) {
      roles_checkboxes[i].checked ? role_params.push(roles_checkboxes[i].value) : null;
    }
    for(let j=0; j < statuses_checkboxes.length; j++) {
      statuses_checkboxes[j].checked ? status_params.push(statuses_checkboxes[j].value) : null;
    }
    let q = document.getElementById('search-fld').value;
    let radix = event.currentTarget.dataset.radix;

    let url = `/admin/search/filter?q=${q}&statuses=${this.parameterize(status_params)}&roles=${this.parameterize(role_params)}&rd=${radix}`;

    fetch(url).then(response => {
      response.text().then(text => {
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        document.getElementById('user_list').replaceWith(vDom.children[0]);
      });
    });

    document.getElementById('close-fliter-btn').click();
  }

  parameterize(p) {
    return p.toString();
  }

  resetFilter() {
    document.getElementById('reset-filter-btn').click();
    document.getElementById('apply-fltr-btn').click();
  }

  cancelFilterApply() {
    document.getElementById('reset-filter-btn').click();
  }
}