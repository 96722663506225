import {Controller} from 'stimulus';

export default class extends Controller {
  selectedRadio = 'list1';
  connect() {
    let defaultAddressDom = document.getElementById('user_default_address');
    if (defaultAddressDom){
      this.selectedRadio = defaultAddressDom.dataset.id;
    }
  }

  clickAddressInput() {
    const addresses = document.getElementsByName('address');
    for (const address of addresses) {
      const id = address.getAttribute('id');
      if (document.getElementById(id).checked) {
        this.selectedRadio = id;
        document.getElementById('address_id_field').value = id;
        document.getElementById(id + '-label').classList.add('border-blue');
        document.getElementById(id + '-label').children[0].classList.add('hidden');
        document.getElementById(id + '-label').children[1].classList.remove('hidden');
      } else {
        document.getElementById(id + '-label').classList.remove('border-blue');
        document.getElementById(id + '-label').children[0].classList.remove('hidden');
        document.getElementById(id + '-label').children[1].classList.add('hidden');
      }
    }
  }
}
