import {Controller} from 'stimulus';

export default class extends Controller {
  sortType;
  sortValue;

  sort(event) {
    this.sortType = event.currentTarget.dataset.sort_id;
    this.sortValue = event.currentTarget.id;
    this.applySort(event);
  }

  clear(event) {
    this.sortType = this.sortValue = undefined;
    this.applySort(event);
  }

  sortClassList(event) {
    ['sort_by_product_A_to_Z', 'sort_by_product_Z_to_A', 'sort_by_i_category_A_to_Z', 'sort_by_i_category_Z_to_A',
      'sort_by_highest_purchase_price', 'sort_by_lowest_purchase_price', 'sort_by_barcode_A_to_Z',
      'sort_by_barcode_Z_to_A', 'sort_by_sku_A_to_Z', 'sort_by_sku_Z_to_A', 'sort_by_highest_stock',
      'sort_by_lowest_stock'].forEach(id => {
      document.getElementById(`${id}_check`)?.classList[(id === event.target.id)? 'remove' : 'add']('!hidden');
      document.getElementById(id)?.classList[(id === event.target.id) ? 'add' : 'remove']('bg-blue/[0.08]');
    });
  }

  clearButtonClassList(event) {
    ['sort_by_product', 'sort_by_i_category', 'sort_by_purchase_price', 'sort_by_barcode', 'sort_by_sku',
      'sort_by_stock'].forEach(id => {
      document.getElementById(`${id}_clear`)?.classList[(id === event.target.dataset.sort_id)? 'remove' : 'add']('hidden');
    });
  }

  applySort(event) {
    let element = document.getElementById('inventoryslider');
    let inventoryFilter = this.application.getControllerForElementAndIdentifier(element, 'inventory-filter');
    let url = `/admin/search/filter-inventory?filters=${JSON.stringify(inventoryFilter.filters)}&sort_type=${this.sortType}&sort_value=${this.sortValue}&state=${this.orderState() || 'all'}`;
    fetch(url).then(response => {
      response.text().then(text => {
        document.getElementById('inventory_listing_container').innerHTML = text;
        this.sortClassList(event);
        this.clearButtonClassList(event);
      });
    });
  }

  orderState() {
    const states = ['all', 'in_stock', 'threshold', 'out_of_stock'];
    for (let i in states) {
      if (window.location.href.includes(states[i])) return states[i];
    }
  }
}
