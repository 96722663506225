import { Controller } from 'stimulus';
import {setQuantity} from '../services/carts/update_quantity';

export default class extends Controller {
  static targets = [ 'quantity' ];

  connect() {
    if(JSON.parse(this.element.dataset.memberRole)){
      sessionStorage.setItem('purchaseMembershipOn', 'false');
      return;
    }
    if (!sessionStorage.getItem('purchaseMembershipOn')){
      if (JSON.parse(this.element.dataset.memberRole)) {
        sessionStorage.setItem('purchaseMembershipOn', 'false');
      } else {
        sessionStorage.setItem('purchaseMembershipOn', 'true');
        // setQuantity('add', 0);
      }
    } else {
      if (JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
        // setQuantity('add', 0);
      }
    }
  }
}
