import {Controller} from 'stimulus';

export default class extends Controller {

  toggleStatus(event) {
    let userId = event.currentTarget.dataset.userId;
    let radix = event.currentTarget.dataset.radix || null;

    fetch(`/admin/activity-status/${userId}?status=${event.currentTarget.value}&rd=${radix}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => {
      response.text().then(text => {
        let vDom = document.createElement('div');
        vDom.innerHTML = text;

        if (window.location.href.includes('admin/users/')) {
          document.getElementById('user_profile').replaceWith(vDom.children[0]);
        } else {
          document.getElementById('user_list').replaceWith(vDom.children[0]);

          if (window.location.href.includes('admin/role') && document.getElementById('user-status-tggl')) {
            document.getElementById('user-status-tggl').dataset.radix = 'role_users';
            document.getElementById('tabs-profile-tab').click();
          }
        }
      });
    }
    );
  }

  stockMonitorToggleCtrl(e) {
    const masterProductStockMonitorDom = document.getElementById('master-product-stock-ctrl');

    e.currentTarget.checked ? masterProductStockMonitorDom.classList.remove('hidden') : masterProductStockMonitorDom.classList.add('hidden');
  }
}
