import {Controller} from 'stimulus';

export default class extends Controller {
  timesChecked = 0;

  connect() {
    let _this = this;
    document.getElementById('promo_code').addEventListener('input', function () {
      document.getElementById('promo_code_submit').disabled = this.value.trim().length === 0;
    });

    let inputs = document.querySelectorAll('.promo_code_checkout');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('click', function () {
        this.checked ? _this.timesChecked++ : _this.timesChecked--;
        _this.promoAddable();
      });
    }
  }

  checkPromo(event) {
    let _this = this;
    if (event.currentTarget.checked) {
      document.getElementById('search_promotion_code').value = event.currentTarget.dataset.code;
      _this.timesChecked += 1;
    } else {
      document.getElementById('search_promotion_code').value = '';
      _this.timesChecked -= 1;
    }
    _this.promoAddable();
  }

  promoAddable() {
    let add_promo_element = document.getElementById('add_promo_code_button');
    this.timesChecked > 0 ? add_promo_element.removeAttribute('disabled') : add_promo_element.setAttribute('disabled', true);
  }
}
