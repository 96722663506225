import {Controller} from 'stimulus';
import flatpickr from 'flatpickr';

export default class extends Controller {
  index;
  preOrderConfig;
  connect() {
    this.index = this.element.dataset.index;
    this.preOrderConfig = JSON.parse(this.element.dataset.config);
    this.dateTime();
  }

  dateTime() {
    flatpickr( `#select_date_range_${this.index}`, {
      dateFormat: 'd.m.Y',
      mode: 'range',
      defaultDate: this.preOrderConfig === {} ?
        [] :
        [this.parseDate(this.preOrderConfig.expected_start_date), this.parseDate(this.preOrderConfig.expected_end_date)],
      minDate: 'today',
      onChange: () => {
        document.getElementById(`select_date_range_${this.index}_error_message`).innerHTML = '';
      }
    });
  }

  parseDate(date_string){
    const date = new Date(date_string);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  }
}
