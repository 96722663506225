import {Controller} from 'stimulus';

export default class extends Controller {
  copy() {
    let number = document.getElementById('transit_number').innerHTML;
    navigator.clipboard
      .writeText(number)
      .then(() => document.getElementById('copy_tooltip').innerHTML = 'copied!',
        () => document.getElementById('copy_tooltip').innerHTML = 'copy!');
  }
}
