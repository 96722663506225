import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';

export default class extends Controller {
  sendOrderDisable() {
    document.getElementById('ship_icon').disabled = true;
  }

  sendOrder(event) {
    let response = event.detail.fetchResponse.response;
    if (response.status === 200) {
      popSnackbar('notice', 'Successfully sent order to Ms-direct');
    } else {
      popSnackbar('error', 'Failed to send the order to Ms-direct');
    }
  }
}
