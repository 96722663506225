import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {editTaxon} from '../services/templates/api';
import {I18n} from '../translation';

export default class extends Controller {

  static targets = ['subCategoryDom'];

  saveCount = false;
  imageUploaded = false;

  modalID = '';

  initialImageUrl;
  categoryImage;

  initialSubCategoryName = {
    en: '',
    fr: '',
    de: ''
  };

  initialSubDescription = {
    en: '',
    fr: '',
    de: ''
  };

  validSubCategoryName = {
    en: true,
    fr: true,
    de: true
  };
  formData = {
    editedSubCategoryName : {
      en: '',
      fr: '',
      de: ''
    },
    editedSubDescription : {
      en: '',
      fr: '',
      de: ''
    }
  }

  localeChips = document.querySelectorAll('.locale-chips');
  currentLocale = 'en';

  subCategoryInput(event) {
    this.saveInitialData(event);
    this.validSubCategoryName[this.currentLocale] =  freeFieldValidation(event.target, `${event.target.id}_error_message`);

    this.formData.editedSubCategoryName[this.currentLocale] = event.target.value;

    // enable save category saveBtn?
    this.submissionCtrl();
  }

  subCategoryDescriptionInput(event){
    this.saveInitialData(event);
    this.formData.editedSubDescription[this.currentLocale] = event.target.value;
    this.submissionCtrl();
  }

  switchLocale(event) {
    this.saveInitialData(event);
    this.currentLocale = event.currentTarget.dataset.locale;

    let name = this.formData.editedSubCategoryName[this.currentLocale];
    let description = this.formData.editedSubDescription[this.currentLocale];
    document.getElementById(`edit_subcategory_name_${this.modalID}`).value = name || '';
    document.getElementById(`subcategory_description_${this.modalID}`).value = description || '';

    this.localeChips = document.getElementById(this.modalID).querySelectorAll('[class*=locale-chips]');
    this.localeChips.forEach(chip => {
      if (chip.dataset.locale !== event.currentTarget.dataset.locale) {
        chip.classList.remove('sk-language-active', 'active-locale');
        chip.classList.add('sk-language-inactive');
      }
    });

    event.currentTarget.classList.add('sk-language-active', 'active-locale');
  }

  saveInitialData(event) {
    if (!this.saveCount) {
      let taxons = JSON.parse(event.currentTarget.dataset.object);
      for (let i = 0; i < taxons.length; i++) {
        this.initialSubCategoryName[taxons[i]['locale']] = taxons[i]['name'];
        this.formData.editedSubCategoryName[taxons[i]['locale']] = taxons[i]['name'];
        this.initialSubDescription[taxons[i]['locale']] = taxons[i]['description'];
        this.formData.editedSubDescription[taxons[i]['locale']] = taxons[i]['description'];
      }
      this.modalID = event.currentTarget.dataset.modalid;
      this.saveCount = true;
    }
  }

  submissionCtrl() {
    let validCount = 0;
    let editedCount = 0;
    Object.keys(this.validSubCategoryName).forEach(locale => {
      if (this.validSubCategoryName[locale]) {
        validCount += 1;
      }
    });

    let locale = ['en', 'fr', 'de'];
    for (let i = 0; i < locale.length; i++) {
      if (this.initialSubCategoryName[locale[i]] !== this.formData.editedSubCategoryName[locale[i]]) {
        editedCount += 1;
      }if (this.initialSubDescription[locale[i]] !== this.formData.editedSubDescription[locale[i]]) {
        editedCount += 1;
      }
    }
    if (validCount === 3 && editedCount > 0) {
      document.getElementById(`saveEditSubCategory_${this.modalID}`).removeAttribute('disabled');
    } else {
      document.getElementById(`saveEditSubCategory_${this.modalID}`).setAttribute('disabled', 'true');
    }
  }

  duplicateAll(event) {
    this.saveInitialData(event);
    this.copySubCategoryName();
    this.copySubDescription();

    // validate if duplicate successful
    if (this.duplicated(this.currentLocale)) {
      popSnackbar('notice', I18n[window.currentLocale]['content_duplication_success_notice']);
    } else {
      popSnackbar('error', I18n[window.currentLocale]['content_duplication_success_error']);
    }
  }
  copySubDescription(){
    Object.keys(this.formData.editedSubDescription).forEach(locale => {
      if (locale !== this.currentLocale) {
        this.formData.editedSubDescription[locale] = this.formData.editedSubDescription[this.currentLocale];
      }
    }, this);
  }

  copySubCategoryName() {
    Object.keys(this.formData.editedSubCategoryName).forEach(locale => {
      if (locale !== this.currentLocale) {
        this.formData.editedSubCategoryName[locale] = this.formData.editedSubCategoryName[this.currentLocale];
      }
    }, this);
  }

  duplicated(currentLocale) {
    return Object.keys(this.formData.editedSubCategoryName).every((locale) => {
      return this.formData.editedSubCategoryName[locale] === this.formData.editedSubCategoryName[currentLocale];
    }, this);
  }

  saveSubCategory(event) {
    this.initialImageUrl = event.currentTarget.dataset.imageUrl;
    let data = []
    if(this.formData.editedSubCategoryName["en"] === ""){
      this.saveInitialData(event)
    }
    let subCategoryId = event.currentTarget.dataset.taxon_id;
    const id = this.element.dataset.uniqId;
    if (event.currentTarget.dataset.entityType == 'taxon'){
      this.getCategoryImage(id);
      Object.keys(this.formData.editedSubCategoryName).forEach(locale => {
        let tmp = {
          cropped_image: false,
          locale: locale,
          categoryName: this.formData.editedSubCategoryName[locale],
          description: this.formData.editedSubDescription[locale],
          images: this.imageUploaded ? this.categoryImage :  JSON.stringify(this.categoryImage[`category-image-${id}`]),
        };
        if (!this.imageUploaded) {
          tmp['image'] = JSON.parse(document.getElementById(event.currentTarget.dataset.imageId).dataset.urls)
        }
        data.push(tmp);
      }, this);
      editTaxon(data, subCategoryId, event.currentTarget.dataset.entityType);
    }else{
      editTaxon(this.formData.editedSubCategoryName, subCategoryId, event.currentTarget.dataset.entityType);
    }
    this.resetFormData();
  }
  getCategoryImage(id) {
    let multipleImageUploadController = this.application.getControllerForElementAndIdentifier(document.getElementById(id), 'multiple-image-upload');
    this.imageUploaded = multipleImageUploadController.variantImages.length === 0
    this.categoryImage = this.imageUploaded ? this.initialImageUrl : multipleImageUploadController.variantImages[0];
  }

  resetFormData() {
    this.saveCount = false;

    this.modalID = '';

    this.initialSubCategoryName = {
      en: '',
      fr: '',
      de: ''
    };

    this.initialSubDescription = {
      en: '',
      fr: '',
      de: ''
    };

    this.validSubCategoryName = {
      en: true,
      fr: true,
      de: true
    };

    this.formData = {
      editedSubCategoryName : {
        en: '',
        fr: '',
        de: ''
      },
      editedSubDescription : {
        en: '',
        fr: '',
        de: ''
      }
    }
  }
}
