import {Controller} from 'stimulus';

export default class extends Controller {

  filter = {
    brands: [],
    colors: [],
    sizes: []
  };
  url = '';
  sortValue = 'recommended';
  memberPrice = true;
  productType;
  taxonId;
  taxonomyId;
  params;
  searchQuery = '';
  appendSearch = window.location.search.replace('?', '&');
  appendFilter = '';
  sizeIds = [];
  brandIds = [];
  colorIds = [];

  connect() {
    this.params = location.search.split('&');
    this.params.forEach(param => {
      if (param.includes('product_type')) {
        this.productType = param.split('=')[1];
      }
      if (param.includes('taxon_id')) {
        this.taxonId = param.split('=')[1];
      }
      if (param.includes('taxonomy_id')) {
        this.taxonomyId = param.split('=')[1];
      }
    });
  }

  selectBrand(event) {
    const clearBrand = document.getElementById('clear-brand');
    const clearBrandMobile = document.getElementById('clear-brand-mobile');
    if (event.target.checked) {
      this.filter.brands.push(event.target.value);
      this.brandIds.push(event.target.dataset.brand_id);
      clearBrand.classList.remove('hidden');
      // clearBrandMobile.classList.remove('hidden');
    } else {
      this.filter.brands.splice(this.filter.brands.indexOf(event.target.value), 1);
      this.brandIds.splice(this.sizeIds.indexOf(event.target.brand_id), 1);
      if(this.filter.brands.length == 0){
        clearBrand.classList.add('hidden');
        // clearBrandMobile.classList.add('hidden');
      }
    }
    // this.applyFilters();
  }

  toggleMemberPrice(event) {
    this.memberPrice = event.currentTarget.checked;
    sessionStorage.setItem('purchaseMembershipOn', event.currentTarget.checked);
    this.applyFilters();
  }

  selectOption(event) {
    let selectedValue = event.currentTarget.dataset.value;
    let selectedActualValue = event.currentTarget.dataset.actual_value;
    document.getElementById(event.currentTarget.dataset.inputId).value = selectedValue;
    this.sortValue = selectedActualValue;
    this.applyFilters();
  }

  selectColor(event) {
    const clearColor = document.getElementById('clear-color');
    const clearColorMobile = document.getElementById('clear-color-mobile');
    if (event.target.checked) {
      this.filter.colors.push(event.target.value);
      this.colorIds.push(event.target.dataset.color_id);
      clearColor.classList.remove('hidden');
      // clearColorMobile.classList.remove('hidden');
    } else {
      this.filter.colors.splice(this.filter.colors.indexOf(event.target.value), 1);
      this.colorIds.splice(this.sizeIds.indexOf(event.target.color_id), 1);
      if(this.filter.colors.length == 0){
        clearColor.classList.add('hidden');
        // clearColorMobile.classList.add('hidden');
      }}
    // this.applyFilters();
  }

  selectSize(event) {
    const clearSize = document.getElementById('clear-size');
    const clearSizeMobile = document.getElementById('clear-size-mobile');
    if (event.target.checked) {
      this.filter.sizes.push(event.target.value);
      this.sizeIds.push(event.target.dataset.size_id);
      clearSize.classList.remove('hidden');
      // clearSizeMobile.classList.remove('hidden');
    } else {
      this.filter.sizes.splice(this.filter.sizes.indexOf(event.target.value), 1);
      this.sizeIds.splice(this.sizeIds.indexOf(event.target.size_id), 1);
      if(this.filter.sizes.length == 0){
        clearSize.classList.add('hidden');
        clearSizeMobile.classList.add('hidden');
      }
    }
    // this.applyFilters();
  }

  clearFilter(e) {
    document.getElementById(e.currentTarget.id).classList.add('hidden');
    if(e.currentTarget.id === 'clear-size' || e.currentTarget.id === 'clear-size-mobile'){
      this.sizeIds.forEach(res => {
        document.getElementById(`size_${res}`).checked = false;
      });
      document.querySelectorAll('.size-mobile').forEach(res => {
        res.checked = false;
      });
      this.filter.sizes = [];
    } else if (e.currentTarget.id === 'clear-color' || e.currentTarget.id === 'clear-color-mobile'){
      this.colorIds.forEach(res => {
        document.getElementById(`color_${res}`).checked = false;
      });
      document.querySelectorAll('.color-mobile').forEach(res => {
        res.checked = false;
      });
      this.filter.colors = [];
    } else if (e.currentTarget.id === 'clear-brand' || e.currentTarget.id === 'clear-brand-mobile') {
      this.brandIds.forEach(res => {
        document.getElementById(`brand_${res}`).checked = false;
      });
      document.querySelectorAll('.brand-mobile').forEach(res => {
        res.checked = false;
      });
      this.filter.brands = [];
    }
    // this.applyFilters();
  }

  clearAndApplyFilters() {
    document.getElementById('clear-size')?.click()
    document.getElementById('clear-color')?.click()
    document.getElementById('clear-brand')?.click()
    this.applyFilters();
  }

  applyFilters() {
    let payload = JSON.stringify(this.filter);
    if (this.productType) {
      this.url = `/search/filter-storefront-product?&q=${this.searchQuery}&filters=${payload}&product_type=${this.productType}&taxon_id=${this.taxonId || ''}&taxonomy_id=${this.taxonomyId || ''}`;
    } else {
      this.url = `/search/filter-storefront-product?q=${this.searchQuery}&filters=${payload}`;
    }
    this.appendFilter = `&${this.url.split('?')[1]}`;
    fetch(`${this.url}${this.appendSearch}&member_checked=${this.memberPrice}&filter_by=${this.sortValue}`).then(response => {
      response.text().then(text => {
        let tempDom = document.createElement('div');
        tempDom.innerHTML = text;
        let callbackDom = tempDom.querySelector('#product-container');
        document.getElementById('product-container').replaceWith(callbackDom);
      });
    });
    document.getElementById('close-filter')?.click();
  }

  search(event) {
    let allTaxonomyProduct = event.currentTarget.dataset.all;
    let productType = event.currentTarget.dataset.product_type;
    let taxonId = event.currentTarget.dataset.taxonId;
    let taxonomyId = event.currentTarget.dataset.taxonomy_id;
    let brandId = event.currentTarget.dataset.brand_id;
    let query = event.currentTarget.value;
    this.searchQuery = event.currentTarget.value || '';
    let urlString = window.location.href;
    let url = new URL(urlString);
    let levelValue = url.searchParams.get('level');
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let url;
      const filterPayload = JSON.stringify(this.filter || []);
      if (productType) {
        url = `/search/filter-storefront-product?q=${query}&product_type=${productType}&taxon_id=${taxonId}&taxonomy_id=${taxonomyId}&filters=${filterPayload}`;
      } else if (brandId) {
        url = `/search/filter-storefront-product?q=${query}&brand_id=${brandId}&filters=${filterPayload}`;
      }else if (taxonId){
        if(JSON.parse(allTaxonomyProduct) && levelValue == 1){
          url = `/search/filter-storefront-product?type=taxonomy&id=${taxonId}&level=1`;
        }else{
          url = `/search/filter-storefront-product?type=taxon&id=${taxonId}&level=2`;
        }
      }
      else {
        url = `/search/filter-storefront-product?q=${query}&filters=${filterPayload}`;
      }
      this.appendSearch = `&${url.split('?')[1]}`;
      fetch(`${url}${this.appendFilter}&member_checked=${this.memberPrice}&filter_by=${this.sortValue}`).then(response => {
        response.text().then(text => {
          let tempDom = document.createElement('div');
          tempDom.innerHTML = text;
          let callbackDom = tempDom.querySelector('#product-container');
          document.getElementById('product-container').replaceWith(callbackDom);
        });
      });
    }, 100);
  }
}
