import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';

export default class extends Controller {
  selected = [];
  selectedBannerDom = document.getElementById('selected-user-comp');
  selectedUserDom = document.getElementById('selected-user-dom');

  select(event) {
    if (event.currentTarget.checked) {
      this.selected.push(event.currentTarget.dataset.userId);
      document.getElementById(`user-${event.currentTarget.dataset.userId}`).classList.add('bg-blue/[0.08]');
    } else {
      let itmIndex = this.selected.indexOf(event.currentTarget.dataset.userId);
      document.getElementById(`user-${event.currentTarget.dataset.userId}`).classList.remove('bg-blue/[0.08]');
      this.selected.splice(itmIndex, 1);
    }

    if (this.selected.length === 0) {
      this.selectedBannerDom.classList.add('hidden');
    } else {
      this.selectedBannerDom.classList.remove('hidden');
    }
    this.selectedUserDom.innerHTML = `${this.pluralize(this.selected.length, 'user')} selected`;
  }

  selectAll(event) {
    let allUsers = document.querySelectorAll('.list');

    if (event.currentTarget.checked) {
      this.selected = []; // imp.
      for (let i = 0; i < allUsers.length; i++) {
        allUsers[i].checked = event.currentTarget.checked;
        // change this shit
        allUsers[i].parentElement.parentElement.parentElement.parentElement.classList.add('bg-blue/[0.08]');
        this.selected.push(allUsers[i].dataset.userId);
      }
      this.selectedUserDom.innerHTML = `${this.pluralize(this.selected.length, 'user')} selected`;
    } else {
      for (let i = 0; i < allUsers.length; i++) {
        allUsers[i].checked = event.currentTarget.checked;
        allUsers[i].parentElement.parentElement.parentElement.parentElement.classList.remove('bg-blue/[0.08]');
      }
      this.selected = [];
    }

    if (this.selected.length === 0) {
      this.selectedBannerDom.classList.add('hidden');
    } else {
      this.selectedBannerDom.classList.remove('hidden');
    }
  }

  pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

  selectedUser() {
    fetch(`/admin/selected-users?users=${this.selected.toString()}`).then(response => {
      response.text().then(text => {
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        document.getElementById('checked-users-for-deletion').replaceWith(vDom.children[0]);
      });
    });
  }

  callAction() {
    let url = '';
    let action = '';
    let current_path = window.location.href;

    if (current_path.includes('/admin/roles/')) {
      let role_id = current_path.split('/')[current_path.split('/').length - 1];
      url = `/admin/bulk-role-users-remove?flag=user_rmv&role_id=${role_id}&users=${this.selected.toString()}`;
      action = 'PUT';
    } else {
      url = `/admin/bulk-delete?users=${this.selected.toString()}`;
      action = 'DELETE';
    }

    fetch(url, {
      method: action,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => {
      let ok = response.ok;
      response.text().then(text => {
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        document.getElementById('user_list').replaceWith(vDom.children[0]);

        console.log('ok');
      });
      document.getElementById('user-del-modal-cnclbtn').click();

      if (ok) {
        if(window.location.href.includes('/admin/roles')) {
          popSnackbar('notice', I18n[window.currentLocale]['snack_msg_on_user_removal']);
        } else {
          popSnackbar('notice', I18n[window.currentLocale]['snack_msg_on_user_deletion']);
        }
      } else {
        popSnackbar('error', I18n[window.currentLocale]['something_went_wrong']);
      }
    });
  }
}