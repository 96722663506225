import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {percentageFieldValidation} from '../services/authentications/percentage_service';
import flatpickr from 'flatpickr';
import {locales} from '../constant/locale_constant';

export default class extends Controller {
  promotion_type;
  present_locale;
  discount_type;
  couponValidCount = [];
  discountValidCount = [];
  allProductApplicable = false;
  start_date;
  end_date;
  start_time;
  end_time;
  previousPromotionApplied;
  discountRequireFieldCount = 5;
  couponRequiredFieldCount = 4;

  connect() {
    this.promotion_type = this.element.dataset.promotionType;
    // document.getElementById('promotion_discount_type_percentage').click();
    this.promotion_type === 'discount' ? this.activateLocale('en') : this.couponCodeGenerator();
    this.dateTime();
  }

  dateTime() {
    flatpickr('#promotion_start_date', {
      dateFormat: 'd.m.Y',
      minDate: Date.now()
    });

    flatpickr('#promotion_end_date', {
      dateFormat: 'd.m.Y',
      minDate: Date.now()
    });

    flatpickr('#promotion_start_time', {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i'
    });

    flatpickr('#promotion_end_time', {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i'
    });
  }

  couponCodeGenerator() {
    const array = new Uint32Array(1);
    let code = window.crypto.getRandomValues(array);
    document.getElementById('promotion_code').value = code;
    this.addValidCount('promotion_code');
    document.getElementById('summary_coupon_code').innerHTML = code;
  }

  addToSummary(){
    let promoCode = document.getElementById('promotion_code').value
    document.getElementById('summary_coupon_code').innerHTML = promoCode;
  }

  discountType(event) {
    event.currentTarget.classList.remove('bg-white');
    event.currentTarget.classList.remove('text-black/[0.87]');
    event.currentTarget.classList.add('bg-blue');
    event.currentTarget.classList.add('text-white');
    let promoAmountElement = document.getElementById('promotion_discount_value');
    event.currentTarget.id === 'promotion_discount_type_percentage' ? this.percentageDiscount(promoAmountElement) : this.fixedAmountDiscount(promoAmountElement);
  }

  percentageDiscount(promoAmountElement) {
    document.getElementById('promotion_discount_type_fixed_amount').classList.remove('bg-blue', 'text-white');
    document.getElementById('promotion_discount_type_fixed_amount').classList.add('text-black/[0.87]', 'bg-white');
    this.discount_type = 'percentage';
    document.getElementById('promotion_discount_value_label').innerHTML = '%';
    document.getElementById('promotion_discount_type').value = 'percentage';
    this.validPercentage(promoAmountElement.value, promoAmountElement.id);
    document.getElementById(`${this.promotion_type}_summary_discount_type`).innerHTML = '%';
  }

  fixedAmountDiscount(promoAmountElement) {
    document.getElementById('promotion_discount_type_percentage').classList.remove('bg-blue', 'text-white');
    document.getElementById('promotion_discount_type_percentage').classList.add('text-black/[0.87]', 'bg-white');
    this.discount_type = 'fixed';
    document.getElementById('promotion_discount_value_label').innerHTML = 'CHF';
    document.getElementById('promotion_discount_type').value = 'fixed_amount';
    this.validFixedAmount(promoAmountElement, promoAmountElement.id);
    document.getElementById(`${this.promotion_type}_summary_discount_type`).innerHTML = 'CHF';
  }

  promotionAppliedTo(event) {
    this.previousPromotionApplied ? this.promotionAlreadyApplied(event) : this.promotionNotApplied(event);

    if (event.currentTarget.id === 'specific_product_promotion') {
      this.specificProductPromotion();
    } else if (event.currentTarget.id === 'specific_collection_promotion') {
      if (this.promotion_type === 'coupon') this.hideCouponApplicableField();
      this.specificCollectionPromotion();
    } else if (event.currentTarget.id === 'membership_promotion'){
      this.membershipPromotion();
    } else {
      if (this.promotion_type === 'coupon') this.hideCouponApplicableField();
      this.allProductPromotion();
    }

    this.submittable();
  }

  hideCouponApplicableField() {
    document.getElementById('coupon_quantity_field').classList.add('hidden');
    let element = document.getElementById('coupon_quantity_applicable_for');
    element.innerHTML = 'Applicable for all products';
  }

  shippingServiceApplicable(event) {
    let element = document.getElementById('coupon_shipping_charges_applicable');
    element.innerHTML = event.currentTarget.value === 'true' ? 'Shipping Charges Applicable' : 'Free Shipping';
    this.addValidCount('shipping_services_applicable');
    this.submittable();
  }

  couponQuantityApplicable(event) {
    let element = document.getElementById('coupon_quantity_applicable_for');
    element.innerHTML = event.currentTarget.value? `Applicable for ${event.currentTarget.value} product(s)` : 'Applicable for all products';
    this[event.currentTarget.value? 'addValidCount' : 'removeValidCount']('coupon_quantity_applicable');
    this.submittable();
  }

  promotionAlreadyApplied(event) {
    if (this.previousPromotionApplied !== event.currentTarget.id) {
      this.previousPromotionApplied = event.currentTarget.id;
      let discountListingElement = document.getElementById('promotion_selected_modal');
      if (discountListingElement) {
        discountListingElement.innerHTML = '';
        this.application.getControllerForElementAndIdentifier(discountListingElement, 'promotion-selected-listing').count = 0;
      }
    }
  }

  promotionNotApplied(event) {
    this.previousPromotionApplied = event.currentTarget.id;
  }

  specificProductPromotion() {
    document.getElementById('specific_product_promotion_button').classList.remove('hidden');
    document.getElementById('specific_collection_promotion_button').classList.add('hidden');
    this.allProductApplicable = false;
    document.getElementById(`${this.promotion_type}_summary_promotion_applied_for`).innerHTML = I18n[window.currentLocale]['for_specific_product'];
  }

  specificCollectionPromotion() {
    document.getElementById('specific_product_promotion_button').classList.add('hidden');
    document.getElementById('specific_collection_promotion_button').classList.remove('hidden');
    this.allProductApplicable = false;
    document.getElementById(`${this.promotion_type}_summary_promotion_applied_for`).innerHTML = I18n[window.currentLocale]['for_specific_collection'];
  }

  membershipPromotion(){
    document.getElementById('specific_product_promotion_button').classList.add('hidden');
    document.getElementById('specific_collection_promotion_button').classList.add('hidden');
    this.allProductApplicable = true;
    document.getElementById(`${this.promotion_type}_summary_promotion_applied_for`).innerHTML = I18n[window.currentLocale]['for_membership'];
  }

  allProductPromotion() {
    document.getElementById('specific_product_promotion_button').classList.add('hidden');
    document.getElementById('specific_collection_promotion_button').classList.add('hidden');
    this.allProductApplicable = true;
    document.getElementById(`${this.promotion_type}_summary_promotion_applied_for`).innerHTML = I18n[window.currentLocale]['for_all_product'];
  }

  switchLocale(event) {
    this.activateLocale(event.currentTarget.dataset.locale);
  }

  activateLocale(selected_locale) {
    locales.forEach(locale => {
      if (selected_locale === locale) {
        document.getElementById(`${selected_locale}_local_promotion`).classList.add('bg-blue', 'text-white', 'active-locale');
        document.getElementById(`${selected_locale}_promotion_name_container`).classList.remove('hidden');
        this.present_locale = selected_locale;
      } else {
        document.getElementById(`${locale}_local_promotion`).classList.remove('bg-blue', 'text-white', 'active-locale');
        document.getElementById(`${locale}_promotion_name_container`).classList.add('hidden');
      }
    });
  }

  duplicateAll() {
    let master_value = document.getElementById(`${this.present_locale}_promotion_name`).value;
    locales.forEach(locale => {
      document.getElementById(`${locale}_promotion_name`).value = master_value;
      master_value ? this.addValidCount(`${locale}_promotion_name`) : this.removeValidCount(`${locale}_promotion_name`);
    });
    popSnackbar('notice', I18n[window.currentLocale]['successful_copy']);
    this.duplicable();
    this.submittable();
  }

  promotionName(event) {
    let freeFieldStatus = freeFieldValidation(event.currentTarget, `${event.currentTarget.id}_error_message`);
    freeFieldStatus ? this.addValidCount(event.currentTarget.id) : this.removeValidCount(event.currentTarget.id);
    document.getElementById('summary_discount_name').innerHTML = event.currentTarget.value;
    this.duplicable();
    this.submittable();
  }

  duplicable() {
    let enName = document.getElementById('en_promotion_name').value;
    let deName = document.getElementById('de_promotion_name').value;
    let frName = document.getElementById('fr_promotion_name').value;
    if (enName === deName && deName === frName) {
      document.getElementById('promotion_name_copy_button').classList.add('pointer-events-none');
    } else {
      document.getElementById('promotion_name_copy_button').classList.remove('pointer-events-none');
    }
  }

  discountValue(event) {
    if (this.discount_type === 'percentage') {
      this.validPercentage(event.currentTarget.value, event.currentTarget.id);
    } else {
      this.validFixedAmount(event.currentTarget, event.currentTarget.id);
    }
    this.submittable();
  }

  validPercentage(input, id) {
    let percentageStatus = percentageFieldValidation(input, `${id}_error_message`, this.promotion_type === 'coupon');
    percentageStatus ? this.addValidCount(id) : this.removeValidCount(id);
    document.getElementById(`${this.promotion_type}_summary_discount_value`).innerHTML = input;
  }

  validFixedAmount(input, id) {
    let fixedAmountStatus = freeFieldValidation(input, `${id}_error_message`);
    fixedAmountStatus ? this.addValidCount(id) : this.removeValidCount(id);
    document.getElementById(`${this.promotion_type}_summary_discount_value`).innerHTML = input.value;
  }

  addValidCount(id) {
    if (!this.validationArray.includes(id)) {
      this.validationArray.push(id);
    }
  }

  removeValidCount(id) {
    if (this.validationArray.includes(id)) {
      this.validationArray.splice(this.validationArray.indexOf(id), 1);
    }
  }

  customerEligibility() {
    this.addValidCount('customer_eligibility');
  }

  startDate(event) {
    this.start_date = event.target.value;
    document.getElementById(`${this.promotion_type}_summary_start_date`).innerHTML = event.target.value;
    this.submittable();
  }

  endDate(event) {
    this.end_date = event.currentTarget.value;
    document.getElementById(`${this.promotion_type}_summary_end_date`).innerHTML = event.target.value;
    this.submittable();
  }

  startTime(event) {
    this.start_time = event.currentTarget.value;
    document.getElementById(`${this.promotion_type}_summary_start_time`).innerHTML = event.target.value;
    this.submittable();
  }

  endTime(event) {
    this.end_time = event.currentTarget.value;
    document.getElementById(`${this.promotion_type}_summary_end_time`).innerHTML = event.target.value;
    this.submittable();
  }

  validDate() {
    let errorMessages = document.getElementById('promotion_timing_error_message');
    if (this.start_date && this.end_date && this.start_time && this.end_time) {
      let start_time = this.dateParseFormat(this.start_date, this.start_time);
      let end_time = this.dateParseFormat(this.end_date, this.end_time);
      if (start_time < end_time) {
        errorMessages.classList.add('hidden');
        return true;
      } else {
        errorMessages.classList.remove('hidden');
        errorMessages.innerHTML = I18n[window.currentLocale]['invalid_timing'];
        return false;
      }
    } else {
      errorMessages.classList.add('hidden');
      errorMessages.innerHTML = '';
      return false;
    }
  }

  dateParseFormat(date, time) {
    let splitDate = date.split('.');
    let splitTime = time.split(':');
    return Date.parse(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}T${splitTime[0]}:${splitTime[1]}`);
  }

  submittable() {
    this.allProductApplicable ? this.submittableAllProductDiscount() : this.submittableFewProductDiscount();
  }

  submittableAllProductDiscount() {
    if (this.validDate() && this.validationArray.length === this.requiredFieldCount()) {
      document.getElementById('promotion_submit_button').disabled = false;
    } else {
      document.getElementById('promotion_submit_button').disabled = true;
    }
  }

  submittableFewProductDiscount() {
    let discountListingElement = document.getElementById('promotion_selected_modal');
    let discountCount = discountListingElement ? this.application.getControllerForElementAndIdentifier(discountListingElement, 'promotion-selected-listing').count : 0;
    if (this.validDate() && parseInt(discountCount) > 0 && this.validationArray.length >= this.requiredFieldCount()) {
      document.getElementById('promotion_submit_button').disabled = false;
    } else {
      document.getElementById('promotion_submit_button').disabled = true;
    }
  }

  requiredFieldCount() {
    if (this.promotion_type === 'coupon')
      this.couponRequiredFieldCount = document.getElementById('coupon_quantity_field').classList.contains('hidden')?
        4 : 5;
    return this.promotion_type === 'discount' ? this.discountRequireFieldCount : this.couponRequiredFieldCount;
  }

  get validationArray() {
    return this[`${this.promotion_type}ValidCount`];
  }
}
