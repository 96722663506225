import {Controller} from 'stimulus';
import {touchTaxonomy} from '../services/templates/api';
import {productList} from '../services/api/products-list';
import {popSnackbar} from '../services/snackbar';

export default class extends Controller {

  touchTaxonomy(event) {
    let apiPath = event.currentTarget.dataset.path;
    let method = event.currentTarget.dataset.method;

    touchTaxonomy(apiPath, method);
  }

  updateProductStatus(e) { // handles status update from product list and detail page
    let apiPath = e.currentTarget.dataset.path;
    let metadata = JSON.parse(e.currentTarget.dataset.meta);
    let targetStatus = metadata.changeToStatus;
    let targetFrame = document.getElementById('tabs-tab')?.querySelector('.active').dataset.targetFrame;

    fetch(`${apiPath}?status=${targetStatus}&page_name=${metadata.page}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => response.text()).then(text => {
      if (!metadata?.page) {
        let url = `/admin/search/filter-products?radix=${targetFrame}`;
        productList(url, targetFrame);
      } else {
        let tmpDom = document.createElement('div');
        tmpDom.innerHTML = text;
        document.getElementById('tab_content').replaceWith(tmpDom.firstChild);
      }
    });
  }

  updateVariantStatus(e) {
    let targetFrame = 'tab_content';
    let apiPath = e.currentTarget.dataset.path;
    let targetStatus = JSON.parse(e.currentTarget.dataset.meta).changeToStatus;
    let variantId = JSON.parse(e.currentTarget.dataset.meta).variantId;

    fetch(`${apiPath}?variant_id=${variantId}&status=${targetStatus}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => response.text()).then(text => {
      let vDom = document.createElement('div');
      vDom.innerHTML = text;
      document.getElementById(targetFrame).replaceWith(vDom.children[0]);
    });
  }

  updateTaxonomyStatus(e) {
    let apiPath = e.currentTarget.dataset.path;
    let targetStatus = JSON.parse(e.currentTarget.dataset.meta).changeToStatus;
    fetch(`${apiPath}?status=${targetStatus}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => response.text()).then(text => {
      let resp = JSON.parse(text);
      if (resp.code === 'ok') {
        // let verb = resp.status === 'active' ? 'Activated' : 'Deactivated';
        // let targetTaxonomy = resp.taxonomy_name;
        // popSnackbar('notice', `${targetTaxonomy} ${verb}!`);
        // eslint-disable-next-line no-undef
        Turbo.visit('/admin/categories');
      } else {
        popSnackbar('error', 'Something went wrong');
      }
    });
  }

  updateTaxonStatus(e) {
    let apiPath = e.currentTarget.dataset.path;
    let targetStatus = JSON.parse(e.currentTarget.dataset.meta).changeToStatus;
    let type = e.currentTarget.dataset.type;
    fetch(`${apiPath}&status=${targetStatus}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => response.text()).then(text => {
      let resp = JSON.parse(text);
      if (resp.code === 'ok') {
        // let verb = resp.status === 'active' ? 'Activated' : 'Deactivated';
        // popSnackbar('notice', `Subcategory ${verb}!`);
        // eslint-disable-next-line no-undef
        let path = type === 'taxon_child' ? 'taxons' : 'categories' ;
        Turbo.visit(`/admin/${path}/${resp.taxonomy_id}`);
      } else {
        popSnackbar('error', 'Something went wrong');
      }
    });
  }

  updateBrandStatus(e) {
    let apiPath = e.currentTarget.dataset.path;
    let targetStatus = JSON.parse(e.currentTarget.dataset.meta).changeToStatus;
    fetch(`${apiPath}?status=${targetStatus}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => response.text()).then(text => {
      if (text !== '') {
        // eslint-disable-next-line no-undef
        Turbo.visit('/admin/brands');
      } else {
        popSnackbar('error', 'Something went wrong');
      }
    });
  }

  cloneProduct(e) {
    let apiPath = e.currentTarget.dataset.path;
    let metadata = JSON.parse(e.currentTarget.dataset.meta);
    let targetFrame = document.getElementById('tabs-tab')?.querySelector('.active').dataset.targetFrame;

    fetch(apiPath, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(() => {
      if (!metadata?.page) {
        let url = `/admin/search/filter-products?radix=${targetFrame}`;
        productList(url, targetFrame);
        let availableCount = document.getElementById('published_products').getElementsByTagName('tbody')[0].children.length;
        // let draftCount = document.getElementById('draft_products').getElementsByTagName('tbody')[0].children.length;
        document.getElementById('all-products-count').innerHTML = availableCount;
        document.getElementById('drafts-count').innerHTML = availableCount;
      } else {
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          Turbo.visit('/admin/product_configurator');
        }, 500);
      }
    });
  }

  cloneVariant(e) {
    let targetFrame = 'tab_content';
    let apiPath = e.currentTarget.dataset.path;

    fetch(apiPath, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => response.text()).then(text => {
      let vDom = document.createElement('div');
      vDom.innerHTML = text;
      document.getElementById(targetFrame).replaceWith(vDom.children[0]);
    });
  }

  deleteProduct(e) {
    let apiPath = e.currentTarget.dataset.path;
    let metadata = JSON.parse(e.currentTarget.dataset.meta);
    let targetFrame = document.getElementById('tabs-tab')?.querySelector('.active').dataset.targetFrame;

    fetch(apiPath, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(() => {
      if (!metadata?.page) {
        let url = `/admin/search/filter-products?radix=${targetFrame}`;
        productList(url, targetFrame);
        let availableCount = document.getElementById('published_products').getElementsByTagName('tbody')[0].children.length;
        let draftCount = document.getElementById('draft_products').getElementsByTagName('tbody')[0].children.length;
        document.getElementById('all-products-count').innerHTML = availableCount;
        document.getElementById('drafts-count').innerHTML = draftCount;
      } else {
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          Turbo.visit('/admin/product_configurator');
        }, 500);
      }
    });
  }

  deleteVariant(e) {
    let targetFrame = 'tab_content';
    let apiPath = e.currentTarget.dataset.path;

    fetch(apiPath, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => response.text()).then(text => {
      let vDom = document.createElement('div');
      vDom.innerHTML = text;
      document.getElementById(targetFrame).replaceWith(vDom.children[0]);
    });
  }

  editProduct(e) {
    window.location.href = `${e.currentTarget.dataset.path}/edit`;
  }

  redirection(e) {
    window.location.href = `${e.currentTarget.dataset.path}`;
  }

  async switchStatus(event) {
    event.preventDefault();
    let metadata = JSON.parse(event.currentTarget.dataset.meta);
    let targetStatus = metadata.changeToStatus;
    const url = `${event.currentTarget.dataset.path}?status=${targetStatus}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    });
    document.getElementById(this.isLanding ? 'internal_category_listing' : 'sub_categories_table').innerHTML = await response.text();
  }

  get isLanding() {
    const locationArray = location.pathname.split('/').filter(val => !!val);
    return locationArray[locationArray.length - 1].includes('internal_categories');
  }

  async ribbonStatusSwitch(event) {
    event.preventDefault();
    const dataset = JSON.parse(event.currentTarget.dataset.meta);
    const response = await fetch(`/admin/ribbons/${dataset.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ status: dataset.changeToStatus })
    });
    if (response.status === 200) {
      popSnackbar('notice', 'Status Updated');
      // eslint-disable-next-line no-undef
      Turbo.visit('/admin/product_configurator', { target: 'tab-content' });
      setTimeout(() => {
        document.getElementById('tabs-product-labels-tab').click();
      }, 500);
    } else {
      popSnackbar('error', 'Status Update Failed');
    }
  }

  async removeRibbon(event) {
    const path = event.currentTarget.dataset.path.split('/');
    const id = path[path.length - 1];
    const url = `/admin/products/${id}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ radix: 'published_products' })
    });
    const text = await response.text();
    document.getElementById('published_products').innerHTML = text;
  }

  deactivateGiftCard(e) {
    const dataset = JSON.parse(e.currentTarget.dataset.meta);
    const type = dataset.type;
    fetch(`/admin/gift_cards/${dataset.id}/change_status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ status: dataset.changeToStatus, type })
    }).then(response => response.text())
      .then(data => {
        const text = data;
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        const turboFrame = window.location.pathname.split('gift_cards/')?.[1] ? 'gift_card_detail' : `${type}_gift_card_list_frame`;
        popSnackbar('notice', 'Gift card status successfully updated');
        if (turboFrame == 'gift_card_detail') {
          setTimeout( () => {
            location.reload();
          }, 500);
        } else {
          document.getElementById(`${type}_gift_card_list_frame`) && document.getElementById(`${type}_gift_card_list_frame`).replaceWith(vDom.children[0]);
        }
      })
      .catch(() => {
        popSnackbar('error', 'Failed');
      });
  }


  updateTagStatus(e) {
    const dataset = JSON.parse(e.currentTarget.dataset.meta);
    const targetStatus = dataset.changeToStatus;
    fetch(`/admin/tags/${dataset.id}/change_status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ status: targetStatus })
    }).then(response => response.text()).then( text => {
      if (text) {
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        document.getElementById('tag_listing_frame').replaceWith(vDom.children[0]);
        popSnackbar('notice', 'Product tag Status Successfully updated');
      } else {
        popSnackbar('error', 'Something went wrong');
      }
    });
  }
}
