import {Controller} from 'stimulus';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {validityCount} from '../services/authentications/validation-count_service';
import {emailValidation} from '../services/authentications/email_service';

export default class extends Controller {
  connect() {
    let validCount = [];
    let numberOfRequiredField = 3;
    document.getElementById('contact_us_username').addEventListener('input', function () {
      let userNameStatus = freeFieldValidation(this, 'contact_us_username_error_message');
      validityCount(userNameStatus, this.id, validCount);
      CompleteValidation();
    });

    document.getElementById('contact_us_email').addEventListener('input', function () {
      let emailStatus = emailValidation(this, 'contact_us_email_error_message');
      validityCount(emailStatus, this.id, validCount);
      CompleteValidation();
    });

    document.getElementById('contact_us_message').addEventListener('input', function () {
      let messageStatus = freeFieldValidation(this, 'contact_us_message_error_message');
      validityCount(messageStatus, this.id, validCount);
      CompleteValidation();
    });

    function CompleteValidation() {
      document.getElementById('contact_us_submit').disabled = validCount.length !== numberOfRequiredField;
    }
  }
}
