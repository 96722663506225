import {Controller} from "stimulus";
import {constructArrayParams} from "../services/construct_array_params";

export default class extends Controller {
  selectedProductPromotion = [];

  productCheck(event) {
    let currentCheckID = event.currentTarget.dataset.productPromotionId;
    event.currentTarget.checked ? this.addProduct(currentCheckID) : this.removeProduct(currentCheckID);
    this.submittable();
    document.getElementById('discount_detail_selected_count').innerHTML = `${this.selectedProductPromotion.length} product selected.`;
  }

  addProduct(product_id) {
    if (!this.selectedProductPromotion.includes(product_id)) {
      this.selectedProductPromotion.push(product_id);
    }
  }

  removeProduct(product_id) {
    if (this.selectedProductPromotion.includes(product_id)) {
      this.selectedProductPromotion.splice(this.selectedProductPromotion.indexOf(product_id), 1);
    }
  }

  submittable() {
    this.selectedProductPromotion.length > 0 ? this.enableSubmitButton() : this.disableSubmitButton();
  }

  enableSubmitButton() {
    document.getElementById('discount_detail_remove_div').classList.remove('hidden');
  }

  disableSubmitButton() {
    document.getElementById('discount_detail_remove_div').classList.add('hidden');
  }

  removeDiscount(event) {
    let url = `/admin/discounts/detail_product_selection?${constructArrayParams(this.selectedProductPromotion, 'product_promotions')}`;
    fetch(url).then(response => {
      response.text().then(text => {
        document.getElementById('discount-remove').innerHTML = text;
      });
    });
  }
}
