import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    if (document.getElementById('toggle-recommendation')) {
      document.getElementById('toggle-recommendation').checked = JSON.parse(this.element.dataset.automaticRecommend);
    }
  }

  async toggleRecommendationSwitch(event) {
    event.target.disabled = true;
    const dataset = event.target.dataset;
    const url = `/admin/recommendations/${dataset.recommendationId ? 'exclude-automatic_recommendation' :  'toggle-automatic' }`;
    const params = dataset.recommendationId ?
      { id: dataset.productId, recommendation_id: dataset.recommendationId }
      : { id: dataset.productId, automatic_recommend: event.target.checked };

    const _event = event;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(params)
    });
    const autoRecommendations = _event.target.dataset.recommendationId;
    if (autoRecommendations) {
      const text = await response.text();
      let vDom = document.createElement('div');
      vDom.innerHTML = text;
      document.getElementById('auto_recommendations').replaceWith(vDom.children[0]);
    } else document.getElementById('toggle-recommendation').disabled = false;
  }
}
