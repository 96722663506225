import {Controller} from 'stimulus';

export default class extends Controller {
  orderState = this.element.dataset.order_state;
  connect() {
  }

  click_open() {
    this.hide_element();
    document.getElementById('order_state_update_button').disabled = this.orderState === 'cart';
  }

  click_ready_for_dispatch() {
    this.hide_element();
    document.getElementById('order_state_update_button').disabled = this.orderState === 'ready_for_dispatch';
  }

  click_delivered() {
    this.hide_element();
    document.getElementById('order_state_update_button').disabled = this.orderState === 'delivered';
  }

  click_close() {
    this.hide_element();
    document.getElementById('order_state_update_button').disabled = this.orderState === 'complete';
  }

  click_in_transit() {
    document.getElementById('order_in_transit').classList.remove('hidden');
    document.getElementById('order_cancel').classList.add('hidden');
    document.getElementById('order_state_update_button').disabled =
        !(document.getElementById('order_transit_id').value.length > 5 && this.orderState !== 'in_transit');
  }

  click_cancel() {
    document.getElementById('order_cancel').classList.remove('hidden');
    document.getElementById('order_in_transit').classList.add('hidden');
    document.getElementById('order_state_update_button').disabled =
        !(document.getElementById('cancel_reason').value.length > 5 && this.orderState !== 'canceled');
  }

  hide_element() {
    document.getElementById('order_in_transit').classList.add('hidden');
    document.getElementById('order_cancel').classList.add('hidden');
  }

  in_transit_input(event) {
    document.getElementById('order_state_update_button').disabled =
        !(event.currentTarget.value.length > 5 && this.orderState !== 'in_transit');
  }

  cancel_input(event) {
    document.getElementById('order_state_update_button').disabled =
        !(event.currentTarget.value.length > 5 && this.orderState !== 'canceled');
  }
}