import {Controller} from 'stimulus';

export default class extends Controller {

  search(event) {
    let radix = event.currentTarget.dataset.radix;
    let query = event.currentTarget.value;
    let productId = event.currentTarget.dataset.scopeId;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let url = `/admin/search/filter-variants?product_id=${productId}&q=${query}&rd=${radix}`;
      fetch(url).then(response => {
        response.text().then(text => {
          let vDom = document.createElement('div');
          vDom.innerHTML = text;
          document.getElementById(radix).replaceWith(vDom.children[0]);
        });
      });
    }, 500);
  }

}