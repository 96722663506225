import {Controller} from 'stimulus';

export default class extends Controller {
  openNav(event) {
    let navigation = event.currentTarget.dataset.navigation;
    document.getElementById(`${navigation}_sidenav`).style.width = '76dvw';
  }

  closeNav(event) {
    let headerMenu = event.currentTarget.dataset.headerMenu;
    document.getElementById(`${headerMenu}_sidenav`).style.width = '0';
  }

  openSubNav(event) {
    let taxonomyId = event.currentTarget.dataset.taxonomyId;
    let headerMenu = event.currentTarget.dataset.headerMenu;
    document.getElementById(`${headerMenu}_${taxonomyId}_sub_sidenav`).style.width = '100%';
  }

  closeSubNav(event) {
    let taxonomyId = event.currentTarget.dataset.taxonomyId;
    let headerMenu = event.currentTarget.dataset.headerMenu;
    document.getElementById(`${headerMenu}_${taxonomyId}_sub_sidenav`).style.width = '0';
  }

  openNavBrand() {
    document.getElementById('brand_sidenav').style.width = '100%';
  }

  closeNavBrand() {
    document.getElementById('brand_sidenav').style.width = '0%';
  }

  languageSwitchOpen() {
    document.getElementById('language_switch').style.width = '76dvw';
  }

  languageSwitchClose() {
    document.getElementById('language_switch').style.width = '0';
  }

  myAccountOpen() {
    document.getElementById('my_account').style.width = '76dvw';
  }

  myAccountClose() {
    document.getElementById('my_account').style.width = '0';
  }


  openGiftCard(){
      document.getElementById('gift_card_sidenav').style.width = '100%';
  }

  closeNavGiftCard() {
      document.getElementById('gift_card_sidenav').style.width = '0%';
    }
}
