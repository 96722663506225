import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {emailValidation} from '../services/authentications/email_service';
import {phoneValidation} from '../services/authentications/phone_service';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {validityCount} from '../services/authentications/validation-count_service';
import {I18n} from '../translation';
import {AsYouType} from 'libphonenumber-js';
import {countryCode} from '../services/authentications/country-code_service';

export default class extends Controller {
  validCount = ['phone'];

  inputValidator = {
    'first_name': false,
    'last_name': false,
    'phone': false,
    'email': false
  };

  userInviteForm = document.getElementById('admin-invitation-form');
  inputs = this.userInviteForm.querySelectorAll('.registration_field');

  connect() {
    let _this = this;
    let code = countryCode('Switzerland');
    let functionMap = {
      'first_name': ValidateName,
      'last_name': ValidateName,
      'phone': ValidatePhone,
      'email': ValidateEmail
    };

    for (let i = 0; i < this.inputs.length; i++) {
      this.inputs[i].addEventListener('input', function (event) {
        functionMap[event.target.id](event.target);

        let allValid = Object.keys(_this.inputValidator).every((key) => {
          return _this.inputValidator[key] === true;
        });

        document.getElementById('admin-invite-btn').disabled = !allValid;
      });
    }

    function ValidateName(input) {
      let freeFieldStatus = freeFieldValidation(input, input.id + '_error_message');
      validityCount(freeFieldStatus, input.id, _this.validCount);
      _this.inputValidator[input.id] = freeFieldStatus;
    }

    function ValidatePhone(input) {
      let validType = new AsYouType(code['short_name']).input(input.value);
      input.value = validType;
      let phoneStatus = phoneValidation(input, code['short_name']);
      validityCount(phoneStatus, input.id, _this.validCount);
      _this.inputValidator[input.id] = phoneStatus
    }

    function ValidateEmail(input) {
      _this.inputValidator[input.id] = emailValidation(input, input.id + '_error_message');
    }
  }

  selectRole(e) {
    const targetField = e.currentTarget.dataset.targetField;
    document.getElementById(targetField).value = e.currentTarget.dataset.value;
    document.getElementById('new_admin_role_id').value = e.currentTarget.dataset.optionId;
  }

  sendInvite() {
    document.getElementById('admin-invitation-form'), addEventListener('turbo:submit-end', async (e) => {
      let resp = await e.detail.fetchResponse.responseHTML;
      let vDom = document.createElement('div');
      vDom.innerHTML = resp;
      if (e.detail.success) {
        popSnackbar('notice', I18n[window.currentLocale]['admin_invitation']);
        document.getElementById('cancel-admin-invite-btn').click();
      }
      else {
        document.getElementById('cancel-admin-invite-btn').click();
        document.getElementById('tab_content').replaceWith(vDom.children[0]);
        popSnackbar('error', I18n[window.currentLocale]['admin_invitation_failure']);
      }
    });
  }
}