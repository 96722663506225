import {Controller} from 'stimulus';

export default class extends Controller {

  search(event) {
    let id = event.currentTarget.dataset.taxonomyId;
    let query = event.currentTarget.value;
    let radix = event.currentTarget.dataset.radix;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let url = id ? `/admin/search/filter-taxons/${id}?q=${query}` : `/admin/search/filter-taxonomies?q=${query}`;

      fetch(`${url}&rd=${radix}`).then(response => {
        response.text().then(text => {
          let vDom = document.createElement('div');
          vDom.innerHTML = text;
          document.getElementById('taxonomies').replaceWith(vDom.children[0]);
        });
      });
    }, 500);
  }
}