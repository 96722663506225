import {Controller} from 'stimulus';

export default class extends Controller {
  switchTab(e) {
    const allNotificationTabs = document.querySelectorAll('.notification-tab');
    allNotificationTabs.forEach((tab) => {
      tab.classList.add('sk-btn--outline-secondary');
      tab.classList.remove('sk-btn--secondary');
    });

    e.currentTarget.classList.remove('sk-btn--outline-secondary');
    e.currentTarget.classList.add('sk-btn--secondary');
  }

  markAllAsRead(e) {
    const unreadNotifications = +e.currentTarget.dataset.unread;

    if (unreadNotifications > 0) {
      fetch('/admin/notifications/mark_all_as_read', {
        method: 'PUT',
        headers: {
          Accept: 'text/vnd.turbo-stream.html',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      }).then((response) => response.text())
        .then((html) => {
          // eslint-disable-next-line no-undef
          Turbo.renderStreamMessage(html);
        });
    }
  }
}