import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';

export default class extends Controller {
  selected = [];
  selectedBannerDom = document.getElementById('selected-taxon-comp');
  selectedTaxonDom = document.getElementById('selected-taxon-dom');

  select(event) {
    if (event.currentTarget.checked) {
      this.selected.push(event.currentTarget.dataset.taxonId);
      document.getElementById(`taxon-${event.currentTarget.dataset.taxonId}`).classList.add('bg-blue/[0.08]');
    } else {
      let itmIndex = this.selected.indexOf(event.currentTarget.dataset.taxonId);
      document.getElementById(`taxon-${event.currentTarget.dataset.taxonId}`).classList.remove('bg-blue/[0.08]');
      this.selected.splice(itmIndex, 1);
    }

    if (this.selected.length === 0) {
      this.selectedBannerDom.classList.add('hidden');
    } else {
      this.selectedBannerDom.classList.remove('hidden');
    }
    this.selectedTaxonDom.innerHTML = `${this.selected.length} ${this.element.dataset.selectedTaxonDomName || 'subcategory'} selected`;
  }

  selectAll(event) {
    let allTaxons = document.querySelectorAll('.list');

    if (event.currentTarget.checked) {
      this.selected = []; // imp.
      for (let i = 0; i < allTaxons.length; i++) {
        allTaxons[i].checked = event.currentTarget.checked;
        allTaxons[i].parentElement.parentElement.parentElement.parentElement.classList.add('bg-blue/[0.08]');
        this.selected.push(allTaxons[i].dataset.taxonId);
      }
      this.selectedTaxonDom.innerHTML = `${this.selected.length} ${this.element.dataset.selectedTaxonDomName || 'subcategory'} selected`;
    } else {
      for (let i = 0; i < allTaxons.length; i++) {
        allTaxons[i].checked = event.currentTarget.checked;
        allTaxons[i].parentElement.parentElement.parentElement.parentElement.classList.remove('bg-blue/[0.08]');
      }
      this.selected = [];
    }

    if (this.selected.length === 0) {
      this.selectedBannerDom.classList.add('hidden');
    } else {
      this.selectedBannerDom.classList.remove('hidden');
    }
  }

  pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

  selectedTaxons(event) {
    const url = event.currentTarget.dataset.url;
    fetch(`${url}${this.selected.toString()}`).then(response => {
      response.text().then(text => {
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        document.getElementById('check-taxon-for-deletion').replaceWith(vDom.children[0]);
      });
    });
  }

  callAction(event) {
    let id = event.currentTarget.dataset.taxonomyId;
    let url = `/admin/taxon/bulk-delete/${id}?taxons=${this.selected.toString()}`;
    let action = 'DELETE';

    fetch(url, {
      method: action,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => {
      response.text().then(text => {
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        document.getElementById('taxonomies').replaceWith(vDom.children[0]);
      });
      document.getElementById('user-del-modal-cnclbtn').click();

      if (response.ok) {
        popSnackbar('notice', I18n[window.currentLocale]['snack_msg_on_subcategory_removal']);
      } else {
        popSnackbar('error', I18n[window.currentLocale]['something_went_wrong']);
      }
    });
  }

  internalCategoryBulkDelete(event) {
    const id = event.currentTarget.dataset.taxonomyId;
    event.preventDefault();
    const url = `/admin/internal_categories/${id}/bulk_destroy?internal_categories=${this.selected.toString()}&descendant_type=children`;
    fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => response.text())
      .then(text => document.getElementById('sub_categories_table').innerHTML = text);
  }
}
