import {Controller} from "stimulus";

export default class extends Controller {
  connect() {
    this.collapse()
  }

  collapse(){
    let classList = document.getElementById(this.element.dataset.id).classList
    if (classList.contains('collapsed')){
      document.getElementById(`collapse-${this.element.dataset.id}`).innerText = "expand_more";
    }else{
      this.removeIcon();
      document.getElementById(`collapse-${this.element.dataset.id}`).innerText = "expand_less";
    }
  }

  removeIcon(){
    let elements = document.querySelectorAll('.accordion-icon');
    elements.forEach((element) => {
      element.innerText = 'expand_more';
    });
  }
}
