import {Controller} from 'stimulus';
import flatpickr from 'flatpickr';
import {popSnackbar} from '../services/snackbar';

export default class extends Controller {
  gift_card = {
    partial_redemption: true,
    name: {
      en: '',
      de: '',
      fr: '',
    },
    expiry_date: '',
    customize_amount: false,
    type: 'digital_gift_card',
    recommendations: [],
    images: [],
    amounts: [],
    description: {
      en: '',
      de: '',
      fr: ''
    }
  };
  temp = [];
  cancelBtn = document.getElementById('cancelBtn');
  giftCardId;
  isEdit = false;
  variantId = '';

  connect() {
    if(this.element.dataset.metadata && this.element.dataset.metadata != 'null') {
      let giftCardData = JSON.parse(this.element.dataset.metadata);
      this.isEdit = true;
      this.temp = JSON.parse(this.element.dataset['amount']);
      this.giftCardId = giftCardData?.id;
      this.gift_card.name.en = giftCardData?.name;
      this.gift_card.name.de = giftCardData?.name;
      this.gift_card.name.fr = giftCardData?.name;
      this.gift_card.description.en = giftCardData?.description;
      this.gift_card.description.de = giftCardData?.description;
      this.gift_card.description.fr = giftCardData?.description;
      this.gift_card.type = giftCardData?.spree_product_type;
      this.gift_card.expiry_date = giftCardData?.expiry_date;
      this.gift_card.partial_redemption = giftCardData?.reusable;
      this.displayOptions();
    }
    if(document.getElementById('digital')) {
      document.getElementById('digital').checked = true;
      this.gift_card.type = 'digital_gift_card';
    }
    this.dateTime();
  }

  dateTime() {
    flatpickr(`#giftCard_expiryDate${ this.isEdit ? this.giftCardId : ''}`, {
      dateFormat: 'd.m.Y'
    });
  }

  handleInput(e) {
    let val = e.currentTarget.value;
    if(val.includes('true') || val.includes('false')) {
      val = JSON.parse(e.currentTarget.value);
    }
    this.gift_card[e.currentTarget.dataset.key] = val;
    document.getElementById(`${e.currentTarget.dataset.key}-error-message`)?.classList.add('hidden');
  }

  setNameAndDescription(e) {
    ['en', 'de', 'fr'].forEach(res => {
      this.gift_card[e.currentTarget.dataset.key][res] = e.currentTarget.value;
      document.getElementById(`${e.currentTarget.dataset.key}-error-message`)?.classList.add('hidden');
    });
  }

  isValid() {
    let valid = false;
    valid = ['name', 'description'].every(res => {
      return this.gift_card[res]['en'].trim().length != 0;
    });
    return valid;
  }

  checkAmountValidity() {
    let valid = false;
    if(this.temp.length != 0 && this.temp.every(res => res['value'].trim().length != 0 && res['sku'].trim().length != 0)){
      valid = true;
    } else {
      valid = false;
    }
    return valid;
  }

  showErrors() {
    this.temp.forEach((res, i) => {
      const errorId = document.getElementById(`option-error-${i}`);
      if (res['value'].trim().length == 0 || res['sku'].trim().length == 0) {
        errorId.classList.remove('hidden');
      }
    });
    ['name', 'description'].forEach(res => {
      const errorId = document.getElementById(`${res}-error-message`);
      document.getElementById(res)?.value.trim().length == 0 ? errorId.classList.remove('hidden') : errorId.classList.add('hidden');
    });
  }

  setCheckboxValue(e) {
    let amt = document.getElementById(e.currentTarget.id);
    amt.value = e.currentTarget.checked;
  }

  addOption() {
    this.temp.push({value: '', sku: ''});
    this.displayOptions();
  }

  displayOptions() {
    document.getElementById( `optionDiv${ this.isEdit ? this.giftCardId : ''}`).innerHTML = '';
    for (let i = 0; i < this.temp.length; i++){
    //   document.getElementById(`optionDiv${ this.isEdit ? this.giftCardId : ''}`).innerHTML += `
    //   <div class="flex">
    //     <p class="font-CeraPro_Medium">Name</p>
    //     <div class=" mt-[4px]">
    //       <input class="sk-form-field sk-form-field__input" type="number" data-key="${i}" id="option-input-${i}${this.isEdit ? this.giftCardId : ''}" data-action="input->gift-card#setAmount" value="${this.temp[i]?.value}" />
    //     </div>
    //     <div class=" mt-[4px]">
    //       <input class="sk-form-field sk-form-field__input" type="text" data-key="${i}" id="option-input-sku-${i}${this.isEdit ? this.giftCardId : ''}" data-action="input->gift-card#setAmount" value="${this.temp[i]?.sku}" />
    //       <i class="material-icons-outlined !text-[20px] pl-[14px] cursor-pointer" data-action="click->gift-card#removeOption" data-key="${i}">close</i>
    //     </div>
    //     <p class="sk-error-message hidden" id="option-error-${i}">Required Field</p>
    // </div>`;
      document.getElementById(`optionDiv${ this.isEdit ? this.giftCardId : ''}`).innerHTML += `
      <div>
        <div class="flex items-center ">
          <div class="sk-form-field my-[12px] w-full">
            <input class="sk-form-field sk-form-field__input" type="number" data-key="${i}" id="option-input-${i}${this.isEdit ? this.giftCardId : ''}" 
            data-action="input->gift-card#setAmount" 
            value="${(+this.temp[i]?.value).toFixed(2)}"
             ${this.temp[i]?.id? 'disabled': ''} />
            <label class="sk-form-field__label">Amount</label>
          </div>
        </div>
      </div>
      <div>
        <div class="flex items-center">
          <div class="sk-form-field my-[12px] w-full">
            <input class="sk-form-field sk-form-field__input" type="text" data-key="${i}"
             id="option-input-sku-${i}${this.isEdit ? this.giftCardId : ''}"
              data-action="input->gift-card#setAmount" value="${this.temp[i]?.sku}"
               ${this.temp[i]?.id? 'disabled': ''} />
            <label class="sk-form-field__label">SKU</label>
          </div>
          <i class="material-icons-outlined text-blue px-[6px] !text-[24x] cursor-pointer" data-cy="deleteSku" data-action="click->gift-card#removeOption" data-key="${i}">delete</i>
        </div>
        <p class="sk-error-message hidden" id="option-error-${i}">Required Field</p>
      </div>`;
    }
  }

  setAmount(e) {
    const val = document.getElementById(`option-input-${e.currentTarget.dataset.key}${this.isEdit ? this.giftCardId : ''}`);
    const sku = document.getElementById(`option-input-sku-${e.currentTarget.dataset.key}${this.isEdit ? this.giftCardId : ''}`);
    this.temp[e.currentTarget.dataset.key] = {
      value: val.value,
      sku: sku.value
    };

    document.getElementById(`option-error-${e.currentTarget.dataset.key}`).classList.add('hidden');
  }

  removeOption(e) {
    this.temp.splice(e.currentTarget.dataset.key, 1);
    this.displayOptions();
  }

  async filterCards(e) {
    const giftCardType = document.getElementById('tabs-tab').querySelector('.active').id;
    const type = giftCardType.split('_')[0];
    const response = await fetch(`/admin/search/filter-gift-cards?q=${e.currentTarget.value}&filter=${giftCardType}&type=${type}`);
    if (response.status === 200) {
      const text = await response.text();
      let vDom = document.createElement('div');
      vDom.innerHTML = text;
      document.getElementById(`${type}_gift_card_list_frame`).replaceWith(vDom.children[0]);
    } else {
      popSnackbar('error', 'Error');
    }
  }

  async createGiftCard(e) {
    let type;
    this.recheckType();
    type = this.gift_card.type.split('_')[0];
    this.gift_card.amounts = this.temp;
    this.gift_card.images = document.getElementById('gift-card-images')?.dataset?.giftCardImages;
    if(this.isValid() && this.checkAmountValidity()) {
      const response = await fetch('/admin/gift_cards', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ gift_card: {...this.gift_card, spree_product_type: this.gift_card.type }, type})
      });

      if (response.status === 200) {
        const text = await response.text();
        let vDom = document.createElement('div');
        this.resetForm();
        vDom.innerHTML = text;
        document.getElementById(`${type}_gift_card_list_frame`).replaceWith(vDom.children[0]);
        popSnackbar('notice', 'Gift Card added successfully');
        e.target.previousElementSibling.click();
      } else {
        popSnackbar('error', 'Creating Gift Card Failed');
      }
    } else {
      this.showErrors();
      popSnackbar('error', 'Please Fill All Required Fields');
    }
  }

  async updateGiftCard(e) {
    let type;
    this.recheckType();
    type = this.gift_card.type.split('_')[0];
    this.gift_card.amounts = this.temp;
    // TEMP PATCH PLS FIX
    this.gift_card.expiry_date = '1.2.23';
    this.gift_card.images = document.getElementById(`gift-card-images${this.giftCardId}`)?.dataset?.giftcardimages;
    if(this.isValid() && this.checkAmountValidity()) {
      e.target.previousElementSibling.click();
      const response = await fetch(`/admin/gift_cards/${this.giftCardId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({gift_card: this.gift_card}, type)
      });

      if (response.status === 200) {
        const text = await response.text();
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        const turboFrame = window.location.pathname.split('gift_cards/')?.[1] ? 'gift_card_detail' : `${type}_gift_card_list_frame`;
        if(turboFrame == 'gift_card_detail'){
          setTimeout( () => {
            location.reload();
          }, 500);
        } else {
          document.getElementById(turboFrame) && document.getElementById(turboFrame).replaceWith(vDom.children[0]);
        }
        popSnackbar('notice', 'Gift Card updated successfully');
      } else {
        popSnackbar('error', 'Update Failed');
      }
    } else {
      this.showErrors();
      popSnackbar('error', 'Please Fill All Required Fields');
    }
  }

  resetForm(){
    this.temp = [];
    // document.getElementById(`digital${this.isEdit ? this.giftCardId : ''}`).checked = true;
    if(document.getElementById(`giftCard_name${this.isEdit ? this.giftCardId : ''}`)){
      document.getElementById(`giftCard_name${this.isEdit ? this.giftCardId : ''}`).value = '';
    }

    if(document.getElementById(`giftCard_description${this.isEdit ? this.giftCardId : ''}`)){
      document.getElementById(`giftCard_description${this.isEdit ? this.giftCardId : ''}`).value = '';
    }
    // document.getElementById(`giftCard_expiryDate${this.isEdit ? this.giftCardId : ''}`).value = '';
    if(document.getElementById('gift-card-image-uploader')){
      document.getElementById('gift-card-image-uploader').innerHTML = '';
    }
    this.displayOptions();
  }

  setExportAmount(e) {
    document.getElementById(`amount_${e.currentTarget.dataset.id}`).value = e.currentTarget.dataset.value;
    this.variantId = e.currentTarget.dataset.variantId;
  }

  resetAmounts(e) {
    this.variantId = '';
    document.getElementById(`amount_${e.currentTarget.dataset.id}`).value = '';
    document.getElementById(`count_${e.currentTarget.dataset.id}`).value = '';
  }

  exportGiftCard(e) {
    const payload = {
      gift_card_count: document.getElementById(`count_${e.currentTarget.dataset.id}`).value,
      product_id: e.currentTarget.dataset.id,
      id: this.variantId,
      amount: document.getElementById(`amount_${e.currentTarget.dataset.id}`).value
    };
    fetch('/admin/gift_cards/bulk_gift_card_code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({gift_card: payload})
    }).then(response => response.json())
      .then((res) => {
        const base64String = res.file;
        const fileName = 'Gift Cards.xls';
        const dataUrl = `data:application/vnd.ms-excel;base64,${base64String}`;
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = fileName;
        a.click();
        e.target.previousElementSibling.click();
        this.resetSearch();
        popSnackbar('notice', 'Gift Card Exported Successfully');
      })
      .catch(() => {
        popSnackbar('error', 'Something went wrong');
      });
  }

  resetSearch(e) {
    const searchField = document.getElementById('search-fld');
    searchField.value = '';
    searchField.dispatchEvent(new Event('input'));
    if(e){
      if(e.target.id.includes('digital')){
        document.getElementById('digital').checked = true;
      } else {
        document.getElementById('physical').checked = true;
      }
      this.gift_card.type = e.target.id;
    }
  }

  recheckType() {
    document.getElementById('digital').checked ? this.gift_card.type = 'digital_gift_card' : 'physical_gift_card';
  }
}
