import {Controller} from 'stimulus';
import {freeFieldValidation} from '../services/authentications/free_field_service';

export default class extends Controller {
  inputValidators = {
    new_role_name: false,
    new_role_description: false
  };

  roleCreationForm = document.getElementById('role-creation-form');
  roleDetailInputs = this.roleCreationForm.querySelectorAll('.mandatory_field');

  connect() {
    let validationFunctionMap = {
      new_role_name: ValdiateNewRoleName,
      new_role_description: ValdiateNewRoleDescription
    };

    this.roleDetailInputs.forEach((input) => {
      input.addEventListener('input', () => {
        validationFunctionMap[input.id](input);
      });
    });

    let _this = this;

    function ValdiateNewRoleName(input) {
      let freeFieldStatus = freeFieldValidation(input, 'new_role_name_error_message');
      _this.singleInputValid(freeFieldStatus, input);
    }

    function ValdiateNewRoleDescription(input) {
      let freeFieldStatus = freeFieldValidation(input, 'new_role_description_error_message');
      _this.singleInputValid(freeFieldStatus, input);
    }
  }

  singleInputValid(freeFieldStatus, input) {
    if (freeFieldStatus) {
      this.inputValidators[input.id] = true;
      if (this.allInputsValid() === true) {
        document.getElementById('role_dtl-card').disabled = false;
      }
    } else {
      this.inputValidators[input.id] = false;
      document.getElementById('role_dtl-card').disabled = true;
    }
  }

  allInputsValid() {
    return Object.keys(this.inputValidators).every((item) => {
      return this.inputValidators[item] === true;
    });
  }

  switchStep(event) {
    const roleName = document.getElementById('new_role_name').value.toLowerCase();
    if (event.currentTarget.dataset.currentTab === 'role_detail'
        && JSON.parse(event.currentTarget.dataset.roleList).includes(roleName)) {
      //TODO: UI translation
      document.getElementById('new_role_name_error_message').innerHTML = 'Role name already exists';
      return;
    }
    let targetTab = event.currentTarget.dataset.switchTabId;
    document.getElementById(targetTab).click();

    switch (targetTab) {
    case 'role_detail':
      document.getElementById('step-tracker-webshop_detail').classList.remove('bg-blue');
      document.getElementById('step-tracker-order_detail').classList.remove('bg-blue');
      document.getElementById('step-tracker-webshop_detail').classList.add('bg-gray-800');
      document.getElementById('step-tracker-order_detail').classList.add('bg-gray-800');
      document.getElementById('step-tracker-role_detail').innerHTML = 'edit';
      document.getElementById('step-tracker-webshop_detail').innerHTML = 'edit';
      document.getElementById('step-tracker-order_detail').innerHTML = 'edit';
      break;
    case 'webshop_detail':
      document.getElementById('step-tracker-order_detail').classList.remove('bg-blue');
      document.getElementById('step-tracker-order_detail').classList.add('bg-gray-800');
      document.getElementById(`step-tracker-${targetTab}`).classList.remove('bg-gray-800');
      document.getElementById(`step-tracker-${targetTab}`).classList.add('bg-blue');
      document.getElementById('step-tracker-role_detail').innerHTML = 'done';
      document.getElementById('step-tracker-webshop_detail').innerHTML = 'edit';
      break;
    default:
      document.getElementById('step-tracker-webshop_detail').classList.remove('bg-gray-800');
      document.getElementById('step-tracker-webshop_detail').classList.add('bg-blue');
      document.getElementById(`step-tracker-${targetTab}`).classList.remove('bg-gray-800');
      document.getElementById(`step-tracker-${targetTab}`).classList.add('bg-blue');
      document.getElementById('step-tracker-role_detail').innerHTML = 'done';
      document.getElementById('step-tracker-webshop_detail').innerHTML = 'done';
      document.getElementById('step-tracker-order_detail').innerHTML = 'done';
    }
  }

  onFieldChange(event) {
    document.getElementById(`${event.currentTarget.id}_error_message`).innerHTML = '';
  }
}
