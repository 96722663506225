import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';
import {VariantService} from '../services/variant_service';
import {setMessage} from '../services/authentications/base_service';
import {validateLength} from '../services/authentications/min-length_service';
import {NumberToggleService} from '../services/number-toggle_service';

export default class extends Controller {
  selectedOptionIds = [];
  variantImages = [];
  variantService = new VariantService();
  numberToggleService = new NumberToggleService();
  variant_preorder;
  connect() {
    this.variant_preorder = this.element.dataset.variantPreorder && {...JSON.parse(this.element.dataset.variantPreorder)};

    if(this.element.dataset.productPreorder){
      const variantId = this.element.dataset.variantId;
      if (JSON.parse(this.element.dataset.productPreorder)) {
        document.getElementById(`pre-order-toggle-${variantId}`).checked = this.variant_preorder.active;
        document.getElementById(`variant-preorder-${variantId}`).style.display = this.variant_preorder.active ? 'block' : 'none';
        document.getElementById(`stock-${variantId}`).value = this.variant_preorder.expected_stock;
      }
    }
  }

  optionSelected(event) {
    let optionValue = event.currentTarget.dataset.value;
    let optionId = event.currentTarget.dataset.optionId; // id is what's actually sent
    let targetFieldId = event.currentTarget.dataset.targetField;
    let targetField = document.getElementById(targetFieldId);

    targetField.value = optionValue;
    targetField.setAttribute('data-scope-id', optionId);
  }

  updateStatus(e) {
    let statusField = e.target.parentElement.querySelector('input[id^=\'variant-status\']');

    statusField.value = e.currentTarget.checked ? 'active' : 'inactive';
  }

  validateFormAndUpdateVariant(e) {
    let variantId = e.currentTarget.dataset.variantId;
    const form = document.getElementById(`edit_variant_${variantId}`);
    const valid = this.variantService.validateVariantForm(form);
    if (valid) this.updateVariant(variantId);
  }

  validateLength(e) {
    const lengthValid = validateLength(e.currentTarget);
    if (!lengthValid) e.stopImmediatePropagation();
  }

  updateVariant(variantId) {
    let loader = document.getElementById(`edit-variant-${variantId}-form-submit-btn`); // loader
    loader.classList.remove('hidden');
    loader.parentElement.disabled = true;
    const startDate = document.getElementById(`select_date_range_${variantId}`).value.split(' to ')[0];
    const endDate = document.getElementById(`select_date_range_${variantId}`).value.split(' to ')[1];
    let variantForm = document.getElementById(`edit_variant_${variantId}`);
    this.getVariantImages(variantId);
    this.getOptionValues(variantId);

    document.getElementById(`option-value-ids-${variantId}`).value = JSON.stringify(this.selectedOptionIds);
    // document.getElementById(`variant-images-${variantId}`).value = JSON.stringify(this.variantImages);

    let formData = new FormData(variantForm);
    formData.append('variant[stock_items][threshold]',
      document.getElementById(`edit_variant_threshold_value_${variantId}`).value);

    formData.append('variant[image_urls]', JSON.stringify(this.variantImages));
    formData.append('variant[pre_order_configs_attributes[][active]]', document.getElementById(`pre-order-toggle-${variantId}`).checked);
    formData.append('variant[pre_order_configs_attributes[][expected_stock]]', document.getElementById(`stock-${variantId}`).value || '0');
    formData.append('variant[pre_order_configs_attributes[][expected_start_date]]', startDate);
    formData.append('variant[pre_order_configs_attributes[][expected_end_date]]', endDate);
    fetch(`/admin/variants/${variantId}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: formData
    }).then((response) => response.text())
      .then((html) => {
        let tmpDom = document.createElement('div');
        tmpDom.innerHTML = html;

        document.getElementById(`modalEditVariant${variantId}`).click();

        document.getElementById('tab_content').replaceWith(tmpDom.firstChild);
        popSnackbar('notice', I18n[window.currentLocale].variant_updation_success_notice);
      });
  }

  resetFormValidations(e) {
    const variantId = e.currentTarget.dataset.variantId;
    const form = document.getElementById(`edit_variant_${variantId}`);
    const imageController = this.application.getControllerForElementAndIdentifier(document.getElementById('variant-image-uploader'), 'multiple-image-upload');
    this.variantService.resetFormValidations(form);
    imageController.emptySelectedExistingImages(this.element);
  }

  onFieldChange(event) {
    const inputId = event.target.dataset.targetField || event.target.id
      || event.target.parentElement.dataset.targetField;
    const errorElement = document.getElementById(`${inputId}_error_message`);
    if (errorElement) setMessage(errorElement.id);
  }

  setInitialStock(e) {
    document.getElementById('variant-stock').value = e.currentTarget.value;
  }

  setThresholdValue(e) {
    document.getElementById(`variant-stock-threshold-${e.currentTarget.dataset.variantId}`).value = e.currentTarget.value;
  }

  toggleNumberField(event) {
    this.numberToggleService.toggleNumberField(event);
  }

  ctrlStockMonitor(e) { // enable or disable monitor
    let thresholdFieldDom = document.getElementById(`monitor-variant-stock-${e.currentTarget.dataset.variantId}`);

    e.currentTarget.checked ? thresholdFieldDom.classList.remove('hidden') : thresholdFieldDom.classList.add('hidden');
  }

  getOptionValues(id) {
    let optionValuesDom = document.getElementById(`input-for-${id}`);
    return optionValuesDom.querySelectorAll('input[data-scope-id]').forEach((input) => {
      this.selectedOptionIds.push(input.dataset.scopeId);
    });
  }

  getVariantImages(scopeId) {
    const modalImageDom = document.getElementById(`edit_variant_${scopeId}`);
    this.variantImages = JSON.parse(modalImageDom.dataset.variantImages  || '[]');
  }

  preOrderToggle(event){
    document.getElementById(`variant-preorder-${event.currentTarget.dataset.variantId}`).style.display = event.target.checked? 'block' : 'none';
  }
}
