import {Controller} from 'stimulus';
import dragula from 'dragula';
import {I18n} from '../translation';
import {popSnackbar} from '../services/snackbar';
import {getPhotoUrl} from '../services/api/blob';
import {
  generateRandomStringPublic,
  renderProductCard
} from '../services/templates/products/product/product_card';
import {pluck} from '../helpers/custom_methods';

export default class extends Controller {
  productImages = [];
  croppedImages = [];
  variantImages = [];
  giftCardImages = [];
  addedVariantImages = []; // for tracking
  selectedExistingImages = [];
  type = 'create'
  uploadComplete = {};

  connect() {
    this.type = this.element.dataset.type?.includes('edit') ? 'update' : 'create';
    if (this.element.dataset.imagesDom) this.initDragula();
  }

  initDragula() {
    let dom = document.getElementById(
      `${this.element.dataset.imagesDom}${this.element.dataset.editVariant ? '-' + this.element.dataset.variantId : ''}`);
    if (this.element.dataset.imagesDom.includes('update')) {
      const id = `${this.element.dataset.imagesDom.replace('update', 'edit')}${this.element.dataset.editVariant ? '-' + this.element.dataset.variantId : ''}`;
      dom = document.getElementById(id);
    }
    const _this = this;
    dragula([dom], {revertOnSpill: true, direction: 'horizontal'})
      .on('drag', (el) => {
        el.classList.remove('sk-border');
        el.classList.add('border', 'border-blue');
      })
      .on('drop', () => {
        const imageFor = dom.id.includes('product') ? 'product' : dom.id.includes('gift') ? 'giftCard' : 'variant';
        _this[`${imageFor}Images`] = [];
        if (imageFor === "product"){
          _this.croppedImages = [];
        }
        const form = dom.closest('form');
        dom.querySelectorAll('.dragImg').forEach((ele) => {
          _this[`${imageFor}Images`].push(JSON.parse(ele.dataset.image));
          if (imageFor === "product"){
            _this.croppedImages.push(JSON.parse(ele.dataset.urls))
          }
        });
        dom.querySelectorAll('video').forEach((ele) => {
          _this[`${imageFor}Images`].push(JSON.parse(ele.dataset.image));
        });
        _this.updateFormImages(form, imageFor);
        if (imageFor === "product") {
          _this.updateFormImages(form, 'cropped');
        }
      })
      .on('dragend', (el) => {
        el.classList.remove('border', 'border-blue');
        el.classList.add('sk-border');
      });
  }

  upload(event) {
    let imageDom = document.getElementById(event.currentTarget.dataset.imagesDom);
    let productId = event.currentTarget.dataset.scopeId;
    const initialLength = imageDom.children.length
    document.getElementById(event.currentTarget.dataset.mainActionBtn).disabled = true;

    const form = event.currentTarget.closest('form');
    this.saveFetchedData(form);
    const imageFor = form.id.includes('product') ? 'product' : form.id.includes('gift') ? 'giftCard' : 'variant';

    [...event.target.files].forEach((image, index) => {
      if (!this.validSize(image)) {
        popSnackbar('error', I18n[window.currentLocale].file_too_large);
        return;
      }

      let tmpDiv = document.createElement('div');
      tmpDiv.innerHTML = '<p>Loading...</p>';
      imageDom.appendChild(tmpDiv);

      const _imageFor = imageFor;
      const _form = form;
      const _event = event;
      this.loadXHR(URL.createObjectURL(image)).then((blobUrl) => {
        getPhotoUrl(blobUrl, (resp) => {
          let tmpDom = document.createElement('div');
          if (this.type === 'update') {
            index += this.croppedImages.length;
          }
          renderProductCard(resp, index, tmpDom, this.type, imageFor, false, productId);
          if ([...event.target.files].length > 1) {
            imageDom.children[initialLength + index].replaceWith(tmpDom);
          } else {
            imageDom.lastElementChild.replaceWith(tmpDom);
          }
          tmpDom.querySelector('img')?.setAttribute('data-image', JSON.stringify(resp));
          tmpDom.querySelector('video')?.setAttribute('data-image', JSON.stringify(resp));
          tmpDom.querySelector('i')?.setAttribute('data-image-index', this.productImages.length + (index + 1));
          this[`${_imageFor}Images`].push(resp);
          this.updateFormImages(_form, _imageFor);
          if (_imageFor === "product") {
            this.updateFormImages(_form, 'cropped');
          }
          this.uploadComplete[resp['url']] = false;

          if (index === event.target.files.length - 1)
            document.getElementById(_event.target.dataset.mainActionBtn).disabled = false;
        });
      });
    });
  }

  insertLink(event) {
    event.preventDefault();
    let imageDom = document.getElementById(event.currentTarget.dataset.imagesDom);
    document.getElementById(event.currentTarget.dataset.mainActionBtn).disabled = true;
    let index = this.productImages.length + 1;
    const form = event.currentTarget.closest('form');
    this.saveFetchedData(form);
    const imageFor = form.id.includes('product') ? 'product' : form.id.includes('gift') ? 'giftCard' : 'variant';
    let tmpDiv = document.createElement('div');
    tmpDiv.innerHTML = '<p>Loading...</p>';
    imageDom.appendChild(tmpDiv);
    let tmpDom = document.createElement('div');
    const imageId = `gallery-${generateRandomStringPublic(6)}.mp4`;
    let inputField = document.getElementById(event.currentTarget.dataset.inputId);
    let image = {
      url: inputField.value,
      blob: "",
      source: "link",
      content_type: "video/mp4"
    }
    tmpDom.innerHTML = `<div class="sk-border h-[216px] p-[12px] rounded-[4px] relative overflow-hidden cursor-pointer" data-position="${index}" id=${imageId}>
    <div class="flex justify-between mb-[16px]">
      <p class="text-[14px] line-clamp-1">${imageId}</p>
      <i class="material-icons-outlined cursor-pointer text-black/[0.7]"
         data-blob-name=""
         data-cy="closeBtn" data-image-id=${imageId}
         data-validate="true"
         data-image-index="${index}"
         data-source="internal-upload"
         data-scope-id = ${event.currentTarget.dataset.recordId}
         data-action="click->multiple-image-upload#removeUpload">close</i> 
    </div>
    <video width="100%" height="150" data-image=${image}>
      <source src="${image.src}" type="${image.content_type}">
    </video>
    <i class="material-icons-outlined absolute left-[40%] top-[44%] !text-[50px]">
      play_circle_filled
    </i>
  </div>`;
    imageDom.lastElementChild.replaceWith(tmpDom);
    tmpDom.querySelector('video')?.setAttribute('data-image', JSON.stringify(image));
    tmpDom.querySelector('i').setAttribute('data-image-index', this.productImages.length + (index + 1));
    this[`${imageFor}Images`].push(image);
    this.updateFormImages(form, imageFor);
    if (imageFor === "product") {
      this.updateFormImages(form, 'cropped');
    }
    document.getElementById(event.target.dataset.mainActionBtn).disabled = false;
    document.getElementById(event.target.dataset.closeModalId)?.click();
  }

  removeUpload(e) {
    const form = e.currentTarget.closest('form');
    this.saveFetchedData(form);
    const imageFor = form.id.includes('product') ? 'product' : form.id.includes('gift') ? 'giftCard' : 'variant';

    let targetImage = document.getElementById(e.currentTarget.dataset?.imageId);
    if (!targetImage) targetImage = document.getElementById(e.currentTarget.dataset?.blobName);
    const closestImageDom = e.currentTarget.closest('div[id*="image"]');

    if (this[`${imageFor}Images`].length === 1) {
      popSnackbar('error', I18n[window.currentLocale].must_have_one_product);
    } else {
      targetImage.remove();
      this.removeImageFromProductArray(e.currentTarget, imageFor);
      this.updateFormImages(form, imageFor);
      if (imageFor === "product") {
        this.updateFormImages(form, 'cropped');
      }
    }

    if (e.currentTarget.dataset.source === 'internal-upload') this.unCheckSelectedExistingImages();
    this.reShuffleUploads(closestImageDom, this[`${imageFor}Images`], imageFor, true, e.currentTarget.dataset.scopeId );
  }

  singleUpload(event) {
    let modalId = this.element.dataset.modalId;
    let productImage = event.currentTarget.dataset.productImage;
    if (!this.validSize(event.target.files[0])) {
      popSnackbar('error', I18n[window.currentLocale].file_too_large);
      return;
    }

    if (window.location.pathname.includes('new') || window.location.pathname.includes('edit')) {
      document.getElementById('product-action-btn').disabled = true;
    } else if (this.element.dataset.action === 'new' || this.element.dataset.action === 'edit') {
      document.getElementById(`brand-form-btn-${this.element.id}`).disabled = true;
    }

    let imageUrl = URL.createObjectURL(event.target.files[0]);
    let dataset = JSON.parse(event.currentTarget.dataset.metadata);
    let imageDomId = dataset.image_dom_id;
    let imageDom = document.getElementById(imageDomId);

    const form = event.currentTarget.closest('form');
    this.saveFetchedData(form);

    imageDom.src = imageUrl;

    let tmpHash = {};
    const targetVariant = JSON.parse(event.currentTarget.dataset.metadata).variant_row_index;

    this.loadXHR((imageDom.src)).then((blobUrl) => {
      getPhotoUrl(blobUrl, (resp) => {
        tmpHash[targetVariant] = resp;

        if (this.variantImages.length === 0) {
          this.variantImages.push(tmpHash);
          this.addedVariantImages.push(targetVariant);
        } else {
          if (this.addedVariantImages.includes(targetVariant)) {
            this.variantImages.forEach((image) => {
              if (Object.keys(image).includes(targetVariant)) {
                image[targetVariant] = tmpHash[targetVariant];
              }
            });
          } else {
            this.variantImages.push(tmpHash);
            this.addedVariantImages.push(targetVariant);
          }
        }
        this.updateFormImages(form, 'variant');

        if (window.location.pathname.includes('new') || window.location.pathname.includes('edit')) {
          //TODO: Might be in use later
          // document.getElementById('product-action-btn').disabled = false;
        } else if (this.element.dataset.action === 'new' || this.element.dataset.action === 'edit') {
          document.getElementById(`brand-form-btn-${this.element.id}`).disabled = false;
        }
        if (productImage === 'false') {
          imageDom.src = resp.url;
          imageDom.dataset.controller = 'auto-cropper';
        }
      }, tmpHash);
    }, tmpHash);
    // might need later
    // if (document.getElementById(`saveEditCategory_${modalId}`)) {
    //   document.getElementById(`saveEditCategory_${modalId}`).removeAttribute('disabled');
    // }
    // if (document.getElementById(`saveEditSubCategory_${modalId}`)) {
    //   document.getElementById(`saveEditSubCategory_${modalId}`).removeAttribute('disabled');
    // }
  }

  validSize(file) {
    const fileSize = file.size / 1024 / 1024; // in MiB
    if (file.type.includes('video') ? (fileSize > 150) : (fileSize > 1)) {
      popSnackbar('error', 'File size is too large');
      return false;
    } else {
      return true;
    }
  }

  loadXHR(url) {
    return new Promise(function (resolve, reject) {
      try {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.onerror = function () {
          reject('Network error.');
        };
        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject('Loading error:' + xhr.statusText);
          }
        };
        xhr.send();
      } catch (err) {
        reject(err.message);
      }
    });
  }

  triggerDropBox(e) {
    const custom_id = e.currentTarget.dataset.customId;
    document.getElementById(`drop_#${custom_id}`).click();
    if (e.currentTarget.dataset.imageDisplayTargetId)
      document.getElementById(e.currentTarget.dataset.imageDisplayTargetId).click();
  }

  selectExistingImages(e) {
    let image = JSON.parse(e.currentTarget.dataset.image);
    if (e.currentTarget.type === 'radio') {
      this.selectedExistingImages = [image];
      return;
    }
    if (e.currentTarget.checked) {
      this.selectedExistingImages.push(image);
    } else {
      this.selectedExistingImages = this.selectedExistingImages.filter((selectedImage) => {
        return selectedImage.blob !== image.blob;
      });
    }
  }

  cancelSelectExistingImages(event) {
    this.productImages = JSON.parse(this.element.dataset.productImages);
    this.variantImages = JSON.parse(this.element.dataset.variantImages);
    const modal_id = event.currentTarget.parentElement.dataset.modalId;
    document.getElementById(modal_id).querySelectorAll('#myCheckbox').forEach(input => input.checked = false);
    this.emptySelectedExistingImages();
  }

  useExistingImages(e) {
    e.preventDefault();
    const dataset = e.currentTarget.dataset;
    const form = document.getElementById(dataset.parentFormId);
    this.saveFetchedData(form);

    if (dataset.selectionType === 'radio') {
      const index = dataset.customId.split('-')[2];
      this.variantImages[index] = {[`variant-${index}-row`]: this.selectedExistingImages[0]};
      // TODO: For multi image adjustment in future
      // this.variantImages = this.selectedExistingImages.reduce((acc, obj, index) => {
      //   acc.push({ [`variant-${index}-row`]: obj });
      //   return acc;
      // }, []);
      const idMatcher = dataset.imageDisplayTargetId.replace('image', 'row');
      document.getElementById(dataset.imageDisplayTargetId).src = this.variantImages[index][idMatcher].url;
      this.updateFormImages(form, 'variant');
      this.closeExistingImagesModal(e);
      return;
    }

    const imageFor = dataset.customId.includes('product') ? 'product' : dataset.customId.includes('gift') ? 'giftCard' : 'variant';
    let imagesDom = document.getElementById(dataset.customId);
    const _this = this;
    const oldImagesCount = this[`${imageFor}Images`].length;
    const selectedImage = this.selectedExistingImages.filter(item => {
      return !_this[`${imageFor}Images`].some(image => image.blob === item.blob);
    })
    selectedImage.forEach(item => {
      this.uploadComplete[item.url] = false;
    });

    const newArray = this[`${imageFor}Images`].concat(selectedImage);
    this[`${imageFor}Images`] = newArray;
    imagesDom.innerHTML = '';
    newArray.forEach((image, index) => {
      let tmpDom = document.createElement('div');
      let new_image = (index+1) <= oldImagesCount
      if (this.type === 'update') {
        index += this.croppedImages.length;
      }
      renderProductCard(image, index, tmpDom, this.type, imageFor, false, dataset.recordId, new_image);
      imagesDom.appendChild(tmpDom);
    });
    this.updateFormImages(form, imageFor);
    if (imageFor === "product") {
      this.updateFormImages(form, 'cropped');
    }

    this.closeExistingImagesModal(e);

    const splitId = dataset.customId.split('-');
    const customIdMap = dataset.customId.includes('new') ? 'new' : `edit-${splitId[splitId.length - 1]}`;
    if (dataset.modalNested) {
      const button = document.getElementById(`modal-${imageFor}-${customIdMap}-btn`);
      if (button) button.click();
      document.getElementById(dataset.imageDisplayTargetId).click();
    }
  }

  emptySelectedExistingImages(dom) {
    this.selectedExistingImages = [];
    if (dom) {
      this.productImages = JSON.parse(dom.dataset.productImages || '[]');
      this.variantImages = JSON.parse(dom.dataset.variantImages || '[]');
    }
  }

  cancelExistingImagesUse(e) {
    e.preventDefault();
    const modal_id = e.currentTarget.parentElement.dataset.modalId;
    this.closeExistingImagesModal(e);
    document.getElementById(modal_id).querySelectorAll('input[name="myCheckbox"]').forEach((checkbox) => {
      checkbox.checked = false;
    });
    document.getElementById(e.target.dataset.imageDisplayTargetId).click();
  }

  closeExistingImagesModal(event) {
    const modal_id = event.currentTarget.parentElement.dataset.modalId;
    document.getElementById(modal_id).querySelectorAll('#myCheckbox').forEach(input => input.checked = false);
    this.emptySelectedExistingImages();
    document.getElementById(modal_id).click();
  }

  unCheckSelectedExistingImages() {
    const selectedProductImageBlobs = pluck(this.productImages, 'blob');
    const parseable = document.getElementById('existing-product-imgs').children;
    Array.from(parseable).forEach((ele) => {
      const imageElement = ele.querySelector('img') || ele.querySelector('video')
      const imageObj = JSON.parse(imageElement.dataset.image);
      if (!selectedProductImageBlobs.includes(imageObj.blob)) {
        ele.querySelector('input[name="myCheckbox"]').checked = false;
      }
    });
  }

  reShuffleUploads(closestImageDom, array, imageFor, imageRemoved, productId) { // can reused in useExistingImages func.
    let imageDom = document.getElementById('product-images');
    (imageDom || closestImageDom).innerHTML = '';

    array.forEach((image, index) => {
      let tmpDom = document.createElement('div');
      renderProductCard(image, index, tmpDom, this.type, imageFor, imageRemoved, productId);
      (imageDom || closestImageDom).appendChild(tmpDom);
    });
  }

  removeImageFromProductArray(ele, imageFor) {
    this[`${imageFor}Images`].forEach((image, index) => {
      if (ele.dataset.blobName === image.blob) {
        this[`${imageFor}Images`].splice(index, 1);
      }
    });
    if (imageFor === "product") {
      this.croppedImages.forEach((image, index) => {
        if (ele.dataset.url === image.original_url) {
          this.croppedImages.splice(index, 1);
        }
      });
    }
  }

  saveFetchedData(form) {
    this.productImages = JSON.parse(form.dataset.productImages || '[]');
    this.croppedImages = JSON.parse(form.dataset.croppedImages || '[]');
    this.variantImages = JSON.parse(form.dataset.variantImages || '[]');
    this.addedVariantImages = JSON.parse(form.dataset.addedVariantImages || '[]');
    this.giftCardImages = JSON.parse(form.dataset.giftCardImages || '[]');
  }

  updateFormImages(form, imageType) {
    form.dataset[`${imageType}Images`] = JSON.stringify(this[`${imageType}Images`]);
  }

  closeModal(e) {
    e.preventDefault();
    const dataset = e.currentTarget.dataset;
    if (dataset.imageDisplayTargetId) {
      document.getElementById(dataset.imageDisplayTargetId).click();
    }
  }
}
