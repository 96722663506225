import {Controller} from 'stimulus';
import {
  passwordConfirmationValidation,
  passwordValidation,
  passwordVisibility
} from '../services/authentications/password_service';
import {validityCount} from '../services/authentications/validation-count_service';
import {completeValidation} from '../services/authentications/complete-validation_service';

export default class extends Controller {
  connect() {
    let validCount = [];

    let password = document.getElementById('reset_password');

    password.addEventListener('input', function () {
      ValidatePassword(this);
    });

    let passwordConfirmation = document.getElementById('reset_password_confirmation');

    passwordConfirmation.addEventListener('input', function () {
      ValidatePasswordConfirmation(this);
    });

    let passwords = document.querySelectorAll('.reset_password_visibility');

    passwordVisibility(passwords);

    function ValidatePassword(input) {
      let passwordStatus = passwordValidation(input, 'reset_password_error_message');
      validityCount(passwordStatus, input.id, validCount);
      ValidatePasswordConfirmation(passwordConfirmation);
      completeValidation('reset_password_submit_button', validCount.length !== 2);
    }

    function ValidatePasswordConfirmation(input) {
      let password = document.getElementById('reset_password').value;
      let passwordConfirmationStatus = passwordConfirmationValidation(input, password, 'reset_password_confirmation_error_message');
      validityCount(passwordConfirmationStatus, input.id, validCount);
      completeValidation('reset_password_submit_button', validCount.length !== 2);
    }
  }
}
