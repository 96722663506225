import { Controller } from 'stimulus';
import {setQuantity} from '../services/carts/update_quantity';

export default class extends Controller {
  connect() {
    let button = document.getElementById('product-detail-member-toggle');
    if(JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
      document.getElementById('product-detail-member-toggle').checked = true;
      if(document.getElementById('applicable_price')){
        document.getElementById('applicable_price').innerHTML = `CHF ${button.dataset.memberPrice}`;
      }
      if(document.getElementById('original_price')) {
        if(!(JSON.parse(this.element.dataset.memberDiscountApplicable))) {
          document.getElementById('original_price').classList.add('hidden');
          document.getElementById('discount_applicable_text').classList.add('hidden');
          return;
        }
        document.getElementById('original_price').innerHTML = `CHF ${button.dataset.originalMemberPrice}`
      }
    } else {
      document.getElementById('product-detail-member-toggle').false = true;
      if(document.getElementById('applicable_price')){
        document.getElementById('applicable_price').innerHTML = `CHF ${button.dataset.actualPrice}`;
      }
      if(document.getElementById('original_price')) {
        document.getElementById('original_price').innerHTML = `CHF ${button.dataset.originalPrice}`
      }
    }
  }

  toggleMembership(event) {
    sessionStorage.setItem('purchaseMembershipOn', event.currentTarget.checked);
    if(event.currentTarget.checked){
      // setQuantity('add', 0);
      if(document.getElementById('applicable_price')){
        document.getElementById('applicable_price').innerHTML = `CHF ${event.currentTarget.dataset.memberPrice}`;
      }
      if(document.getElementById('original_price')) {
        if(!JSON.parse(this.element.dataset.memberDiscountApplicable)) {
          document.getElementById('original_price').classList.remove('hidden');
          document.getElementById('discount_applicable_text').classList.remove('hidden');
          return;
        }
        document.getElementById('original_price').innerHTML = `CHF ${event.currentTarget.dataset.originalMemberPrice}`
      }
    } else {
      // setQuantity('subtract', 1);
      if(document.getElementById('applicable_price')){
        document.getElementById('applicable_price').innerHTML = `CHF ${event.currentTarget.dataset.actualPrice}`;
      }
      if(document.getElementById('original_price')) {
          if(!JSON.parse(this.element.dataset.memberDiscountApplicable)) {
              document.getElementById('original_price').classList.remove('hidden');
              document.getElementById('discount_applicable_text').classList.remove('hidden');
              return;
          }
        document.getElementById('original_price').innerHTML = `CHF ${event.currentTarget.dataset.originalPrice}`
      }
    }
  }
}
