import Carousel from 'stimulus-carousel';

export default class extends Carousel {
  productCount;

  get defaultOptions() {
    return {
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        600: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };
  }

  connect() {
    this.productCount = parseInt(this.element.dataset.productCount);
    super.connect();
    this.defaultOptions;
    this.sliderButton();
    this.swipeButton();
  }

  swipeButton() {
    let _this = this;
    window.addEventListener('resize', function () {
      _this.sliderButton();
    });
  }

  sliderButton() {
    let _this = this;
    if (window.innerWidth < 600) {
      _this.productCount > 1 ? this.display() : this.hide();
    } else if (window.innerWidth > 599 && window.innerWidth < 1280) {
      _this.productCount > 3 ? this.display() : this.hide();
    } else {
      _this.productCount > 5 ? this.display() : this.hide();
    }
  }

  display() {
    let element = document.getElementById('product_slider_button');
    element.classList.remove('hidden');
  }

  hide() {
    let element = document.getElementById('product_slider_button');
    element.classList.add('hidden');
  }
}
