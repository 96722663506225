import { Controller } from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';

export default class extends Controller {
  assignRole() {
    document.getElementById('role-updater-form').addEventListener('turbo:submit-end', (e) => {
      if(e.detail.success) {
        popSnackbar('notice', I18n[window.currentLocale]['role_update_success']);
        document.getElementById('cancel-assign-btn').click();
      } else {
        popSnackbar('error', I18n[window.currentLocale]['role_update_failure']);
      }
    });
  }
}