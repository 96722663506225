import {Controller} from 'stimulus';

export default class extends Controller {
  // NO USAGE.
  productOptionTypes = JSON.parse(this.element.dataset.productOptionTypes);

  selectOption(event) {
    let targetFieldId = event.currentTarget.dataset?.targetField;
    let value = event.currentTarget.dataset?.value;

    let targetField = document.getElementById(targetFieldId);
    targetField.value = value;
    targetField.dataset.value = event.currentTarget.dataset?.optionId;

    this.productOptionTypes.includes(value) ? this.customAppend(value) : null;
  }

  customAppend(value) {
    let addValueCtrl = document.getElementById('add-option-value-ctrl');
    addValueCtrl.dataset.optionTypeTarget = value;

    document.querySelectorAll('*[id^=option-type]').forEach(el => {
      el.id.includes(value) ? el.classList.remove('hidden') : el.classList.add('hidden');
    });
  }
}
