import {message} from './shared';

export const updateProfilePicture = (image, email, callback) => {
  let data = new FormData();
  data.append('user[profile_attributes][photo]', image);
  data.append('user[email]', email);

  fetch('/profile/update', {
    method: 'put',
    body: data
  }).then(response => {
    if (response.ok) {
      response.json().then(value => {
        callback(value);
      });
      message('Profile picture updated successfully.', 'success');
    } else {
      message('Fail to update profile picture.', 'error');
    }
  });
};
