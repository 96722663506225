import {Controller} from 'stimulus';

export default class extends Controller {
  inputId;


  connect() {
    let initialValue = this.element.dataset.initialValue;
    this.inputId = this.element.dataset.inputid;
    document.getElementById(this.inputId).value = initialValue;
  }

  selectOption(event) {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let productType = event.currentTarget.dataset.product_type;
    let taxonId = urlParams.get('taxon_id');
    let taxonomyId = urlParams.get('taxonomy_id');
    let brandId = event.currentTarget.dataset.brand_id;
    let selectedValue = event.currentTarget.dataset.value;
    let selectedActualValue = event.currentTarget.dataset.actual_value;
    document.getElementById(this.inputId).value = selectedValue;
    let url;
    if (productType) {
      url = `/search/filter-storefront-product?filter_by=${selectedActualValue}&product_type=${productType}&taxon_id=${taxonId ? taxonId : ''}&taxonomy_id=${taxonomyId ? taxonomyId : ''}`;
    } else if (brandId) {
      url = `/search/filter-storefront-product?filter_by=${selectedActualValue}&brand_id=${brandId}`;
    } else {
      url = `/search/filter-storefront-product?filter_by=${selectedActualValue}`;
    }
    fetch(url).then(response => {
      response.text().then(text => {
        let tempDom = document.createElement('div');
        tempDom.innerHTML = text;
        let callbackDom = tempDom.querySelector('#product-container');
        document.getElementById('product-container').replaceWith(callbackDom);
      });
    });
  }
}
