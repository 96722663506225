import {Controller} from 'stimulus';

export default class extends Controller {
  regenerateSku() {
    fetch('/admin/new-sku', {}).then(response => {
      response.json().then(data => {
        document.getElementById('product_sku').value = data.sku;
      });
    });
  }

  regenerateBarCode() {
    fetch('/admin/new-barcode', {}).then(response => {
      response.json().then(data => {
        document.getElementById('product_barcode').value = data.barcode;
      });
    });
  }
}