import {Controller} from 'stimulus';

export default class extends Controller {
    indexCount = (+document.getElementById('taxon-count')?.dataset?.count || 1) - 1;

  selectTaxonomy(event) {
    const selectedTaxonomy = event.currentTarget.dataset.value;
    const configuratorDom = document.getElementById('pc-step-one');
    const activeLocale = configuratorDom ? configuratorDom.querySelector('.active-locale').dataset.locale : 'en';
    const index = event.currentTarget.dataset.index;

    fetch(`/admin/taxonomy-taxons?locale=${activeLocale}&taxonomy=${selectedTaxonomy.toLowerCase()}&index=${index}`, {})
      .then(response => response.text())
      .then(html => {
        let tmpDom = document.createElement('div');
        tmpDom.innerHTML = html;

        document.getElementById(`taxonomy_dropdown_options_${index}_${activeLocale}`).replaceWith(tmpDom.firstChild || '');
      });

    fetch(`/admin/taxon-children?locale=${activeLocale}&taxonomy=${selectedTaxonomy.toLowerCase()}&index=${index}`, {})
      .then(response => response.text())
      .then(html => {
        let tmpDom = document.createElement('div');
        tmpDom.innerHTML = html;
        document.getElementById(`taxon_dropdown_options_${index}_${activeLocale}`).replaceWith(tmpDom.firstChild || '');
      });
  }

  selectTaxon(event) {
    let selectedTaxon = event.currentTarget.dataset.value;
    const configuratorDom = document.getElementById('pc-step-one');
    let activeLocale = configuratorDom.querySelector('.active-locale').dataset.locale;
    const index = event.currentTarget.dataset.index;

    fetch(`/admin/taxon-children?locale=${activeLocale}&taxon=${selectedTaxon.toLowerCase()}&index=${index}`, {})
      .then(response => response.text())
      .then(html => {
        let tmpDom = document.createElement('div');
        tmpDom.innerHTML = html;

        document.getElementById(`taxon_dropdown_options_${index}_${activeLocale}`).replaceWith(tmpDom.firstChild || '');
      });
  }

  selectTaxonChild(event) {
    let selectedTaxonChild = event.currentTarget.dataset.value;
    const configuratorDom = document.getElementById('pc-step-one');
    const activeLocale = configuratorDom ? configuratorDom.querySelector('.active-locale').dataset.locale : 'en';
    const index = event.currentTarget.dataset.index;

        fetch(`/admin/taxonomy-taxons?locale=${activeLocale}&taxonomy=${selectedTaxonChild.toLowerCase()}&index=${index}`, {})
      .then(response => response.text())
      .then(html => {
        let tmpDom = document.createElement('div');
        tmpDom.innerHTML = html;

        document.getElementById(`taxon_dropdown_options_${index}_${activeLocale}`).replaceWith(tmpDom.firstChild || '');
      });
  }

    addCategory(){
      this.indexCount = this.indexCount + 1;
        fetch(`/admin/add-category?index=${this.indexCount}`, {})
            .then(response => response.json())
            .then(data => {
                if (data.en && data.de && data.fr){
                    ['en', 'fr', 'de'].forEach(locale => {
                        let tmpDom = document.createElement('div');
                        tmpDom.innerHTML = data[locale];
                        document.getElementById(`category_add_${locale}`).appendChild(tmpDom.firstChild);
                    })
                }
            });
    }

    removeCategory(event){
      let index = event.currentTarget.dataset.index;
      ['en', 'fr', 'de'].forEach(locale => {
        document.getElementById(`category_set_${index}_${locale}`)?.remove();
      })
    }
}
