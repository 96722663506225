import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';

export default class extends Controller {

  productCount;
  selectedProducts = [];
  checkedProductCount = 0;
  products = {
    brand_id: '',
    status: '',
    official_price: '',
    official_price_type: '',
    price: '',
    price_type: '',
    type: '',
    internal_category_id: '',
    product_type: '',
    category_ids: '',
    ribbon_id: '',
    tag_ids: [],
    sport_ids: [],
    product_ids: [],
    official_type: ''
  };
  tagIds = [];
  guestPriceType = 'percentage';
  officialPriceType = 'percentage';

  connect() {
    this.productCount = this.element.dataset.productCount;
    ['brandDropdown', 'productTypeDropdown', 'product_taxonomy_en', 'product_taxon_en', 'internal-category_bulk', 'typeDropdown', 'ribbonDropdown', 'tagDropdown'].forEach(res => {
      document.getElementById(res).readOnly = true;
    });

    ['price', 'official_price'].forEach( res => {
      document.getElementById(res).addEventListener('input', () => {
        this.checkForValue();
      })
    })
  }

  goToBulkEdit() {
    document.getElementById('product-tabs').classList.remove('mx-[24px]', 'mb-[24px]');
    document.getElementById('product-tabs').classList.add('m-[24px]');
    document.getElementById('product-selection-controller-element').classList.add('hidden');
    document.getElementById('search-products-header').classList.add('hidden');
    document.getElementById('tabs-tab').classList.add('hidden');
    document.getElementById('bulk-edit-page').classList.remove('hidden');
  }

  cancelBulkEdit() {
    document.getElementById('product-tabs').classList.remove('m-[24px]');
    document.getElementById('product-tabs').classList.add('mx-[24px]', 'mb-[24px]');
    document.getElementById('product-selection-controller-element').classList.remove('hidden');
    document.getElementById('search-products-header').classList.remove('hidden');
    document.getElementById('tabs-tab').classList.remove('hidden');
    document.getElementById('bulk-edit-page').classList.add('hidden');
    this.resetForm();
  }

  checkProduct(event) {
    if(event.target.checked){
      this.checkedProductCount++;
      this.selectedProducts.push(event.target.dataset.productId);
    } else {
      this.checkedProductCount--;
      this.selectedProducts.splice(this.selectedProducts.indexOf(event.target.dataset.productId), 1);
    }
    if(this.checkedProductCount > 0){
      document.getElementById('product_update_container').classList.remove('hidden');
      document.getElementById('product_selected_count').innerHTML = `${this.checkedProductCount} selected`;
    } else {
      document.getElementById('product_update_container').classList.add('hidden');
    }
    if(this.checkedProductCount != this.productCount) {
      document.getElementById('uncheck_products').checked = false;
    } else {
      document.getElementById('uncheck_products').checked = true;
    }
  }

  selectAll(event) {
    if(event.target.checked) {
      document.getElementById('select_all_products').classList.add('hidden');
      document.getElementById('uncheck_products').checked = true;
      this.checkedProductCount = this.productCount;
      this.selectedProducts = [];
      JSON.parse(document.getElementById('get-product-ids').dataset.productIds).forEach(res => {
        this.selectedProducts.push(res.toString());
      });
      document.querySelectorAll('.product-check-box').forEach(res => res.checked = true);
      document.getElementById('product_update_container').classList.remove('hidden');
      document.getElementById('product_selected_count').innerHTML = `${this.checkedProductCount} selected`;
    } else {
      document.getElementById('select_all_products').checked = false;
      document.getElementById('select_all_products').classList.remove('hidden');
      this.checkedProductCount = 0;
      this.selectedProducts = [];
      document.getElementById('product_update_container').classList.add('hidden');
      document.querySelectorAll('.product-check-box').forEach(res => res.checked = false);
    }
  }

  toggleActiveGuest(event) {
    if (event.target.dataset.key == 'percentage') {
      this.guestPriceType = 'percentage';
      document.getElementById('guestPercentage').classList.add('bg-blue', 'text-white');
      document.getElementById('guestPercentage').classList.remove('text-black/[0.6]');
      document.getElementById('guestFixed').classList.remove('bg-blue', 'text-white');
      document.getElementById('guestFixed').classList.add('text-black/[0.6]');
      document.getElementById('price').placeholder = '%';
    } else {
      this.guestPriceType = 'fixed';
      document.getElementById('guestFixed').classList.add('bg-blue', 'text-white');
      document.getElementById('guestFixed').classList.remove('text-black/[0.6]');
      document.getElementById('guestPercentage').classList.remove('bg-blue', 'text-white');
      document.getElementById('guestPercentage').classList.add('text-black/[0.6]');
      document.getElementById('price').placeholder = 'CHF';
    }
    this.checkForValue();
  }

  toggleActiveOfficial(event) {
    if (event.target.dataset.key == 'percentage') {
      this.officialPriceType = 'percentage';
      document.getElementById('officialPercentage').classList.add('bg-blue', 'text-white');
      document.getElementById('officialPercentage').classList.remove('text-black/[0.6]');
      document.getElementById('officialFixed').classList.remove('bg-blue', 'text-white');
      document.getElementById('officialFixed').classList.add('text-black/[0.6]');
      document.getElementById('official_price').placeholder = '%';
    } else {
      this.officialPriceType = 'fixed';
      document.getElementById('officialFixed').classList.add('bg-blue', 'text-white');
      document.getElementById('officialFixed').classList.remove('text-black/[0.6]');
      document.getElementById('officialPercentage').classList.remove('bg-blue', 'text-white');
      document.getElementById('officialPercentage').classList.add('text-black/[0.6]');
      document.getElementById('official_price').placeholder = 'CHF';
    }
    this.checkForValue();

  }

  toggleActive(e) {
    this.products.status = e.currentTarget.value;
  }

  handleInput() {
    this.products.official_price = document.getElementById('official_price').value;
    this.products.official_price_type = this.officialPriceType;
    this.products.price = document.getElementById('price').value;
    this.products.price_type = this.guestPriceType;
    this.products.type = document.getElementById('typeDropdown').value;
    this.products.official_type = document.getElementById('typeDropdownPrice').value;
    this.products.product_type = document.getElementById('productTypeDropdown').value;
    this.products.internal_category_id = document.getElementById('subsubDropdownValue')?.value || document.getElementById('subDropdownValue')?.value || document.getElementById('internalDropdownValue')?.value;
  }

  selectDropdown(e) {
    ['typeDropdown', 'brandDropdown', 'internal-category_bulk', 'internal_subcategory_bulk', 'internal_sub_nested_category_bulk', 'productTypeDropdown', 'ribbonDropdown', 'tagDropdown', 'product_taxon_en'].forEach(() => {
      switch (e.currentTarget.dataset.targetField) {
      case 'brandDropdown':
        this.products.brand_id = e.currentTarget.dataset.optionId;
        document.getElementById(e.currentTarget.dataset.targetField).value = e.currentTarget.dataset.value;
        document.getElementById('brandDropdown_error_message_').classList.add('hidden');
        break;
      case 'ribbonDropdown':
        this.products.ribbon_id = e.currentTarget.dataset.optionId;
        document.getElementById(e.currentTarget.dataset.targetField).value = e.currentTarget.dataset.value;
        break;
      case 'product_taxon_en':
        this.products.category_ids = [`${JSON.parse(e.currentTarget.dataset.metadata).find(data => data.id === +e.currentTarget.dataset.optionId).taxon_id}`];
        document.getElementById(e.currentTarget.dataset.targetField).value = e.currentTarget.dataset.value;
        document.getElementById('product_taxon_en_error_message_').classList.add('hidden');
        this.categoryValidator();
        break;
      case 'internal-category_bulk':
        document.getElementById('internalDropdownValue').value = e.currentTarget.dataset.optionId;
        this.handleInput();
        document.getElementById(e.currentTarget.dataset.targetField).value = e.currentTarget.dataset.value;
        break;
      case 'internal_subcategory_bulk':
        document.getElementById('subDropdownValue').value = e.currentTarget.dataset.optionId;
        this.handleInput();
        document.getElementById(e.currentTarget.dataset.targetField).value = e.currentTarget.dataset.value;
        break;
      case 'internal_sub_nested_category_bulk':
        document.getElementById('subsubDropdownValue').value = e.currentTarget.dataset.optionId;
        this.handleInput();
        document.getElementById(e.currentTarget.dataset.targetField).value = e.currentTarget.dataset.value;
        break;
      case 'productTypeDropdown':
        document.getElementById(e.currentTarget.dataset.targetField).value = e.currentTarget.dataset.value;
        this.handleInput();
        document.getElementById('productTypeDropdown_error_message_').classList.add('hidden');
        break;
      default:
        document.getElementById(e.currentTarget.dataset.targetField).value = e.currentTarget.dataset.value;
        this.handleInput();
        this.checkForType();
        this.checkForValue();
      }
    });
  }
  selectSports(e) {
    if (!this.products.sport_ids.includes(e.currentTarget.dataset.sportId)){
      this.products.sport_ids.push(e.currentTarget.dataset.sportId);
    }
    else {
      this.products.sport_ids.splice(this.products.sport_ids.indexOf(e.currentTarget.dataset.sportId), 1);
    }
    if (e.currentTarget.dataset.sportId == '0') {
      this.products.sport_ids = ['0'];
    } else if (this.products.sport_ids.includes('0')) {
      this.products.sport_ids.splice(this.products.sport_ids.indexOf('0'), 1);
    }
    this.displaySports();
  }

  displaySports() {
    const sportChips = document.querySelectorAll('.sk-sports');
    sportChips.forEach(res => {
      const sportChip = document.getElementById(`sport_chip_${res.dataset.sportId}`);
      const icon = document.getElementById(`sport_icon_${res.dataset.sportId}`);
      if(this.products.sport_ids.includes(res.dataset.sportId)) {
        sportChip.classList.remove('sk-chip-inactive');
        sportChip.classList.add('sk-chip-active');
        icon.style.display = 'block';
      } else {
        sportChip.classList.add('sk-chip-inactive');
        sportChip.classList.remove('sk-chip-active');
        icon.style.display = 'none';
      }
    });
  }

  categoryValidator() {
    const subCategory = document.getElementById('product_taxon_en');
    const errorField = document.getElementById('product_taxon_en_error_message_');
    const category = document.getElementById('product_taxonomy_en');
    let returnType = true;
    if (!subCategory.value && category.value) {
      errorField.style.display = 'block';
      returnType = false;
    } else {
      errorField.style.display = 'none';
      returnType = true;
    }
    return returnType;
  }

  bulkUpdate() {
    this.products.product_ids = this.selectedProducts;
    this.products.tag_ids = this.tagIds?.map(res => res.id);
    if(this.checkForType() && this.categoryValidator() && !this.checkForValue()){
      for (const key in this.products) {
        if (this.products[key] == '') {
          delete this.products[key];
        }
      }
      ['ribbon_id', 'sport_ids', 'tag_ids', 'internal_category_id'].forEach(res => {
        if (this.products[res]?.includes('0')) {
          this.products[res] = Array.isArray(this.products[res]) ? [] : null;
        }
      });
      !this.products.price ? delete this.products.price_type : '';
      !this.products.official_price ? delete this.products.official_price_type : '';
      fetch('/admin/product_configurator/bulk_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({products: this.products})
      }).then(response => {
        const productsObject = {
          brand_id: '',
          status: '',
          official_price: '',
          official_price_type: '',
          price: '',
          price_type: '',
          type: '',
          internal_category_id: '',
          product_type: '',
          category_ids: '',
          ribbon_id: '',
          tag_ids: [],
          sport_ids: [],
          product_ids: []
        };
        this.products = {...productsObject, ...this.products};
        if (response.status === 200) {
          document.getElementById('cancel-bulkedit').click();
          popSnackbar('notice', 'Products Updated');
          setTimeout(() => {
            location.reload();
          }, 500);
        } else {
          popSnackbar('error', 'Bulk Update Failed');
        }
      });
    } else {
      popSnackbar('error', 'Please Fill All The Required Fields!');
    }
  }

  removeErrors() {
    ['brandDropdown', 'productTypeDropdown', 'product_taxon_en'].forEach(res => {
      document.getElementById(`${res}_error_message_`)?.classList.add('hidden');
    });
  }

  selectTags(e) {
    if(!this.tagIds.some(res => res.id == e.currentTarget.dataset.optionId)){
      this.tagIds.push({id: e.currentTarget.dataset.optionId, name: e.currentTarget.dataset.optionName});
    }
    const index0 = this.tagIds.some( res => res.id == '0');
    if (e.currentTarget.dataset.optionId == '0') {
      this.tagIds = [{id: e.currentTarget.dataset.optionId, name: e.currentTarget.dataset.optionName}];
    } else if (index0) {
      this.tagIds.splice(0, 1);
    }
    this.displayTags();
  }

  displayTags() {
    document.getElementById('selected-tag-list').innerHTML = '';
    for(let i= 0; i < this.tagIds.length; i++) {
      document.getElementById('selected-tag-list').innerHTML += `
             <div class="flex items-center space-x-[8px] border rounded-full cursor-pointer px-[12px] py-[6px] mr-[16px] mt-[16px]"
             data-cy="productTag" data-mdb-ripple="true">
          <p class="text-[14px] pt-[2px]">${this.tagIds[i].name}</p>
          <i class="material-icons-outlined !text-[16px] text-black/[0.6]" data-action="click->product-selection#removeTag" data-id="${this.tagIds[i].id}">close</i>
        </div>
            `;
    }
  }

  removeTag(e) {
    let indexTag = -1;
    this.tagIds.forEach((value, index) => {
      if(value.id == e.target.dataset.id){
        indexTag = index;
      }
    });
    this.tagIds.splice( indexTag, 1);
    this.displayTags();
  }

  isValid() {
    return Array.from(document.querySelectorAll('#brandDropdown, #productTypeDropdown, #subCategoryDropdown')).every(res => res.value);
  }

  resetForm() {
    this.products = {
      brand_id: '',
      status: '',
      official_price: '',
      official_price_type: '',
      price: '',
      price_type: '',
      type: '',
      internal_category_id: '',
      product_type: '',
      category_ids: '',
      ribbon_id: '',
      tag_ids: [],
      sport_ids: [],
      product_ids: []
    };
    document.getElementById('guestPercentage').click();
    document.getElementById('officialPercentage').click();
    this.products.tag_ids = [];
    this.tagIds = [];
    this.displayTags();
    this.removeErrors();
    this.displaySports();
  }

  checkForType() {
    [{id: 'price', type: 'typeDropdown_error_id', input: 'typeDropdown'}, {id: 'official_price', type: 'typeDropdownPrice_error_message', input: 'typeDropdownPrice'}].forEach(val => {
      if (document.getElementById(val.id).value && !document.getElementById(val.input).value) {
        document.getElementById(val.type).innerText = 'This Field is Required';
      } else {
        document.getElementById(val.type).innerText = '';
      }
    })
    return [{id: 'price', input: 'typeDropdown'}, {id: 'official_price', input: 'typeDropdownPrice'}].every( res => !document.getElementById(res.id).value || (document.getElementById(res.id).value && document.getElementById(res.input).value));
  }

  checkForValue() {
   [{id: 'price',input: 'typeDropdown', error: 'guest-percentage-value', value: 'guestPriceType' } , { id: 'official_price', type: 'typeDropdownPrice_error_message', input: 'typeDropdownPrice', error: 'official-percentage-value', value: 'officialPriceType' }].forEach( val => {
      if(document.getElementById(val.id).value && document.getElementById(val.id).value > 100 && this[val.value] == 'percentage' && document.getElementById(val.input).value == 'Decrement'){
        document.getElementById(val.error).style.display = 'block';
      } else {
        document.getElementById(val.error).style.display = 'none';
      }
    })
    return ['guest-percentage-value', 'official-percentage-value'].some( res => window.getComputedStyle(document.getElementById(res)).display === 'block');
  }
}