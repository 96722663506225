import {setMessage} from './base_service';
import {freeFieldValidation} from './free_field_service';
import {InternalCategoryService} from '../internal-category_service';

class FormValidationService {
  form;
  #firstErrorElement;

  constructor(formDoms) {
    this.form = formDoms;
  }

  onInput(event) {
    const inputId = event.target.dataset.targetField || event.target.id
          || event.target.parentElement.dataset.targetField;
    let errorElement;
    this.form.find(form => {
      errorElement = form.querySelector(`p[id='${inputId}_error_message']`);
      return !!errorElement;
    });
    if (errorElement) setMessage(errorElement.id);
  }

  validateDecimals(e) {
    const splitValue = e.currentTarget.value.split('.');
    if (splitValue.length > 1) {
      e.currentTarget.value =
          splitValue[1].length > 2 ? +(`${splitValue[0]}.${splitValue[1].substring(0,2)}`)
            : e.currentTarget.value;
    }
  }

  /*
  * formDom - Takes an arry of DOMs to loop and validate the fields inside it
  * checkElements - Takes an array of fields to check for inside the dom loop
  * filterOptions - simple filtration for possible use case
  *   attribute - element attribute to take as a subject of filtration
  *   method - method to execute on the attribute
  *   matcher - comparison matcher for the method
  *   negate - adds a not in front of the filter return value
  */

  validateForm(formDom = this.form, checkElements = ['input'], filterOptions) {
    this.#firstErrorElement = undefined;
    let valid = true;
    formDom.forEach(dom => {
      checkElements.forEach(field => {
        (filterOptions? [...dom.querySelectorAll(field)].filter(input => {
          return filterOptions.negate? !input[filterOptions.attribute][filterOptions.method](filterOptions.matcher)
            : input[filterOptions.attribute][filterOptions.method](filterOptions.matcher);
        }) : dom.querySelectorAll(field)).forEach(input => {
          const filteredIds = new InternalCategoryService().filteredIds;
          if (!filteredIds.includes(input.id)) {
            const _this = this;
            const errorElement = dom.querySelector(`p[id='${input.id}_error_message']`);
            if (errorElement) {
              valid = freeFieldValidation(input, errorElement.id) && valid;
              if (!_this.firstErrorElement && valid === false) _this.#firstErrorElement = errorElement;
            }
          }
        });
      });
    });
    return valid;
  }

  scrollToError() {
    if (this.firstErrorElement) this.firstErrorElement
      .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }

  resetForm(formDoms = this.form) {
    formDoms.forEach(form => {
      form.querySelectorAll('p[id$=\'_error_message\']').forEach(element => {
        element.innerHTML = '';
      });
    });
  }

  get firstErrorElement() {
    return this.#firstErrorElement;
  }
}

export { FormValidationService };