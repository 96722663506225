import {Controller} from 'stimulus';
import Cropper from 'cropperjs';
import {updateProfilePicture} from '../services/user_profile';

export default class extends Controller {
  crop(event) {
    let loaderDom = document.getElementById('img-loader');
    loaderDom.parentElement.classList.remove('img-gradient');
    loaderDom.style.display = 'none';

    document.getElementById('profile_image_cropper').click();
    const image = document.getElementById('profile_image_crop');
    const file = event.target.files?.[0];
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      this.cropImage(image, event);
    };
  }

  cancel() {
    document.getElementById('crop-btn').click();
    document.getElementById('profile_picture_save').disabled = true;
  }

  cropImage(image) {
    // eslint-disable-next-line no-unused-vars
    let croppedData;
    const cropper = new Cropper(image, {
      aspectRatio: 1,
      zoomable: false,
      viewMode: 1,
      crop(event) {
        croppedData = {
          width: event.detail.width,
          height: event.detail.height
        };
      },
      ready() {
        document.getElementById('crop-btn').addEventListener('click', () => {
          document.getElementById('profile_picture_save').disabled = false;
          const canvas = cropper.getCroppedCanvas();
          const image = document.getElementById('profile_image_crop');
          image.src = canvas?.toDataURL();
          image.onload = () => {
            cropper.destroy();
          };
        });
        document.getElementById('rotate-btn').addEventListener('click', () => {
          cropper.rotate(90);
        });
      },
    });
  }

  saveImage(event) {
    let loaderDom = document.getElementById('img-loader');
    let user_email = event.currentTarget.dataset.userEmail;
    loaderDom.parentElement.classList.add('img-gradient');
    loaderDom.style.display = 'block';

    let _this = this;
    const imgEl = document.getElementById('profile_image_crop');
    const imgUrl = imgEl.src;
    this.loadXHR(imgUrl).then((imgBlob) => {
      imgBlob.filename = `new_name_secure.${imgBlob.type.split('/')[1]}`;
      updateProfilePicture(imgBlob, user_email, function () {
        document.getElementById('profile_picture_cancel').click();
        _this.replaceProfile(imgUrl);
        _this.replaceHeaderProfile(imgUrl);
        _this.replaceHeaderMenuProfile(imgUrl);
        document.getElementById('crop-btn').click();
      });
    });
  }

  replaceHeaderMenuProfile(imgUrl) {
    this.replaceProfilePicture('header_menu_profile_picture', 'header_menu_optional_profile_picture', imgUrl, 'object-cover h-full' );
  }

  replaceHeaderProfile(imgUrl) {
    this.replaceProfilePicture('header_profile_picture', 'header_optional_profile_picture', imgUrl, 'object-cover h-full' );
  }

  replaceProfile(imgUrl) {
    this.replaceProfilePicture('profile_picture', 'optional_profile_picture', imgUrl, 'rounded-full object-cover h-[82px] w-[82px]' );
  }

  replaceProfilePicture(profileId, optionalId, imgUrl, imgClassList) {
    const profilePicture = document.getElementById(profileId);
    if (profilePicture == null) {
      const img = document.createElement('img');
      img.src = imgUrl;
      img.id = profileId;
      img.classList = imgClassList;
      document.getElementById(optionalId).replaceWith(img);
    } else {
      profilePicture.src = imgUrl;
    }
  }

  loadXHR(url) {
    return new Promise(function (resolve, reject) {
      try {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.onerror = function () {
          reject('Network error.');
        };
        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject('Loading error:' + xhr.statusText);
          }
        };
        xhr.send();
      } catch (err) {
        reject(err.message);
      }
    });
  }
}
