import {Controller} from 'stimulus';
import {emailValidation} from '../services/authentications/email_service';

export default class extends Controller {
  connect() {
    let email = document.getElementById('forgot_password_email');
    email.addEventListener('input', function () {
      ValidateEmail(this);
    });

    function ValidateEmail(input) {
      let emailStatus = emailValidation(input, 'forgot_password_email_error_message');
      Complete(emailStatus);
    }

    function Complete(status) {
      let element = document.getElementById('forgot_password_submit_button');
      element.disabled = !status;
    }
  }
}
