import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    let elements = document.querySelectorAll('.selected-color');
    elements.forEach((element) => {
      element.classList?.add('border-blue');
      this.addToParam(element.id,this.element.dataset.from);
    });
  }

  selectProduct(event){
    const id = event?.currentTarget.getAttribute('id');
    const selectedVariantElement = document.getElementById(this.element.id).children;
    for(let i = 0; i < selectedVariantElement.length; i++){
      if(selectedVariantElement[i].children[1].getAttribute('id') === id){
        document.getElementById(id)?.classList?.add('border-blue');
        document.getElementById(id)?.classList?.remove('border-white');
        this.addToParam(id,this.element.dataset.from);
      } else{
        const childClassList = selectedVariantElement[i]?.children[1]?.classList;
        childClassList.remove('border-blue');
        childClassList.add('border-white');

      }
    }
    let imgTagId = this.element.dataset.productImgId;
    fetch(
        `/variant_selector/${event.currentTarget.dataset.colorId}/image?product_id=${event.currentTarget.dataset.productId}`
    ).then(response => {
      response.json().then(data => {
        if(document.getElementById(imgTagId) && data.url){
         document.getElementById(imgTagId).src =  data.url
        }
      });
    });
  }

  addToParam(id,linkId){
    let myLink = document.getElementById(linkId);
    if (myLink) {
      let currentUrl = myLink.getAttribute('href').split('?')[0];
      let additionalParams = `selected-variant=${id}`;
      let newUpdatedUrl = currentUrl + '?' + additionalParams;
      myLink.setAttribute('href', newUpdatedUrl);
    }
  }
}
