import {Controller} from 'stimulus';
import {variantCard} from '../services/templates/products/variants/variant-card';

export default class extends Controller {
  selectedVariantDomIds = [];
  editVariantModalDom;
  productVariantsController = '';

  connect() {
    let elements = document.getElementById('product-variants');
    let counter = 0;

    for(let i=0; i<elements.children.length; i+=2) {
      this.selectedVariantDomIds.push(`variant-${counter}-row`);
      counter++;
    }
  }

  editVariantStock() {
    this.editVariantModalDom = document.getElementById('variant-cards-dom');
    this.resetSelectedVariants();
    document.getElementById('modal-title').innerHTML = 'Edit Stock';
    document.getElementById('modal-subtitle').innerHTML = 'The stock management quantity set for the product will help you keep track of the inventory. Please note that you will be notified when the product goes out of stock.';

    this.getSelectedVariants();
    this.editScope('editStock');

    this.selectedVariantDomIds.forEach(variantDomId => {
      this.variantDom(variantDomId, 'editStock');
    }, this);
  }

  editVariantThreshold() {
    this.editVariantModalDom = document.getElementById('variant-cards-dom');
    this.resetSelectedVariants();
    document.getElementById('modal-title').innerHTML = 'Edit Threshold';
    document.getElementById('modal-subtitle').innerHTML = 'Enter the product threshold you want to set. Do note that you will be notified when the product stock quantity reaches the threshold.';

    this.getSelectedVariants();
    this.editScope('editThreshold');

    this.selectedVariantDomIds.forEach(variantDomId => {
      this.variantDom(variantDomId, 'editThreshold');
    }, this);
  }

  editVariantDimensions() {
    this.editVariantModalDom = document.getElementById('edit-dimensions-variants-card');
    document.getElementById('edit-dimensions-variants-card').innerHTML = ''; // reset modal content
    this.getSelectedVariants();
    this.editScope('editDimensions');

    this.selectedVariantDomIds.forEach(variantDomId => {
      this.variantDom(variantDomId, 'editDimensions');
    }, this);
  }

  variantDom(domId) {
    let targetVariantDom = this.getVariantRowDom(domId)[0]; // sku reside in first dom element

    let sku = targetVariantDom.querySelector('.sku').innerText;
    let variantOptions = targetVariantDom.querySelector('.variant-options').innerText;

    this.appendSelectedVariant(variantOptions, sku);
  }

  appendSelectedVariant(variantOptions, sku) {
    // if (document.querySelector(`[value="${sku}"]`)) return; // if not leads to dom duplicacy

    let tmpDom = document.createElement('div');
    tmpDom.innerHTML = variantCard(variantOptions, sku);

    this.editVariantModalDom.appendChild(tmpDom.firstChild);
  }

  applyEditValue(event) {
    let editType = '';
    if (event.currentTarget.dataset.scope === 'editStock') {
      editType = 'initial-stock-input';
    } else if (event.currentTarget.dataset.scope === 'editThreshold') {
      editType = 'threshold-input';
    }

    let inputTargetId = event.currentTarget.dataset.inputTarget;
    let editValue = document.getElementById(inputTargetId).value;

    if (editType === 'initial-stock-input' || editType === 'threshold-input') {
      this.selectedVariantDomIds.forEach(variantDomId => {
        let targetDom = this.getVariantRowDom(variantDomId);
        targetDom[0].querySelector(`.${editType}`).value = editValue;

        this.resetView(targetDom);
        document.getElementById('applyEditValue').value = 0;
      }, this);
    }

    document.getElementById('cancelThresholdModalBtn').click();
    document.getElementById('variant-row-header').checked = false;
  }

  applyDimensionsEditValue() { // needed to do this separately
    let editDimeModal = document.getElementById('modalEditDimension');
    let inputDimeValues = editDimeModal.querySelectorAll('.dimensions-input');

    inputDimeValues.forEach(input => {
      let editValue = input.value;
      let dimeType = input.id;

      this.selectedVariantDomIds.forEach(variantDomId => {
        let targetDom = this.getVariantRowDom(variantDomId);
        if(editValue !== '0') {
          targetDom[1].querySelector(`.${dimeType}-input`).value = editValue;
        }
      }, this);
    });


    // resetting modal
    inputDimeValues.forEach(input => {
      input.value = 0;
    });
    this.editVariantModalDom.innerHTML = '';

    // remove checked and blue highlight
    this.selectedVariantDomIds.forEach(variantDomId => {
      let targetDom = this.getVariantRowDom(variantDomId);
      this.resetView(targetDom);
    }, this);

    document.getElementById('variant-dimensions-cancel-edit-btn').click();
    document.getElementById('variant-row-header').checked = false;
  }

  getVariantRowDom(domId) {
    let dom = document.querySelector(`[data-variant-index=${domId}]`);

    return document.querySelectorAll(`.variant-${dom.dataset.index}-row`);
  }

  editScope(scope) {
    document.querySelectorAll('.apply-edit-value-ctrl').forEach(element => {
      element.dataset.scope = scope;
    });
  }

  resetView(targetDom) {
    targetDom[0].querySelector('.variant-item').checked = false; // remove checked
    targetDom.forEach(ele => {
      ele.classList.remove('bg-blue/[0.08]');
    });
    document.querySelector('.variant-options-check').checked = false; // remove checked from checkbox displayed on select
    document.getElementById('variants-dropdown-options').classList.add('hidden'); // remove table selected variant table header

    this.productVariantsController.showVariantTableHeader();
    this.productVariantsController.selectedVariants = [];
    // document.getElementById('variant-cards-dom').innerHTML = '';
    this.editVariantModalDom.innerHTML = '';
  }

  getSelectedVariants() {
    let mainForm = '';
    if(window.location.pathname.includes('edit')) {
      let scopeId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
      mainForm = document.getElementById(`edit_product_${scopeId}`);
    } else {
      mainForm = document.getElementById('new_product');
    }
    const productVariantsController = this.application.getControllerForElementAndIdentifier(mainForm, 'product-variants');

    this.productVariantsController = productVariantsController;
    this.selectedVariantDomIds = productVariantsController.selectedVariants;
  }

  resetSelectedVariants() {
    document.getElementById('variant-cards-dom').innerHTML = '';
  }

  removeVariant() {
    this.getSelectedVariants();
    let variantRows = document.getElementById('product-variants');

    this.selectedVariantDomIds.forEach(variantDomId => {
      let newID = variantDomId.replace('checkbox', 'row');

      variantRows.querySelectorAll(`.${newID}`).forEach(row => {
        row.remove();
      });
    });

    document.getElementById('variant-row-header').checked = false;
    document.querySelector('.variant-options-check').checked = false;
    // document.getElementById('variants-dropdown-options').classList.add('hidden');
    this.selectedVariantDomIds = [];
  }
}
