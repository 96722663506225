import {Controller} from 'stimulus';
import {phoneValidation} from '../services/authentications/phone_service';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {countryCode} from '../services/authentications/country-code_service';
import {AsYouType} from 'libphonenumber-js';
import {zipcodeValidation} from '../services/authentications/zipcode_service';
import {validateLength} from '../services/authentications/min-length_service';
import flatpickr from "flatpickr";

export default class extends Controller {
  connect() {
    let initialGender = document.getElementById('hidden_edit_gender').value;
    let code = countryCode('Switzerland');
    this.dateTimeUser();
    this.dateTimeUserMobile()
    let functionMap = {
      'edit_first_name': validateName,
      'edit_last_name': validateName,
      'edit_phone': validatePhone,
      'edit_street_name': validateName,
      'edit_zipcode': validateZipcode,
      'edit_city': validateName,
      'edit_state': validateName
    };

    let inputs = document.querySelectorAll('.edit_profile');
    let inputGender = document.querySelectorAll('.gender_value');

    let initial_field_value = [];
    let edit_status = [];
    for (let i = 0; i < inputs.length; i++) {
      initial_field_value[i] = inputs[i].value;
      edit_status[i] = 'no_update';
      inputs[i].addEventListener('input', function () {
        functionMap[this.id](this, i);
        submitButton();
      });
    }

    for (let i = 0; i < inputGender.length; i++) {
      inputGender[i].addEventListener('click', function () {
        validateGender(this);
      });
    }

    function validateName(input, i) {
      let freeFieldStatus = freeFieldValidation(input, `${input.id}_error_message`);
      if (freeFieldStatus) {
        updateEditStatus(input, i);
      } else {
        edit_status[i] = 'error';
      }
    }

    function validateGender(input) {
      document.getElementById('edit_gender_desktop').value = input.dataset.select;
      document.getElementById('edit_gender_mobile').value = input.dataset.select;
      document.getElementById('hidden_edit_gender').value = input.dataset.value;
      submitButton();
    }

    function validateZipcode(input, i) {
      let zipcodeStatus = zipcodeValidation(input, `${input.id}_error_message`);
      if (zipcodeStatus) {
        updateEditStatus(input, i);
      } else {
        edit_status[i] = 'error';
      }
    }

    function validatePhone(input, i) {
      input.value = new AsYouType(code['short_name']).input(input.value);
      let phoneStatus = phoneValidation(input, code['short_name']);
      if (phoneStatus) {
        updateEditStatus(input, i);
      } else {
        edit_status[i] = 'error';
      }
    }

    function updateEditStatus(input, i) {
      if (input.value === initial_field_value[i]) {
        edit_status[i] = 'no_update';
      } else {
        edit_status[i] = 'update';
      }
    }

    function submitButton() {
      if (edit_status.includes('error')) {
        document.getElementById('edit_profile_submit').disabled = true;
      } else if (edit_status.includes('update')) {
        document.getElementById('edit_profile_submit').disabled = false;
      } else if (edit_status.includes('no_update')) {
        document.getElementById('edit_profile_submit').disabled =
            initialGender === document.getElementById('hidden_edit_gender').value;
      }
    }
  }

  dateTimeUser() {
    flatpickr('#user-desktop-dob', {
      dateFormat: 'd.m.Y',
      maxDate: new Date().fp_incr(0),
      disableMobile: true
    });
  }
  dateTimeUserMobile() {
    flatpickr('#user-mobile-dob', {
      dateFormat: 'd.m.Y',
      maxDate: new Date().fp_incr(0),
      disableMobile: true
    });
  }

  setData(event){
    document.getElementById(event.currentTarget.dataset.actualData).value = event.currentTarget.value;
  }

  validateDob(event){
    if (event.currentTarget.value !== event.currentTarget.dataset.realData){
      document.getElementById('edit_profile_submit').disabled = false;
    }else{
      document.getElementById('edit_profile_submit').disabled = true;
    }
  }

  validateLength(e) {
    const lengthValid = validateLength(e.currentTarget);
    if (!lengthValid) e.stopImmediatePropagation();
  }

  validateZip(e){
    let zipcodeStatus = zipcodeValidation(e.currentTarget, `${e.currentTarget.id}_error_message`);
    if (!zipcodeStatus) e.stopImmediatePropagation();

  }
}
