import {Controller} from 'stimulus';

export default class extends Controller {
  brandCount = [];
  sportCount = [];

  brandSelect(event) {
    let id = event.currentTarget.id;
    let checked = event.currentTarget.checked;
    checked ? this.addCount(id, this.brandCount) : this.removeCount(id, this.brandCount);
    document.getElementById('brand_counter').innerHTML = `(${this.brandCount.length})`;
  }

  sportSelect(event) {
    let id = event.currentTarget.id;
    let checked = event.currentTarget.checked;
    checked ? this.addCount(id, this.sportCount) : this.removeCount(id, this.sportCount);
    document.getElementById('sport_counter').innerHTML = `(${this.sportCount.length})`;
  }

  addCount(id, array) {
    array.push(id);
  }

  removeCount(id, array) {
    array.splice(array.indexOf(id), 1);
  }
}