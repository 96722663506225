import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {SharedService} from '../services/shared_service';

export default class extends Controller {

  sharedService = new SharedService();
  currentLocale = 'en';
  productTags = {
    en: '',
    de: '',
    fr: ''
  };
  editable = false;
  saveOnce = false;
  localeChips;
  selectedTags = [];
  addedTag = {
    id: '',
    name: ''
  };

  connect() {
    this.selectedTags = [];
    if (this.element.dataset.modal) {
      this.localeChips = document.getElementById(`product-tag-modal-${this.element.dataset.modal}`).querySelectorAll('[class*="locale-chip"]');
    }
    if (this.element.dataset.tag) {
      this.editable = true;
      this.productTags = {...JSON.parse(this.element.dataset.tag)};
      if (Object.keys(this.productTags).length === 0) {
        this.productTags = {
          en: '',
          de: '',
          fr: ''
        };
      }
      document.getElementById(`tag-name-${this.element.dataset.modal}`).value = this.productTags.en || '';
    }

    document.getElementById('tag-name-' + this.element.dataset.modal).addEventListener('keydown', function(event) {
      if (event.key == 'Enter') {
        event.preventDefault();
      }
    });
  }

  switchLocale(event) {
    this.displayIssue(event.target.dataset.locale);
    this.currentLocale = event.target.dataset.locale;
    this.localeChips.forEach(res => {
      document.getElementById(`tag-name-${this.element.dataset.modal}`).value = this.productTags[event.target.dataset.locale];
      if (res.id === this.currentLocale) {
        res.classList.remove('sk-language-inactive');
        res.classList.add('sk-language-active');
      } else {
        res.classList.remove('sk-language-active');
        res.classList.add('sk-language-inactive');
      }
    });
  }

  duplicateAll() {
    const copyValue = this.productTags[this.currentLocale];
    ['en', 'de', 'fr'].forEach(locale => this.productTags[locale] = copyValue);
    popSnackbar('notice', 'Tag name Successfully Copied');
  }

  onInputChange(event) {
    this.productTags[this.currentLocale] = event.target.value;
    this.displayIssue(this.currentLocale);
  }

  submit() {
    this.saveOnce = true;
    this.displayIssue(this.currentLocale);

    const isValid = ['en', 'de', 'fr'].every(value => !!this.productTags[value].length);
    if (isValid) {

      if(window.location.pathname.includes('product_configurator')){
        this.addProductConfiguratorTag();
      } else {
        if (this.element.dataset.modal == 'add') {
          this.addProductTag();
        } else {
          this.editProduct();
        }
      }
      document.getElementById(`product-tag-cancel-${this.element.dataset.modal}`).click();
    } else {
      popSnackbar('error', 'You need to fill for all Locales!');
    }
  }

  displayIssue(currentLocale) {
    ['en', 'de', 'fr'].forEach(locale => {
      if (!this.productTags[locale].length && locale == currentLocale && this.saveOnce) {
        document.getElementById(`${locale}-product-tag-error-${this.element.dataset.modal}`).style.display = 'block';
      } else {
        document.getElementById(`${locale}-product-tag-error-${this.element.dataset.modal}`).style.display = 'none';
      }
    });
  }

  resetForm() {
    this.productTags = {...JSON.parse(this.element.dataset.tag)};
    if (Object.keys(this.productTags).length === 0) {
      this.productTags = {
        en: '',
        de: '',
        fr: ''
      };
    }
    document.getElementById('tag-name-' + this.element.dataset.modal).value = this.productTags.en;
    ['en', 'de', 'fr'].forEach(locale => {
      document.getElementById(`${locale}-product-tag-error-${this.element.dataset.modal}`).style.display = 'none';
    });
  }

  selectChecked(event) {
    if (event.target.checked) {
      this.selectedTags.push(JSON.parse(event.target.dataset.id).id);
      const allTags = JSON.parse(document.getElementById('product-tag-select-all').dataset.allTags);
      if(allTags.length == this.selectedTags.length){
        document.getElementById('product-tag-select-all').checked = true;
      }
    } else {
      this.selectedTags.splice(this.selectedTags.indexOf(JSON.parse(event.target.dataset.id).id), 1);
      document.getElementById('product-tag-select-all').checked = false;
    }
    this.checkedBox();
  }

  checkedBox() {
    (this.selectedTags || []).forEach(id => {
      if(document.getElementById('checked-' + id)){
        document.getElementById('checked-' + id).checked = true;
      }
    });
    this.bulkDelete();
  }

  selectAll(event) {
    if (event.target.checked) {
      this.selectedTags = JSON.parse(event.target.dataset.allTags);
    } else {
      this.selectedTags = [];
      JSON.parse(event.target.dataset.allTags).forEach( tag => {
        if(document.getElementById('checked-' + tag)){
          document.getElementById('checked-' + tag).checked = false;
        }
      });
    }
    this.checkedBox();
  }

  bulkDelete(){
    if ((this.selectedTags || []).length > 0) {
      document.getElementById('product-selected').style.display = 'flex';
      document.getElementById('product_tag_selected_count').innerText = this.selectedTags.length;
    } else {
      document.getElementById('product-selected').style.display = 'none';
    }

  }

  search(event) {
    this.sharedService.debounceAction(async () => {
      const response = await fetch(`/admin/search/filter-tags?q=${event.target.value}`);
      if (response.status === 200) {
        const text = await response.text();
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        document.getElementById('tag_listing_frame').replaceWith(vDom.children[0]);
      } else {
        popSnackbar('error', 'Product tag Updation Failed');
      }
    }, 300);
  }

  async addProductTag(){
    const response = await fetch('/admin/tags', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(this.productTags)
    });
    if (response.status === 200) {
      const text = await response.text();
      let vDom = document.createElement('div');
      vDom.innerHTML = text;
      document.getElementById('tag_listing_frame').replaceWith(vDom.children[0]);
      document.getElementById('search-fld').value = '';
      popSnackbar('notice', 'Product tag successfully created');
    } else {
      popSnackbar('error', 'Product tag Creation Failed');
    }
  }

  async editProduct(){
    const response = await fetch(`/admin/tags/${this.element.dataset.modal}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(this.productTags)
    });
    if (response.status === 200) {
      const text = await response.text();
      let vDom = document.createElement('div');
      vDom.innerHTML = text;
      document.getElementById('tag_listing_frame').replaceWith(vDom.children[0]);
      document.getElementById('search-fld').value = '';
      popSnackbar('notice', 'Product tag successfully updated');
    } else {
      popSnackbar('error', 'Product tag Updation Failed');
    }
  }

  async deleteAll(){
    const response = await fetch('/admin/tags/bulk_destroy', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({delete_ids: this.selectedTags})
    });
    if (response.status === 200) {
      const text = await response.text();
      let vDom = document.createElement('div');
      vDom.innerHTML = text;
      document.getElementById('tag_listing_frame').replaceWith(vDom.children[0]);
      document.getElementById('search-fld').value = '';
      popSnackbar('notice', 'Product tag(s) Deleted successfully');
    } else {
      popSnackbar('error', 'Something went wrong');
    }
  }

  async addProductConfiguratorTag(){
    const response = await fetch('/admin/tags?type=configurator', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(this.productTags)
    });
    if (response.status === 200) {
      const result = await response.json();
      const element = document.getElementById(this.element.closest('form').id);
      const productConfig = this.application.getControllerForElementAndIdentifier(element, 'product-configurator-stepper');
      productConfig.ribbon_ids.push({id: result.tag.id, name: result.tag.en});
      productConfig.displayTags();
      popSnackbar('notice', 'Product tag successfully created');
    } else {
      popSnackbar('error', 'Product tag Creation Failed');
    }
  }
}
