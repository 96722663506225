import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['form'];
  category = [];
  status = [];
  ribbons = [];
  tags = [];

  search(event) {
    let radix = document.getElementById('tabs-tab').querySelector('.active').dataset.targetFrame;
    let query = event.currentTarget.value;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let url = `/admin/search/filter-products?radix=${radix}&q=${query}`;
      fetch(url).then(response => {
        response.text().then(text => {
          let vDom = document.createElement('div');
          vDom.innerHTML = text;
          document.getElementById(radix).replaceWith(vDom.children[0]);
        });
      });
    }, 500);
  }

  filter() {
    const category_params = this.category;
    const status_params = this.status;
    const ribbon_params = this.ribbons;
    const tag_params = this.tags;

    let q = document.getElementById('search-fld').value;
    let radix = document.getElementById('tabs-tab').querySelector('.active').dataset.targetFrame;

    let url = `/admin/search/filter-products?radix=${radix}&q=${q}&statuses=${this.parameterize(status_params)}&taxonomies=${this.parameterize(category_params)}&ribbons=${this.parameterize(ribbon_params)}&tags=${this.parameterize(tag_params)}`;

    fetch(url).then(response => {
      response.text().then(text => {
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        document.getElementById(radix).replaceWith(vDom.children[0]);
      });
    });

    document.getElementById('close-fliter-btn').click();
  }

  parameterize(p) {
    return p.toString();
  }

  resetFilter() {
    this.category = [];
    this.ribbons = [];
    this.status = [];
    this.tags = [];
    document.getElementById('reset-filter-btn').click();
    document.getElementById('apply-fltr-btn').click();
  }

  cancelFilterApply() {
    document.getElementById('reset-filter-btn').click();
  }

  checkboxInput(event){
    if(event.target.checked){
      if(event.target.name == 'category[]'){
        this.category.push(event.target.value);
      } else if(event.target.name == 'statuses[]') {
        this.status.push(event.target.value);
      } else if(event.target.name == 'ribbons[]') {
        this.ribbons.push(event.target.value);
      } else {
        this.tags.push(event.target.value);
      }
    } else {
      this.unCheckFilter(event);
    }
  }

  unCheckFilter(event){
    if(event.target.name == 'category[]'){
      this.category.splice(this.category.indexOf(event.target.value), 1);
    } else if(event.target.name == 'statuses[]') {
      this.status.splice(this.status.indexOf(event.target.value), 1);
    } else if(event.target.name == 'ribbons[]'){
      this.ribbons.splice(this.ribbons.indexOf(event.target.value), 1);
    } else {
      this.tags.splice(this.ribbons.indexOf(event.target.value), 1);
    }
  }
}
