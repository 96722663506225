import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    let error_promo_element = document.getElementById('promo_code_error_message');
    if (this.element.dataset.state === 'false') {
      error_promo_element.removeAttribute('hidden');
    } else {
      error_promo_element.setAttribute('hidden', 'true');
    }
  }
}
