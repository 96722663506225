import {Controller} from 'stimulus';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {phoneValidation} from '../services/authentications/phone_service';
import {AsYouType} from 'libphonenumber-js';
import {countryCode} from '../services/authentications/country-code_service';
import {zipcodeValidation} from '../services/authentications/zipcode_service';
import {streetNameValidation} from '../services/authentications/street-validation_service';
import {validateLength} from '../services/authentications/min-length_service';

export default class extends Controller {
  connect() {
    let code = countryCode('Switzerland');
    let default_address = this.element.dataset.id;
    if (default_address === 'true' || default_address === true) {
      document.getElementById('default_address_check_box').click();
      document.getElementById('default_address_check_box_div').classList.add('hidden');
    }

    let functionMap = {
      'shipping_first_name': validateName,
      'shipping_last_name': validateName,
      'shipping_company': notRequiredField,
      'shipping_phone': validatePhone,
      'shipping_street_name': validateStreet,
      'shipping_zipcode': validateZipcode,
      'shipping_city': validateName,
      'shipping_state': notRequiredField
    };

    let valueMap = {
      'shipping_first_name': '',
      'shipping_last_name': '',
      'shipping_company': '',
      'shipping_phone': '',
      'shipping_street_name': '',
      'shipping_zipcode': '',
      'shipping_city': '',
      'shipping_state': '',
      'default_address_check_box': ''
    };

    let validCount = [];

    let editCount = [];

    let prefieldValue = document.querySelectorAll('.shipping_edit_field');

    let inputGender = document.querySelectorAll('.gender_value');

    for (let i = 0; i < inputGender.length; i++) {
      inputGender[i].addEventListener('click', function () {
        validateGender(this);
      });
    }
    function validateGender(input) {
      document.getElementById('gender_desktop').value = input.dataset.select;
      document.getElementById('gender_mobile').value = input.dataset.select;
      document.getElementById('hidden_actual_gender').value = input.dataset.value;
      if (document.getElementById('for-validation').value != document.getElementById('hidden_actual_gender').value){
        document.getElementById('shipping_address_submit_button').disabled = false;
      }else{
        document.getElementById('shipping_address_submit_button').disabled = true;

      }

    }

    for (let i = 0; i < prefieldValue.length; i++) {
      valueMap[prefieldValue[i].id] = prefieldValue[i].value;
    }

    if (document.getElementById('default_address_check_box').checked) {
      valueMap['default_address_check_box'] = 'true';
    } else {
      valueMap['default_address_check_box'] = 'false';
    }

    let inputs = document.querySelectorAll('.shipping_edit_field');

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('input', function () {
        functionMap[this.id](this);
        editable();
      });
    }

    document.getElementById('default_address_check_box').addEventListener('click', function () {
      if (valueMap[this.id] === 'false') {
        this.checked ? arrayAppend(editCount, this.id) : arrayRemove(editCount, this.id);
      } else {
        this.checked ? arrayRemove(editCount, this.id) : arrayAppend(editCount, this.id);
      }
      document.getElementById('default_address_value').value = this.checked;
      editable();
    });

    // document.getElementById('shipping_apartment').addEventListener('input', function () {
    //   editCounter(this.value, this.id);
    //   editable();
    // });

    function notRequiredField(input) {
      afterValidationCheck(true, input.id, input.value);
    }

    function validateName(input) {
      let freeFieldStatus = freeFieldValidation(input, `${input.id}_error_message`);
      afterValidationCheck(freeFieldStatus, input.id, input.value);
    }

    function validateStreet(input) {
      let streetFieldStatus = streetNameValidation(input, `${input.id}_error_message`);
      afterValidationCheck(streetFieldStatus, input.id, input.value);
    }

    function validateZipcode(input) {
      let zipcodeStatus = zipcodeValidation(input, `${input.id}_error_message`);
      afterValidationCheck(zipcodeStatus, input.id, input.value);
    }

    function validatePhone(input) {
      input.value = new AsYouType(code['short_name']).input(input.value);
      let phoneStatus = phoneValidation(input, code['short_name']);
      afterValidationCheck(phoneStatus, input.id, input.value);
    }

    function editable() {
      document.getElementById('shipping_address_submit_button').disabled = !(editCount.length > 0 && validCount.length === 0);
    }

    function afterValidationCheck(status, id, value) {
      if (status) {
        arrayRemove(validCount, id);
        editCounter(value, id);
      } else {
        arrayAppend(validCount, id);
      }
    }

    function editCounter(value, id ) {
      value !== valueMap[id] ? arrayAppend(editCount, id) : arrayRemove(editCount, id);
    }

    function arrayAppend(array, id) {
      if (!array.includes(id)) {
        array.push(id);
      }
    }

    function arrayRemove(array, id) {
      if (array.includes(id)) {
        array.splice(array.indexOf(id), 1);
      }
    }
  }

  setData(event){
    document.getElementById(event.currentTarget.dataset.actualData).value = event.currentTarget.value;
  }

  validateLength(e) {
    const lengthValid = validateLength(e.currentTarget);
    if (!lengthValid) e.stopImmediatePropagation();
  }
}
