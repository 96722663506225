export const setQuantity = function (key, qty) {
  let add = 0;
  let count = parseInt(document.getElementById('cart_quantity').innerHTML) || parseInt(document.getElementById('cart_quantity_mobile').innerHTML);
  if(JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
    add = 1;
  }
  if (key === 'add') {
    document.getElementById('cart_quantity').innerHTML = count + qty + add;
    document.getElementById('cart_quantity_mobile').innerHTML = count + qty + add;
    document.getElementById('count-id').innerHTML = count + qty + add;
  } else {
    let result = count - qty + add;
    document.getElementById('cart_quantity').innerHTML = result <= 0 ? 0 : result;
    document.getElementById('cart_quantity_mobile').innerHTML = result <= 0 ? 0 : result;
    document.getElementById('count-id').innerHTML = result <= 0 ? 0 : result;
  }
};
