import {setMessage} from './base_service';
import {I18n} from '../../translation';

export const freeFieldValidation = function (input, id, showError = true) {
  if (input.value.trim()) {
    if(document.getElementById(id).innerHTML === I18n[window.currentLocale]['field_required']){
      setMessage(id);
    }
    return true;
  } else {
    if(showError) {
      setMessage(id, I18n[window.currentLocale]['field_required']);
    }
    return false;
  }
};
