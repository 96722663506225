import {Controller} from 'stimulus';

export default class extends Controller {

  search(event) {
    let query = event.currentTarget.value;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {

      fetch(`/admin/search/filter-brands?q=${query}`).then(response => {
        response.text().then(text => {
          let vDom = document.createElement('div');
          vDom.innerHTML = text;
          document.getElementById('brands_list').replaceWith(vDom.children[0]);
        });
      });
    }, 500);
  }

  filter() {
    let formTarget = document.getElementById('filter-form');
    let statuses_checkboxes = formTarget.getElementsByClassName('sk-form-field__checkbox statuses');
    let status_params = [];

    for (let i = 0; i < statuses_checkboxes.length; i++) {
      statuses_checkboxes[i].checked ? status_params.push(statuses_checkboxes[i].value) : null;
    }
    let q = document.getElementById('search-fld').value;

    let url = `/admin/search/filter-brands?q=${q}&statuses=${this.parameterize(status_params)}`;

    fetch(url).then(response => {
      response.text().then(text => {
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        document.getElementById('brands_list').replaceWith(vDom.children[0]);
      });
    });

    document.getElementById('close-fliter-btn').click();
  }

  parameterize(p) {
    return p.toString();
  }

  resetFilter() {
    document.getElementById('reset-filter-btn').click();
    document.getElementById('apply-fltr-btn').click();
  }
}
