import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    let products = JSON.parse(this.element.dataset.products);
    const item_list_id = this.element.dataset.itemListId;
    const item_list_name = this.element.dataset.itemListName;

    // eslint-disable-next-line no-undef
    gtag('event', 'view_item_list', {
      item_list_id: item_list_id,
      item_list_name: item_list_name,
      items: products
    });
    this.memberToggle()
  }

  memberToggle(){
    if(document.getElementById('product-listing-member-mobile') && document.getElementById('product-listing-member') && JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
     if( !document.getElementById('product-listing-member').checked ){
       document.getElementById('product-listing-member').click()
     }
        if( !document.getElementById('product-listing-member-mobile').checked ){
            document.getElementById('product-listing-member-mobile').click()
        }
    }else{
      if (document.getElementById('product-listing-member')){
        if( document.getElementById('product-listing-member').checked ){
          document.getElementById('product-listing-member').click()
        }
      }
      if (document.getElementById('product-listing-member-mobile')){
        if( document.getElementById('product-listing-member-mobile').checked ){
          document.getElementById('product-listing-member-mobile').click()
        }
      }
    }
  }
}
