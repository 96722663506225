import {popSnackbar} from '../snackbar';

export const getPhotoUrl = function (image, callback) {
  let data = new FormData();
  data.append('photo', image);
  // data.append('cropImage', cropImage );

  fetch('/upload', {
    method: 'post',
    body: data,
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    }
  }).then(response => {
    if (response.ok) {
      response.json().then(value => {
        callback(value);
      });
    } else {
      popSnackbar('error', 'Upload failed');
    }
  });
};

export const cancelUpload = function (blobName) {
  fetch(`/cancel-upload?blob_name=${blobName}`, {
    method: 'post',
  }).then(response => {
    if (!response.ok) {
      popSnackbar('error', 'Error removing image.');
    }
  });
};