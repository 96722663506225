import {Controller} from 'stimulus';
import {I18n} from '../translation';

export default class extends Controller {
  translate = I18n;
  defaultAddressId = this.element.dataset.defaultAddress ? JSON.parse(this.element.dataset.defaultAddress)[0] : null;
  tempId;

  connect() {
    let _this = this;
    if (this.defaultAddressId) {
      document.getElementById(`${this.defaultAddressId}_radio_button`).click();
    }
    let radio = document.querySelectorAll('.change_address_radio_button');
    for (let i = 0; i < radio.length; i++) {
      radio[i].addEventListener('click', function () {
        if (this.id === `${_this.defaultAddressId}_radio_button`) {
          document.getElementById('change_default_address_button').setAttribute('disabled', true);
        } else {
          document.getElementById('change_default_address_button').removeAttribute('disabled');
        }
        _this.tempId = this.value;
      });
    }
  }
}
