import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let items = JSON.parse(params.data);
    let shippingCharge = this.getShippingCharge(params.total);

    // eslint-disable-next-line no-undef
    gtag('event', 'purchase', {
      value: +params.total, // custom_metric
      currency: 'CHF',
      tax: 'inclusive',
      shipping: shippingCharge,
      items: items
    });
  }

  getShippingCharge(total) {
    if(total >= 0 && total <= 45.00) {
      return 7.50;
    } else if(total >= 45.01 && total <= 99.99) {
      return 11.50;
    } else {
      return 13.50;
    }
  }
}
