import {Controller} from 'stimulus';
import {freeFieldValidation} from "../services/authentications/free_field_service";
import {zipcodeValidation} from "../services/authentications/zipcode_service";
import {emailValidation} from "../services/authentications/email_service";
import {AsYouType} from "libphonenumber-js";
import {phoneValidation} from "../services/authentications/phone_service";
import {countryCode} from "../services/authentications/country-code_service";
import {popSnackbar} from "../services/snackbar";
import {I18n} from "../translation";
import {getPhotoUrl} from "../services/api/blob";
import {setMessage} from "../services/authentications/base_service";
import {validateLength} from '../services/authentications/min-length_service';

export default class extends Controller {
  selectedId = [];
  validation = {
    // company_image: false,
    company_name: false,
    company_phone: false,
    company_email: false,
    street: false,
    zipcode: false,
    city: false
  };
  code = countryCode('Switzerland');

  connect() {
    let field = document.querySelectorAll('.updateField')
    if (field){
      this.updateValidation(field)
    }
  }

  addRetailer(){
    document.getElementById('retailer-form'), addEventListener('turbo:submit-end', async (event) => {
      if (event.detail.success && !event.detail.fetchResponse.redirected) {
        popSnackbar('notice', 'Retailer created successfully.');
      }
      else {
        popSnackbar('error', 'Retailer creation unsuccessful');
      }
      this.resetForm();
    });
  }

  updateRetailer(){
    document.getElementById('update-form'), addEventListener('turbo:submit-end', async (event) => {
      if (event.detail.success && !event.detail.fetchResponse.redirected) {
        popSnackbar('notice', 'Retailer updated successfully.');
      }
      else {
        popSnackbar('error', 'Retailer update unsuccessful');
      }
    });
  }

  updateValidation(field){
    let _this = this
    field.forEach(function(element) {
      _this.validation[element.id] = Boolean(element.value)
    });
  }
  updateStatus(event){
    this.changeStatus(event.currentTarget.checked,event.currentTarget.dataset.id);
  }

  changeStatus(status,id){
    let url = `/admin/retailers/${id}/update_status`
    fetch(`${url}?status=${status}`, {
      method: 'GET'
    }).then(response => response.text()).then(text => {
      let vDom = document.createElement('div');
      vDom.innerHTML = text;
      document.getElementById('retailer-listing').replaceWith(vDom.children[0]);
    });
  }

  selectAll(event){
    let selectedDiv = document.querySelectorAll(`.checkBox`)
    if (event.currentTarget.checked) {
      this.selectedId = JSON.parse(event.currentTarget.dataset.retailers);
      this.selectDiv(selectedDiv,true);
      document.getElementById('selected-div').classList.remove('hidden');
      document.getElementById('selected-count').innerText = `${this.selectedId.length} retailer selected`;
    }else{
      this.selectedId = []
      this.selectDiv(selectedDiv,false)
      document.getElementById('selected-div').classList.add('hidden')
    }
  }

  selectDiv(selectedDiv,option){
    selectedDiv.forEach(function(element) {
      element.checked = option
      if (option){
        document.getElementById(`row-${element.dataset.retailerId}`).classList.add('bg-blue/[0.08]')
        document.getElementById(`row-${element.dataset.retailerId}`).classList.remove('bg-white')
      }else{
        document.getElementById(`row-${element.dataset.retailerId}`).classList.remove('bg-blue/[0.08]')
        document.getElementById(`row-${element.dataset.retailerId}`).classList.add('bg-white')
      }
    });
  }


  selectRetailer(event){
   if (event.currentTarget.checked){
     document.getElementById('selected-div').classList.remove('hidden');
     this.selectedId.push(event.currentTarget.dataset.retailerId);
     document.getElementById(`row-${event.currentTarget.dataset.retailerId}`).classList.remove('bg-white')
     document.getElementById(`row-${event.currentTarget.dataset.retailerId}`).classList.add('bg-blue/[0.08]')
     document.getElementById('selected-count').innerText = `${this.selectedId.length} retailer selected`;
   }else{
     let selectAllCheckBox = document.getElementById('selectAllCheckBox')
     if (selectAllCheckBox.checked){
       selectAllCheckBox.checked = false;
     }
     document.getElementById('selected-div').classList.remove('hidden');
     let index = this.selectedId.indexOf(event.currentTarget.dataset.retailerId);
     this.selectedId.splice(index,  1);
     document.getElementById(`row-${event.currentTarget.dataset.retailerId}`).classList.remove('bg-blue/[0.08]')
     document.getElementById(`row-${event.currentTarget.dataset.retailerId}`).classList.add('bg-white')

     if (this.selectedId.length === 0){
       document.getElementById('selected-div').classList.add('hidden');
     }else{
       document.getElementById('selected-count').innerText = `${this.selectedId.length} retailer selected`;
     }
   }
  }

  renderSelectedBlukRetailer() {
    fetch(`/admin/retailers/selected_retailers?retailers=${this.selectedId}`).then(response => {
      response.text().then(text => {
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        document.getElementById('checked-retailer-for-deletion').replaceWith(vDom.children[0]);
      });
    });
  }

  blukDelete(){
    fetch(`/admin/retailers/bulk_destroy?retailers=${this.selectedId}`).then(response=>{
      response.text().then( text =>{
        let vDom = document.createElement('div');
        vDom.innerHTML = text;
        document.getElementById('retailer-listing').replaceWith(vDom.children[0]);
        this.selectedId = [];
        document.getElementById('selected-div').classList.add('hidden')
        document.getElementById('retailer-delete-cancel').click();
        popSnackbar('notice', 'Retailer deleted successfully.');

      })
    })
  }

  fieldValidation(event){
    this.validateLength(event);
    let validate = freeFieldValidation(event.target, `${event.target.id}_error_message`);
    this.validation[event.target.id] = validate;
    this.checkButton(event.currentTarget.dataset.submitButton);
  }

  zipcodeValidation(event){
    let zipValidate = zipcodeValidation(event.target, `${event.target.id}_error_message`);
    this.validation[event.target.id] = zipValidate;
    this.checkButton(event.currentTarget.dataset.submitButton);
  }

  emailValidation(event){
    let emailValidate = emailValidation(event.target, `${event.target.id}_error_message`);
    this.checkEmail(event.currentTarget.value,event.target.id,event.currentTarget.dataset.submitButton)
    this.validation[event.target.id] = emailValidate;
    this.checkButton(event.currentTarget.dataset.submitButton);

  }

  checkEmail(email,element_id,submitButton) {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const valid = emailPattern.test(email.toLowerCase());
    const getUrl = window.location.pathname.match(/\/admin\/retailers\/(\d+)/)
    if (valid) {
      fetch('/admin/retailers/retailer_email_check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({email: email.toLowerCase(), retailer_id: getUrl ? getUrl[1] : null })
      }).then(response => {
        if (response.ok) {
          response.json().then(value => {
            if (value['present']){
              setMessage(`${element_id}_error_message`, 'Email already taken.');
              this.validation[element_id] = !value['present'];
              this.checkButton(submitButton)
            };
          });
        }
      });
    }
  }


  validatePhone(event) {
    const input = event.currentTarget;
      input.value = new AsYouType(this.code['short_name']).input(input.value);
      let phoneStatus = phoneValidation(input, this.code['short_name']);
      if (input.value.length > 0) {
        this.validation[event.target.id] = phoneStatus;
      }
      this.checkButton(event.currentTarget.dataset.submitButton);

  }

  validateLength(e) {
    let lengthValid;
    if(e.currentTarget){
      lengthValid = validateLength(e.currentTarget);
    }else{
      lengthValid = validateLength(e);
    }
    if (!lengthValid) e.stopImmediatePropagation();
  }

  checkButton(submitId) {
    let keys = Object.keys(this.validation);
    let valid = false;
    for (let key of keys) {
      valid = this.validation[key];
      if (!valid) {
        break;
      }
    }
    document.getElementById(submitId).disabled = !valid;
  }

  updateImage(event){
    if (!this.validSize(event.target.files[0])) {
      popSnackbar('error', I18n[window.currentLocale].file_too_large);
      return;
    }
    let imageUrl = URL.createObjectURL(event.target.files[0]);
    document.getElementById('detail_image').src = imageUrl;

    document.getElementById('img-loader').style.display = 'block';
    this.loadXHR(imageUrl).then((blobUrl) => {
      getPhotoUrl(blobUrl, (resp) => {
        let data = new FormData();
        data.append('retailer_image', JSON.stringify(resp));

        let retailer_id = window.location.pathname.split('/').pop();
        fetch(`/admin/retailers/${retailer_id}/update_image`, {
          method: 'put',
          body: data,
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
          }
        }).then(response => {
          if (response.ok) {
            document.getElementById('img-loader').style.display = 'none';
            popSnackbar('notice', 'Upload successful');
          } else {
            document.getElementById('img-loader').style.display = 'none';
            popSnackbar('error', 'Upload failed');
          }
        });
      });
    });
  }

  uploadImage(event){
    if (!this.validSize(event.target.files[0])) {
      popSnackbar('error', I18n[window.currentLocale].file_too_large);
      return;
    }
    let imageUrl = URL.createObjectURL(event.target.files[0]);
    let imageDomId = event.currentTarget.dataset.imageDomId;
    let imageDom = document.getElementById(imageDomId);

    const retailer_image_dom = document.getElementById('retailer_image');

    imageDom.src = imageUrl;

    document.getElementById('add-img-loader').style.display = 'block';
    this.loadXHR((imageDom.src)).then((blobUrl) => {
      getPhotoUrl(blobUrl, (resp) => {
        retailer_image_dom.value = JSON.stringify(resp)
        // this.validation["company_image"] = true;
        document.getElementById('add-img-loader').style.display = 'none';
        // this.checkButton('add-retailer')
      });
    });
  }


  validSize(file) {
    const fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 1) {
      popSnackbar('error', 'File size is too large');
      return false;
    } else {
      return true;
    }
  }
  loadXHR(url) {
    return new Promise(function (resolve, reject) {
      try {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.onerror = function () {
          reject('Network error.');
        };
        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject('Loading error:' + xhr.statusText);
          }
        };
        xhr.send();
      } catch (err) {
        reject(err.message);
      }
    });
  }

  resetForm(){
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      document.getElementById('retailer-form').reset();
      document.getElementById('add-retailer').disabled = true;
    },1000)

  }
}
