import {Controller} from 'stimulus';

export default class extends Controller {
  image_position = 0;
  image_count = this.element.dataset.productImageCount;

  connect() {
    let index = this.element.dataset.index;
    let source = document.getElementById(`product_image_${index || 0}`).src;
    this.swap_image(source, 0);
  }

  slider(event) {
    event.currentTarget.classList.add('border-blue');
    for (let i = 0; i < this.element.dataset.productImageCount; i++) {
      if (i !== +event.currentTarget.dataset.count) {
        document.getElementById(`product_image_div_${i}`)?.classList?.remove('border-blue');
      }
    }
    let source = document.getElementById(event.currentTarget.dataset.id).src;
    this.swap_image(source, event.currentTarget.dataset.count);
  }

  next_slider() {
    let index = (this.image_position + 1) % this.image_count;
    let source = document.getElementById(`product_image_${index}`).src;
    this.swap_image(source, index);
  }

  previous_slider() {
    let index = 0;
    if (parseInt(this.image_position) === 0) {
      index = this.image_count - 1;
    } else {
      index = this.image_position - 1;
    }
    let source = document.getElementById(`product_image_${index}`).src;
    this.swap_image(source, index);
  }

  swap_image(url, count) {
    document.getElementById('main_product_image').src = url;
    this.image_position = count;
  }
}
