import {Controller} from 'stimulus';
import {constructArrayParams} from '../services/construct_array_params';

export default class extends Controller {
  selectedBrand = [];

  selectBrand(event) {
    let productType;
    let taxonId;
    let taxonomyId;
    let params = location.search.split('&');
    params.forEach(param => {
      if (param.includes('product_type')) {
        productType = param.split('=')[1];
      }
      if (param.includes('taxon_id')) {
        taxonId = param.split('=')[1];
      }
      if (param.includes('taxonomy_id')) {
        taxonomyId = param.split('=')[1];
      }
    });
    let id = event.currentTarget.dataset.brand_id;
    event.currentTarget.checked ? this.addBrand(id) : this.removeBrand(id);
    let url;
    if (productType) {
      url = `/search/filter-storefront-product?filter_by='new'${constructArrayParams(this.selectedBrand, 'brands')}&product_type=${productType}&taxon_id=${taxonId}&taxonomy_id=${taxonomyId}`;
    } else {
      url = `/search/filter-storefront-product?filter_by='new'${constructArrayParams(this.selectedBrand, 'brands')}`;
    }
    fetch(url).then(response => {
      response.text().then(text => {
        let tempDom = document.createElement('div');
        tempDom.innerHTML = text;
        let callbackDom = tempDom.querySelector('#product-container');
        document.getElementById('product-container').replaceWith(callbackDom);
      });
    });
  }

  addBrand(brandId) {
    if (!this.selectedBrand.includes(brandId)) {
      this.selectedBrand.push(brandId);
    }
  }

  removeBrand(brandId) {
    if (this.selectedBrand.includes(brandId)) {
      this.selectedBrand.splice(this.selectedBrand.indexOf(brandId), 1);
    }
  }
}
