import {Controller} from 'stimulus';
import {models} from 'powerbi-client';

export default class extends Controller {
  embedConfiguration = {};
  reportData = {};
  index;

  connect() {
    this.fetchReportsData();
  }

  async fetchReportsData() {
    if(this.element.dataset.reports){
      this.reportData = JSON.parse(this.element.dataset.reports);
      this.embedConfiguration = {
        accessToken: 'reportData',
        embedUrl: '',
        id: '',
        permissions: models.Permissions.All,
        tokenType: models.TokenType.Embed,
        type: 'report',
        settings: {
          panes: {
            filters: {visible: false},
            pageNavigation: {visible: true}
          }
        },
      };
      document.getElementById('report_dropdown').value = this.reportData[0].name;
      this.setReport(this.reportData[0]);
    }
  }

  embedReport(){
    const embedElement = document.getElementById('embed-container');
    window.powerbi.embed(embedElement, this.embedConfiguration);
  }

  async setReport(report) {
    const payload = {
      embed_token: {
        type: 'report',
        resource_id: report.id,
      }
    };
    const url = '/admin/embed_tokens';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(payload),
    });
    const reportResponse =  await response.json();
    this.embedConfiguration.accessToken = reportResponse.token;
    this.embedConfiguration.embedUrl = report.embedUrl;
    this.embedConfiguration.id = report.id;
    this.embedReport();
  }

  selectOption(event) {
    let targetFieldId = event.currentTarget.dataset?.targetField;
    let value = event.currentTarget.dataset?.value;
    let targetField = document.getElementById(targetFieldId);
    targetField.value = value;
    const index = this.reportData.findIndex((obj) => {
      return obj.name === value;
    });
    this.setReport(this.reportData[index]);
  }
}
