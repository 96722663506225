import {Controller} from 'stimulus';

export default class extends Controller {
  allCancelReason = document.querySelectorAll('.individual_cancel_reason');
  allCancelReasonId = [];

  connect() {
    let _this = this;
    for (let i = 0; i < this.allCancelReason.length; i++) {
      this.allCancelReason[i].addEventListener('input', function () {
        _this.afterInput(this);
        _this.updatable();
      });
    }
  }

  commonCheck(event) {
    if (event.currentTarget.checked) {
      for (let i = 0; i < this.allCancelReason.length; i++) {
        this.allCancelReason[i].setAttribute('readonly', 'true');
        this.allCancelReason[i].value = '';
      }
    } else {
      for (let i = 0; i < this.allCancelReason.length; i++) {
        this.allCancelReason[i].removeAttribute('readonly');
        this.allCancelReason[i].value = '';
      }
      document.getElementById('common_reason').value = '';
    }
    this.allCancelReasonId = [];
    this.updatable();
  }

  commonInput(event) {
    let commonReason = event.currentTarget.value;
    for (let i = 0; i < this.allCancelReason.length; i++) {
      this.allCancelReason[i].value = commonReason;
      this.afterInput(this.allCancelReason[i]);
    }
    this.updatable();
  }

  afterInput(input) {
    if (input.value.trim()) {
      this.addId(input);
    } else {
      this.removeId(input);
    }
  }

  addId(input) {
    if (!this.allCancelReasonId.includes(input.id)) {
      this.allCancelReasonId.push(input.id);
    }
  }

  removeId(input) {
    if (this.allCancelReasonId.includes(input.id)) {
      this.allCancelReasonId.splice(this.allCancelReasonId.indexOf(input.id), 1);
    }
  }

  updatable() {
    document.getElementById('cancel_order_button').disabled = this.allCancelReasonId.length !== this.allCancelReason.length;
  }
}