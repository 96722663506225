import {Controller} from 'stimulus';

export default class extends Controller {
  previousTaxonId;

  connect() {
    this.previousTaxonId = this.element.dataset.taxon_id;
  }

  changeTaxon(event) {
    document.getElementById(`taxon_${this.previousTaxonId}_mobile`).classList.remove('text-blue');
    document.getElementById(`taxon_${this.previousTaxonId}_desktop`).classList.remove('text-blue');
    document.getElementById(`taxon_${event.currentTarget.dataset.taxonId}_mobile`).classList.add('text-blue');
    document.getElementById(`taxon_${event.currentTarget.dataset.taxonId}_desktop`).classList.add('text-blue');

    this.previousTaxonId = event.currentTarget.dataset.taxonId;
    let element;
    if (event.currentTarget.dataset.view === 'mobile') {
      element = document.getElementById('target_product-listing-side-nav_desktop');
    } else {
      element = document.getElementById('target_product-listing-side-nav_mobile');
    }
    this.application.getControllerForElementAndIdentifier(element, 'product-listing-side-nav').previousTaxonId = event.currentTarget.dataset.taxonId;
  }
}
