import {Controller} from 'stimulus';
import {
  passwordConfirmationValidation,
  passwordValidation,
  passwordVisibility
} from '../services/authentications/password_service';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {validityCount} from '../services/authentications/validation-count_service';
import {completeValidation} from '../services/authentications/complete-validation_service';

export default class extends Controller {
  connect() {
    let validCount = [];
    let oldPassword = document.getElementById('old_password');
    let newPassword = document.getElementById('change_password');
    let confirmPassword = document.getElementById('change_password_confirmation');
    let passwordVisibilities = document.querySelectorAll('.change_password_visibility');

    oldPassword.addEventListener('input', function () {
      ValidateOldPassword(this);
    });

    newPassword.addEventListener('input', function () {
      ValidatePassword(this);
    });

    confirmPassword.addEventListener('input', function () {
      ValidatePasswordConfirmation(this);
    });

    passwordVisibility(passwordVisibilities);

    function ValidateOldPassword(input) {
      let freeFieldStatus = freeFieldValidation(input, 'old_password_error_message');
      validityCount(freeFieldStatus, input.id, validCount);
      completeValidation('change_password_submit_button', validCount.length !== 3);
    }

    function ValidatePassword(input) {
      let passwordStatus = passwordValidation(input, 'new_password_error_message');
      validityCount(passwordStatus, input.id, validCount);
      ValidatePasswordConfirmation(confirmPassword);
      completeValidation('change_password_submit_button', validCount.length !== 3);
    }

    function ValidatePasswordConfirmation(input) {
      let password = newPassword.value;
      let passwordConfirmationStatus = passwordConfirmationValidation(input, password, 'change_password_confirmation_error_message');
      validityCount(passwordConfirmationStatus, input.id, validCount);
      completeValidation('change_password_submit_button', validCount.length !== 3);
    }

    document.getElementById('close-change-password-modal').addEventListener('click', function () {
      validCount = [];
      oldPassword.value = '';
      newPassword.value = '';
      confirmPassword.value = '';
      document.getElementById('old_password_error_message').innerHTML = '';
      document.getElementById('new_password_error_message').innerHTML = '';
      document.getElementById('change_password_confirmation_error_message').innerHTML = '';
      for (let i = 0; i < passwordVisibilities.length; i++) {
        passwordVisibilities[i].parentElement.children[0].type = 'password';
        passwordVisibilities[i].innerHTML = 'visibility_off';
      }
      completeValidation('change_password_submit_button', validCount.length !== 3);
    });
  }
}
