import {Controller} from 'stimulus';
import {setQuantity} from "../services/carts/update_quantity";

export default class extends Controller {
  deleteCartLineitems(event){
    let itemId =  event.currentTarget.dataset.itemId;
    fetch('/carts/remove_cart_items', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({itemId: itemId})
    }).then(response => response.json())
        .then(data => {
          document.getElementById("cart-dom").innerHTML=data.items
          setQuantity('subtract', 1 );
    });
  }
}