export const validityCount = function (status, value, array) {
  status ? validInput(value, array) : invalidInput(value, array);
};

export const validInput = function (value, array) {
  if (!array.includes(value)) {
    array.push(value);
  }
};

export const invalidInput = function (value, array) {
  if (array.includes(value)) {
    array.splice(array.indexOf(value), 1);
  }
};
