import {Controller} from 'stimulus';
import {constructArrayParams} from "../services/construct_array_params";

export default class extends Controller {
  search(event) {
    let element = document.getElementById('trigger_promotion-collection-selection_controller');
    let selectedCollection = this.application.getControllerForElementAndIdentifier(element, "promotion-collection-selection").selectedCollection;
    let query = event.currentTarget.value;
    let promotion = event.currentTarget.dataset.promotion;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let url = `/admin/search/filter-promotion-collections?q=${query}${constructArrayParams(selectedCollection, 'selectedProduct')}&promotion=${promotion}`;
      fetch(url).then(response => {
        response.text().then(text => {
          document.getElementById('discount_collection_listing').innerHTML = text;
        });
      });
    }, 500);
  }
}
