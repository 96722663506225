import {Controller} from 'stimulus';

export default class extends Controller {
  search(event) {
    let promotionType = event.currentTarget.dataset.promotionType;
    let query = event.currentTarget.value;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let url = `/admin/search/filter-promotion?q=${query}&promotion_type=${promotionType}`;
      fetch(url).then(response => {
        response.text().then(text => {
          document.getElementById('discount_listing_container').innerHTML = text;
        });
      });
    }, 500);
  }
}
