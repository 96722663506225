import {setMessage} from './base_service';
import {
  getExampleNumber,
  isValidPhoneNumber
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import {I18n} from '../../translation';

export const phoneValidation = function (input, countryCode) {
  if (isValidPhoneNumber(input.value, countryCode)) {
    setMessage(input.id + '_error_message', '');
    return true;
  } else {
    if (input.value) {
      let exampleNumber = getExampleNumber(countryCode, examples);
      setMessage(input.id + '_error_message', `${I18n[window.currentLocale]['invalid_phone_example']} ${exampleNumber.nationalNumber}`);
      return false;
    } else {
      setMessage(input.id + '_error_message', '');
      return true;
    }
  }
};
