export const colorComponent = function (optionType, index) {
  let dom =
        `<div class="flex items-center my-[16px]" id="add_value_${optionType}_${index}" data-index="${index}">
       <div class="sk-form-field w-full">
         <input type="text" class="sk-form-field__input ov-input-fld" placeholder=" " id="add_color_${index}_value_display"
         data-action="keydown->pro-options#punchOptionValue" data-option-type="${optionType}"
         data-option-value-index="${index}" data-color-name=true data-delete-id="option_value_delete_${optionType}_${index}">
         <label for="add_color_${index}_value_display" class="sk-form-field__label">color</label>
         <p class="sk-error-message" id="add_color_${index}_value_display_error_message"></p>
       </div>
       <div class="sk-form-field">
         <input type="color" class="sk-form-field__input !w-[78px] ml-[16px] mr-[10px]" id="add_color_${index}_value"
         data-action="change->pro-options#punchOptionValue" data-option-type="${optionType}" 
         data-option-value-index="${index}" data-delete-id="option_value_delete_${optionType}_${index}">
         <i class="sk-form-field__icons material-icons-outlined cursor-pointer !text-[16px] text-black/[0.60] !top-[12px] !right-[18px]">expand_more</i>
       </div> 
       <i class="material-icons-outlined cursor-pointer text-black/[0.60] hover:bg-blue/[0.08] hover:rounded-full p-[8px]"
       data-option-type="${optionType}" data-index="${index}" data-action="click->pro-options#removeOptionValue"
       id="option_value_delete_${optionType}_${index}"
       data-value=""
       data-option-value-dom-id="add_value_${optionType}_${index}">delete</i>
    </div>`;

  return dom;
};
