import {Controller} from 'stimulus';

export default class extends Controller {
  static = ['session'];

  signOut() {
    // eslint-disable-next-line no-undef
    logOut((response => {
      if (response) {
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      }
    }));
  }
}