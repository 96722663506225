import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    window.addEventListener("scroll", this.scrollFunction.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.scrollFunction.bind(this));
  }

  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById('scrollTopBtn').style.display = "flex";
    } else {
      document.getElementById('scrollTopBtn').style.display = "none";
    }
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}