import {Controller} from 'stimulus';
import {popSnackbar} from "../services/snackbar";
import {I18n} from "../translation";
export default class extends Controller {
  submitRetailer(){
    document.getElementById('selection-retailer'), addEventListener('turbo:submit-end', async (event) => {
      if (event.detail.success && !event.detail.fetchResponse.redirected) {
        popSnackbar('notice', I18n[window.currentLocale]['retailer_success']);
      }
      else {
        popSnackbar('error', I18n[window.currentLocale]['retailer_unsuccessful']);
      }
    });
  }
}