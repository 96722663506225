import {Controller}  from 'stimulus';
import {popSnackbar} from '../services/snackbar';

export default class extends Controller {
  // outOfStockItems

  connect() {
    let items = JSON.parse(this.element.dataset.items);
    // eslint-disable-next-line no-undef
    gtag('event', 'view_cart', {
      currency: 'CHF',
      value: 30.00, // custom_metric
      items: items
    });

    // this.outOfStockItems = JSON.parse(this.element.dataset.outOfStockItems);
    //
    // if (this.outOfStockItems.length > 0) {
    //   this.outOfStockItems.forEach((item) => {
    //     document.getElementById(`out-of-stock-tag-${item.id}`).classList.remove('hidden');
    //   });
    // }
  }

  shippingAddressFormEvent() {
    // eslint-disable-next-line no-undef
    gtag('event', 'open_shipping_address_form', {});
  }

  beginCheckout(e) {
    let items = JSON.parse(e.currentTarget.dataset.items);
    // eslint-disable-next-line no-undef
    gtag('event', 'begin_checkout', {
      currency: 'CHF',
      value: 30.00, // custom_metric
      items: items
    });
  }

  payment(event){
    event.preventDefault();
    event.currentTarget.disabled = true;
    let form;
    let element1 = document.getElementById('content-1');
    let quantityController1 = this.application.getControllerForElementAndIdentifier(element1, 'quantity');
    const variant = quantityController1.variantId;
    const retailerId = quantityController1.retailerId;
    let element3 = document.getElementById('content-2');
    let quantityController3 = this.application.getControllerForElementAndIdentifier(element3, 'checkout');
    let additionalChecked = quantityController3.additionalAddress;
    let billingChecked = quantityController3.differentBilling;
    let registrationGuest = quantityController3.registration;
    let membershipId = event.currentTarget.dataset.membershipId;
    form = document.getElementById('address-form');
    let formData = new FormData(form);
    formData.append('variant_id', variant);
    formData.append('retailer_id', JSON.stringify(retailerId));
    if(JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
      formData.append('membership_id', membershipId);
    }
    if(window.location.search.includes('register=true') && registrationGuest){
      formData.append('register_user', "true");
    } else {
      formData.append('register_user', "false");
    }
    if(!additionalChecked && JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))){
      formData.append('phone', formData.get('membership_detail_attributes[phone]'));
      formData.append('city', formData.get('membership_detail_attributes[city]'));
      formData.append('street_name', formData.get('membership_detail_attributes[street_name]'));
      formData.append('zipcode', formData.get('membership_detail_attributes[zipcode]'));
      formData.append('first_name', formData.get('membership_detail_attributes[first_name]'));
      formData.append('last_name', formData.get('membership_detail_attributes[last_name]'));
      formData.append('email', formData.get('membership_detail_attributes[email]').toLowerCase());
      formData.append('gender', formData.get('membership_detail_attributes[gender]'));
      formData.append('membership_detail_attributes[dob]', formData.get('dob'));
    }
    if(billingChecked) {
      let billingForm = document.getElementById('address-form-billing')
      let billingFormData = new FormData(billingForm);
      formData.append('billing_address[phone]', billingFormData.get('billing_address[phone]'));
      formData.append('billing_address[company]', billingFormData.get('billing_address[company]'));
      formData.append('billing_address[country]', billingFormData.get('billing_address[country]'));
      formData.append('billing_address[city]', billingFormData.get('billing_address[city]'));
      formData.append('billing_address[street_name]', billingFormData.get('billing_address[street_name]'));
      formData.append('billing_address[zipcode]', billingFormData.get('billing_address[zipcode]'));
      formData.append('billing_address[first_name]', billingFormData.get('billing_address[first_name]'));
      formData.append('billing_address[last_name]', billingFormData.get('billing_address[last_name]'));
      formData.append('billing_address[state]', billingFormData.get('billing_address[state]'));
    }
    let cleanedFormData = this.removeEmptyFromFormData(formData);

    fetch('/orders', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: cleanedFormData
    }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          window.location.href = data.redirect;
        });
      } else {
        response.json().then(data => {
          popSnackbar('error', data.error);
          window.location.href = data.redirect;
        });
      }
    });
  }

  removeEmptyFromFormData(formData) {
    let newFormData = new FormData();

    for (let [key, value] of formData.entries()) {
      if (value !== null && value !== '' && value !== undefined) {
        newFormData.append(key, value);
      }
    }

    return newFormData;
  }

  login() {
    window.location.href = `users/sign_in?membership=${JSON.parse(sessionStorage.getItem('purchaseMembershipOn'))}&redirect_to=${window.location.pathname}`;
  }
}
