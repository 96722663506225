import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {I18n} from '../translation';
import {LOCALES} from '../constants/constants';

export default class extends Controller {

  formData = {
    ribbonLocales: {
      en: '',
      fr: '',
      de: '',
    },
    colour: ''
  };

  modalId = '';
  localeChips = document.getElementById('addRibbon').querySelectorAll('[class*="locale-chip"]');
  currentLocale = 'en';
  languageErrorTriggered = false;

  copyToAllLocale() {
    document.getElementById('copyCategoryContent').removeAttribute('disable');
  }

  handleInput(event) {
    this.formData.ribbonLocales[this.currentLocale] = event.target.value;
    freeFieldValidation(event.target, `${event.target.id}_error_message`);
  }

  switchLocale(event) {
    this.currentLocale = event.currentTarget.dataset.locale;

    let data = this.formData.ribbonLocales[this.currentLocale]; // prefill if data already present.
    document.getElementById('add_ribbon_name_input').value = data || ''; // clear field for other locale.

    this.updateStyles(this.currentLocale);
  }

  setColour(event) {
    const color = JSON.parse(event.currentTarget.dataset.ribbonColor);
    this.formData.colour = color.hex;
    document.getElementById('add_ribbon_color_input').value = color.name;
    event.currentTarget.closest('form').querySelector('#ribbon-color-display').style.backgroundColor = color.hex;
    document.getElementById('add_ribbon_color_input_error_message').innerHTML = '';
  }

  updateStyles(locale) {
    this.localeChips.forEach(res => {
      if(res.id === locale){
        res.classList.remove('sk-language-inactive');
        res.classList.add('sk-language-active');
      } else {
        res.classList.remove('sk-language-active');
        res.classList.add('sk-language-inactive');
      }
    });
  }

  duplicateAll() {
    this.copyCategoryName();
    if (this.duplicated(this.currentLocale)) {
      popSnackbar('notice', I18n[window.currentLocale]['content_duplication_success_notice']);
      this.removeCopyValidator();
    } else {
      popSnackbar('error', I18n[window.currentLocale]['content_duplication_success_error']);
    }
  }

  copyCategoryName() {
    Object.keys(this.formData.ribbonLocales).forEach(locale => {
      if (locale !== this.currentLocale) {
        this.formData.ribbonLocales[locale] = this.formData.ribbonLocales[this.currentLocale];
      }
    }, this);
  }

  duplicated(currentLocale) {
    return Object.keys(this.formData.ribbonLocales).every((locale) => {
      return this.formData.ribbonLocales[locale] === this.formData.ribbonLocales[currentLocale];
    }, this);
  }

  async createRibbon(){
    const response = await fetch('/admin/ribbons', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify( { ...this.formData.ribbonLocales, color: this.formData.colour } )
    });
    if (response.status === 200) {
      const text = await response.text();
      const labelCountDom = document.getElementById('product-labels-count');
      let vDom = document.createElement('div');
      vDom.innerHTML = text;
      document.getElementById('product_labels').replaceWith(vDom.children[0]);
      labelCountDom.innerText = (+labelCountDom.innerText + 1).toString();
      popSnackbar('notice', 'Ribbon Created');
    } else {
      popSnackbar('error', 'Ribbon Creation Failed');
    }
  }

  validateFormAndSave() {
    const form = document.getElementById('add-ribbon-form');
    let valid = true;
    form.querySelectorAll('input').forEach(input => {
      const errorElementId = input.parentElement.querySelector('p[class*=\'error-message\']').id;
      valid = freeFieldValidation(input, errorElementId) && valid;
    });

    LOCALES.every(locale => !!this.formData.ribbonLocales[locale]) && valid ? this.saveRibbon() : this.validateCopyError();
  }

  async saveRibbon() {
    this.createRibbon();
    document.getElementById('cancel-ribbon-createBtn').click();
  }

  validateCopyError() {
    Object.keys(this.formData.ribbonLocales).forEach(res => {
      if(!this.formData.ribbonLocales[res]){
        popSnackbar('error', 'You need to fill for all Locales!');
      }
    });
  }

  removeCopyValidator() {
    this.languageErrorTriggered = false;
    const errorInfo = document.getElementById('language-switcher_addRibbon').querySelector('[class*="text-error"]');
    if (errorInfo) {
      errorInfo.remove();
    }
  }

  cancelAndResetModal(event) {
    this.currentLocale = 'en';
    event.currentTarget.closest('form').querySelector('#ribbon-color-display').style.backgroundColor = '';
    this.resetFormData();
    this.removeValidations();
  }

  removeValidations() {
    this.removeCopyValidator();
    document.getElementById('add_ribbon_name_input_error_message').innerHTML = '';
    document.getElementById('add_ribbon_color_input_error_message').innerHTML = '';
    this.updateStyles('en');
  }

  resetFormData() {
    this.formData = {
      ribbonLocales: {
        en: '',
        fr: '',
        de: '',
      },
    };
    this.languageErrorTriggered = false;
  }
}
