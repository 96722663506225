import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    setTimeout(closeSnackbar, 4000);

    function closeSnackbar() {
      const snackBar = document.getElementById('close_snackbar');
      if (snackBar) snackBar.click();
    }
  }

  close() {
    document.getElementById('snackbar').classList.add('hidden');
  }
}
