import {Controller} from 'stimulus';
import {PromotionService} from '../services/promotion_service';

export default class extends Controller {
  count;
  connect() {
    this.count = this.element.dataset.count;
    let element = document.getElementById('trigger_add-promotion_controller');
    this.application.getControllerForElementAndIdentifier(element, 'add-promotion').submittable();
  }

  remove(event) {
    document.getElementById(event.currentTarget.dataset.div_id).remove();
    this.count -= 1;
    let element = document.getElementById('trigger_add-promotion_controller');
    if (event.currentTarget.dataset.div_id.includes('product')) new PromotionService().updateCouponCountDisplay();
    const addPromotionController = this.application.getControllerForElementAndIdentifier(element, 'add-promotion');
    addPromotionController.removeValidCount('coupon_quantity_applicable');
    addPromotionController.submittable();
  }
}
