import {Controller} from 'stimulus';

export default class extends Controller {
  filterForm = document.getElementById('filter-form');
  filterCountDom = document.getElementById('filters-applied');
  filterApplied = 0;

  connect() {
    let inputs = this.filterForm.querySelectorAll('.fltr_checkbox');

    let _this = this;
    inputs.forEach(input => {
      input.addEventListener('input', (event) => {
        if (event.target.checked) {
          _this.filterApplied++;

        } else {
          _this.filterApplied--;
        }
      });
    });
  }

  setActiveFilterCount() {
    this.filterCountDom.innerHTML = this.filterApplied === 0 ? '' : this.filterApplied;
  }

  clearFilters() {
    this.filterCountDom.innerHTML = '';
    this.filterApplied = 0;
  }
}