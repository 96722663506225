import {setMessage} from './base_service';
import {I18n} from '../../translation';

export const emailValidation = function (input, id, showError  = true) {
  let emailRegex = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (input.value.match(emailRegex)) {
    setMessage(id);
    return true;
  } else {
    if(showError){
      if (input.value.trim()) {
        setMessage(id, I18n[window.currentLocale]['invalid_email']);
      } else {
        setMessage(id, I18n[window.currentLocale]['field_required']);
      }
    }
    return false;
  }
};
