import {Controller} from 'stimulus';
import {
  colorOptionValueDom,
  customOptionValueDom,
  customOptionValuesGroupDom
} from '../services/templates/products/preview/preview-components';

export default class extends Controller {
  activeLocale = 'en';
  targetForm = {};

  connect() {
    let productNameField = document.getElementById('preview-product-name');
    let productDescriptionField = document.getElementById('product_description_editor');
    let normalPriceField = document.getElementById('preview-normal-price');
    let officialMemberPriceField = document.getElementById('preview-official-member-price');

    if(window.location.pathname.includes('new')) {
      this.targetForm = document.getElementById('new_product');
    } else {
      const productId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
      this.targetForm = document.getElementById(`edit_product_${productId}`);
    }

    // prefill data if edit
    if(window.location.pathname.includes('edit')) {
      productNameField.innerText = this.targetForm.querySelector(`#product_name_${this.activeLocale}`).value;
      productDescriptionField.innerText = this.targetForm.querySelector(`#product_description_${this.activeLocale}`).value;
      normalPriceField.innerText = `CHF ${document.getElementById('normal_product_price').value}`;
      officialMemberPriceField.innerText = `CHF ${document.getElementById('official_member_product_price').value}`;
    }

    this.targetForm.querySelector('#language-switcher').addEventListener('click', event => {
      this.activeLocale = event.target.innerText;
    });

    // product name
    this.targetForm.querySelector(`#product_name_${this.activeLocale}`).addEventListener('input', (event) => {
      productNameField.innerHTML = event.target.value;

      if (event.target.value === '') {
        productNameField.innerHTML = 'Product Name';
      }
    });

    // product description
    this.targetForm.querySelector(`#product_description_${this.activeLocale}`).addEventListener('input', (event) => {
      productDescriptionField.innerHTML = event.target.value;

      if (event.target.value === '') {
        productDescriptionField.innerHTML = 'Product Description';
      }
    });

    // product normal price
    document.getElementById('normal_product_price').addEventListener('input', (event) => {
      normalPriceField.innerHTML = `CHF ${event.target.value}`;

      if (event.target.value === '') {
        normalPriceField.innerHTML = 'CHF 00.00';
      }
    });

    // product official member price
    document.getElementById('official_member_product_price').addEventListener('input', (event) => {
      officialMemberPriceField.innerHTML = `CHF ${event.target.value}`;

      if (event.target.value === '') {
        officialMemberPriceField.innerHTML = 'CHF 00.00';
      }
    });

    // option values
    document.getElementById('add-options').addEventListener('click', () => {
      document.getElementById('preview-option-values').innerHTML = ''; // avoid duplication

      const optionValueController = this.application.getControllerForElementAndIdentifier(this.targetForm, 'pro-options');
      const optionModalLanguageSwitcher = document.getElementById('pcOptionModal');
      let previewOptionValuesDom = document.getElementById('preview-option-values');
      let activeLocale = optionModalLanguageSwitcher.querySelector('.active-locale').dataset.locale;
      let targetOptions = optionValueController.dataParam[activeLocale];
      let colorOptions = optionValueController.colorsPayload;

      if (this.isEmptyHash(targetOptions) && colorOptions.length > 0){
        colorOptions.forEach((color) => {
          let tmpDom = document.createElement('div');
          tmpDom.innerHTML = customOptionValuesGroupDom('color');

          previewOptionValuesDom.appendChild(tmpDom.firstChild);
          previewOptionValuesDom.querySelector('#option-selectors-for-color').innerHTML = 'Select color';

          const optionValueDom = colorOptionValueDom(color['hexcode']);
          let tmpDom1 = document.createElement('div');
          tmpDom1.innerHTML = optionValueDom;

          previewOptionValuesDom.querySelector('#preview-product-color').appendChild(tmpDom1.firstChild);
        });
      }

      Object.keys(targetOptions).forEach((optionType) => {
        let tmpDom = document.createElement('div');
        tmpDom.innerHTML = customOptionValuesGroupDom(optionType);

        previewOptionValuesDom.appendChild(tmpDom.firstChild);
        previewOptionValuesDom.querySelector(`#option-selectors-for-${optionType}`).innerHTML = `Select ${optionType}`;

        targetOptions[optionType].forEach((optionValue) => {
          const optionValueDom = optionType === 'Color' ? colorOptionValueDom(optionValue.value.split(', ').at(-1) || '#000000') : customOptionValueDom(optionValue.name); // don't use fallback
          let tmpDom = document.createElement('div');
          tmpDom.innerHTML = optionValueDom;

          previewOptionValuesDom.querySelector(`#preview-product-${optionType}`).appendChild(tmpDom.firstChild);
        });
      });
    });
  }

  isEmptyHash(obj){
    return Object.keys(obj).length === 0;
  }

  previewSelected(e) {
    let mainPreviewImage = document.getElementById('main_preview_image');
    let selectedImage = document.getElementById(e.currentTarget.dataset.imageId);

    mainPreviewImage.src = selectedImage.src;
  }
}
