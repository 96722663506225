import {Controller} from 'stimulus';

export default class extends Controller {
  allCheckBox = document.querySelectorAll('.inventory-check-box');
  checkedInventoryCount = 0;
  inventoryCount;
  inventorySelected = [];
  completeSelection = false;

  connect() {
    let _this = this;
    document.getElementById('inventory_indeterminate_checkbox').indeterminate = true;
    this.inventoryCount = this.element.dataset.inventory_count;
    for (let i = 0; i < this.allCheckBox.length; i++) {
      this.allCheckBox[i].addEventListener('click', function () {
        if (_this.inventorySelected.length === +_this.inventoryCount) {
          _this.inventorySelected = [];
          _this.selectAll(false);
        }
        if (this.checked) {
          _this.addInventoryId(parseInt(this.dataset.inventoryId));
          _this.highlightInventory(`individual_inventory_container_${i}`);
          _this.checkedInventoryCount += 1;
        } else {
          _this.removeInventoryId(parseInt(this.dataset.inventoryId));
          _this.removeHighlight(`individual_inventory_container_${i}`);
          if (_this.checkedInventoryCount > 0) _this.checkedInventoryCount -= 1;
        }
        _this.updateContainer();
        _this.selectAllButton();
      });
    }
  }

  selectAll(event) {
    if (event === true || (event && event.currentTarget.checked)) {
      this.completeSelection = true;
      const connectDom = document.getElementById('inventory-selection-controller-element');
      this.inventorySelected = JSON.parse(connectDom.dataset.variantIds);
      for (let i = 0; i < this.allCheckBox.length; i++) {
        this.allCheckBox[i].checked = true;
        this.highlightInventory(`individual_inventory_container_${i}`);
      }
      this.checkedInventoryCount = this.inventoryCount;
    } else {
      this.inventorySelected = [];
      for (let i = 0; i < this.allCheckBox.length; i++) {
        this.allCheckBox[i].checked = false;
        this.removeHighlight(`individual_inventory_container_${i}`);
      }
      this.checkedInventoryCount = 0;
    }
    this.updateContainer();
  }

  uncheckInventory(event) {
    if (event.currentTarget.checked) {
      for (let i = 0; i < this.allCheckBox.length; i++) {
        this.allCheckBox[i].checked = false;
        this.removeHighlight(`individual_inventory_container_${i}`);
        this.removeInventoryId(parseInt(this.allCheckBox[i].dataset.inventoryId));
      }
      this.checkedInventoryCount = 0;
    }
    this.updateContainer();
    this.selectAllButton();
    event.currentTarget.click();
    document.getElementById('inventory_indeterminate_checkbox').indeterminate = true;
  }

  addInventoryId(id) {
    if (!this.inventorySelected.includes(id)) {
      this.inventorySelected.push(id);
    }
  }

  removeInventoryId(id) {
    if (this.inventorySelected.includes(id)) {
      this.inventorySelected.splice(this.inventorySelected.indexOf(id), 1);
    }
  }

  updateContainer() {
    this.checkedInventoryCount > 0 ? this.viewUpdateContainer() : this.hideUpdateContainer();
  }

  viewUpdateContainer() {
    document.getElementById('inventory_update_container').classList.remove('hidden');
    document.getElementById('inventory_selected_count').innerHTML = `${this.checkedInventoryCount} selected`;
  }

  hideUpdateContainer() {
    document.getElementById('inventory_update_container').classList.add('hidden');
  }

  highlightInventory(id) {
    document.getElementById(id).classList.add('bg-blue/[0.08]');
  }

  removeHighlight(id) {
    document.getElementById(id).classList.remove('bg-blue/[0.08]');
  }

  selectAllButton() {
    this.checkedInventoryCount === parseInt(this.inventoryCount) ? this.checkSelectAllButton() : this.uncheckSelectAllButton();
  }

  checkSelectAllButton() {
    document.getElementById('select_all_inventory').checked = true;
  }

  uncheckSelectAllButton() {
    document.getElementById('select_all_inventory').checked = false;
  }

  restock(event) {
    document.getElementById(event.currentTarget.dataset.constantId).classList.add('hidden');
    document.getElementById(event.currentTarget.dataset.editId).classList.remove('hidden');
  }

  cancelRestock(event) {
    document.getElementById(event.currentTarget.dataset.constantId).classList.remove('hidden');
    document.getElementById(event.currentTarget.dataset.editId).classList.add('hidden');
  }

  increaseStock(event) {
    let initialQuantity = event.currentTarget.dataset.value;
    let index = event.currentTarget.dataset.index;
    let countElement = document.getElementById(event.currentTarget.dataset.editStockCountId);
    countElement.value = parseInt(countElement.value) + 1;
    document.getElementById(`stock_save_button_${index}`).disabled = countElement.value === initialQuantity;
  }

  decreaseStock(event) {
    let initialQuantity = event.currentTarget.dataset.value;
    let index = event.currentTarget.dataset.index;
    let countElement = document.getElementById(event.currentTarget.dataset.editStockCountId);
    if (parseInt(countElement.value) > 0) {
      countElement.value = parseInt(countElement.value) - 1;
    }
    document.getElementById(`stock_save_button_${index}`).disabled = countElement.value === initialQuantity;
  }

  bulkRestock(event) {
    let state = event.currentTarget.dataset.state;
    fetch(`/admin/inventories/selection?variants=${this.inventorySelected}&state=${state}`).then(response => {
      response.text().then(text => {
        document.getElementById('restock_modal_replace').innerHTML = text;
      });
    });
  }

  inputQuantity(event) {
    let initialQuantity = event.currentTarget.dataset.value;
    let index = event.currentTarget.dataset.index;
    document.getElementById(`stock_save_button_${index}`).disabled = event.currentTarget.value === initialQuantity;
  }

  download() {
    const url = `/admin/inventories/download?variants=${JSON.stringify(this.inventorySelected)}`;
    window.open(`${location.origin}${url}`, '_blank');
  }
}
