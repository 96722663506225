import {Controller} from 'stimulus';

export default class extends Controller {
  sortType;
  sortValue;

  sort(event) {
    this.sortType = event.currentTarget.dataset.sort_id;
    this.sortValue = event.currentTarget.id;
    this.applySort(event);
  }

  clear(event) {
    this.sortType = this.sortValue = undefined;
    this.applySort(event);
  }

  sortClassList(event) {
    ['sort_by_newest_id', 'sort_by_oldest_id', 'sort_by_latest_order_date', 'sort_by_oldest_order_date',
      'sort_by_customer_A_to_Z', 'sort_by_customer_Z_to_A', 'sort_by_status_A_to_Z', 'sort_by_status_Z_to_A',
      'sort_by_highest_price', 'sort_by_lowest_price', 'sort_by_highest_product_count', 'sort_by_lowest_product_count',
      'sort_by_highest_total_amount', 'sort_by_lowest_total_amount'].forEach(id => {
      document.getElementById(`${id}_check`)?.classList[(id === event.target.id)? 'remove' : 'add']('!hidden');
      document.getElementById(id)?.classList[(id === event.target.id) ? 'add' : 'remove']('bg-blue/[0.08]');
    });
  }

  clearButtonClassList(event) {
    ['sort_by_id', 'sort_by_order_date', 'sort_by_customer', 'sort_by_status', 'sort_by_purchase_price',
      'sort_by_quantity', 'sort_by_total_amount'].forEach(id => {
      document.getElementById(`${id}_clear`)?.classList[(id === event.target.dataset.sort_id)? 'remove' : 'add']('hidden');
    });
  }

  applySort(event) {
    let element = document.getElementById('orderslider');
    let orderFilter = this.application.getControllerForElementAndIdentifier(element, 'order-filter');
    let url = `/admin/search/filter-orders?filters=${JSON.stringify(orderFilter.filters)}&sort_type=${this.sortType}&sort_value=${this.sortValue}&state=${this.orderState() || 'all'}`;
    fetch(url).then(response => {
      response.text().then(text => {
        document.getElementById('order-listing').innerHTML = text;
        this.sortClassList(event);
        this.clearButtonClassList(event);
      });
    });
  }

  orderState() {
    const states = ['all', 'order_placed', 'ready_for_dispatch', 'in_transit', 'delivered', 'canceled', 'complete', 'returned'];
    for (let i in states) {
      if (window.location.href.includes(states[i])) return states[i];
    }
  }
}
