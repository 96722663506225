import {Controller} from 'stimulus';

export default class extends Controller {
  allStockQuantity = document.querySelectorAll('.individual_stock_quantity');
  allStockQuantityId = [];

  connect() {
    let _this = this;
    for (let i = 0; i < this.allStockQuantity.length; i++) {
      this.allStockQuantity[i].addEventListener('input', function () {
        _this.afterInput(this);
        _this.updatable();
      });
    }
  }

  afterInput(input) {
    if (input.value) {
      this.addId(input);
    } else {
      this.removeId(input);
    }
  }

  addId(input) {
    if (!this.allStockQuantityId.includes(input.id)) {
      this.allStockQuantityId.push(input.id);
    }
  }

  removeId(input) {
    if (this.allStockQuantityId.includes(input.id)) {
      this.allStockQuantityId.splice(this.allStockQuantityId.indexOf(input.id), 1);
    }
  }

  updatable() {
    document.getElementById('restock_button').disabled =
        this.allStockQuantityId.length !== this.allStockQuantity.length;
  }

  bulkInput(event) {
    document.getElementById('bulk_add_button').disabled = parseInt(event.currentTarget.value) < 0;
  }

  bulkAdd() {
    let bulkQuantity = document.getElementById('bulk_input_quantity').value;
    this.allStockQuantityId = [];
    for (let i = 0; i < this.allStockQuantity.length; i++) {
      this.allStockQuantity[i].value = bulkQuantity;
      this.afterInput(this.allStockQuantity[i]);
    }
    this.updatable();
    document.getElementById('bulk_input_quantity').value = '';
    document.getElementById('bulk_add_button').disabled = true;
  }

  increaseQuantity(event) {
    let inputElement = document.getElementById(event.currentTarget.dataset.bulkInputId);
    inputElement.value = parseInt(inputElement.value) + 1;
  }

  decreaseQuantity(event) {
    let inputElement = document.getElementById(event.currentTarget.dataset.bulkInputId);
    if (parseInt(inputElement.value) > 0) {
      inputElement.value = parseInt(inputElement.value) -1;
    }
  }
}