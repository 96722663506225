import { Controller } from 'stimulus';
import { popSnackbar } from '../services/snackbar';

export default class extends Controller {
  updateProfile() {
    document.getElementById('profile-update-form').addEventListener('turbo:submit-end', (e) => {
      if(e.detail.fetchResponse.response){
        let profileName = document.getElementById('my_full_name');
        let firstName = document.getElementById('edit_first_name').value;
        let lastName = document.getElementById('edit_last_name').value;
        profileName.innerHTML =  `${firstName} ${lastName}`;

        document.getElementById('cancel-profile-update').click();
        popSnackbar('notice', 'Profile update successful.');
      } else {
        popSnackbar('error', 'Error updating profile.');
      }
    });
  }
}