import {Controller} from 'stimulus';

export default class extends Controller {

  showConfigurator(event) {
    const id = event.currentTarget.dataset.bsTarget;
    document.getElementById('ribbon-configurator').classList[id.includes('#tabs-product-labels') ? 'remove' : 'add']('hidden');
    document.getElementById('slider-filter-button').classList[id.includes('#tabs-product-labels') ? 'add' : 'remove']('hidden');
  }
}
