import {Controller} from "stimulus";

export default class extends Controller {
  currentStatus = "all"

  selectOrderOption(query,selectedStatus) {
    let url = `/search/filter-user-orders?q=${query}&filters=${selectedStatus}`;
    fetch(url).then(response => {
      response.text().then(text => {
        document.getElementById('accordionMyOrder').innerHTML = text;
      });
    });
  }

  selectStatus(event){
    let selectedValue = event.currentTarget.dataset.value;
    this.currentStatus = event.currentTarget.dataset.actual_value;
    document.getElementById(event.currentTarget.dataset.inputId).value = selectedValue;
    this.selectOrderOption(' ',this.currentStatus)
  }

  searchQuery(event){
    let query = event.currentTarget.value
    this.selectOrderOption(query,this.currentStatus)
  }
}
